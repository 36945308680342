import { AuthTypes } from '~/store/ducks/Auth/types';

export const login = (code: string = null, token: any = null) => ({
  type: AuthTypes.LOGIN,
  payload: {
    code,
    token,
  },
});

export const storeToken = (data: any) => ({
  type: AuthTypes.STORE_TOKEN,
  payload: {
    data,
  },
});

export const removeToken = () => ({
  type: AuthTypes.LOGOUT,
});

export const logout = () => ({
  type: AuthTypes.LOGOUT,
});
