import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const EquipmentsSystemContainer = styled.section`
  .block {
    .room__checked {
      position: absolute;
      left: 5px;
      color: ${colors.green};
    }

    .block__system__grid {
      width: 100%;
      padding: 10px;
      min-height: 70px;
      position: relative;
      margin-bottom: 15px;

      .block__system__title {
        margin-bottom: 0;
        color: ${colors.primary};
        font-size: 14px;
        line-height: 1.2;
        text-align: center;
      }

      .block__system__subtitle {
        color: ${colors.gray100};
        font-size: 12px;
        margin-bottom: 0;
      }

      .block__system__image {
        width: 120px;
        height: 50px;

        @media ${devices.tablet} {
          width: 100px;
          height: 50px;
        }
      }

      .block__system__grid--item {
        &.room__name {
          grid-column-start: 1;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 1;
          padding: 0 25px;

          @media ${devices.tablet} {
            padding: 0 15px;
            grid-column-start: 1;
            grid-column-end: 3;
            order: 1;
          }
        }

        &.room__image {
          grid-column-start: 1;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 3;

          @media ${devices.tablet} {
            grid-column-start: 3;
            grid-column-end: 5;
            order: 2;
          }

          svg {
            font-size: 45px;
          }
        }

        &.room__equipment {
          grid-column-start: 1;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 3;

          @media ${devices.tablet} {
            grid-column-start: 5;
            grid-column-end: 7;
            order: 3;
          }
        }

        &.room__sku {
          grid-column-start: 1;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 4;

          @media ${devices.tablet} {
            grid-column-start: 7;
            grid-column-end: 9;
            order: 4;
          }
        }

        &.room__capacity {
          grid-column-start: 1;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 4;

          @media ${devices.tablet} {
            grid-column-start: 9;
            grid-column-end: 10;
            order: 5;
          }
        }

        &.room__dt {
          grid-column-start: 1;
          grid-column-end: 6;
          justify-self: center;
          align-self: center;
          order: 5;

          @media ${devices.tablet} {
            grid-column-start: 10;
            grid-column-end: 11;
            order: 6;
          }
        }

        &.room__heatload {
          grid-column-start: 6;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 6;

          @media ${devices.tablet} {
            grid-column-start: 11;
            grid-column-end: 12;
            order: 7;
          }
        }

        &.room__action {
          grid-column-start: 12;
          grid-column-end: 13;
          justify-self: end;
          align-self: center;
          order: 6;
          position: absolute;
          top: 10px;
          right: 5px;

          @media ${devices.tablet} {
            position: initial;
            grid-column-start: 12;
            grid-column-end: 13;
            order: 8;
          }
        }

        &.condensing-unit__image {
          grid-column-start: 1;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 1;

          @media ${devices.tablet} {
            grid-column-start: 1;
            grid-column-end: 3;
          }

          svg {
            font-size: 45px;
          }
        }

        &.condensing-unit__equipment {
          grid-column-start: 1;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 2;

          @media ${devices.tablet} {
            grid-column-start: 3;
            grid-column-end: 6;
          }
        }

        &.condensing-unit__sku {
          grid-column-start: 1;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 3;

          @media ${devices.tablet} {
            grid-column-start: 6;
            grid-column-end: 8;
          }
        }

        &.condensing-unit__dt {
          grid-column-start: 1;
          grid-column-end: 6;
          justify-self: center;
          align-self: center;
          order: 4;

          @media ${devices.tablet} {
            grid-column-start: 8;
            grid-column-end: 10;
          }
        }

        &.condensing-unit__heatload {
          grid-column-start: 6;
          grid-column-end: 13;
          justify-self: center;
          align-self: center;
          order: 5;

          @media ${devices.tablet} {
            grid-column-start: 10;
            grid-column-end: 12;
          }
        }

        &.condensing-unit__action {
          grid-column-start: 12;
          grid-column-end: 13;
          justify-self: end;
          align-self: center;
          order: 6;
          position: absolute;
          top: 10px;
          right: 5px;

          @media ${devices.tablet} {
            position: initial;
            grid-column-start: 12;
            grid-column-end: 13;
          }
        }
      }
    }

    .block__condensing-unit {
      min-height: 70px;
    }
  }

  p {
    font-size: 14px;
    color: ${colors.gray100};
    font-weight: 500;

    &.block__system--title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 25px;
      color: ${colors.gray100};
    }

    &.block__system--info {
      margin: 3px 0;
      font-weight: 400;
    }
  }

  .form {
    .formControl {
      margin-bottom: 15px;
    }
  }
`;
