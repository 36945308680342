import { Reducer } from 'redux';
import { HeatTransferTypes, HeatTransferState } from './types';

const DEFAULT_MATERIAL = { id: 10 };
const DEFAULT_WALL = {
  incidentSolarRadiation: false,
  thickness: 0,
  material: DEFAULT_MATERIAL,
  temperature: 0,
};

export const INITIAL_STATE: HeatTransferState = {
  ceiling: DEFAULT_WALL,
  floor: DEFAULT_WALL,
  walls: [DEFAULT_WALL, DEFAULT_WALL, DEFAULT_WALL, DEFAULT_WALL],
};

const reducer: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HeatTransferTypes.STORE_DATA:
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

export default reducer;
