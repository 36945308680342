/* eslint-disable no-console */
import React, { useState } from 'react';

import {
  Box,
  SimpleGrid,
  GridItem,
  Stack,
  ButtonGroup,
  HStack,
  VStack,
  Skeleton,
  Text,
  Button as ChakraButton,
  Image,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Switch,
  Table,
  Tbody,
  Tr,
  Td,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { MdSearch } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import { toastError } from '~/helpers/toast';
import { colors } from '~/styles/colors';
import Yup from '~/helpers/validations';
import SelectGivenOptions from '~/components/Form/Select/SelectGivenOptions';
import { Compressor, Evaporator } from '~/store/ducks/Equipment/types';
import { selectCompressor } from '~/store/ducks/Equipment/actions';
import EquipmentService from '~/store/ducks/Equipment/services';
import EquipmentTable from '~/components/Equipments/EquipmentTable';
import TextArea from '~/components/Form/TextArea';
import { parseValue2Decimal, parseValueInteger } from '~/helpers/functions';

const schemaFilter = Yup.object().shape({
  count: Yup.number().nullable().required(),
  loadLoss: Yup.number().nullable().required(),
  ambientTemperature: Yup.number().nullable(),
  compressorType: Yup.string().nullable(),
  fairing: Yup.string().nullable(),
  hotGasDefrost: Yup.string().nullable(),
  capacityControl: Yup.string().nullable(),
  range: Yup.number().positive().nullable().required(),
});

const schemaSpecial = Yup.object().shape({
  model: Yup.string().nullable().required(),
  count: Yup.number().nullable().required(),
  loadLoss: Yup.number().nullable().required(),
  ambientTemperature: Yup.number().nullable().required(),
  condensingTemperature: Yup.number().nullable().required(),
  compressorType: Yup.string().nullable().required(),
  fairing: Yup.string().nullable().required(),
  hotGasDefrost: Yup.string().nullable().required(),
  capacityControl: Yup.string().nullable().required(),
  maxPower: Yup.number().nullable().required(),
  realTimePower: Yup.number().nullable().required(),
  unitCapacity: Yup.number().nullable().required(),
  rejectedHeat: Yup.number().nullable().required(),
  commercialDescription: Yup.string().nullable().required(),
});

const equipmentProfiles = [
  {
    value: 1,
    label: 'Baixo Perfil',
  },
  {
    value: 2,
    label: 'Médio Perfil',
  },
  {
    value: 3,
    label: 'Alto Perfil',
  },
];

const ModalCompressor = ({ isCompressorOpen, onCompressorClose, system }) => {
  const dispatch = useDispatch();
  const [CompressorProfile, setCompressorProfile] = useState<any>({ value: 1 });
  const [compressors, setCompressors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompressor, setSelectedCompressor] = useState<Compressor>(null);
  const [specialEnabled, setSpecialEnabled] = useState(true);

  const initialValuesFilter = {
    count: 1,
    loadLoss: null,
    ambientTemperature: null,
    compressorType: null,
    fairing: null,
    hotGasDefrost: null,
    capacityControl: null,
    range: 5,
  };

  const initialValuesSpecial = {
    model: system.compressor?.equipment.model || null,
    count: system.compressor?.equipment.count || 1,
    unitCapacity: system.compressor?.equipment.unitCapacity || 0,
    loadLoss: system.compressor?.equipment?.productCustom.loadLoss || null,
    ambientTemperature: system.compressor?.equipment?.productCustom.ambientTemperature || null,
    condensingTemperature: system.compressor?.equipment?.productCustom.condensingTemperature || null,
    compressorType: system.compressor?.equipment?.productCustom.compressorType || null,
    fairing: system.compressor?.equipment?.productCustom.fairing || null,
    hotGasDefrost: system.compressor?.equipment?.productCustom.hotGasDefrost || null,
    capacityControl: system.compressor?.equipment?.productCustom.capacityControl || null,
    maxPower: system.compressor?.equipment?.productCustom.maxPower || null,
    realTimePower: system.compressor?.equipment?.productCustom.realTimePower || null,
    rejectedHeat: system.compressor?.equipment?.productCustom.rejectedHeat || null,
    commercialDescription: system.compressor?.equipment?.productCustom.commercialDescription || null,
  };

  const getcompressors = async (data) => {
    setLoading(true);
    try {
      const response = await EquipmentService.getCompressor(data);
      setCompressors(response.data);
      setLoading(false);
    } catch (err) {
      toastError('project', 'Erro ao recuperar compressores.');
      console.error(err);
    }
  };

  const handleCompressorProfileChange = async (profile) => {
    await getcompressors(null);
    setCompressorProfile(profile);
  };

  const handleSelectCompressor = (compressor: Compressor): void => {
    setSelectedCompressor(compressor);
  };

  const handleCompressorModalClose = (): void => {
    setCompressors([]);
    // setSpecialEnabled(false);
    onCompressorClose();
  };

  const handleCompressorSearch = (values): void => {
    getcompressors(values);
    setCompressorProfile({ value: 1 });
  };

  const handleSubmitCompressor = (): void => {
    const selectedCondUnit = compressors.find((e: Evaporator) => e.id === selectedCompressor.id);
    dispatch(selectCompressor(selectedCondUnit));
    setCompressors([]);
    onCompressorClose();
  };

  const handleChangeSpecial = (): void => {
    setSpecialEnabled(!specialEnabled);
  };

  const handleSubmitSpecial = (customCompressor) => {
    const { count, model, consumedPower, heatLoad, unitCapacity } = customCompressor;

    const special = {
      count,
      model,
      consumedPower,
      heatLoad,
      unitCapacity,
      nominalUnitCapacity: unitCapacity,
      specialEquipment: true,
      productCustom: {
        ...customCompressor,
      },
    };

    dispatch(selectCompressor(special));
    setCompressors([]);
    onCompressorClose();
  };

  const calcTotalCapacity = (count, unitCapacity): number => {
    const result = count * unitCapacity;
    return parseValueInteger(result);
  };

  const calcRequiredCapacity = (): number => {
    if (system.configurations.length === 0) return 0;

    const requiredCapacity = system.configurations.reduce(
      (acc, configuration) => acc + configuration.room.heatLoad.totalKcalh,
      0
    );

    return parseValueInteger(requiredCapacity);
  };

  const calcCapacityLooseness = (count, unitCapacity): number => {
    const requiredCapacity = calcRequiredCapacity();
    const totalCapacity = count * unitCapacity;

    if (totalCapacity === 0) return 0;
    // const capacityLooseness = 1 - requiredCapacity / totalCapacity;
    const capacityLooseness = (totalCapacity / requiredCapacity) * 100;
    return parseValueInteger(capacityLooseness) - 100;
  };

  return (
    <Modal isOpen={isCompressorOpen} size="5xl" onClose={handleCompressorModalClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent className="modal__select">
        <ModalHeader>
          <Text color={colors.secondary} fontSize="1.25rem" fontWeight="500">
            Preencher Dados da Unidade de Compressão
          </Text>
        </ModalHeader>
        <ModalCloseButton onClick={() => handleCompressorModalClose()} />
        <ModalBody>
          <Box>
            <SimpleGrid columns={[12]} gap={5} mt={5}>
              {/* <GridItem colStart={[1]} colEnd={[13, 13, 6, 5]} h="100%">
                <Box>
                  <Text className="modal__select__section--title"> Pesquisa de Compressores</Text>
                  <Formik
                    initialValues={initialValuesFilter}
                    isInitialValid
                    validationSchema={schemaFilter}
                    validateOnChange={false}
                    onSubmit={(values) => {
                      handleCompressorSearch(values);
                    }}
                  >
                    {({ values, submitForm }) => (
                      <Form className="form">
                        <SimpleGrid columns={[2]} spacing={[5]}>
                          <Box>
                            <Field
                              size="xs"
                              name="count"
                              component={Input}
                              type="number"
                              label="Quantidade *"
                              value={values.count || ''}
                            />
                            <Field
                              size="xs"
                              name="loadLoss"
                              component={Input}
                              type="number"
                              label="Perda de Carga (K) *"
                              value={values.loadLoss || ''}
                            />
                          </Box>
                          <Box>
                            <Field
                              size="xs"
                              name="range"
                              component={Input}
                              type="number"
                              label="Range (%) *"
                              value={values.range || ''}
                            />
                          </Box>
                        </SimpleGrid>
                        <SimpleGrid columns={[2]} spacing={[5]}>
                          <Box>
                            <Field
                              size="xs"
                              name="ambientTemperature"
                              component={Input}
                              type="number"
                              label="Temperatura Ambiente"
                              value={values.ambientTemperature || ''}
                            />
                            <Field
                              size="xs"
                              name="compressorType"
                              component={Input}
                              type="number"
                              label="Tipo de Compressor"
                              value={values.compressorType || ''}
                            />
                            <Field
                              size="xs"
                              name="fairing"
                              component={SelectGivenOptions}
                              type="number"
                              label="Carenagem"
                              value={values.fairing || ''}
                              options={['Sim', 'Não'].map((item: string) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            />
                          </Box>
                          <Box>
                            <Field
                              size="xs"
                              name="hotGasDefrost"
                              component={SelectGivenOptions}
                              type="number"
                              label="Degelo a Gás Quente"
                              value={values.hotGasDefrost || ''}
                              options={['Sim', 'Não'].map((item: string) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            />
                            <Field
                              size="xs"
                              name="capacityControl"
                              component={SelectGivenOptions}
                              type="number"
                              label="Controle de Capacidade"
                              value={values.capacityControl || ''}
                              options={['Não', 'Step', 'Inversor'].map((item: string) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            />
                          </Box>
                        </SimpleGrid>
                        <HStack justify="flex-end" mt={4}>
                          <Button
                            leftIcon={<MdSearch size={18} />}
                            type="button"
                            disabled={specialEnabled}
                            $primary
                            onClick={submitForm}
                          >
                            Pesquisar
                          </Button>
                        </HStack>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </GridItem> */}
              <GridItem colStart={[1]} colEnd={[13]} h="100%">
                <Box>
                  {/* <Stack align="center" direction="row">
                    <FormControl display="flex" alignItems="center" mb={5}>
                      <FormLabel htmlFor="enable-special" mb="0" fontSize={13} fontWeight="500" color={colors.gray100}>
                        Adicionar Equipamento Especial?
                      </FormLabel>
                      <Switch size="sm" id="enable-special" onChange={handleChangeSpecial} isChecked={specialEnabled} />
                    </FormControl>
                  </Stack> */}

                  {specialEnabled ? (
                    <Formik
                      initialValues={initialValuesSpecial}
                      // validateOnMount
                      // validationSchema={schemaSpecial}
                      // validateOnChange={false}
                      onSubmit={(values) => {
                        handleSubmitSpecial(values);
                      }}
                    >
                      {({ values, submitForm }) => (
                        <Form className="form">
                          <SimpleGrid columns={[1, 2, 4]} spacing={[0, 5]}>
                            <Box>
                              <Field
                                size="xs"
                                name="model"
                                component={Input}
                                type="text"
                                label="Código Completo do Produto"
                                value={values.model || ''}
                              />
                              <Field
                                size="xs"
                                name="count"
                                component={Input}
                                type="number"
                                label="Quantidade de Compressores"
                                value={values.count || ''}
                              />
                              <Field
                                size="xs"
                                name="loadLoss"
                                component={Input}
                                type="number"
                                label="Perda de Carga (K) *"
                                value={values.loadLoss || ''}
                              />
                              <Field
                                size="xs"
                                name="ambientTemperature"
                                component={Input}
                                type="number"
                                label="Temperatura Ambiente (°C)"
                                value={values.ambientTemperature || ''}
                              />
                            </Box>
                            <Box>
                              <Field
                                size="xs"
                                name="condensingTemperature"
                                component={Input}
                                type="number"
                                label="Temperatura de Condensação (°C)"
                                value={values.condensingTemperature || ''}
                              />
                              <Field
                                size="xs"
                                name="compressorType"
                                component={Input}
                                type="text"
                                label="Tipo de Compressor"
                                value={values.compressorType || ''}
                              />
                              <Field
                                size="xs"
                                name="hotGasDefrost"
                                component={SelectGivenOptions}
                                type="text"
                                label="Degelo a Gás Quente"
                                value={values.hotGasDefrost || ''}
                                options={['Com', 'Sem'].map((option, index) => (
                                  <option value={option} key={String(index)}>
                                    {option}
                                  </option>
                                ))}
                              />
                              <Field
                                size="xs"
                                name="capacityControl"
                                component={SelectGivenOptions}
                                type="text"
                                label="Controle de Capacidade"
                                value={values.capacityControl || ''}
                                options={['Com', 'Sem'].map((option, index) => (
                                  <option value={option} key={String(index)}>
                                    {option}
                                  </option>
                                ))}
                              />
                            </Box>
                            <Box>
                              <Field
                                size="xs"
                                name="maxPower"
                                component={Input}
                                type="number"
                                label="Potência Máxima Consumida"
                                value={values.maxPower || ''}
                              />
                              <Field
                                size="xs"
                                name="realTimePower"
                                component={Input}
                                type="number"
                                label="Consumo em Regime (kW)"
                                value={values.realTimePower || ''}
                              />
                              <Field
                                size="xs"
                                name="unitCapacity"
                                component={Input}
                                type="number"
                                label="Capacidade por Compressor (Kcal/h)"
                                value={values.unitCapacity || ''}
                              />
                            </Box>
                            <Box>
                              <Field
                                size="xs"
                                name="rejectedHeat"
                                component={Input}
                                type="number"
                                label="Calor Rejeitado"
                                value={values.rejectedHeat || ''}
                              />
                              <Table className="equipment__table" size="xs" variant="unstyled" colorScheme="blackAlpha">
                                <Tbody>
                                  <Tr>
                                    <Td>Capacidade Total (Kcal/h)</Td>
                                    <Td>{calcTotalCapacity(values.count, values.unitCapacity)}</Td>
                                  </Tr>
                                  <Tr>
                                    <Td>Capacidade Necessária (Kcal/h)</Td>
                                    <Td>{calcRequiredCapacity()}</Td>
                                  </Tr>
                                  <Tr>
                                    <Td>Folga de capacidade</Td>
                                    <Td>{calcCapacityLooseness(values.count, values.unitCapacity)}%</Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </Box>
                          </SimpleGrid>
                          <SimpleGrid columns={[1]} spacing={[5]} mt={[5, 0]}>
                            <Field
                              size="xs"
                              name="commercialDescription"
                              component={TextArea}
                              label="Descrição Comercial"
                              value={values.commercialDescription || ''}
                            />
                          </SimpleGrid>
                          <ModalFooter padding="40px 0 15px">
                            <Button mr={3} $default onClick={() => handleCompressorModalClose()}>
                              Cancelar
                            </Button>
                            <Button $primary disabled={false} mr={3} onClick={submitForm}>
                              Confirmar
                            </Button>
                          </ModalFooter>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <>
                      <HStack justify="center">
                        {compressors.length === 0 && !loading && (
                          <VStack align="center" mt={10}>
                            <Icon boxSize="80px" as={MdSearch} color={colors.gray100} />
                            <Text className="modal__select__section--info">Pesquise um equipamento.</Text>
                          </VStack>
                        )}
                        {compressors.length > 0 && (
                          <ButtonGroup className="modal__select__button--group" size="sm" isAttached variant="outline">
                            {equipmentProfiles.map((profile: any) => (
                              <ChakraButton
                                key={profile.value}
                                className={CompressorProfile.value === profile.value ? 'active' : null}
                                onClick={() => handleCompressorProfileChange(profile)}
                              >
                                {profile.label}
                              </ChakraButton>
                            ))}
                          </ButtonGroup>
                        )}
                      </HStack>
                      <VStack className="equipment__wrapper" align="center" justify="center" mt={5}>
                        {loading && (
                          <Stack w="100%">
                            <Skeleton height="200px" />
                            <Skeleton height="200px" />
                            <Skeleton height="200px" />
                          </Stack>
                        )}
                        {!loading &&
                          compressors.map((compressor: Compressor, index: number) => (
                            <button
                              onClick={() => handleSelectCompressor(compressor)}
                              key={String(index)}
                              type="button"
                              className={`equipment__item elgin__box--shadow ${
                                selectedCompressor?.id === compressor.id && 'active'
                              }`}
                            >
                              <SimpleGrid columns={[1, 2]} spacing={5} w="100%">
                                <VStack>
                                  <Image
                                    className="equipment__image"
                                    fit="contain"
                                    src={null}
                                    alt="Evaporator Image"
                                    fallbackSrc="https://via.placeholder.com/200x150"
                                  />
                                  <Text className="equipment__name" align="left">
                                    {compressor.model}
                                  </Text>
                                </VStack>
                                <HStack spacing={10}>
                                  {/* <EquipmentTable
                                equipment={Compressor}
                                handleDTChange={() => handleCompressorDTChange(Compressor)}
                                handleDecrease={() => handleCompressorDecrease(Compressor)}
                                handleIncrease={() => handleCompressorIncrease(Compressor)}
                                isEditable={false}
                              /> */}
                                </HStack>
                              </SimpleGrid>
                            </button>
                          ))}
                      </VStack>
                    </>
                  )}
                </Box>
              </GridItem>
            </SimpleGrid>
          </Box>
        </ModalBody>
        {!specialEnabled && (
          <ModalFooter mt={1}>
            <Button mr={3} $default onClick={() => handleCompressorModalClose()}>
              Cancelar
            </Button>
            <Button $primary disabled={!selectedCompressor} mr={3} onClick={() => handleSubmitCompressor()}>
              Confirmar
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalCompressor;
