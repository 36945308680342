import { HeatLoad, Room, RoomStatus } from '~/store/ducks/Room/types';

/**
 * Action types
 */
export enum ProjectActionTypes {
  FETCH_ROOMS = '@project/FETCH_ROOMS',
  FETCH_PROJECT = '@project/FETCH_PROJECT',
  STORE_DATA = '@project/STORE_DATA',
  STORE_ROOMS = '@project/STORE_ROOMS',
  REMOVE_ROOM = '@project/REMOVE_ROOM',
  STORE_ROOM_INTO_PROJECT = '@project/STORE_ROOM_INTO_PROJECT',
  UPDATE_ROOM_INTO_PROJECT = '@project/UPDATE_ROOM_INTO_PROJECT',
  REMOVE_ROOM_FROM_PROJECT = '@project/REMOVE_ROOM_FROM_PROJECT',
  REQUEST_LOADING = '@project/REQUEST_LOADING',
  REQUEST_SUCCESS = '@project/REQUEST_SUCCESS',
  REQUEST_FAILURE = '@project/REQUEST_FAILURE',
  SLIDER_REQUEST_LOADING = '@project/SLIDER_REQUEST_LOADING',
  SLIDER_REQUEST_SUCCESS = '@project/SLIDER_REQUEST_SUCCESS',
  SLIDER_REQUEST_FAILURE = '@project/SLIDER_REQUEST_FAILURE',
  SET_ACTIVE_ROOM = '@project/SET_ACTIVE_ROOM',
}

/**
 * Data types
 */

export interface ProjectRoom {
  id: number;
  project: { id: number };
  name: string;
  status?: string;
  ambientTemperature: number;
  roomTemperature: number;
  operatingHours: number;
  length: number;
  height: number;
  width: number;
  heatLoad?: HeatLoad;
  safetyMargin: number;
  calculationType: number;
}

export interface Customer {
  name: string;
  email: string;
  cnpj: string;
  phone: string;
  address: {
    street: string;
    state: string;
    city: string;
    zipcode: string;
    country: string;
  };
  segment: string;
}
export interface Project {
  id?: number;
  name: string;
  projectLoading?: boolean;
  sliderLoading?: boolean;
  status?: string;
  activeRoom?: number;
  rooms?: ProjectRoom[];
  customer: Customer;
}

/**
 * State type
 */

export interface ProjectState extends Project {}

export const PROJECT_ROOM_INITIAL_STATE: ProjectRoom = {
  id: null,
  name: null,
  project: null,
  status: RoomStatus.CREATING,
  ambientTemperature: null,
  roomTemperature: null,
  operatingHours: null,
  length: null,
  height: null,
  width: null,
  heatLoad: null,
  safetyMargin: null,
  calculationType: 1,
};
