/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */

import _ from 'lodash';

export const calcArea = (length, width) => +(length * width).toFixed(2);

export const calcVolume = (length, width, height) => +(length * width * height).toFixed(2);

export const isObjectEqual = (obj1, obj2) => _.isEqual(obj1, obj2);

export const isArrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    // TODO goods from api attributes are null
    if (!isObjectEqual(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
};

export const parseValue2Decimal = (value: number, decimal: number = 2) => (value ? +value.toFixed(decimal) : value);

export const mountVoltageString = (voltage50hz: number, voltage60hz: number, powerSupplyPhases: number) => {
  const volt50 =
    voltage50hz > 0 ? `${parseValue2Decimal(voltage50hz)}v/${parseValue2Decimal(powerSupplyPhases, 0)}F (50hz)` : '';
  const volt60 =
    voltage60hz > 0 ? `${parseValue2Decimal(voltage60hz)}v/${parseValue2Decimal(powerSupplyPhases, 0)}F (60hz)` : '';
  // eslint-disable-next-line no-nested-ternary
  return volt50 !== '' && volt60 !== ''
    ? `${volt50} / ${volt60}`
    : // eslint-disable-next-line no-nested-ternary
    volt50 !== ''
    ? volt50
    : volt60 !== ''
    ? volt60
    : '-';
};

export const parseValueInteger = (value: number) => (value ? +value.toFixed() : value);

export const filterObjectEmptyValues = (obj) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === '') {
      delete obj[propName];
    }
  }
  return obj;
};

export const removeDuplicatedObjectFromArray = (data: any[], property: string) => _.uniqBy(data, property);

export const handlePowerSupply = (ps: string) => {
  try {
    const splittedArray = ps.replaceAll(/[hzvF]*\s*/g, '').split(',');
    const frequency = +splittedArray[0];
    const voltage = +splittedArray[1];
    const phase = +splittedArray[2];

    return {
      powerSupply50Hz: frequency === 50 ? voltage : null,
      powerSupply60Hz: frequency === 60 ? voltage : null,
      powerSupplyPhases: phase,
    };
  } catch (err) {
    return null;
  }
};
