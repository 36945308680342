/* eslint-disable no-console */
import React, { useState } from 'react';
import { Box, Divider, SimpleGrid, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { EquipmentSystem } from '~/store/ducks/Equipment/types';
import Stat from '~/components/Form/Stat';
import { Button } from '~/components/Form/Button/Button_Styles';
import EquipmentService from '~/store/ducks/Equipment/services';
import { toastError } from '~/helpers/toast';
import { parseValue2Decimal } from '~/helpers/functions';
import { storeSystemResult } from '~/store/ducks/Equipment/actions';

type SystemResultProps = {
  system: EquipmentSystem;
};

const SystemResult: React.FC<SystemResultProps> = ({ system }) => {
  const dispatch = useDispatch();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  // const [result, setResult] = useState(null);
  // const [evaporatorSKU, setEvaporatorSKU] = useState([]);
  const { condensingUnit: condensingUnitConfiguration, configurations } = system;

  const handleSubmit = async () => {
    const { equipment: evaporator, room } = configurations[0];
    const { equipment: condensingUnit } = condensingUnitConfiguration;

    console.log('system => ', system);
    console.log('configurations => ', configurations);

    // Seleciona os dados do SKU com DT=6 para calculo do DT Resultante
    console.log('evaporator?.sku => ', evaporator?.sku);
    console.log(
      'Array.from() => ',
      configurations.map((item) => item.equipment.sku.sku)
    );
    const respSKU = await EquipmentService.getSKUs({
      evaporator: {
        sku: configurations.map((item) => item.equipment.sku.sku),
        refrigerantFluid: system.refrigerantFluid.code,
        evaporationTemperature: condensingUnit?.evaporationTemperature || system.operationTemperature,
        evaporatorDt: 6,
      },
    });
    // setEvaporatorSKU(respSKU.data);
    console.log('respSKU => ', respSKU);

    const body = {
      // evaporatorCapacity: configurations.reduce(
      //   (capacity, data) => capacity + data.equipment.unitCapacity * data.equipment.count,
      //   0
      // ),
      // evaporatorCapacityDt: configurations.reduce(
      //   (capacity, data) => capacity + data.equipment.unitCapacityDt * data.equipment.count,
      //   0
      // ),
      // evaporatorId: evaporator?.sku?.id,
      // condensingUnitCapacity: condensingUnit?.unitCapacity,
      // condensingUnitId: condensingUnit?.sku?.id,
      // evaporationTemperature: condensingUnit?.evaporationTemperature || system.operationTemperature,
      // ambientTemperature: condensingUnit?.ambientTemperature || room.ambientTemperature,
      // coldstorageTemperature: room.roomTemperature,
      // evaporatorCount: evaporator?.count,
      // evaporatorDT: 6,

      // evaporatorCapacity: respSKU.data.reduce(
      //   (capacity, data) => capacity + data.unitCapacityDt * evaporator?.count,
      //   0
      // ),
      // evaporatorCapacityDt: respSKU.data.reduce(
      //   (capacity, data) => capacity + data.unitCapacityDt * evaporator?.count,
      //   0
      // ),

      // evaporatorCapacity: configurations[0].equipment.unitCapacity,
      // evaporatorCapacityDt: configurations[0].equipment.unitCapacityDt,

      evaporatorCapacity: configurations.reduce((capacity, data) => capacity + data.equipment.unitCapacity, 0),
      evaporatorCapacityDt: configurations.reduce((capacity, data) => capacity + data.equipment.unitCapacityDt, 0),

      evaporatorId: respSKU.data[0]?.id,
      condensingUnitCapacity: condensingUnit?.unitCapacity,
      condensingUnitId: condensingUnit?.sku?.id,
      evaporationTemperature: condensingUnit?.evaporationTemperature || system.operationTemperature,
      ambientTemperature: condensingUnit?.ambientTemperature || room.ambientTemperature,
      coldstorageTemperature: room.roomTemperature,
      evaporatorCount: evaporator?.count,
      evaporatorDT: 6,
    };

    try {
      setButtonLoading(true);
      const response = await EquipmentService.calculateResult(body);
      dispatch(storeSystemResult(response.data));
    } catch (err) {
      console.error(err);
      toastError('equipment', 'Erro ao calcular DT Resultante');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      <Divider my={5} />
      <Text className="block__system--title"> Resultado </Text>
      <Box className="elgin__box--shadow" minH="70px" p={4}>
        <SimpleGrid spacing={5} columns={[1, 1, 4]}>
          <Button
            type="button"
            disabled={!system.condensingUnit || !system.configurations}
            isLoading={buttonLoading}
            onClick={handleSubmit}
            $primary
          >
            Calcular
          </Button>
          <Stat size="xs" label="DT Resultante" value={parseValue2Decimal(system?.resultingDt) || 0} field={null} />
          <Stat
            size="xs"
            label="Capacidade Total (Kcal/h)"
            value={parseValue2Decimal(system?.resultingCapacity) || 0}
            field={null}
          />
        </SimpleGrid>
      </Box>
    </>
  );
};

export default SystemResult;
