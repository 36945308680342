import styled from 'styled-components';

import { darken } from 'polished';
import { colors } from '~/styles/colors';
import { devices } from '~/styles/breakpoints';

export const SliderContainer = styled.section`
  padding: 10px;
  padding-left: 0;

  .slick-prev:before,
  .slick-next:before {
    color: ${colors.gray200};
  }

  .item {
    height: 85px;
    display: flex !important;
    align-items: flex-start;
    justify-content: center;
    min-height: 0px;
    min-width: 0px;

    &.flex-start {
      justify-content: flex-start;
    }

    @media ${devices.mobileL} {
      height: 120px;
    }

    @media ${devices.mobileXL} {
      height: 130px;
    }

    @media ${devices.tablet} {
      height: 140px;
    }

    .item__add {
      p {
        display: none;
        line-height: 1;

        @media ${devices.mobileXL} {
          display: block;
        }
      }

      svg {
        font-size: 1.75rem;
        color: ${colors.primary};
      }
    }

    .item__text {
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      overflow: hidden;
      white-space: pre-wrap;

      @media ${devices.tablet} {
        font-size: 14px;
        white-space: unset;
      }
    }
  }

  .inner__item {
    position: relative;
    width: 100px;
    height: 80px;
    display: flex;
    margin: 0 5px;
    padding-bottom: 6px;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${colors.gray100};
    transition: all 0.1s ease;
    padding: 5px 10px;
    line-height: 1.2;

    @keyframes example {
      0% {
        background-color: ${darken(0.02, '#E6E4E3')};
      }
      25% {
        background-color: ${darken(0.04, '#E6E4E3')};
      }
      50% {
        background-color: ${darken(0.06, '#E6E4E3')};
      }
      75% {
        background-color: ${darken(0.08, '#E6E4E3')};
      }
      100% {
        background-color: ${darken(0.1, '#E6E4E3')};
      }
    }

    @media ${devices.mobileL} {
      width: 120px;
      height: 100px;
    }

    @media ${devices.mobileXL} {
      width: 130px;
      height: 110px;
    }

    @media ${devices.tablet} {
      width: 150px;
      height: 120px;
    }

    @media ${devices.laptop} {
      height: 130px;
    }

    &:hover,
    &:focus {
      border-left: 2px solid ${colors.secondary};
      border-bottom: 2px solid ${colors.secondary};
      border-right: 2px solid ${colors.secondary};
    }

    &:disabled {
      background-color: ${colors.gray50};
      border-left: none;
      border-bottom: none;
      border-right: none;

      /* chakra skeleton override */
      -webkit-animation: 0.8s linear infinite alternate example;
      animation: 0.8s linear infinite alternate example;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      cursor: default;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .item__wrapper {
    position: relative;
    border-left: 2px solid ${colors.transparent};
    border-bottom: 2px solid ${colors.transparent};
    border-right: 2px solid ${colors.transparent};

    &:hover {
      .remove__item {
        opacity: 1;
      }
    }

    &:focus {
      border-left: 2px solid ${colors.secondary};
      border-bottom: 2px solid ${colors.secondary};
      border-right: 2px solid ${colors.secondary};
    }

    &.selected {
      .inner__item {
        border-left: 2px solid ${colors.secondary};
        border-bottom: 2px solid ${colors.secondary};
        border-right: 2px solid ${colors.secondary};
      }
      .remove__item {
        opacity: 1;
      }
    }

    .remove__item {
      opacity: 0;
      color: ${colors.red};
      position: absolute;
      top: 3px;
      right: 7px;
      font-size: 1.5rem;
      border-radius: 4px;

      @media ${devices.mobileL} {
        top: 5px;
        right: 15px;
      }

      @media ${devices.tablet} {
        top: 10px;
        right: 15px;
      }

      &:focus {
        opacity: 1;
        box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 1);
      }

      &:hover {
        opacity: 1;
        box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
