import React from 'react';
import { Button, HStack, Link, Spacer, useBreakpointValue } from '@chakra-ui/react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Link as RouterLink } from 'react-router-dom';
import { colors } from '~/styles/colors';
import { ProjectRouterContainer } from '~/components/ProjectRouter/ProjectRouter_Styles';

type ProjectRouterType = {
  previousLink: string;
  previusText: string;
  nextLink: string;
  nextText: string;
  noForward?: boolean;
};

const ProjectRouter = ({ previousLink, previusText, nextLink, nextText, noForward = false }: ProjectRouterType) => {
  const responsiveSize = useBreakpointValue(['xs', 'sm']);
  const enabledRedirectEquipmentsProposal = process.env.REACT_APP_ENABLE_REDIRECT_EQUIPMENTS_PROPOSAL === 'true';

  const isForwardButtonEnabled = (): boolean => {
    if (noForward) return false;
    return enabledRedirectEquipmentsProposal;
  };

  return (
    <ProjectRouterContainer className="hide--on--print">
      <HStack paddingX={0} mb={10}>
        <Link as={RouterLink} to={previousLink}>
          <Button
            leftIcon={<MdKeyboardArrowLeft size={24} />}
            size={responsiveSize}
            color={colors.gray100}
            colorScheme="gray"
            variant="outline"
          >
            {previusText}
          </Button>
        </Link>
        <Spacer />
        {isForwardButtonEnabled() && (
          <Link as={RouterLink} to={nextLink}>
            <Button
              size={responsiveSize}
              rightIcon={<MdKeyboardArrowRight size={24} />}
              color={colors.gray100}
              colorScheme="gray"
              variant="outline"
            >
              {nextText}
            </Button>
          </Link>
        )}
      </HStack>
    </ProjectRouterContainer>
  );
};

export default ProjectRouter;
