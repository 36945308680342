import { ProjectRoom } from '~/store/ducks/Project/types';
import { HeatLoad } from '~/store/ducks/Room/types';

/**
 * Action types
 */
export enum EquipmentTypes {
  STORE_INITIAL_DATA = '@equipment/STORE_INITIAL_DATA',
  STORE_SETTINGS = '@equipment/STORE_SETTINGS',
  SAVE_SYSTEM = '@equipment/SAVE_SYSTEM',
  STORE_SYSTEM = '@equipment/STORE_SYSTEM',
  STORE_SYSTEMS = '@equipment/STORE_SYSTEMS',
  GET_ALL_SYSTEMS = '@equipment/GET_ALL_SYSTEMS',
  SELECT_ROOM = '@equipment/SELECT_ROOM',
  RETURN_CONFIGURATION = '@equipment/RETURN_CONFIGURATION',
  ADD_CONFIGURATION = '@equipment/ADD_CONFIGURATION',
  REMOVE_CONFIGURATION = '@equipment/REMOVE_CONFIGURATION',
  SELECT_EVAPORATOR = '@equipment/SELECT_EVAPORATOR',
  SELECT_CONDENSING_UNIT = '@equipment/SELECT_CONDENSING_UNIT',
  REMOVE_CONDENSING_UNIT = '@equipment/REMOVE_CONDENSING_UNIT',
  SELECT_COMPRESSSOR = '@equipment/SELECT_COMPRESSSOR',
  REMOVE_COMPRESSOR = '@equipment/REMOVE_COMPRESSOR',
  SELECT_CONDENSER = '@equipment/SELECT_CONDENSER',
  REMOVE_CONDENSER = '@equipment/REMOVE_CONDENSER',
  CONFIRM_SYSTEM = '@equipment/CONFIRM_SYSTEM',
  CHANGE_ACTIVE_SYSTEM = '@equipment/CHANGE_ACTIVE_SYSTEM',
  REQUEST_LOADING = '@equipment/REQUEST_LOADING',
  REQUEST_SUCCESS = '@equipment/REQUEST_SUCCESS',
  STORE_SYSTEM_RESULT = '@equipment/STORE_SYSTEM_RESULT',
}

/**
 * Data types
 */

export interface RefrigerantFluid {
  id: number;
  code: string;
  multiplier: number;
  condition_factor: number;
}

export interface Evaporator {
  id?: number;
  image?: string;
  model: string;
  count: number;
  sku?: { id: number; sku: string };
  line?: string;
  specialEquipment: boolean;
  unitCapacity: number;
  unitCapacityDt?: number;
  length?: number;
  width?: number;
  heigth?: number;
  fanDiameter?: number;
  nominalUnitCapacity: number;
  heatLoad: number;
  consumedPower: number;
  temperatureDelta: number;
  unitAirFlow: number;
  airExchangeRate?: number;
  voltage60hz?: number;
  voltage50hz?: number;
  powerSupplyPhases?: number;
  productCustom?: {
    id: number;
    airArrow: number;
    defrost: string;
    defrostPower: number;
    fanDiameter: number;
    fanPower: number;
    model: string;
    unitCapacity: number;
    wingPerInch: number;
    commercialDescription: string;
  };
}

export interface CondensingUnit {
  id?: number;
  image?: string;
  model: string;
  line?: string;
  sku?: { id: number; sku: string };
  count: number;
  specialEquipment: boolean;
  unitCapacity: number;
  evaporationTemperature?: number;
  consumedPower: number;
  nominalUnitCapacity: number;
  heatLoad: number;
  ambientTemperature?: number;
  temperatureDelta?: number;
  unitAirFlow?: number;
  unitCapacityDt?: number;
  voltage60hz?: number;
  voltage50hz?: number;
  powerSupplyPhases?: number;
  productCustom?: {
    id: number;
    model: string;
    unitCapacity: number;
    ambientTemperature: number;
    capacityControl: string;
    compressorType: string;
    condensingTemperature: number;
    consumedPower: number;
    fairing: string;
    loadLoss: string;
    realTimePower: number;
    rejectedHeat: number;
    hotGasDefrost: string;
    commercialDescription: string;
  };
}

export interface Compressor {
  id?: number;
  model: string;
  count: number;
  unitCapacity: number;
  consumedPower: number;
  nominalUnitCapacity: number;
  heatLoad: number;
  specialEquipment: boolean;
  voltage60hz?: number;
  voltage50hz?: number;
  powerSupplyPhases?: number;
  productCustom?: {
    id: number;
    model: string;
    unitCapacity: number;
    ambientTemperature: number;
    capacityControl: string;
    compressorType: string;
    condensingTemperature: number;
    fairing: string;
    loadLoss: number;
    realTimePower: number;
    rejectedHeat: number;
    altitude: number;
    hotGasDefrost: string;
    maxPower: number;
    commercialDescription: string;
  };
}

export interface Condenser {
  id?: number;
  image?: string;
  count: number;
  model: string;
  specialEquipment: boolean;
  unitCapacity: number;
  nominalUnitCapacity: number;
  consumedPower: number;
  heatLoad: number;
  temperatureDelta: number;
  voltage60hz?: number;
  voltage50hz?: number;
  powerSupplyPhases?: number;
  productCustom?: {
    id: number;
    fanDiameter: number;
    fanPower: number;
    model: string;
    unitCapacity: number;
    wingPerInch: number;
    rejectedHeat: number;
    condenserType: string;
    fanCount: number;
    fanType: string;
    height: number;
    noiseLevel: number;
    numberOfPoles: number;
    commercialDescription: string;
  };
}

export interface EvaporatorOptionals {
  chamber: string[];
  defrost: string[];
  engine: string[];
  engineType: string[];
  expansionValve: string[];
  fanDiameter: number[];
  finsPerInch: number[];
  finsProtection: string[];
  gicle: string[];
  isolatedTray: string[];
  logo: string[];
  numberOfFans: number[];
  orifice: string[];
  powerSupply50Hz: number[];
  powerSupply60Hz: number[];
  powerSupplyPhases: number[];
  productLine: string[];
  valveKit: string[];
  valveType: string[];
  version: string[];
  voltage: string[];
  voltageAndDefrost: string[];
  powerSupply?: string[];
}

export interface CondensingUnitOptionals {
  capacityControl: string[];
  hotAirDefrost: string[];
  compressorType: string[];
  condensationControl: string[];
  productLine: string[];
  compressor: string[];
  logo: string[];
  chamber: string[];
  numberOfCompressors: number[];
  circuitBreaker: string[];
  contactor: string[];
  insulationinTheSuctionLine: string[];
  liquidFilter: string[];
  liquidServiceValve: string[];
  oilSeparator: string[];
  overloadRelay: string[];
  sequenceRelayAndPhaseOutage: string[];
  suctionAccumulator: string[];
  suctionFilter: string[];
  suctionServiceValve: string[];
  tank: string[];
  tankInletValve: string[];
  viewfinder: string[];
  elginSmart: string[];
  controller: string[];
}
export interface EvaporatorSKU {
  id: number;
  sku: string;
  chamber: string;
  engineType: string;
  expansionValve: string;
  finsProtection: string;
  isolatedTray: string;
  fanPower: [];
  heigth: number;
  length: number;
  nominalUnitCapacity: number;
  refrigerantFluid: RefrigerantFluid;
  unitAirFlow: number;
  unitCapacity: number;
  unitCapacityDt: number;
  width: number;
  consumedPower: number;
  voltage50hz: number;
  voltage60hz: number;
  powerSupplyPhases: number;
}

export interface CondensingUnitSKU {
  id: number;
  sku: string;
  model: string[];
  heigth: number;
  length: number;
  unitCapacity: number;
  nominalUnitCapacity: number;
  consumedPower: number;
  refrigerantFluid: RefrigerantFluid;
  loadLoss: number[];
  hotAirDefrost: string[];
  capacityControl: string[];
  compressorType: string[];
  compressor: string[];
  logo: string[];
  chamber: string[];
  numberOfCompressors: string[];
  condensationControl: string[];
  productLine: string[];
  oilSeparator: string[];
  suctionAccumulator: string[];
  suctionFilter: string[];
  contactor: string[];
  liquidServiceValve: string[];
  tankInletValve: string[];
  liquidFilter: string[];
  tank: string[];
  viewfinder: string[];
  insulationinTheSuctionLine: string[];
  suctionServiceValve: string[];
  overloadRelay: string[];
  circuitBreaker: string[];
  sequenceRelayAndPhaseOutage: string[];
  controller: string[];
  elginSmart: string[];
  voltage50hz: number;
  voltage60hz: number;
  powerSupplyPhases: number;
}
export interface EquipmentRoom {
  id: number;
  name: string;
  height: number;
  length: number;
  width: number;
  temperatureDelta?: number;
  roomTemperature: number;
  heatLoad?: HeatLoad;
  ambientTemperature: number;
}

export interface Configuration {
  id?: number;
  room?: EquipmentRoom;
  configType: string;
  equipment: any;
}

export interface EquipmentSystem {
  id: number;
  name: string;
  project: { id: number };
  type?: string;
  active?: boolean;
  operationTemperature: number;
  refrigerantFluid: RefrigerantFluid;
  configurations: Configuration[];
  condensingUnit?: Configuration;
  rack: boolean;
  compressor?: Compressor;
  condenser?: Condenser;
  resultingDt: number;
  resultingCapacity: number;
}

export interface Equipment {
  id: number;
  project: { id: number };
  loading: boolean;
  projectRooms: ProjectRoom[];
  systems: EquipmentSystem[];
}

export enum EquipmentInterfaceTypes {
  EVAPORATOR = 'EVAPORATOR',
  CONDENSING_UNIT = 'CONDENSING_UNIT',
  COMPRESSOR = 'COMPRESSOR',
  CONDENSER = 'CONDENSER',
}

export enum SystemTypes {
  DEFAULT = 'DEFAULT', // Condensing Unit
  SPECIAL = 'SPECIAL', // Rack/Compressor + Condenser
}

// export const refrigerantFluids = ['R22', 'R134a', 'R407C', 'R448A', 'R449A', 'R404A', 'R507', 'R452a'];
export const refrigerantFluids = ['R22', 'R134a', 'R448A', 'R449A', 'R404A'];

/**
 * State type
 */
export interface EquipmentState extends Equipment {}
