import { Reducer } from 'redux';
import { Auth, AuthTypes } from '~/store/ducks/Auth/types';

export const AUTH_INITIAL_STATE: Auth = {
  accessToken: null,
  expiresIn: null,
};

const reducer: Reducer<any> = (state = AUTH_INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.STORE_TOKEN:
      return {
        ...state,
        accessToken: action.payload.data.access_token,
        expiresIn: action.payload.data.expires_in,
      };
    case AuthTypes.REMOVE_TOKEN:
    case AuthTypes.LOGOUT:
      return AUTH_INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
