/* eslint-disable no-console */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import ProposalService from '~/store/ducks/Proposal/services';
import {
  proposalRequestFailure,
  proposalRequestLoading,
  proposalRequestSuccess,
  storePresentation,
  storeProposal,
} from '~/store/ducks/Proposal/actions';
import { ProposalTypes } from '~/store/ducks/Proposal/types';
import { toastError } from '~/helpers/toast';

export function* fetchProposal(action): any {
  const { id } = action.payload;
  let response;

  try {
    yield put(proposalRequestLoading());
    response = yield call(ProposalService.getProposal, id);

    if (response.status === 200) {
      yield put(storeProposal(response.data));
    } else {
      response = yield call(ProposalService.createProposal, id);
      yield put(storeProposal(response.data));
    }

    yield put(proposalRequestSuccess());
  } catch (err) {
    yield put(proposalRequestFailure());
    console.error(err);
    // toastError('proposal', 'Erro ao recuperar/criar proposta.');
    toastError('proposal', 'Erro ao recuperar detalhes dos itens da proposta.');
  }
}

export function* fetchPresentation(action): any {
  const { projectId, proposalId } = action.payload;

  try {
    const response = yield call(ProposalService.getPresentation, projectId, proposalId);
    yield put(storePresentation(response.data));
  } catch (err) {
    console.error(err);
    toastError('proposal', 'Erro ao recuperar apresentação.');
  }
}

export function* fetchEquipmentFeature(action): any {
  const { projectId } = action.payload;

  try {
    yield put(proposalRequestLoading());
    const response = yield call(ProposalService.getProposal, projectId);
    yield put(storeProposal(response.data));
    yield put(proposalRequestSuccess());
  } catch (err) {
    yield put(proposalRequestFailure());
    console.error(err);
    toastError('proposal', 'Erro ao recuperar cliente do projeto.');
  }
}

export function* fetchTermsConditions(action): any {
  const { projectId } = action.payload;

  try {
    yield put(proposalRequestLoading());
    const response = yield call(ProposalService.getProposal, projectId);
    yield put(storeProposal(response.data));
    yield put(proposalRequestSuccess());
  } catch (err) {
    yield put(proposalRequestFailure());
    console.error(err);
    toastError('proposal', 'Erro ao recuperar cliente do projeto.');
  }
}

export function* fetchProposalOwners(action): any {
  const { projectId } = action.payload;

  try {
    yield put(proposalRequestLoading());
    const response = yield call(ProposalService.getProposal, projectId);
    yield put(storeProposal(response.data));
    yield put(proposalRequestSuccess());
  } catch (err) {
    yield put(proposalRequestFailure());
    console.error(err);
    toastError('proposal', 'Erro ao recuperar cliente do projeto.');
  }
}

export function* fetchCommercialConditions(action): any {
  const { projectId } = action.payload;

  try {
    yield put(proposalRequestLoading());
    const response = yield call(ProposalService.getProposal, projectId);
    yield put(storeProposal(response.data));
    yield put(proposalRequestSuccess());
  } catch (err) {
    yield put(proposalRequestFailure());
    console.error(err);
    toastError('proposal', 'Erro ao recuperar cliente do projeto.');
  }
}

export default all([
  takeLatest(ProposalTypes.FETCH_PROPOSAL, fetchProposal),
  takeLatest(ProposalTypes.FETCH_PRESENTATION, fetchPresentation),
]);
