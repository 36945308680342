import { goodsItemInitialState, GoodsActionTypes } from './types';

export const storeGoods = (data: any) => ({
  type: GoodsActionTypes.STORE_DATA,
  payload: {
    data,
  },
});

export const addGoodsItem = () => ({
  type: GoodsActionTypes.ADD_ITEM,
  payload: {
    data: goodsItemInitialState,
  },
});
