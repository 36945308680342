/* eslint-disable no-console */
import { put, call, select, takeLatest, race, all, cancelled } from 'redux-saga/effects';
import axios from 'axios';
import {
  roomRequestLoading,
  roomRequestSuccess,
  setStatusCreating,
  setStatusUpdating,
  storeRoomData,
} from '~/store/ducks/Room/actions';
import { getRoomState } from '~/store/ducks/Room/selectors';
import RoomService from '~/store/ducks/Room/services';
import { RoomActionTypes } from '~/store/ducks/Room/types';
import { toastError, toastSuccess } from '~/helpers/toast';
import {
  projectRequestLoading,
  projectRequestSuccess,
  removeRoomFromProject,
  sliderRequestLoading,
  slidertRequestSuccess,
  storeRoomIntoProject,
  updateRoomIntoProject,
} from '~/store/ducks/Project/actions';
import { getProjectState } from '~/store/ducks/Project/selectors';

function* getRoom(action: any): any {
  const projectState = yield select(getProjectState);
  const { id, projectId, index } = action.payload;

  yield put(roomRequestLoading());

  try {
    if (id) {
      const response = yield call(RoomService.getRoom, id, projectId);
      yield put(storeRoomData(response.data));
      yield put(setStatusUpdating());
    } else {
      const localRoom = RoomService.getLocalRoom(projectState, index);
      yield put(storeRoomData(localRoom));
      yield put(setStatusCreating());
    }
  } catch (e) {
    console.error(e);
    toastError('room', 'Erro ao recuperar câmara');
  } finally {
    yield put(roomRequestSuccess());
  }
}

function* saveRoom(action): any {
  const room = yield select(getRoomState);
  const { id } = room.data;
  let response = null;

  // Source for API request cancellation due to multiple sagas watchers from form suggestions.
  const cancelSource = axios.CancelToken.source();
  const cancelToken = cancelSource.token;

  if (!RoomService.isRoomValid(room.data)) {
    yield put(updateRoomIntoProject(room.data));
    return;
  }

  try {
    yield put(sliderRequestLoading());

    if (id) {
      response = yield call(RoomService.updateRoom, id, room.data, { cancelToken });
    } else {
      response = yield call(RoomService.createRoom, room.data, { cancelToken });
    }

    if (action.type === RoomActionTypes.STORE_GOODS || action.type === RoomActionTypes.STORE_DOORS) {
      toastSuccess('room', 'Câmara atualizada com sucesso!');
    }

    yield put(updateRoomIntoProject(response.data));
    yield put(storeRoomData(response.data));
    yield put(slidertRequestSuccess());
  } catch (e) {
    yield put(slidertRequestSuccess());
    console.error(e);
    toastError('room', 'Erro ao atualizar câmara');
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* deleteRoom(action: any): any {
  const { id, projectId } = action.payload;

  try {
    yield put(projectRequestLoading());
    yield call(RoomService.deleteRoom, id, projectId);
    yield put(removeRoomFromProject(action.payload));
    toastSuccess('project', 'Câmara removida com sucesso!');
  } catch (e) {
    console.error(e);
    toastError('project', 'Erro ao remover câmara.');
  } finally {
    yield put(projectRequestSuccess());
  }
}

function* copyRoom(action: any) {
  const { room: roomData } = action.payload;

  try {
    yield put(projectRequestLoading());
    const parsedRoom = RoomService.parseRoomBeforeCopy(roomData);
    const response = yield call(RoomService.createRoom, parsedRoom);
    yield put(storeRoomIntoProject(response.data));

    toastSuccess('project', 'Câmara copiada com sucesso!');
  } catch (e) {
    console.error(e);
    toastError('project', 'Erro ao copiar câmara.');
  } finally {
    yield put(projectRequestSuccess());
  }
}

export const roomUpdate = race([
  takeLatest(
    [
      RoomActionTypes.STORE_ROOM_INFO,
      RoomActionTypes.STORE_INTERNAL_DIMENSION,
      RoomActionTypes.STORE_GOODS,
      RoomActionTypes.STORE_HEAT_TRANSFER_DIMENSIONS,
      RoomActionTypes.STORE_ADDITIONAL_LOAD,
      RoomActionTypes.STORE_DOORS,
      RoomActionTypes.STORE_SAFETY_MARGIN,
      RoomActionTypes.STORE_TOTAL_KCALH,
      RoomActionTypes.STORE_CALCULATION_TYPE,
    ],
    saveRoom
  ),
]);

export const room = all([
  takeLatest(RoomActionTypes.GET_ROOM, getRoom),
  takeLatest(RoomActionTypes.COPY_ROOM, copyRoom),
  // takeLatest([RoomActionTypes.DELETE_ROOM], deleteRoom),
]);
