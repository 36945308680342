import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
  min-height: 140px;
  min-width: 270px;
  margin-top: 40px;
  border-radius: 1px;
  padding: 20px 25px 10px;

  .heat-load__title {
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
    font-size: 14px;
    color: ${colors.secondary};
    font-weight: 500;
  }

  .heat-load__subtitle {
    font-size: 12px;
    color: ${colors.gray100};
    font-weight: 500;

    @media ${devices.mobileL} {
      font-size: 14px;
    }
  }

  .safety-margin {
    display: flex;
    align-items: center;

    > input {
      width: 50px;
    }

    .safety-margin__preview {
      font-size: 14px;
      color: ${colors.primary};
      font-weight: 600;

      @media ${devices.mobileL} {
        font-size: 16px;
      }
    }
  }

  p {
    line-height: 0.5rem;
    color: ${colors.gray200};

    &.title {
      font-size: 12px;
      line-height: 1;

      @media ${devices.tablet} {
        font-size: 14px;
      }
    }

    &.value {
      font-size: 18px;
      font-weight: bold;

      @media ${devices.tablet} {
        font-size: 22px;
      }
    }
  }
`;

export const Content = styled.div``;
