import React from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';

import { useSelector } from 'react-redux';
import { AccordionContainer } from './FormRoomWrapper_Styles';

import FormRoomInfo from '~/components/Checklist/FormRoomInfo';
import FormInternalDimension from '~/components/Checklist/FormInternalDimension';
import FormHeatTransfer from '~/components/Checklist/FormHeatTransfer';
import FormAdditionalLoad from '~/components/Checklist/FormAdditionalLoad';
import FormRoomGoods from '~/components/Checklist/FormRoomGoods';
import FormRoomDoor from '~/components/Checklist/FormRoomDoor';
import { ApplicationState } from '~/store/store';

const FormRoomWrapper: React.FC = () => {
  const room = useSelector((state: ApplicationState) => state.room);

  return (
    <AccordionContainer>
      <Accordion className="accordion" allowMultiple allowToggle>
        <AccordionItem className="accordion__item">
          <AccordionButton className="accordion__button">
            <Box flex="1" textAlign="left">
              <h2>DADOS DA CÂMARA</h2>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel className="accordion__panel">
            <FormRoomInfo />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem className="accordion__item">
          <AccordionButton className="accordion__button">
            <Box flex="1" textAlign="left">
              <h2>DIMENSÕES INTERNAS</h2>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel className="accordion__panel">
            <FormInternalDimension />
          </AccordionPanel>
        </AccordionItem>

        {room?.data.calculationType === 1 && (
          <AccordionItem className="accordion__item">
            <AccordionButton className="accordion__button">
              <Box flex="1" textAlign="left">
                <h2>PRODUTOS</h2>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel className="accordion__panel">
              <FormRoomGoods />
            </AccordionPanel>
          </AccordionItem>
        )}

        {room?.data.calculationType === 1 && (
          <AccordionItem className="accordion__item">
            <AccordionButton className="accordion__button">
              <Box flex="1" textAlign="left">
                <h2>ISOLAMENTO TÉRMICO</h2>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel className="accordion__panel">
              <FormHeatTransfer />
            </AccordionPanel>
          </AccordionItem>
        )}

        {room?.data.calculationType === 1 && (
          <AccordionItem className="accordion__item">
            <AccordionButton className="accordion__button">
              <Box flex="1" textAlign="left">
                <h2>OUTRAS CARGAS</h2>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel className="accordion__panel">
              <FormAdditionalLoad />
            </AccordionPanel>
          </AccordionItem>
        )}

        {room?.data.calculationType === 1 && (
          <AccordionItem className="accordion__item">
            <AccordionButton className="accordion__button">
              <Box flex="1" textAlign="left">
                <h2>PORTAS</h2>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel className="accordion__panel">
              <FormRoomDoor />
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </AccordionContainer>
  );
};

export default FormRoomWrapper;
