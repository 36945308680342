import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWrapper } from './Route';
import Home from '~/pages/Home';
import Checklist from '~/pages/Checklist';
import Projects from '~/pages/Projects';
import About from '~/pages/About';
import Equipments from '~/pages/Equipments';
import Proposal from '~/pages/Proposal';
import Login from '~/pages/Login';
import AuthRedirect from '~/pages/AuthRedirect';
import Comparison from '~/pages/Comparison';

export default function Routes() {
  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/projects" />

        {/* Project */}
        <RouteWrapper
          path="/projects/:id/rooms"
          component={Checklist}
          pageTitle="Cálculo de Carga Térmica"
          isPrivate
          isRefreshState
        />
        <RouteWrapper
          path="/projects/:id/equipments"
          component={Equipments}
          pageTitle="Seleção de Equipamentos"
          isPrivate
        />
        <RouteWrapper path="/projects/:id/proposal" component={Proposal} pageTitle="Resultado da Seleção" isPrivate />
        <RouteWrapper path="/projects" component={Projects} pageTitle="Projetos" isPrivate />
        <RouteWrapper path="/comparison" component={Comparison} pageTitle="Comparar Produtos" isPrivate />

        {/* Auth */}
        <RouteWrapper path="/auth/callback" component={Login} />
        <RouteWrapper path="/login" component={AuthRedirect} isLogin />
        <RouteWrapper path="/logout" component={AuthRedirect} isLogin={false} />

        {/* Other */}
        <RouteWrapper path="/about" component={About} pageTitle="Sobre" isPrivate />
      </Switch>
    </>
  );
}
