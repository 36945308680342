import {
  Compressor,
  Condenser,
  CondensingUnit,
  Configuration,
  EquipmentTypes,
  Evaporator,
} from '~/store/ducks/Equipment/types';

export const storeInitialData = (projectId, projectRooms) => ({
  type: EquipmentTypes.STORE_INITIAL_DATA,
  payload: {
    projectId,
    projectRooms,
  },
});

export const storeSettings = (data: any) => ({
  type: EquipmentTypes.STORE_SETTINGS,
  payload: {
    data,
  },
});

export const saveSystem = () => ({
  type: EquipmentTypes.SAVE_SYSTEM,
});

export const storeSystem = (data: any) => ({
  type: EquipmentTypes.STORE_SYSTEM,
  payload: {
    data,
  },
});

export const storeSystems = (projectId, projectRooms, systems) => ({
  type: EquipmentTypes.STORE_SYSTEMS,
  payload: {
    projectId,
    projectRooms,
    systems,
  },
});

export const getAllSystems = (id: number) => ({
  type: EquipmentTypes.GET_ALL_SYSTEMS,
  payload: {
    id,
  },
});

export const selectRoom = (data: number) => ({
  type: EquipmentTypes.SELECT_ROOM,
  payload: {
    data,
  },
});

export const returnConfiguration = (data: Configuration) => ({
  type: EquipmentTypes.RETURN_CONFIGURATION,
  payload: {
    data,
  },
});

export const addConfiguration = (data: Configuration) => ({
  type: EquipmentTypes.ADD_CONFIGURATION,
  payload: {
    data,
  },
});

export const removeConfiguration = (data: number) => ({
  type: EquipmentTypes.REMOVE_CONFIGURATION,
  payload: {
    data,
  },
});

export const selectEvaporator = (configuration: Configuration, equipment: Evaporator) => ({
  type: EquipmentTypes.SELECT_EVAPORATOR,
  payload: {
    configuration,
    equipment,
  },
});

export const selectCondensingUnit = (configuration: Configuration, condensingUnit: CondensingUnit) => ({
  type: EquipmentTypes.SELECT_CONDENSING_UNIT,
  payload: {
    configuration,
    condensingUnit,
  },
});

export const removeCondensingUnit = () => ({
  type: EquipmentTypes.REMOVE_CONDENSING_UNIT,
  payload: {},
});

export const selectCompressor = (compressor: Compressor) => ({
  type: EquipmentTypes.SELECT_COMPRESSSOR,
  payload: {
    compressor,
  },
});

export const removeCompressor = () => ({
  type: EquipmentTypes.REMOVE_COMPRESSOR,
  payload: {},
});

export const selectCondenser = (condenser: Condenser) => ({
  type: EquipmentTypes.SELECT_CONDENSER,
  payload: {
    condenser,
  },
});

export const removeCondenser = () => ({
  type: EquipmentTypes.REMOVE_CONDENSER,
  payload: {},
});

export const confirmSystem = () => ({
  type: EquipmentTypes.CONFIRM_SYSTEM,
  payload: {},
});

export const changeActiveSystem = (index: number) => ({
  type: EquipmentTypes.CHANGE_ACTIVE_SYSTEM,
  payload: {
    index,
  },
});

export const equipmentRequestLoading = () => ({
  type: EquipmentTypes.REQUEST_LOADING,
});

export const equipmentRequestSuccess = () => ({
  type: EquipmentTypes.REQUEST_SUCCESS,
});

export const storeSystemResult = (data) => ({
  type: EquipmentTypes.STORE_SYSTEM_RESULT,
  payload: {
    data,
  },
});
