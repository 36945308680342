/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import {
  Box,
  SimpleGrid,
  GridItem,
  Stack,
  HStack,
  VStack,
  Skeleton,
  Text,
  Image,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Tr,
  Td,
  FormControl,
  FormLabel,
  Switch,
  Checkbox,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { MdSearch } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import _ from 'lodash';
import { BsImage } from 'react-icons/bs';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import { toastError } from '~/helpers/toast';
import { colors } from '~/styles/colors';
import Yup from '~/helpers/validations';
import {
  CondensingUnit,
  CondensingUnitOptionals,
  Configuration,
  EquipmentInterfaceTypes,
  EquipmentSystem,
} from '~/store/ducks/Equipment/types';
import { selectCondensingUnit } from '~/store/ducks/Equipment/actions';
import EquipmentService from '~/store/ducks/Equipment/services';
import EquipmentTable from '~/components/Equipments/EquipmentTable';
import StepSKU from '~/components/Equipments/StepSKU';
import { parseValue2Decimal, handlePowerSupply } from '~/helpers/functions';
import CondensingUnitForm from '~/components/Equipments/SearchForms/CondensingUnitForm';
import EquipmentTableComparison from '~/components/Equipments/EquipmentTable/EquipmentTableComparison';
import TextArea from '~/components/Form/TextArea';

const schemaFilter = Yup.object().shape({
  count: Yup.number().nullable().required(),
  loadLoss: Yup.number().nullable().required(),
  ambientTemperature: Yup.number().nullable().required(),
  capacity: Yup.number().nullable().required(),
  evaporationTemperature: Yup.number().nullable().required(),
  compressorType: Yup.string().nullable(),
  hotGasDefrost: Yup.string().nullable(),
  capacityControl: Yup.string().nullable(),
  compressor: Yup.string().nullable(),
  logo: Yup.string().nullable(),
  chamber: Yup.string().nullable(),
  condensationControl: Yup.string().nullable(),
  productLine: Yup.string().nullable(),
  numberOfCompressors: Yup.number().nullable(),
});

const schemaSpecial = Yup.object().shape({
  model: Yup.string().nullable().required(),
  count: Yup.number().nullable().required(),
  loadLoss: Yup.number().nullable().required(),
  ambientTemperature: Yup.number().nullable().required(),
  condensingTemperature: Yup.number().nullable().required(),
  compressorType: Yup.string().nullable().required(),
  fairing: Yup.string().nullable().required(),
  hotGasDefrost: Yup.string().nullable().required(),
  capacityControl: Yup.string().nullable().required(),
  realTimePower: Yup.number().nullable().required(),
  rejectedHeat: Yup.number().nullable().required(),
  consumedPower: Yup.number().nullable().required(),
  unitCapacity: Yup.number().nullable().required(),
  commercialDescription: Yup.string().nullable().required('Informe uma descrição.'),
});

const equipmentProfiles = [
  {
    value: 1,
    label: 'Baixo Perfil',
    min: 0, // Lower Possible Number
    max: +process.env.REACT_APP_EQUIPMENTS_PROFILE_LOW,
  },
  {
    value: 2,
    label: 'Médio Perfil',
    min: +process.env.REACT_APP_EQUIPMENTS_PROFILE_LOW,
    max: +process.env.REACT_APP_EQUIPMENTS_PROFILE_MEDIUM,
  },
  {
    value: 3,
    label: 'Alto Perfil',
    min: +process.env.REACT_APP_EQUIPMENTS_PROFILE_MEDIUM,
    max: 100000, // Random High Number
  },
];

type ModalCondensingUnitProps = {
  isCondensingOpen: boolean;
  onCondensingClose: any;
  system: EquipmentSystem;
};

const ModalCondensingUnit = ({ isCondensingOpen, onCondensingClose, system }: ModalCondensingUnitProps) => {
  const dispatch = useDispatch();
  const [condensingProfile, setCondensingProfile] = useState<any>(equipmentProfiles[0]);
  const [condensingUnits, setCondensingUnits] = useState([]);
  const [filteredCondensingUnits, setFilteredCondensingUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [specialEnabled, setSpecialEnabled] = useState(false);
  const { nextStep, prevStep, reset: resetStep, activeStep } = useSteps({ initialStep: 0 });
  const [selectedModel, setSelectedModel] = useState<CondensingUnit>(null);
  const [searchData, setSearchData] = useState(null);
  const [selectedSKU, setSelectedSKU] = useState(null);
  const [optionals, setOptionals] = useState<CondensingUnitOptionals>(null);
  const [optionalsLoading, setOptionalsLoading] = useState(false);
  const [comparisonEnabled, setComparisonEnabled] = useState(false);
  const [comparisonSelected, setComparisonSelected] = useState([]);

  const getOptionals = async (line) => {
    try {
      setOptionalsLoading(true);
      const response = await EquipmentService.getOptionals('condensingUnits', line);
      setOptionals(response.data);
      setOptionalsLoading(false);
    } catch (err) {
      setOptionalsLoading(false);
      toastError('project', 'Erro ao recuperar opcionais.');
      console.error(err);
    }
  };

  useEffect(() => {
    if (isCondensingOpen) {
      getOptionals('Todos');
    }

    return () => setOptionals(null);
  }, [isCondensingOpen]);

  const calcCapacity = () => {
    try {
      const roomsHeatLoad = system.configurations.reduce(
        (acc, configuration: Configuration) => acc + configuration.room.heatLoad.totalKcalh,
        0
      );
      return parseValue2Decimal(roomsHeatLoad);
    } catch (err) {
      return 0;
    }
  };

  const initialValuesFilter = {
    count: 1,
    loadLoss: 0,
    compressorType: null,
    hotGasDefrost: null,
    capacityControl: null,
    capacity: calcCapacity(),
    ambientTemperature: null,
    evaporationTemperature: system.operationTemperature,
    compressor: null,
    logo: null,
    chamber: null,
    condensationControl: null,
    productLine: null,
    numberOfCompressors: null,
  };

  const initialValuesSpecial = {
    model: system.condensingUnit?.equipment?.model || null,
    count: system.condensingUnit?.equipment?.count || null,
    unitCapacity: system.condensingUnit?.equipment?.unitCapacity || null,
    consumedPower: system.condensingUnit?.equipment?.consumedPower || null,
    loadLoss: system.condensingUnit?.equipment?.productCustom?.loadLoss || null,
    ambientTemperature: system.condensingUnit?.equipment?.productCustom?.ambientTemperature || null,
    condensingTemperature: system.condensingUnit?.equipment?.productCustom?.condensingTemperature || null,
    compressorType: system.condensingUnit?.equipment?.productCustom?.compressorType || null,
    fairing: system.condensingUnit?.equipment?.productCustom?.fairing || null,
    hotGasDefrost: system.condensingUnit?.equipment?.productCustom?.hotGasDefrost || null,
    capacityControl: system.condensingUnit?.equipment?.productCustom?.capacityControl || null,
    realTimePower: system.condensingUnit?.equipment?.productCustom?.realTimePower || null,
    rejectedHeat: system.condensingUnit?.equipment?.productCustom?.rejectedHeat || null,
    commercialDescription: system.condensingUnit?.equipment?.productCustom?.commercialDescription || null,
  };

  const getCondensingUnits = async (data, page?, size?) => {
    const { count, capacity, ambientTemperature, powerSupply, productLine, ...rest } = data;

    const body = {
      condensingUnit: {
        ambientTemperature: +ambientTemperature,
        refrigerantFluid: system.refrigerantFluid?.code,
        unitCapacity: capacity / (handlePowerSupply(powerSupply)?.powerSupply60Hz !== null ? count : 0.83),
        ...handlePowerSupply(powerSupply),
        productLine: productLine !== 'Todos' ? productLine : null,
        ...rest,
      },
    };

    try {
      setLoading(true);
      const response = await EquipmentService.getModels(body);
      const resp = response.data;

      console.log('resp => ', resp);

      resp.forEach((element) => {
        element.unitCapacity *= handlePowerSupply(powerSupply)?.powerSupply60Hz !== null ? count : 0.83;
      });

      setCondensingUnits(resp);
      setComparisonSelected([]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastError('project', 'Erro ao recuperar unidades condensadoras.');
      console.error(err);
    }
  };

  const handleCondensingProfileChange = async (profile) => {
    setCondensingProfile(profile);
    setFilteredCondensingUnits(
      condensingUnits.filter(
        (condensingUnit) => condensingUnit.fanDiameter > profile.min && condensingUnit.fanDiameter <= profile.max
      )
    );
  };

  useEffect(() => {
    // TODO
    // handleCondensingProfileChange(equipmentProfiles[0]);
    setFilteredCondensingUnits(condensingUnits);
  }, [condensingUnits]);

  const resetModal = (): void => {
    setCondensingUnits([]);
    setFilteredCondensingUnits([]);
    onCondensingClose();
    resetStep();
    setSpecialEnabled(false);
    setComparisonEnabled(false);
    setSelectedModel(null);
    setSelectedSKU(null);
    setSearchData(null);
    setSpecialEnabled(false);
  };

  const handleCondensingSearch = (values): void => {
    getCondensingUnits(values);
    setSearchData(values);
  };

  const handleCondensingModalClose = (): void => {
    resetModal();
  };

  const handleSubmitCondensing = (): void => {
    const parsedCondensingUnit = {
      ...selectedModel,
      count: searchData.count,
      sku: { id: selectedSKU.id, sku: selectedSKU.sku },
      ambientTemperature: +searchData.ambientTemperature,
      evaporationTemperature: searchData.evaporationTemperature,
      heatLoad: selectedModel.unitCapacity,
      temperatureDelta: null,
      unitAirFlow: null,
      unitCapacityDt: system?.resultingCapacity,
      consumedPower: selectedSKU.consumedPower,
      voltage60hz: selectedSKU.voltage60hz,
      voltage50hz: selectedSKU.voltage50hz,
      powerSupplyPhases: selectedSKU.powerSupplyPhases,
    };
    console.log('system (2) => ', system);
    dispatch(selectCondensingUnit(system.configurations[0], parsedCondensingUnit));
    console.log(
      'selectCondensingUnit(system.configurations[0], parsedCondensingUnit) => ',
      selectCondensingUnit(system.configurations[0], parsedCondensingUnit)
    );
    // dispatch(selectCondensingUnit(system.configurations.parsedCondensingUnit));
    resetModal();
  };

  const handleChangeSpecial = (): void => {
    setSpecialEnabled(!specialEnabled);
  };

  const handleChangeComparison = (): void => {
    if (comparisonEnabled) {
      setComparisonSelected([]);
    }

    setComparisonEnabled(!comparisonEnabled);
  };

  const handleSubmitSpecial = (selectedCondUnit) => {
    const { count, model, consumedPower, heatLoad, unitCapacity } = selectedCondUnit;

    const special = {
      count,
      model,
      consumedPower,
      heatLoad,
      unitCapacity: count * unitCapacity,
      nominalUnitCapacity: unitCapacity,
      specialEquipment: true,
      productCustom: {
        ...selectedCondUnit,
      },
    };

    dispatch(selectCondensingUnit(system.configurations[0], special));
    resetModal();
  };

  const handleStepPrevious = (): void => {
    if (activeStep === 0) {
      handleCondensingModalClose();
    } else {
      prevStep();
    }
  };

  const handleStepNext = (): void => {
    if (activeStep === 1) {
      handleSubmitCondensing();
    } else {
      nextStep();
    }
  };

  const handleSelectModel = (condensingUnit: CondensingUnit): void => {
    setSelectedModel(condensingUnit);
  };

  const handleSelectSKU = (sku: any): void => {
    setSelectedSKU(sku);
  };

  const getImageFromAssets = (line: string): string => {
    if (line) {
      return `${process.env.PUBLIC_URL}/assets/images/unidades-condensadoras/${line.split(' ').join('_')}.jpg`;
    }

    return null;
  };

  const handleComparisonSelected = (event: React.ChangeEvent<HTMLInputElement>, condensingUnit: CondensingUnit) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      if (comparisonSelected.length === 3) return;

      const newItem = {
        id: condensingUnit.id,
        model: condensingUnit.model,
        evaporationTemperature: system.operationTemperature,
        refrigerantFluid: system.refrigerantFluid.code,
        nominalCapacity: condensingUnit.nominalUnitCapacity,
        ambientTemperature: searchData.ambientTemperature,
      };

      setComparisonSelected([...comparisonSelected, newItem]);
    } else {
      const newArray = comparisonSelected.filter((selected: CondensingUnit) => selected.id !== condensingUnit.id);
      setComparisonSelected([...newArray]);
    }
  };

  return (
    <Modal isOpen={isCondensingOpen} size="6xl" onClose={handleCondensingModalClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent className="modal__select">
        <ModalHeader>
          <Text color={colors.secondary} fontSize="1.25rem" fontWeight="500">
            Seleção de Unidade Condensadora
          </Text>
          <Stack direction={['column', 'row', 'row']} spacing={5} mt={2}>
            <VStack justify="start" align="start" spacing={0}>
              <Text color={colors.gray100} fontSize=".8rem" fontWeight="700">
                {`${system?.name}`}
              </Text>
              <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                Fluído Refrigerante: {`${system?.refrigerantFluid?.code}`}
              </Text>
              <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                Regime Estimado: {`${system?.operationTemperature} ºC`}
              </Text>
              <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                Temperatura de Evaporação: {`${system?.operationTemperature} ºC`}
              </Text>
            </VStack>
            {selectedModel?.model && (
              <VStack justify="start" align="start" spacing={0}>
                <Text color={colors.gray100} fontSize=".8rem" fontWeight="700">
                  Equipamento Selecionado
                </Text>
                <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                  Linha: {selectedModel?.line}
                </Text>
                <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                  Marca: Elgin
                </Text>
                <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                  Modelo: {selectedModel?.model}
                </Text>
              </VStack>
            )}
            <VStack justify="start" align="start" spacing={0}>
              <Text className="modal__select__section--info">
                Para sistemas com múltiplas câmaras por Unidade Condensadora, não selecionar unidades sem controle de
                capacidade.
              </Text>
            </VStack>
          </Stack>
        </ModalHeader>
        <ModalCloseButton onClick={() => handleCondensingModalClose()} />
        <ModalBody>
          <Steps activeStep={activeStep} colorScheme="twitter" size="sm" responsive={false}>
            <Step label="Modelo">
              <Box mt={5}>
                <SimpleGrid columns={[12]} gap={5}>
                  <GridItem colStart={[1]} colEnd={[13, 13, 6, 6]} h="100%">
                    <Box>
                      <Text className="modal__select__section--title"> Pesquisa de Unidades Condensadoras </Text>
                      <CondensingUnitForm
                        searchData={searchData}
                        initialValuesFilter={initialValuesFilter}
                        schemaFilter={schemaFilter}
                        handleCondensingSearch={handleCondensingSearch}
                        optionalsLoading={optionalsLoading}
                        optionals={optionals}
                        optionalsFull={optionals}
                        specialEnabled={specialEnabled}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colStart={[1, 1, 7]} colEnd={[13]} h="100%">
                    <Box>
                      <Text className="modal__select__section--title"> Sugestão de Unidades Condensadoras </Text>
                      <Stack align="center" direction="column" mb={5} spacing={5}>
                        {condensingUnits.length > 0 && (
                          <FormControl display="flex" alignItems="center">
                            <FormLabel
                              htmlFor="enable-comparison"
                              mb="0"
                              fontSize={13}
                              fontWeight="500"
                              color={colors.gray100}
                            >
                              Comparar Modelos?
                            </FormLabel>
                            <Switch
                              size="sm"
                              id="enable-comparison"
                              onChange={handleChangeComparison}
                              isChecked={comparisonEnabled}
                            />
                            {comparisonEnabled && (
                              <Text color={colors.gray100} fontSize={12} ml={3}>
                                {`${comparisonSelected.length} de 3 selecionados`}
                              </Text>
                            )}
                          </FormControl>
                        )}
                      </Stack>

                      {specialEnabled ? (
                        <Formik
                          initialValues={initialValuesSpecial}
                          isInitialValid
                          validationSchema={schemaSpecial}
                          validateOnChange={false}
                          onSubmit={(values) => {
                            handleSubmitSpecial(values);
                          }}
                        >
                          {({ values, submitForm }) => (
                            <Form className="form">
                              <SimpleGrid columns={[2]} spacing={[5]}>
                                <Box>
                                  <Field
                                    size="xs"
                                    name="model"
                                    component={Input}
                                    type="text"
                                    label="Modelo"
                                    value={values.model || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="count"
                                    component={Input}
                                    type="number"
                                    label="Quantidade"
                                    value={values.count || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="loadLoss"
                                    component={Input}
                                    type="number"
                                    label="Perda de Carga (K) *"
                                    value={values.loadLoss || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="ambientTemperature"
                                    component={Input}
                                    type="number"
                                    label="Temperatura Ambiente"
                                    value={values.ambientTemperature || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="condensingTemperature"
                                    component={Input}
                                    type="number"
                                    label="Temperatura de Condensação"
                                    value={values.condensingTemperature || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="compressorType"
                                    component={Input}
                                    type="text"
                                    label="Tipo de Compressor"
                                    value={values.compressorType || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="fairing"
                                    component={Input}
                                    type="text"
                                    label="Carenagem"
                                    value={values.fairing || ''}
                                  />
                                </Box>
                                <Box>
                                  <Field
                                    size="xs"
                                    name="hotGasDefrost"
                                    component={Input}
                                    type="text"
                                    label="Degelo a Gás Quente"
                                    value={values.hotGasDefrost || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="capacityControl"
                                    component={Input}
                                    type="text"
                                    label="Controle de Capacidade"
                                    value={values.capacityControl || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="consumedPower"
                                    component={Input}
                                    type="number"
                                    label="Potência Máxima Consumida"
                                    value={values.consumedPower || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="realTimePower"
                                    component={Input}
                                    type="number"
                                    label="Consumo em Regime"
                                    value={values.realTimePower || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="unitCapacity"
                                    component={Input}
                                    type="number"
                                    label="Capacidade"
                                    value={values.unitCapacity || ''}
                                  />
                                  <Field
                                    size="xs"
                                    name="rejectedHeat"
                                    component={Input}
                                    type="number"
                                    label="Calor Rejeitado"
                                    value={values.rejectedHeat || ''}
                                  />
                                  <Table
                                    className="equipment__table"
                                    size="xs"
                                    variant="unstyled"
                                    colorScheme="blackAlpha"
                                  >
                                    <Tbody>
                                      <Tr>
                                        <Td>Capacidade Total</Td>
                                        <Td>{parseValue2Decimal(values.count * values.unitCapacity)}</Td>
                                      </Tr>
                                      <Tr>
                                        <Td>Capacidade Necessária</Td>
                                        <Td>1</Td>
                                      </Tr>
                                      <Tr>
                                        <Td>Folga de capacidade</Td>
                                        <Td>1</Td>
                                      </Tr>
                                    </Tbody>
                                  </Table>
                                </Box>
                              </SimpleGrid>
                              <SimpleGrid columns={[1]} spacing={[5]} mt={[5, 0]}>
                                <Field
                                  size="xs"
                                  name="commercialDescription"
                                  component={TextArea}
                                  label="Descrição Comercial"
                                  value={values.commercialDescription || ''}
                                />
                              </SimpleGrid>
                              <ModalFooter padding="40px 0 15px">
                                <Button mr={3} $default onClick={() => handleCondensingModalClose()}>
                                  Cancelar
                                </Button>
                                <Button $primary disabled={false} mr={3} onClick={submitForm}>
                                  Confirmar
                                </Button>
                              </ModalFooter>
                            </Form>
                          )}
                        </Formik>
                      ) : (
                        <>
                          {/* <HStack justify="center">
                            {condensingUnits.length > 0 && (
                              <ButtonGroup
                                className="modal__select__button--group"
                                size="sm"
                                isAttached
                                variant="outline"
                              >
                                {equipmentProfiles.map((profile: any) => (
                                  <ChakraButton
                                    key={profile.value}
                                    className={`modal__select__button--item
                                    ${condensingProfile.value === profile.value ? 'active' : null} `}
                                    onClick={() => handleCondensingProfileChange(profile)}
                                  >
                                    {profile.label}
                                  </ChakraButton>
                                ))}
                              </ButtonGroup>
                            )}
                          </HStack> */}
                          {filteredCondensingUnits.length === 0 && !loading && (
                            <HStack justify="center">
                              {searchData && (
                                <VStack align="center" mt={10}>
                                  <Icon boxSize="60px" as={MdSearch} color={colors.gray100} />
                                  <Text className="modal__select__section--info">
                                    Nenhum modelo encontrado. Recomendação: Selecionar os filtros para
                                    &ldquo;Todos&ldquo;.
                                  </Text>
                                </VStack>
                              )}
                              {system.condensingUnit && !searchData && (
                                <div className="equipment__wrapper">
                                  <Text color={colors.secondary} fontSize="14px">
                                    Selecionado
                                  </Text>
                                  <div className="equipment__item elgin__box--shadow active">
                                    <SimpleGrid columns={[1, 2]} spacing={5} w="100%">
                                      <VStack justify="space-around">
                                        <Image
                                          className="equipment__image"
                                          fit="contain"
                                          src={getImageFromAssets(system.condensingUnit.equipment.line)}
                                          alt="Evaporator Image"
                                          fallback={<Icon as={BsImage} boxSize="120px" color={colors.gray100} />}
                                        />
                                        <Text className="equipment__name" align="left">
                                          {system.condensingUnit?.equipment.count}{' '}
                                          {system.condensingUnit?.equipment.model}
                                        </Text>
                                      </VStack>
                                      <HStack spacing={10}>
                                        <EquipmentTable
                                          equipment={system.condensingUnit.equipment}
                                          searchData={searchData}
                                          selectedConfiguration={null}
                                          equipmentType={EquipmentInterfaceTypes.CONDENSING_UNIT}
                                        />
                                      </HStack>
                                    </SimpleGrid>
                                  </div>
                                </div>
                              )}
                            </HStack>
                          )}

                          <VStack className="equipment__wrapper" align="center" justify="center" mt={5}>
                            {loading && (
                              <Stack w="100%">
                                <Skeleton height="200px" />
                                <Skeleton height="200px" />
                                <Skeleton height="200px" />
                              </Stack>
                            )}

                            {!loading && (
                              <>
                                {filteredCondensingUnits.map((condensing: CondensingUnit, index: number) => (
                                  <HStack justify="space-between" position="relative">
                                    <Box alignSelf="start" className="equipment__checkbox--wrapper">
                                      {comparisonEnabled && (
                                        <Checkbox
                                          onChange={(e) => {
                                            handleComparisonSelected(e, condensing);
                                          }}
                                          colorScheme="twitter"
                                          isChecked={
                                            comparisonSelected.filter((selected) => selected.id === condensing.id)
                                              .length > 0
                                          }
                                        >
                                          <Text fontSize={13} fontWeight={400} color={colors.gray100}>
                                            Comparar
                                          </Text>
                                        </Checkbox>
                                      )}
                                    </Box>
                                    {(condensing.unitCapacity / parseValue2Decimal(searchData.capacity)) * 100 > 90 && (
                                      <button
                                        onClick={() => handleSelectModel(condensing)}
                                        key={String(index)}
                                        type="button"
                                        disabled={comparisonEnabled}
                                        className={`equipment__item elgin__box--shadow ${
                                          selectedModel?.model === condensing.model && 'active'
                                        }`}
                                      >
                                        <SimpleGrid columns={[1, 2]} spacing={5} w="100%">
                                          <VStack>
                                            <Image
                                              className="equipment__image"
                                              fit="contain"
                                              src={getImageFromAssets(condensing.line)}
                                              alt="Condensing Unit Image"
                                              fallback={<Icon as={BsImage} color={colors.gray100} />}
                                            />
                                            <Text className="equipment__name" align="left">
                                              {condensing.model}
                                            </Text>
                                          </VStack>
                                          <HStack spacing={10}>
                                            <EquipmentTable
                                              equipment={condensing}
                                              equipmentType={EquipmentInterfaceTypes.CONDENSING_UNIT}
                                              searchData={searchData}
                                            />
                                          </HStack>
                                        </SimpleGrid>
                                      </button>
                                    )}
                                  </HStack>
                                ))}
                              </>
                            )}
                          </VStack>
                        </>
                      )}
                    </Box>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </Step>
            {comparisonEnabled ? (
              <Step label="Comparativo">
                <EquipmentTableComparison
                  equipmentType={EquipmentInterfaceTypes.CONDENSING_UNIT}
                  comparisonSelected={comparisonSelected}
                />
              </Step>
            ) : (
              <Step label="SKU">
                <StepSKU
                  equipmentType={EquipmentInterfaceTypes.CONDENSING_UNIT}
                  selectedModel={selectedModel}
                  searchData={searchData}
                  selectSKU={handleSelectSKU}
                  system={system}
                />
              </Step>
            )}
          </Steps>
        </ModalBody>
        {!specialEnabled && (
          <ModalFooter mt={1}>
            <Button mr={3} $default onClick={() => handleStepPrevious()}>
              {activeStep === 0 ? 'Cancelar' : 'Voltar'}
            </Button>

            {comparisonEnabled ? (
              <Button
                $primary
                disabled={comparisonSelected.length === 0}
                mr={3}
                onClick={() => handleStepNext()}
                style={{ display: activeStep === 0 ? 'block' : 'none' }}
              >
                {activeStep === 0 ? 'Comparar' : ''}
              </Button>
            ) : (
              <Button
                $primary
                disabled={(!selectedModel && activeStep === 0) || (!selectedSKU && activeStep === 1)}
                mr={3}
                onClick={() => handleStepNext()}
              >
                {activeStep === 0 ? 'Avançar' : 'Confirmar'}
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalCondensingUnit;
