/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import {
  Box,
  SimpleGrid,
  GridItem,
  Stack,
  ButtonGroup,
  HStack,
  VStack,
  Skeleton,
  Text,
  Button as ChakraButton,
  Image,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Tr,
  FormControl,
  FormLabel,
  Switch,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { MdSearch } from 'react-icons/md';
import { BsImage } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { forEach } from 'lodash';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import { toastError } from '~/helpers/toast';
import { colors } from '~/styles/colors';
import Yup from '~/helpers/validations';

import {
  EquipmentInterfaceTypes,
  EvaporatorOptionals,
  Evaporator,
  EquipmentSystem,
  Configuration,
} from '~/store/ducks/Equipment/types';
import { selectEvaporator } from '~/store/ducks/Equipment/actions';
import EquipmentService from '~/store/ducks/Equipment/services';
import EquipmentTable from '~/components/Equipments/EquipmentTable';
import StepSKU from '~/components/Equipments/StepSKU';
import Warning from '~/components/Form/Warning';
import { filterObjectEmptyValues, handlePowerSupply, parseValue2Decimal } from '~/helpers/functions';
import EvaporatorForm from '~/components/Equipments/SearchForms/EvaporatorForm';
import EquipmentTableComparison from '~/components/Equipments/EquipmentTable/EquipmentTableComparison';
import TextArea from '~/components/Form/TextArea';

const schemaFilter = Yup.object().shape({
  count: Yup.number().nullable().required(),
  unitCapacity: Yup.number().nullable(),
  evaporatorDt: Yup.number().nullable().required(),
  defrost: Yup.string().nullable(),
  finsPerInch: Yup.number().nullable(),
  productLine: Yup.string().nullable(),
  fanDiameter: Yup.number().nullable(),
  numberOfFans: Yup.number().nullable(),
  powerSupply: Yup.string().nullable(),
  operationTemperature: Yup.number().nullable(),
});

const schemaSpecial = Yup.object().shape({
  model: Yup.string().required(),
  count: Yup.number().required(),
  temperatureDelta: Yup.number().required(),
  wingPerInch: Yup.string().required(),
  defrost: Yup.string().required(),
  airArrow: Yup.string().required(),
  fanDiameter: Yup.number().required(),
  defrostPower: Yup.number().required(),
  fanPower: Yup.number().required(),
  unitCapacity: Yup.number().required(),
  unitAirFlow: Yup.number().required(),
  commercialDescription: Yup.string().required(),
});

interface Profile {
  value: number;
  label: string;
  min: number;
  max: number;
}

const equipmentProfiles: Profile[] = [
  {
    value: 1,
    label: 'Baixo Perfil',
    min: 0, // Lower Possible Number
    max: +process.env.REACT_APP_EQUIPMENTS_PROFILE_LOW,
  },
  {
    value: 2,
    label: 'Médio Perfil',
    min: +process.env.REACT_APP_EQUIPMENTS_PROFILE_LOW,
    max: +process.env.REACT_APP_EQUIPMENTS_PROFILE_MEDIUM,
  },
  {
    value: 3,
    label: 'Alto Perfil',
    min: +process.env.REACT_APP_EQUIPMENTS_PROFILE_MEDIUM,
    max: 100000, // Random High Number
  },
];

type ModalEvaporatorProps = {
  isEvaporatorOpen: boolean;
  onEvaporatorClose: any;
  selectedConfiguration: Configuration;
  system: EquipmentSystem;
};

const ModalEvaporator = ({
  isEvaporatorOpen,
  onEvaporatorClose,
  selectedConfiguration,
  system,
}: ModalEvaporatorProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [evaporatorProfile, setEvaporatorProfile] = useState<any>(equipmentProfiles[0]);
  const [evaporators, setEvaporators] = useState([]);
  const [filteredEvaporators, setFilteredEvaporators] = useState([]);
  const [specialEnabled, setSpecialEnabled] = useState(false);
  const { nextStep, prevStep, reset: resetStep, activeStep } = useSteps({ initialStep: 0 });
  const [selectedModel, setSelectedModel] = useState<Evaporator>(null);
  const [searchData, setSearchData] = useState(null);
  const [selectedSKU, setSelectedSKU] = useState(null);
  const [optionals, setOptionals] = useState<EvaporatorOptionals>(null);
  const [optionalsFiltered, setOptionalsFiltered] = useState<any>(null);
  const [optionalsLoading, setOptionalsLoading] = useState(false);
  const [optionalsPowerSupply, setOptionalsPowerSupply] = useState([]);
  const [comparisonEnabled, setComparisonEnabled] = useState(false);
  const [comparisonSelected, setComparisonSelected] = useState([]);

  const initialValuesFilter = {
    count: 1,
    unitCapacity: null,
    evaporatorDt:
      parseValue2Decimal(selectedConfiguration?.room.temperatureDelta) ||
      parseValue2Decimal(selectedConfiguration?.equipment.temperatureDelta),
    defrost: null,
    fanDiameter: null,
    finsPerInch: null,
    productLine: null,
    numberOfFans: null,
    powerSupply: null,
    operationTemperature: system?.operationTemperature,
  };

  const initialValuesSpecial = {
    model: selectedConfiguration?.equipment?.model || null,
    count: selectedConfiguration?.equipment?.count || null,
    unitCapacity: selectedConfiguration?.equipment?.unitCapacity || null,
    unitAirFlow: selectedConfiguration?.equipment?.unitAirFlow || null,
    temperatureDelta: parseValue2Decimal(selectedConfiguration?.equipment?.temperatureDelta) || null,
    wingPerInch: selectedConfiguration?.equipment?.productCustom?.wingPerInch || null,
    defrost: selectedConfiguration?.equipment?.productCustom?.defrost || null,
    airArrow: selectedConfiguration?.equipment?.productCustom?.airArrow || null,
    fanDiameter: selectedConfiguration?.equipment?.productCustom?.fanDiameter || null,
    defrostPower: selectedConfiguration?.equipment?.productCustom?.defrostPower || null,
    fanPower: selectedConfiguration?.equipment?.productCustom?.fanPower || null,
    commercialDescription: selectedConfiguration?.equipment?.productCustom?.commercialDescription || null,
  };

  const filterObjectDefrost = (item: string[], temperature: number) => {
    const array: string[] = [];
    if (item !== undefined) {
      item.forEach((element) => {
        if (temperature < 2) {
          if (element.toUpperCase() !== 'AR') {
            array.push(element);
          }
        } else if (element.toUpperCase() === 'AR') {
          array.push(element);
        }
      });
    }
    return array;
  };

  const getOptionals = async (line) => {
    try {
      setOptionalsLoading(true);
      const response = await EquipmentService.getOptionals('evaporators', line);
      setOptionals(response.data);
      setOptionalsLoading(false);
      setOptionalsFiltered(filterObjectDefrost(response.data.defrost, selectedConfiguration?.room.roomTemperature));
    } catch (err) {
      setOptionalsLoading(false);
      toastError('project', 'Erro ao recuperar opcionais.');
      console.error(err);
    }
  };

  useEffect(() => {
    if (isEvaporatorOpen) {
      getOptionals('Todos');
    }

    return () => setOptionals(null);
  }, [isEvaporatorOpen]);

  const parseOptionalsPowerSupply = (opts) => {
    const powerSupply50Hz = opts.powerSupply50Hz.sort((a, b) => a - b);
    const powerSupply60Hz = opts.powerSupply60Hz.sort((a, b) => a - b);
    const powerSupplyPhases = opts.powerSupplyPhases.sort((a, b) => a - b);
    const parsedArray: string[] = [];

    for (const voltage of powerSupply50Hz) {
      for (const phase of powerSupplyPhases) {
        if (voltage === 220 || (voltage === 380 && phase !== 1)) {
          parsedArray.push(`50hz, ${voltage}v, ${phase}F`);
        }
      }
    }

    for (const voltage of powerSupply60Hz) {
      for (const phase of powerSupplyPhases) {
        if (voltage === 220) {
          parsedArray.push(`60hz, ${voltage}v, ${phase}F`);
        } else if (phase !== 1) {
          parsedArray.push(`60hz, ${voltage}v, ${phase}F`);
        }
      }
    }

    setOptionalsPowerSupply(parsedArray);
  };

  useEffect(() => {
    if (optionals) {
      parseOptionalsPowerSupply(optionals);
    }
  }, [optionals]);

  const getEvaporators = async (data, page?, size?) => {
    const { count, evaporatorDt, powerSupply, productLine, defrost, ...rest } = data;

    // Gets to for delta temperature difference
    const dTResponse = await EquipmentService.getOptionals('evaporators', data.productLine || 'Todos');
    const dTIndex = dTResponse.data.delta_temperature_difference.findIndex((x) => x[0].includes(evaporatorDt));
    const dT = dTIndex >= 0 ? dTResponse.data.delta_temperature_difference[dTIndex][1][0] : 0;

    const body = {
      evaporator: {
        evaporationTemperature: system?.operationTemperature,
        refrigerantFluid: system.refrigerantFluid.code,
        evaporatorDt: dT,
        defrost: defrost !== undefined ? [defrost] : optionalsFiltered,
        unitCapacity: selectedConfiguration.room.heatLoad.totalKcalh / count,
        ...handlePowerSupply(powerSupply),
        ...rest,
        operationTemperature: parseValue2Decimal(selectedConfiguration?.room.roomTemperature),
        productLine: productLine !== 'Todos' ? productLine : null,
      },
    };

    try {
      setLoading(true);
      const response = await EquipmentService.getModels(body);
      const resp = response.data;

      resp.forEach((element) => {
        // element.unitCapacityDt *= handlePowerSupply(powerSupply)?.powerSupply60Hz !== null ? count : 0.87;
        element.unitCapacityDt *= count;
      });

      setEvaporators(resp);
      setLoading(false);
      setComparisonSelected([]);
    } catch (err) {
      setLoading(false);
      toastError('project', 'Erro ao recuperar evaporadores.');
      console.error(err);
    }
  };

  const handleEvaporatorProfileChange = (profile?: Profile) => {
    const lowProfile: Profile = equipmentProfiles[0];
    const mediumProfile: Profile = equipmentProfiles[1];
    const highProfile: Profile = equipmentProfiles[2];

    if (evaporators && evaporators.length === 0) {
      setFilteredEvaporators([]);
      return;
    }

    if (profile) {
      setEvaporatorProfile(profile);
      setFilteredEvaporators(
        evaporators.filter(
          (evaporator: Evaporator) => evaporator.fanDiameter > profile.min && evaporator.fanDiameter <= profile.max
        )
      );

      return;
    }

    // Low Profile
    const lowProfileEquipments = evaporators.filter(
      (evaporator: Evaporator) => evaporator.fanDiameter > lowProfile.min && evaporator.fanDiameter <= lowProfile.max
    );

    if (lowProfileEquipments.length > 0) {
      setEvaporatorProfile(lowProfile);
      setFilteredEvaporators(lowProfileEquipments);
      return;
    }

    // Medium Profile
    const mediumProfileEquipments = evaporators.filter(
      (evaporator: Evaporator) =>
        evaporator.fanDiameter > mediumProfile.min && evaporator.fanDiameter <= mediumProfile.max
    );

    if (mediumProfileEquipments.length > 0) {
      setEvaporatorProfile(mediumProfile);
      setFilteredEvaporators(mediumProfileEquipments);
      return;
    }

    // High Profile
    const highProfileEquipments = evaporators.filter(
      (evaporator: Evaporator) => evaporator.fanDiameter > highProfile.min && evaporator.fanDiameter <= highProfile.max
    );

    if (highProfileEquipments.length > 0) {
      setEvaporatorProfile(highProfile);
      setFilteredEvaporators(highProfileEquipments);
    }
  };

  useEffect(() => {
    try {
      handleEvaporatorProfileChange(equipmentProfiles[0]);
    } catch (err) {
      handleEvaporatorProfileChange(equipmentProfiles[0]);
    }
  }, [evaporators]);

  const resetModal = (): void => {
    setEvaporators([]);
    setFilteredEvaporators([]);
    onEvaporatorClose();
    resetStep();
    setSpecialEnabled(false);
    setComparisonEnabled(false);
    setSelectedModel(null);
    setSelectedSKU(null);
    setSearchData(null);
  };

  const handleEvaporatorSearch = (values): void => {
    getEvaporators(values);
    setSearchData(values);
  };

  const handleEvaporatorModalClose = (): void => {
    resetModal();
  };

  const handleSubmitEvaporator = (): void => {
    const selectedEvaporatorWithCount = {
      ...selectedModel,
      count: searchData.count,
      sku: { id: selectedSKU.id, sku: selectedSKU.sku },
      heatLoad: selectedModel.unitCapacity,
      temperatureDelta: selectedConfiguration.room.temperatureDelta,
      consumedPower: selectedSKU.consumedPower,
      voltage60hz: selectedSKU.voltage60hz,
      voltage50hz: selectedSKU.voltage50hz,
      powerSupplyPhases: selectedSKU.powerSupplyPhases,
    };
    console.log('system (1) => ', system);
    dispatch(selectEvaporator(selectedConfiguration, selectedEvaporatorWithCount));
    console.log(
      'selectCondensingUnit(selectedConfiguration, selectedEvaporatorWithCount) => ',
      selectEvaporator(selectedConfiguration, selectedEvaporatorWithCount)
    );
    resetModal();
  };

  const handleChangeSpecial = (): void => {
    setSpecialEnabled(!specialEnabled);
  };

  const handleChangeComparison = (): void => {
    if (comparisonEnabled) {
      setComparisonSelected([]);
    }

    setComparisonEnabled(!comparisonEnabled);
  };

  const handleSubmitSpecialEvaporator = (selectedEvaporator) => {
    const { count, model, temperatureDelta, heatLoad, unitCapacity, unitAirFlow, fanPower } = selectedEvaporator;

    const special = {
      count,
      model,
      consumedPower: count * fanPower,
      heatLoad,
      unitCapacity: count * unitCapacity,
      nominalUnitCapacity: unitCapacity,
      temperatureDelta,
      specialEquipment: true,
      unitAirFlow,
      unitCapacityDt: null,
      airExchangeRate: null,
      productCustom: {
        ...selectedEvaporator,
      },
    };

    dispatch(selectEvaporator(selectedConfiguration, special));
    resetModal();
  };

  const handleStepPrevious = (): void => {
    if (activeStep === 0) {
      handleEvaporatorModalClose();
    } else {
      prevStep();
    }
  };

  const handleStepNext = (): void => {
    if (activeStep === 1) {
      handleSubmitEvaporator();
    } else {
      nextStep();
    }
  };

  const handleSelectModel = (evaporator: Evaporator): void => {
    setSelectedModel(evaporator);
  };

  const handleSelectSKU = (sku: any): void => {
    setSelectedSKU(sku);
  };

  const getImageFromAssets = (line: string): string | null => {
    if (line) {
      return `${process.env.PUBLIC_URL}/assets/images/evaporadores/${line.split(' ').join('_')}.jpg`;
    }

    return null;
  };

  const handleComparisonSelected = (event: React.ChangeEvent<HTMLInputElement>, evaporator: Evaporator) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      if (comparisonSelected.length === 3) return;

      const newItem = {
        id: evaporator.id,
        model: evaporator.model,
        evaporationTemperature: system.operationTemperature,
        refrigerantFluid: system.refrigerantFluid.code,
        nominalCapacity: evaporator.nominalUnitCapacity,
      };

      setComparisonSelected([...comparisonSelected, newItem]);
    } else {
      const newArray = comparisonSelected.filter((selected: Evaporator) => selected.id !== evaporator.id);
      setComparisonSelected([...newArray]);
    }
  };

  return (
    <>
      <Modal isOpen={isEvaporatorOpen} size="6xl" onClose={onEvaporatorClose} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent className="modal__select">
          <ModalHeader>
            <Text color={colors.secondary} fontSize="1.25rem" fontWeight="500">
              Seleção de Evaporadores
            </Text>
            <Stack direction={['column', 'row', 'row']} spacing={5} mt={2}>
              <VStack justify="start" align="start" spacing={0}>
                <Text color={colors.gray100} fontSize=".8rem" fontWeight="700">
                  {`${system?.name}`}
                </Text>
                <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                  Fluído Refrigerante: {`${system?.refrigerantFluid?.code}`}
                </Text>
                <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                  Regime Estimado: {`${system?.operationTemperature} ºC`}
                </Text>
              </VStack>
              <VStack justify="start" align="start" spacing={0}>
                <Text color={colors.gray100} fontSize=".8rem" fontWeight="700">
                  {`${selectedConfiguration?.room.name}`}
                </Text>
                <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                  Temperatura Interna: {`${parseValue2Decimal(selectedConfiguration?.room.roomTemperature)} ºC`} (DT:{' '}
                  {parseValue2Decimal(
                    selectedConfiguration?.room.temperatureDelta || selectedConfiguration?.equipment.temperatureDelta
                  )}
                  )
                </Text>
                <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                  Carga Térmica: {`${parseValue2Decimal(selectedConfiguration?.room.heatLoad.totalKcalh)} Kcal`}
                </Text>
              </VStack>
              {selectedModel?.model && (
                <VStack justify="start" align="start" spacing={0}>
                  <Text color={colors.gray100} fontSize=".8rem" fontWeight="700">
                    Equipamento Selecionado
                  </Text>
                  <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                    Linha: {selectedModel?.line}
                  </Text>
                  <Text color={colors.gray100} fontSize=".75rem" fontWeight="500">
                    Modelo: {selectedModel?.model}
                  </Text>
                </VStack>
              )}
            </Stack>
          </ModalHeader>
          <ModalCloseButton onClick={() => handleEvaporatorModalClose()} />
          <ModalBody>
            <Steps activeStep={activeStep} colorScheme="twitter" size="sm" responsive={false}>
              <Step label="Modelo">
                <Box mt={5}>
                  <SimpleGrid columns={[12]} gap={5}>
                    <GridItem colStart={[1]} colEnd={[13, 13, 6, 6]} h="100%">
                      <Box>
                        <Text className="modal__select__section--title"> Pesquisa de Evaporadores</Text>
                        <EvaporatorForm
                          searchData={searchData}
                          initialValuesFilter={initialValuesFilter}
                          schemaFilter={schemaFilter}
                          handleEvaporatorSearch={handleEvaporatorSearch}
                          optionalsFull={optionals}
                          optionalsLoading={optionalsLoading}
                          optionals={optionals}
                          optionalsFiltered={optionalsFiltered}
                          optionalsPowerSupply={optionalsPowerSupply}
                          specialEnabled={specialEnabled}
                          operationTemperature={system?.operationTemperature}
                        />
                      </Box>
                    </GridItem>
                    <GridItem colStart={[1, 1, 7]} colEnd={[13]} h="100%">
                      <Box>
                        <Text className="modal__select__section--title"> Sugestão de Evaporadores </Text>
                        <Stack align="center" direction="column" mb={5} spacing={5}>
                          {evaporators.length > 0 && (
                            <FormControl display="flex" alignItems="center">
                              <FormLabel
                                htmlFor="enable-comparison"
                                mb="0"
                                fontSize={13}
                                fontWeight="500"
                                color={colors.gray100}
                              >
                                Comparar Modelos?
                              </FormLabel>
                              <Switch
                                size="sm"
                                id="enable-comparison"
                                onChange={handleChangeComparison}
                                isChecked={comparisonEnabled}
                              />
                              {comparisonEnabled && (
                                <Text color={colors.gray100} fontSize={12} ml={3}>
                                  {`${comparisonSelected.length} de 3 selecionados`}
                                </Text>
                              )}
                            </FormControl>
                          )}
                        </Stack>
                        {specialEnabled ? (
                          <Formik
                            initialValues={initialValuesSpecial}
                            isInitialValid
                            validationSchema={schemaSpecial}
                            validateOnChange={false}
                            onSubmit={(values) => {
                              handleSubmitSpecialEvaporator(values);
                            }}
                          >
                            {({ values, submitForm }) => (
                              <Form className="form">
                                <SimpleGrid columns={[2]} spacing={[5]}>
                                  <Box>
                                    <Field
                                      size="xs"
                                      name="model"
                                      component={Input}
                                      type="text"
                                      label="Modelo"
                                      value={values.model || ''}
                                    />
                                    <Field
                                      size="xs"
                                      name="count"
                                      component={Input}
                                      type="number"
                                      label="Quantidade"
                                      value={values.count || ''}
                                    />
                                    <Field
                                      size="xs"
                                      name="wingPerInch"
                                      component={Input}
                                      type="number"
                                      label="Aletas por Polegada"
                                      value={values.wingPerInch || ''}
                                    />
                                    <Field
                                      size="xs"
                                      name="defrost"
                                      component={Input}
                                      type="text"
                                      label="Tipo de Degelo"
                                      value={values.defrost || ''}
                                    />
                                    <Field
                                      size="xs"
                                      name="airArrow"
                                      component={Input}
                                      type="number"
                                      label="Flecha de Ar Mínima"
                                      value={values.airArrow || ''}
                                    />
                                  </Box>
                                  <Box>
                                    <Field
                                      size="xs"
                                      name="fanDiameter"
                                      component={Input}
                                      type="number"
                                      label="Diâmetro do Ventilador"
                                      value={values.fanDiameter || ''}
                                    />
                                    <Field
                                      size="xs"
                                      name="defrostPower"
                                      component={Input}
                                      type="number"
                                      label="Potência Degelo"
                                      value={values.defrostPower || ''}
                                    />
                                    <Field
                                      size="xs"
                                      name="fanPower"
                                      component={Input}
                                      type="number"
                                      label="Potência Ventilador"
                                      value={values.fanPower || ''}
                                    />
                                    <Field
                                      size="xs"
                                      name="unitCapacity"
                                      component={Input}
                                      type="number"
                                      label="Capacitade Unitária"
                                      value={values.unitCapacity || ''}
                                    />
                                    <Field
                                      size="xs"
                                      name="unitAirFlow"
                                      component={Input}
                                      type="number"
                                      label="Vazão de Ar Unitária"
                                      value={values.unitAirFlow || ''}
                                    />
                                    <Table
                                      className="equipment__table"
                                      size="xs"
                                      variant="unstyled"
                                      colorScheme="blackAlpha"
                                    >
                                      <Tbody>
                                        <Tr>
                                          <Td>Capacidade Total</Td>
                                          <Td>{parseValue2Decimal(values.unitCapacity * values.count)}</Td>
                                        </Tr>
                                        <Tr>
                                          <Td>Vazão de Ar Total</Td>
                                          <Td>{parseValue2Decimal(values.unitAirFlow * values.count)}</Td>
                                        </Tr>
                                        <Tr>
                                          <Td>Número de Trocas</Td>
                                          <Td>
                                            {parseValue2Decimal(
                                              (values.unitAirFlow * values.count) /
                                                (selectedConfiguration.room.length * selectedConfiguration.room.width)
                                            )}
                                          </Td>
                                        </Tr>
                                      </Tbody>
                                    </Table>
                                  </Box>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={[5]} mt={[5, 0]}>
                                  <Field
                                    size="xs"
                                    name="commercialDescription"
                                    component={TextArea}
                                    label="Descrição Comercial"
                                    value={values.commercialDescription || ''}
                                  />
                                </SimpleGrid>
                                <ModalFooter padding="40px 0 15px">
                                  <Button mr={3} $default onClick={() => handleEvaporatorModalClose()}>
                                    Voltar
                                  </Button>
                                  <Button $primary disabled={!selectedConfiguration} mr={3} onClick={submitForm}>
                                    Confirmar
                                  </Button>
                                </ModalFooter>
                              </Form>
                            )}
                          </Formik>
                        ) : (
                          <>
                            <HStack justify="center">
                              {evaporators.length > 0 && (
                                <ButtonGroup
                                  className="modal__select__button--group"
                                  size="sm"
                                  isAttached
                                  variant="outline"
                                >
                                  {equipmentProfiles.map((profile: any) => (
                                    <ChakraButton
                                      key={profile.value}
                                      className={`modal__select__button--item
                                       ${evaporatorProfile.value === profile.value ? 'active' : null} `}
                                      onClick={() => handleEvaporatorProfileChange(profile)}
                                    >
                                      {profile.label}
                                    </ChakraButton>
                                  ))}
                                </ButtonGroup>
                              )}
                            </HStack>
                            {filteredEvaporators.length === 0 && !loading && (
                              <HStack justify="center">
                                {searchData && (
                                  <VStack align="center" mt={10}>
                                    <Icon boxSize="60px" as={MdSearch} color={colors.gray100} />
                                    <Text className="modal__select__section--info">
                                      Nenhum modelo encontrado. Recomendação: Selecionar os filtros para
                                      &ldquo;Todos&ldquo;.
                                    </Text>
                                  </VStack>
                                )}
                                {selectedConfiguration?.equipment && !searchData && (
                                  <div className="equipment__wrapper">
                                    <Text color={colors.secondary} fontSize="14px">
                                      Selecionado
                                    </Text>
                                    <div className="equipment__item elgin__box--shadow active">
                                      <SimpleGrid columns={[1, 2]} spacing={5} w="100%">
                                        <VStack justify="space-around">
                                          <Image
                                            className="equipment__image"
                                            fit="contain"
                                            src={getImageFromAssets(selectedConfiguration.equipment.line)}
                                            alt="Evaporator Image"
                                            fallback={<Icon as={BsImage} boxSize="100px" color={colors.gray100} />}
                                          />
                                          <Text className="equipment__name" align="left">
                                            {selectedConfiguration.equipment?.count}{' '}
                                            {selectedConfiguration.equipment?.model}
                                          </Text>
                                        </VStack>
                                        <HStack spacing={10}>
                                          <EquipmentTable
                                            equipment={selectedConfiguration.equipment}
                                            equipmentType={EquipmentInterfaceTypes.EVAPORATOR}
                                            searchData={searchData}
                                            selectedConfiguration={selectedConfiguration}
                                          />
                                        </HStack>
                                      </SimpleGrid>
                                    </div>
                                  </div>
                                )}
                              </HStack>
                            )}
                            <VStack className="equipment__wrapper" align="center" justify="center" mt={5}>
                              {loading && (
                                <Stack w="100%">
                                  <Skeleton height="200px" />
                                  <Skeleton height="200px" />
                                </Stack>
                              )}

                              {!loading && (
                                <>
                                  {filteredEvaporators.map((evaporator: Evaporator, index: number) => (
                                    <HStack justify="space-between" position="relative">
                                      <Box alignSelf="start" className="equipment__checkbox--wrapper">
                                        {comparisonEnabled && (
                                          <Checkbox
                                            onChange={(e) => {
                                              handleComparisonSelected(e, evaporator);
                                            }}
                                            colorScheme="twitter"
                                            isChecked={
                                              comparisonSelected.filter((selected) => selected.id === evaporator.id)
                                                .length > 0
                                            }
                                          >
                                            <Text fontSize={13} fontWeight={400} color={colors.gray100}>
                                              Comparar
                                            </Text>
                                          </Checkbox>
                                        )}
                                      </Box>
                                      <button
                                        onClick={() => handleSelectModel(evaporator)}
                                        key={String(index)}
                                        type="button"
                                        disabled={comparisonEnabled}
                                        className={`equipment__item elgin__box--shadow ${
                                          selectedModel?.model === evaporator.model && 'active'
                                        }`}
                                      >
                                        <SimpleGrid columns={[1, 2]} spacing={5} w="100%">
                                          <VStack>
                                            <Image
                                              className="equipment__image"
                                              fit="contain"
                                              src={getImageFromAssets(evaporator.line)}
                                              alt="Evaporator Image"
                                              fallback={<Icon as={BsImage} color={colors.gray100} />}
                                            />
                                            <Text className="equipment__name" align="left">
                                              {evaporator.count} {evaporator.model}
                                            </Text>
                                          </VStack>
                                          <HStack spacing={10}>
                                            <EquipmentTable
                                              equipment={evaporator}
                                              equipmentType={EquipmentInterfaceTypes.EVAPORATOR}
                                              searchData={searchData}
                                              selectedConfiguration={selectedConfiguration}
                                            />
                                          </HStack>
                                        </SimpleGrid>
                                        {evaporator.length / 1000 > selectedConfiguration.room.length &&
                                          evaporator.length / 1000 > selectedConfiguration.room.width && (
                                            <Warning message="Esta câmara não suporta as dimensões deste equipamento." />
                                          )}
                                      </button>
                                    </HStack>
                                  ))}
                                </>
                              )}
                            </VStack>
                          </>
                        )}
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              </Step>

              {comparisonEnabled ? (
                <Step label="Comparativo">
                  <EquipmentTableComparison
                    equipmentType={EquipmentInterfaceTypes.EVAPORATOR}
                    comparisonSelected={comparisonSelected}
                  />
                </Step>
              ) : (
                <Step label="SKU">
                  <StepSKU
                    equipmentType={EquipmentInterfaceTypes.EVAPORATOR}
                    selectedConfiguration={selectedConfiguration}
                    selectedModel={selectedModel}
                    searchData={searchData}
                    selectSKU={handleSelectSKU}
                    system={system}
                  />
                </Step>
              )}
            </Steps>
          </ModalBody>
          {!specialEnabled && (
            <ModalFooter mt={1}>
              <Button mr={3} $default onClick={() => handleStepPrevious()}>
                {activeStep === 0 ? 'Cancelar' : 'Voltar'}
              </Button>

              {comparisonEnabled ? (
                <Button
                  $primary
                  disabled={comparisonSelected.length === 0}
                  mr={3}
                  onClick={() => handleStepNext()}
                  style={{ display: activeStep === 0 ? 'block' : 'none' }}
                >
                  {activeStep === 0 ? 'Comparar' : ''}
                </Button>
              ) : (
                <Button
                  $primary
                  disabled={(!selectedModel && activeStep === 0) || (!selectedSKU && activeStep === 1)}
                  mr={3}
                  onClick={() => handleStepNext()}
                >
                  {activeStep === 0 ? 'Avançar' : 'Confirmar'}
                </Button>
              )}
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalEvaporator;
