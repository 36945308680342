/* eslint-disable no-console */
import React, { useRef, useState } from 'react';
import {
  Box,
  SimpleGrid,
  GridItem,
  IconButton,
  HStack,
  Text,
  Button as ChakraButton,
  Image,
  Icon,
  Center,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { MdClose, MdEdit } from 'react-icons/md';
import { BsImage } from 'react-icons/bs';

import { useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button } from '~/components/Form/Button/Button_Styles';
import { colors } from '~/styles/colors';
import { Configuration, EquipmentSystem } from '~/store/ducks/Equipment/types';
import {
  removeCompressor,
  removeCondenser,
  removeCondensingUnit,
  removeConfiguration,
  returnConfiguration,
  saveSystem,
} from '~/store/ducks/Equipment/actions';
import SystemSettings from '~/components/Equipments/SystemSettings';
import Stat from '~/components/Form/Stat';
import ModalCondensingUnit from '~/components/Equipments/ModalCondensingUnit';
import ModalEvaporator from '~/components/Equipments/ModalEvaporator';
import { EquipmentsSystemContainer } from '~/components/Equipments/EquipmentsSystem/EquipmentsSystem_Styles';
import { parseValue2Decimal } from '~/helpers/functions';
import ModalCompressor from '~/components/Equipments/ModalCompressor';
import SelectorUnit from '~/components/Equipments/SelectorUnit';
import SelectorCondenser from '~/components/Equipments/SelectorCondenser';
import ModalCondenser from '~/components/Equipments/ModalCondenser';
import SystemResult from '~/components/Equipments/SystemResult';

const EquipmentsSystem = (system: EquipmentSystem) => {
  const dispatch = useDispatch();
  const [selectedConfiguration, setSelectedConfiguration] = useState<Configuration>(null);
  const { isOpen: isEvaporatorOpen, onOpen: onEvaporatorOpen, onClose: onEvaporatorClose } = useDisclosure();
  const { isOpen: isCondensingOpen, onOpen: onCondensingOpen, onClose: onCondensingClose } = useDisclosure();
  const { isOpen: isCompressorOpen, onOpen: onCompressorOpen, onClose: onCompressorClose } = useDisclosure();
  const { isOpen: isCondenserOpen, onOpen: onCondenserOpen, onClose: onCondenserClose } = useDisclosure();
  const { configurations } = system;
  const { condensingUnit, compressor, condenser } = system;
  const nodeRef = useRef(null);

  const handleRemoveConfiguration = (configuration: Configuration): void => {
    dispatch(returnConfiguration(configuration));
    dispatch(removeConfiguration(configuration.room.id));
    setSelectedConfiguration(null);
  };

  const handleEvaporatorModalOpen = (configuration: Configuration): void => {
    setSelectedConfiguration(configuration);
    onEvaporatorOpen();
  };

  const handleRemoveCondensingUnit = (): void => {
    dispatch(removeCondensingUnit());
  };

  const handleRemoveCompressor = (): void => {
    dispatch(removeCompressor());
  };

  const handleRemoveCondenser = (): void => {
    dispatch(removeCondenser());
  };

  const handleConfirmSystem = (): void => {
    setSelectedConfiguration(null);
    dispatch(saveSystem());
  };

  const isSystemRequirementsCorrect = (): boolean => {
    // const emptyEvaporators = system.configurations.filter((configuration) => configuration.equipment === null);

    // console.log('system => ', system);

    // if (system.rack) {
    //   return (
    //     ((system.compressor !== null && typeof system.compressor !== 'undefined') ||
    //       (system.condenser !== null && typeof system.condenser !== 'undefined')) &&
    //     system.configurations?.length > 0 &&
    //     emptyEvaporators.length === 0
    //   );
    // }

    // return system.condensingUnit !== null && system.configurations?.length > 0 && emptyEvaporators.length === 0;

    const isEvaporator = system.configurations.filter((configuration) => configuration.equipment === null).length === 0;
    const isCondensingUnit = system.condensingUnit !== null;
    const isRackCompressor = system.compressor !== null;
    const isCondenser = system.condenser !== null;

    return isEvaporator || isCondensingUnit || isRackCompressor || (isCondenser && isEvaporator && isRackCompressor);
  };

  return (
    <EquipmentsSystemContainer>
      <Box className="block">
        <SimpleGrid columns={[12]} gap={5}>
          <GridItem colStart={[1]} colEnd={[13]}>
            <Box>
              <Text className="block__system--title"> Configuração do Sistema </Text>
              <SystemSettings {...system} />
            </Box>
          </GridItem>
          <GridItem colStart={[1, 1]} colEnd={[13]}>
            <Box>
              <SimpleGrid columns={[12]} gap={5}>
                <GridItem colStart={[1]} colEnd={[13]}>
                  <Text className="block__system--title"> Evaporador </Text>
                  <Box mb={2} overflowY="auto" padding={1}>
                    <CSSTransition
                      in={configurations?.length === 0}
                      timeout={200}
                      classNames="fade__item"
                      unmountOnExit
                    >
                      <Text> Selecione as câmaras compatíveis com esse sistema. </Text>
                    </CSSTransition>
                    <TransitionGroup nodeRef={nodeRef}>
                      {configurations?.map((configuration: Configuration, index: number) => (
                        <CSSTransition ref={nodeRef} key={String(index)} timeout={200} classNames="fade__item">
                          <SimpleGrid className="block__system__grid elgin__box--shadow" columns={[12]} gap={5}>
                            <GridItem className="block__system__grid--item room__name">
                              <Text className="block__system__title">{configuration.room?.name}</Text>
                            </GridItem>

                            <GridItem className="block__system__grid--item room__image">
                              <Center>
                                {configuration.equipment?.specialEquipment ? (
                                  <Image
                                    className="block__system__image"
                                    fit="contain"
                                    src={null}
                                    alt="Evaporator Image"
                                    fallback={<Icon as={BsImage} color={colors.gray100} />}
                                  />
                                ) : (
                                  <Image
                                    className="block__system__image"
                                    fit="contain"
                                    src={`${
                                      process.env.PUBLIC_URL
                                    }/assets/images/evaporadores/${configuration?.equipment?.line
                                      .split(' ')
                                      .join('_')}.jpg`}
                                    alt="Evaporator Image"
                                    fallback={<Icon as={BsImage} color={colors.gray100} />}
                                  />
                                )}
                              </Center>
                            </GridItem>
                            <GridItem className="block__system__grid--item room__equipment">
                              {configuration.equipment ? (
                                <HStack>
                                  <Stat
                                    size="xs"
                                    label="Evaporador"
                                    value={`${configuration.equipment.count}x  ${configuration.equipment.model}`}
                                    field={null}
                                  />
                                  <IconButton
                                    variant="outline"
                                    size="xs"
                                    aria-label="Editar Evaporador"
                                    onClick={() => handleEvaporatorModalOpen(configuration)}
                                    icon={<MdEdit size={16} color={colors.secondary} />}
                                  />
                                </HStack>
                              ) : (
                                <ChakraButton
                                  onClick={() => handleEvaporatorModalOpen(configuration)}
                                  type="button"
                                  variant="link"
                                  textDecoration="underline"
                                  fontSize={14}
                                  color={colors.secondary}
                                >
                                  Selecione o Evaporador
                                </ChakraButton>
                              )}
                            </GridItem>

                            {configuration.equipment?.specialEquipment ? (
                              <GridItem className="block__system__grid--item room__sku">
                                <HStack>
                                  <Stat size="xs" label="Equipamento Especial" value={null} field={null} />
                                </HStack>
                              </GridItem>
                            ) : (
                              <GridItem className="block__system__grid--item room__sku">
                                <HStack>
                                  <Stat
                                    size="xs"
                                    label="SKU"
                                    value={configuration.equipment?.sku.sku || '- -'}
                                    field={null}
                                  />
                                </HStack>
                              </GridItem>
                            )}
                            <GridItem className="block__system__grid--item room__dt">
                              <HStack>
                                <Stat
                                  size="xs"
                                  label="DT"
                                  value={
                                    parseValue2Decimal(configuration.equipment?.temperatureDelta) ||
                                    parseValue2Decimal(configuration.room?.temperatureDelta)
                                  }
                                  field={null}
                                />
                              </HStack>
                            </GridItem>
                            <GridItem className="block__system__grid--item room__capacity">
                              <HStack>
                                <Stat
                                  size="xs"
                                  label="Carga do Evaporador"
                                  value={`${
                                    parseValue2Decimal(configuration.equipment?.unitCapacityDt) || '- -'
                                  } KCal/h`}
                                  field={null}
                                />
                              </HStack>
                            </GridItem>
                            <GridItem className="block__system__grid--item room__heatload">
                              <HStack>
                                <Stat
                                  size="xs"
                                  label="Carga Requerida"
                                  value={`${parseValue2Decimal(configuration.room?.heatLoad.totalKcalh)} KCal/h`}
                                  field={null}
                                />
                              </HStack>
                            </GridItem>
                            <GridItem className="block__system__grid--item room__action">
                              <IconButton
                                variant="outline"
                                size="xs"
                                aria-label="Remover Câmara"
                                onClick={() => handleRemoveConfiguration(configuration)}
                                icon={<MdClose size={18} color={colors.red} />}
                              />
                            </GridItem>
                          </SimpleGrid>
                        </CSSTransition>
                      ))}
                    </TransitionGroup>
                  </Box>
                </GridItem>
              </SimpleGrid>
            </Box>
          </GridItem>
        </SimpleGrid>

        {system.rack ? (
          <>
            <SelectorUnit
              title="Rack/Compressor"
              system={system}
              equipment={compressor}
              onModalOpen={onCompressorOpen}
              handleRemoveUnit={handleRemoveCompressor}
            />
            <SelectorCondenser
              title="Condensador"
              system={system}
              equipment={condenser}
              onModalOpen={onCondenserOpen}
              handleRemoveUnit={handleRemoveCondenser}
            />
          </>
        ) : (
          <SelectorUnit
            title="Unidade Condensadora"
            system={system}
            equipment={condensingUnit}
            onModalOpen={onCondensingOpen}
            handleRemoveUnit={handleRemoveCondensingUnit}
          />
        )}

        <SystemResult system={system} />

        <HStack justify="flex-end" mt={10}>
          <Tooltip
            hasArrow
            label="Selecione todos os equipamentos"
            bg={colors.gray100}
            size="xs"
            isDisabled={isSystemRequirementsCorrect()}
            shouldWrapChildren
          >
            <Button $primary disabled={!isSystemRequirementsCorrect()} onClick={() => handleConfirmSystem()}>
              Salvar
            </Button>
          </Tooltip>
        </HStack>
      </Box>

      {/* MODAL EVAPORATOR */}
      <ModalEvaporator
        isEvaporatorOpen={isEvaporatorOpen}
        onEvaporatorClose={onEvaporatorClose}
        selectedConfiguration={selectedConfiguration}
        system={system}
      />

      {/* MODAL CONDENSING UNIT */}
      <ModalCondensingUnit isCondensingOpen={isCondensingOpen} onCondensingClose={onCondensingClose} system={system} />

      {/* MODAL COMPRESSOR */}
      <ModalCompressor isCompressorOpen={isCompressorOpen} onCompressorClose={onCompressorClose} system={system} />

      {/* MODAL CONDENSER */}
      <ModalCondenser isCondenserOpen={isCondenserOpen} onCondenserClose={onCondenserClose} system={system} />
    </EquipmentsSystemContainer>
  );
};

export default EquipmentsSystem;
