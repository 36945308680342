import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
  .formControl {
    margin-bottom: 25px;

    label:first-child {
      font-size: 14px;
    }
  }
`;
