import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const InputWrapper = styled.div`
  margin: 0 !important;
  display: flex;
  align-items: end;

  .formControl {
    margin-bottom: 25px;

    label {
      font-size: 14px;
    }

    input {
      border-radius: 4px;

      &.formControl__input__unit {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .right-addon {
      width: 70px;
      font-size: 12px;
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      .unit-wrapper {
        width: 100%;
        height: 100%;
        background-color: ${colors.transparent};
        display: flex;
        justify-content: center;
        align-items: center;

        .unit-wrapper__value {
          font-size: 0.8rem;
          color: ${colors.gray200};
        }
      }
    }

    label {
      font-size: 14px;

      &.xs {
        font-size: 12px;
      }
    }
  }
`;
