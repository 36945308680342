/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  GridItem,
  SimpleGrid,
  Skeleton,
  Stack,
  Fade,
} from '@chakra-ui/react';

import { MdNoteAdd, MdDeleteForever } from 'react-icons/md';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useDispatch, useSelector } from 'react-redux';
import { SliderContainer } from './SliderRoom_Styles';
import { Button } from '~/components/Form/Button/Button_Styles';
import { deleteRoom, getRoomByProject, resetRoom } from '~/store/ducks/Room/actions';
import { breakpointSizes } from '~/styles/breakpoints';
import { ApplicationState } from '~/store/store';
import {
  projectRequestLoading,
  projectRequestSuccess,
  removeRoomFromProject,
  setProjectActiveRoom,
  storeRoomIntoProject,
} from '~/store/ducks/Project/actions';
import { ProjectRoom, PROJECT_ROOM_INITIAL_STATE } from '~/store/ducks/Project/types';
import { toastError, toastSuccess } from '~/helpers/toast';
import RoomService from '~/store/ducks/Room/services';

type SliderRoomType = {
  rooms: ProjectRoom[];
  projectId: number;
};

const SliderRoom: React.FC<SliderRoomType> = ({ ...props }) => {
  const { projectId } = props;
  const { projectLoading } = useSelector((state: ApplicationState) => state.project);
  const { sliderLoading } = useSelector((state: ApplicationState) => state.project);
  const [rooms, setRooms] = useState<ProjectRoom[]>(props.rooms);
  const [isOpen, setIsOpen] = useState(false);
  const [activeRoom, setActiveRoom] = useState(null);
  const cancelRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setRooms(props.rooms);
  }, [props.rooms]);

  const settings = {
    dots: true,
    infinite: rooms.length > 3,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: breakpointSizes.laptop,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: rooms.length > 2,
          dots: true,
        },
      },
      {
        breakpoint: breakpointSizes.tablet,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: rooms.length > 1,
          dots: false,
        },
      },
      {
        breakpoint: breakpointSizes.mobileXL,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: rooms.length > 0,
          dots: false,
        },
      },
      {
        breakpoint: breakpointSizes.mobileL,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: breakpointSizes.mobileS,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const onClose = () => setIsOpen(false);

  const addRoom = () => {
    const newRoom: ProjectRoom = {
      ...PROJECT_ROOM_INITIAL_STATE,
      name: `Câmara ${rooms.length + 1}`,
      project: { id: projectId },
    };

    dispatch(storeRoomIntoProject(newRoom));
    setRooms([...rooms, newRoom]);
  };

  const removeRoom = async (index: number) => {
    const aux = [...rooms];
    const removedRoom = aux.splice(index, 1)[0];
    const { id } = removedRoom;
    const newList = rooms.filter((_, i: number) => index !== i);

    try {
      onClose();
      setActiveRoom(null);

      if (id) {
        dispatch(deleteRoom(id, projectId));
        dispatch(projectRequestLoading());
        await RoomService.deleteRoom(id, projectId);
        dispatch(removeRoomFromProject(id));
        toastSuccess('project', 'Câmara removida com sucesso!');
      } else {
        dispatch(removeRoomFromProject(null));
        dispatch(resetRoom());
      }

      setRooms([...newList]);
    } catch (e) {
      console.error(e);
      toastError('project', 'Erro ao remover câmara.');
    } finally {
      dispatch(projectRequestSuccess());
    }
  };

  const selectRoom = (id: number, index: number) => {
    if (activeRoom === index) return;

    setActiveRoom(index);
    dispatch(setProjectActiveRoom(index));
    dispatch(getRoomByProject(id, projectId, index));
  };

  const handleRemove = (id: number) => {
    setIsOpen(true);
    setActiveRoom(id);
  };

  return (
    <SliderContainer className="hide--on--print">
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} isCentered onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remover Câmara
            </AlertDialogHeader>

            <AlertDialogBody>Tem certeza que deseja remover a câmara?</AlertDialogBody>

            <AlertDialogFooter>
              <Button $default ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button $red onClick={() => removeRoom(activeRoom)} ml={3}>
                Remover
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <SimpleGrid columns={[5, 6, 7, 10, 12]}>
        <GridItem colSpan={[2, 2, 2, 2, 2]}>
          <div className="item flex-start">
            <button className="inner__item elgin__box item__add" type="button" onClick={() => addRoom()}>
              <MdNoteAdd />
              <p className="item__text">Adicionar Câmara</p>
            </button>
          </div>
        </GridItem>
        <GridItem colStart={[4, 4, 3, 3, 4]} colEnd={[6, 7, 8, 11, 13]}>
          {projectLoading && (
            <Stack>
              <Skeleton h={[81, 80, 110, 130, 130]} />
            </Stack>
          )}
          <Fade in={!projectLoading}>
            <Slider {...settings}>
              {rooms &&
                rooms.map((item, index) => (
                  <div key={String(index)} className="item">
                    <div className={`item__wrapper ${index === activeRoom ? 'selected' : ''}`}>
                      <button
                        className="inner__item elgin__box"
                        type="button"
                        disabled={sliderLoading && index !== activeRoom}
                        onClick={() => selectRoom(item.id, index)}
                      >
                        <p className="item__text">{item.name || `Câmara ${index + 1}`}</p>
                      </button>

                      <button type="button" className="remove__item" onClick={() => handleRemove(index)}>
                        <MdDeleteForever title="Remover Câmara" />
                      </button>
                    </div>
                  </div>
                ))}
            </Slider>
          </Fade>
        </GridItem>
      </SimpleGrid>
    </SliderContainer>
  );
};

export default SliderRoom;
