import React from 'react';
import { Center, Container } from '@chakra-ui/react';

import { BlankLayoutContainer } from '~/pages/_layouts/BlankLayout/BlankLayout_Styles';

type BlankLayoutProps = {
  children: any;
};

export default function BlankLayout({ children }: BlankLayoutProps) {
  return (
    <BlankLayoutContainer>
      <Container maxW="container.lg">
        <Center h="70vh">{children}</Center>
      </Container>
    </BlankLayoutContainer>
  );
}
