/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import { Reducer } from 'redux';
import {
  Configuration,
  EquipmentInterfaceTypes,
  EquipmentRoom,
  EquipmentState,
  EquipmentSystem,
  EquipmentTypes,
} from '~/store/ducks/Equipment/types';
import { ProjectRoom } from '~/store/ducks/Project/types';

const SYSTEM_INITIAL_STATE: EquipmentSystem = {
  id: null,
  name: '',
  active: true,
  project: null,
  rack: false,
  operationTemperature: null,
  refrigerantFluid: null,
  configurations: [],
  condensingUnit: null,
  compressor: null,
  condenser: null,
  resultingDt: null,
  resultingCapacity: null,
};

const INITIAL_STATE: EquipmentState = {
  id: null,
  loading: false,
  project: null,
  projectRooms: [],
  systems: [SYSTEM_INITIAL_STATE],
};

const getAvailableRooms = (systems, projectRooms) => {
  try {
    const systemRoomsIds = systems.reduce((acc, system) => {
      for (const configuration of system.configurations) {
        acc.push(configuration.room.id);
      }
      return acc;
    }, []);

    const filteredRooms = projectRooms.reduce((acc, projectRoom) => {
      if (!systemRoomsIds.includes(projectRoom.id)) {
        acc.push(projectRoom);
      }
      return acc;
    }, []);

    return filteredRooms;
  } catch (err) {
    console.log(err);
    return projectRooms;
  }
};

const checkSystems = (state) => {
  const isAnySystemEmpty = () => {
    const emptySystem = state.systems.filter(
      (system: EquipmentSystem) => system.configurations.length === 0 && system.condensingUnit === null
    );

    return state.projectRooms.length > 0 && emptySystem.length === 0;
  };

  return isAnySystemEmpty()
    ? [SYSTEM_INITIAL_STATE, ...state.systems.map((system: EquipmentSystem) => ({ ...system, active: false }))]
    : [...state.systems.map((system: EquipmentSystem) => ({ ...system, active: false }))];
};

const checkNewSystem = (state, action) => {
  const availableRooms = getAvailableRooms(action.payload.systems, action.payload.projectRooms);

  return availableRooms.length > 0
    ? [
        ...state.systems,
        ...action.payload.systems.map((system) => ({
          ...system,
          configurations: system.configurations.map((configuration) => ({
            ...configuration,
            room: action.payload.projectRooms.find((room) => room.id === configuration.room.id),
          })),
        })),
      ]
    : [
        ...action.payload.systems.map((system) => ({
          ...system,
          configurations: system.configurations.map((configuration) => ({
            ...configuration,
            room: action.payload.projectRooms.find((room) => room.id === configuration.room.id),
          })),
        })),
      ];
};

const reducer: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EquipmentTypes.STORE_INITIAL_DATA:
      return {
        ...state,
        systems: [SYSTEM_INITIAL_STATE],
        project: { id: action.payload.projectId },
        projectRooms: action.payload.projectRooms.map((room: ProjectRoom) => {
          const { status, operatingHours, ambientTemperature, project, ...rest } = room;
          return { ...rest };
        }),
      };
    case EquipmentTypes.STORE_SETTINGS:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              project: { ...state.project },
              name: action.payload.data.name,
              operationTemperature: action.payload.data.operationTemperature,
              refrigerantFluid: action.payload.data.refrigerantFluid,
              rack: action.payload.data.rack,
              condensingUnit: action.payload.data.rack ? null : system.condensingUnit,
              compressor: action.payload.data.rack ? system.compressor : null,
              condenser: action.payload.data.rack ? system.condenser : null,
              resultingDt: null,
              resultingCapacity: null,
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.STORE_SYSTEMS:
      return {
        ...state,
        project: { id: action.payload.projectId },
        projectRooms: getAvailableRooms(action.payload.systems, action.payload.projectRooms),
        systems: checkNewSystem(state, action),
      };
    case EquipmentTypes.SELECT_ROOM:
      return {
        ...state,
        projectRooms: state.projectRooms.filter((room: EquipmentRoom) => room.id !== action.payload.data),
      };
    case EquipmentTypes.RETURN_CONFIGURATION:
      return {
        ...state,
        projectRooms: [...state.projectRooms, action.payload.data.room],
      };
    case EquipmentTypes.ADD_CONFIGURATION:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              configurations: [...system.configurations, action.payload.data],
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.REMOVE_CONFIGURATION:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              configurations: system.configurations.filter(
                (config: Configuration) => config.room.id !== action.payload.data
              ),
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.SELECT_EVAPORATOR:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              configurations: system.configurations.map((configuration: Configuration) =>
                configuration.room.id === action.payload.configuration.room.id
                  ? {
                      ...configuration,
                      configType: EquipmentInterfaceTypes.EVAPORATOR,
                      equipment: action.payload.equipment,
                    }
                  : configuration
              ),
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.SELECT_CONDENSING_UNIT:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              condensingUnit: {
                ...system.condensingUnit,
                equipment: action.payload.condensingUnit,
                configType: EquipmentInterfaceTypes.CONDENSING_UNIT,
              },
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.REMOVE_CONDENSING_UNIT:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              condensingUnit: null,
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.SELECT_COMPRESSSOR:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              compressor: {
                ...system.compressor,
                equipment: action.payload.compressor,
                configType: EquipmentInterfaceTypes.COMPRESSOR,
              },
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.REMOVE_COMPRESSOR:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              compressor: null,
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.SELECT_CONDENSER:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              condenser: {
                ...system.condenser,
                equipment: action.payload.condenser,
                configType: EquipmentInterfaceTypes.CONDENSER,
              },
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.REMOVE_CONDENSER:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              condenser: null,
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.CONFIRM_SYSTEM:
      return {
        ...state,
        systems: checkSystems(state),
      };
    case EquipmentTypes.CHANGE_ACTIVE_SYSTEM:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem, index: number) => {
          if (index === action.payload.index) {
            return {
              ...system,
              active: true,
            };
          }
          return { ...system, active: false };
        }),
      };
    case EquipmentTypes.STORE_SYSTEM_RESULT:
      return {
        ...state,
        systems: state.systems.map((system: EquipmentSystem) => {
          if (system.active) {
            return {
              ...system,
              resultingDt: action.payload.data.resultingDt,
              resultingCapacity: action.payload.data.resultingCapacity,
            };
          }
          return { ...system };
        }),
      };
    case EquipmentTypes.REQUEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EquipmentTypes.REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
