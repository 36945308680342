import React from 'react';
import {
  Box,
  SimpleGrid,
  GridItem,
  IconButton,
  HStack,
  Text,
  Button as ChakraButton,
  Image,
  Icon,
  Divider,
  Center,
} from '@chakra-ui/react';
import { MdClose, MdEdit } from 'react-icons/md';
import { BsImage } from 'react-icons/bs';

import { colors } from '~/styles/colors';
import Stat from '~/components/Form/Stat';

const SelectorCondenser = ({ title, equipment, onModalOpen, handleRemoveUnit, system }) => (
  <>
    <Divider my={5} />
    <SimpleGrid columns={[12]} gap={5}>
      <GridItem colStart={[1, 1]} colEnd={[13, 13]}>
        <Box>
          <Text className="block__system--title"> {title} </Text>
          <Box className="block__condensing-unit" mb={2} overflowY="auto" padding={1}>
            <SimpleGrid className="block__system__grid elgin__box--shadow" columns={[12]} gap={5}>
              <GridItem className="block__system__grid--item condensing-unit__image">
                <Center>
                  {equipment?.image ? (
                    <Image
                      className="block__system__image"
                      fit="contain"
                      src={equipment.image}
                      alt="Equipment Image"
                      fallbackSrc="https://via.placeholder.com/120x50"
                    />
                  ) : (
                    <Icon as={BsImage} color={colors.gray100} />
                  )}
                </Center>
              </GridItem>
              <GridItem className="block__system__grid--item condensing-unit__equipment">
                {equipment ? (
                  <HStack>
                    <Stat
                      size="xs"
                      label="Modelo"
                      value={`${equipment?.equipment.count}x  ${equipment?.equipment.model}`}
                      field={null}
                    />
                    <IconButton
                      variant="outline"
                      size="xs"
                      aria-label="Editar Equipamento"
                      onClick={onModalOpen}
                      icon={<MdEdit size={16} color={colors.secondary} />}
                    />
                  </HStack>
                ) : (
                  <ChakraButton
                    onClick={onModalOpen}
                    type="button"
                    variant="link"
                    textDecoration="underline"
                    fontSize={14}
                    disabled={!system.refrigerantFluid}
                    color={colors.secondary}
                  >
                    Selecione o equipamento
                  </ChakraButton>
                )}
              </GridItem>
              {equipment?.equipment?.specialEquipment ? (
                <GridItem
                  className="block__system__grid--item condensing-unit__sku"
                  display={`${equipment ? 'block' : 'none'}`}
                >
                  <HStack>
                    <Stat size="xs" label="Equipamento Especial" value={null} field={null} />
                  </HStack>
                </GridItem>
              ) : (
                <GridItem
                  className="block__system__grid--item condensing-unit__sku"
                  display={`${equipment ? 'block' : 'none'}`}
                >
                  <HStack>
                    <Stat size="xs" label="SKU" value={equipment?.equipment?.sku?.sku || '- -'} field={null} />
                  </HStack>
                </GridItem>
              )}

              <GridItem
                display={`${equipment ? 'block' : 'none'}`}
                className="block__system__grid--item condensing-unit__dt"
              >
                <HStack>
                  <Stat
                    size="xs"
                    label="Capacidade Total"
                    value={`${equipment?.equipment?.unitCapacity} KCal/h`}
                    field={null}
                  />
                </HStack>
              </GridItem>
              <GridItem
                display={`${equipment ? 'block' : 'none'}`}
                className="block__system__grid--item condensing-unit__heatload"
              >
                <HStack>
                  <Stat size="xs" label="Consumo" value={`${equipment?.equipment?.consumedPower} kW`} field={null} />
                </HStack>
              </GridItem>
              <GridItem className="block__system__grid--item condensing-unit__action">
                {equipment && (
                  <IconButton
                    variant="outline"
                    size="xs"
                    aria-label="Remover Equipamento"
                    onClick={handleRemoveUnit}
                    icon={<MdClose size={18} color={colors.red} />}
                  />
                )}
              </GridItem>
            </SimpleGrid>
          </Box>
        </Box>
      </GridItem>
    </SimpleGrid>
  </>
);

export default SelectorCondenser;
