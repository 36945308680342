import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Button as ChakraButton } from '@chakra-ui/react';
import { colors } from '~/styles/colors';
import { EvaporatorSKU } from '~/store/ducks/Equipment/types';

type EvaporatorTableSKUProps = {
  tableRef: any;
  skus: EvaporatorSKU[];
  selectedSKU: any;
  handleSelectSKU: any;
};

const EvaporatorTableSKU: React.FC<EvaporatorTableSKUProps> = ({ tableRef, skus, selectedSKU, handleSelectSKU }) => (
  <Table ref={tableRef} className="table-sku" size="sm" mt={5} colorScheme="blackAlpha">
    <Thead>
      <Tr>
        <Th>SKU</Th>
        <Th>Gabinente</Th>
        <Th>Tipo Motor</Th>
        <Th>Válvula Expansão</Th>
        <Th>Proteção Aletas</Th>
        <Th>Bandeja Isolada</Th>
        <Th>Altura</Th>
        <Th>Comp.</Th>
        <Th>Largura</Th>
        <Th>Cap.</Th>
        <Th>Cap. Nominal</Th>
        <Th>DT</Th>
      </Tr>
    </Thead>
    <Tbody>
      {skus.map((sku: EvaporatorSKU, index: number) => (
        <Tr key={String(index)} className={`table-sku__row ${sku.id === selectedSKU?.id ? 'selected' : ''}`}>
          <Td>
            <ChakraButton
              type="button"
              variant="link"
              size="sm"
              fontWeight={400}
              color={colors.primary}
              onClick={() => {
                handleSelectSKU(sku);
              }}
            >
              {sku.sku}
            </ChakraButton>
          </Td>
          <Td>{sku.chamber}</Td>
          <Td>{sku.engineType}</Td>
          <Td>{sku.expansionValve}</Td>
          <Td>{sku.finsProtection}</Td>
          <Td>{sku.isolatedTray}</Td>
          <Td>{sku.heigth}</Td>
          <Td>{sku.length}</Td>
          <Td>{sku.width}</Td>
          <Td>{sku.unitCapacity}</Td>
          <Td>{sku.nominalUnitCapacity}</Td>
          <Td>{sku.unitCapacityDt}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

export default EvaporatorTableSKU;
