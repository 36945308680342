import React from 'react';
import { FormControl, FormErrorMessage, FormLabel, InputGroup, Input as ChakraInput } from '@chakra-ui/react';
import { FieldAttributes, useField } from 'formik';
import InputRightAddon from '~/components/Form/InputRightAddon';

import { InputWrapper } from './Input_Styles';
import { colors } from '~/styles/colors';
import { parseValue2Decimal } from '~/helpers/functions';

type InputProps = {
  form: any;
  field: FieldAttributes<any>;
  name: string;
  label: string;
  unit?: string;
  value: any;
  type: string;
  size?: string;
};

const Input = ({ ...props }: InputProps) => {
  const { form, field, label, unit, value: propsValue, size, ...rest } = props;
  const { name } = field;
  const [, { error, touched }] = useField(name);

  return (
    <InputWrapper>
      <FormControl className="formControl" isInvalid={!!error && touched}>
        <FormLabel className={size || 'sm'} color={colors.gray100} htmlFor={name}>
          {label}
        </FormLabel>
        <InputGroup size={size || 'sm'}>
          {rest.type === 'number' ? (
            <ChakraInput
              size={size || 'sm'}
              className={unit && 'formControl__input__unit'}
              {...field}
              {...rest}
              onWheel={(event) => event.currentTarget.blur()}
            />
          ) : (
            <ChakraInput
              size={size || 'sm'}
              data-testid={name}
              className={unit && 'formControl__input__unit'}
              {...field}
              {...rest}
            />
          )}

          {!!unit && <InputRightAddon unit={unit} />}
        </InputGroup>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </InputWrapper>
  );
};

export default Input;
