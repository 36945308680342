import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const Container = styled.div`
  .stat-wrapper {
    .stat-label {
      font-size: 12px;
      line-height: 1.2;
      color: ${colors.gray100};

      @media ${devices.mobileL} {
        font-size: 14px;
      }
    }

    .stat-value {
      font-size: 16px;
      color: ${colors.primary};

      @media ${devices.mobileL} {
        font-size: 20px;
      }
    }

    .stat-sub {
      font-size: 12px;
      color: ${colors.gray100};
      margin-bottom: 0;

      @media ${devices.mobileL} {
        font-size: 14px;
      }
    }
  }

  &.xs {
    .stat-wrapper {
      .stat-label {
        font-size: 10px;

        @media ${devices.mobileL} {
          font-size: 11px;
        }
      }

      .stat-value {
        font-size: 14px;

        @media ${devices.mobileL} {
          font-size: 14px;
        }
      }

      .stat-sub {
        font-size: 10px;

        @media ${devices.mobileL} {
          font-size: 11px;
        }
      }
    }
  }
`;
