/* eslint-disable no-console */
import React, { useState } from 'react';

import {
  Box,
  SimpleGrid,
  GridItem,
  Stack,
  ButtonGroup,
  HStack,
  VStack,
  Skeleton,
  Text,
  Button as ChakraButton,
  Image,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Switch,
  Table,
  Tbody,
  Tr,
  Td,
  Center,
  Flex,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { MdSearch } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import { toastError } from '~/helpers/toast';
import { colors } from '~/styles/colors';
import Yup from '~/helpers/validations';
import SelectGivenOptions from '~/components/Form/Select/SelectGivenOptions';
import { Condenser, Evaporator } from '~/store/ducks/Equipment/types';
import { selectCondenser } from '~/store/ducks/Equipment/actions';
import EquipmentService from '~/store/ducks/Equipment/services';
import EquipmentTable from '~/components/Equipments/EquipmentTable';
import TextArea from '~/components/Form/TextArea';
import { parseValue2Decimal } from '~/helpers/functions';

const schemaFilter = Yup.object().shape({
  dt: Yup.number().nullable().required(),
  height: Yup.number().nullable().required(),
  rejectedHeat: Yup.number().nullable().required(),
  range: Yup.object().shape({
    start: Yup.number().nullable().required(),
    end: Yup.number().nullable().required(),
  }),
  count: Yup.number().nullable().required(),
  condenserType: Yup.string().nullable(),
  numberOfPoles: Yup.number().nullable(),
  fanDiameter: Yup.number().nullable(),
  fanType: Yup.number().nullable(),
  wingPerInch: Yup.string().nullable(),
  fanCount: Yup.number().nullable(),
});

const schemaSpecial = Yup.object().shape({
  dt: Yup.number().nullable().required(),
  model: Yup.string().nullable().required(),
  height: Yup.number().nullable().required(),
  rejectedHeat: Yup.number().nullable().required(),
  count: Yup.number().nullable().required(),
  condenserType: Yup.string().nullable().required(),
  numberOfPoles: Yup.number().nullable().required(),
  fanDiameter: Yup.number().nullable().required(),
  fanType: Yup.string().nullable().required(),
  wingPerInch: Yup.string().nullable().required(),
  fanCount: Yup.number().nullable().required(),
  fanPower: Yup.number().nullable().required(),
  unitCapacity: Yup.number().nullable().required(),
  noiseLevel: Yup.number().nullable().required(),
  commercialDescription: Yup.string().nullable().required(),
});

const equipmentProfiles = [
  {
    value: 1,
    label: 'Baixo Perfil',
  },
  {
    value: 2,
    label: 'Médio Perfil',
  },
  {
    value: 3,
    label: 'Alto Perfil',
  },
];

const ModalCondenser = ({ isCondenserOpen, onCondenserClose, system }) => {
  const dispatch = useDispatch();
  const [CondenserProfile, setCondenserProfile] = useState<any>({ value: 1 });
  const [condensers, setCondensers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCondenser, setSelectedCondenser] = useState<Condenser>(null);
  const [specialEnabled, setSpecialEnabled] = useState(true);

  const initialValuesFilter = {
    dt: 1,
    height: null,
    rejectedHeat: null,
    count: null,
    condenserType: null,
    numberOfPoles: null,
    fanDiameter: null,
    fanType: null,
    wingPerInch: null,
    fanCount: null,
    range: 5,
  };

  const initialValuesSpecial = {
    count: system.condenser?.equipment.count || null,
    model: system.condenser?.equipment.model || null,
    unitCapacity: system.condenser?.equipment?.unitCapacity || null,
    dt: system.condenser?.equipment?.productCustom.dt || null,
    height: system.condenser?.equipment?.productCustom.height || null,
    rejectedHeat: system.condenser?.equipment?.productCustom.rejectedHeat || null,
    condenserType: system.condenser?.equipment?.productCustom.condenserType || null,
    numberOfPoles: system.condenser?.equipment?.productCustom.numberOfPoles || null,
    fanDiameter: system.condenser?.equipment?.productCustom.fanDiameter || null,
    fanType: system.condenser?.equipment?.productCustom.fanType || null,
    wingPerInch: system.condenser?.equipment?.productCustom.wingPerInch || null,
    fanCount: system.condenser?.equipment?.productCustom.fanCount || null,
    fanPower: system.condenser?.equipment?.productCustom.fanPower || null,
    noiseLevel: system.condenser?.equipment?.productCustom.noiseLevel || null,
    commercialDescription: system.condenser?.equipment?.productCustom.commercialDescription || null,
  };

  const getCondensers = async (data) => {
    setLoading(true);
    try {
      const response = await EquipmentService.getCondenser(data);
      setCondensers(response.data);
      setLoading(false);
    } catch (err) {
      toastError('project', 'Erro ao recuperar condensadores.');
      console.error(err);
    }
  };

  const handleCondenserProfileChange = async (profile) => {
    await getCondensers(null);
    setCondenserProfile(profile);
  };

  const handleSelectCondenser = (condenser: Condenser): void => {
    setSelectedCondenser(condenser);
  };

  const handleCondenserModalClose = (): void => {
    setCondensers([]);
    // setSpecialEnabled(false);
    onCondenserClose();
  };

  const handleCondenserSearch = (values): void => {
    getCondensers(values);
    setCondenserProfile({ value: 1 });
  };

  const handleSubmitCondenser = (): void => {
    const selectedCondUnit = condensers.find((e: Evaporator) => e.id === selectedCondenser.id);
    dispatch(selectCondenser(selectedCondUnit));
    setCondensers([]);
    onCondenserClose();
  };

  const handleChangeSpecial = (): void => {
    setSpecialEnabled(!specialEnabled);
  };

  const handleSubmitSpecial = (customCondenser) => {
    const { count, dt, model, heatLoad, unitCapacity, fanPower } = customCondenser;

    const special = {
      count,
      model,
      consumedPower: count * fanPower,
      heatLoad,
      unitCapacity: count * unitCapacity,
      nominalUnitCapacity: unitCapacity,
      temperatureDelta: dt,
      specialEquipment: true,
      productCustom: {
        ...customCondenser,
      },
    };
    dispatch(selectCondenser(special));
    setCondensers([]);
    onCondenserClose();
  };

  return (
    <Modal isOpen={isCondenserOpen} size="5xl" onClose={handleCondenserModalClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent className="modal__select">
        <ModalHeader>
          <Text color={colors.secondary} fontSize="1.25rem" fontWeight="500">
            Preencher dados do Condensador Remoto
          </Text>
        </ModalHeader>
        <ModalCloseButton onClick={() => handleCondenserModalClose()} />
        <ModalBody>
          <Box>
            <SimpleGrid columns={[12]} gap={5} mt={5}>
              {/* <GridItem colStart={[1]} colEnd={[13, 13, 6, 5]} h="100%">
                <Box>
                  <Text className="modal__select__section--title"> Pesquisa de Condensadores</Text>
                  <Formik
                    initialValues={initialValuesFilter}
                    isInitialValid
                    validationSchema={schemaFilter}
                    validateOnChange={false}
                    onSubmit={(values) => {
                      handleCondenserSearch(values);
                    }}
                  >
                    {({ values, submitForm }) => (
                      <Form className="form">
                        <SimpleGrid columns={[2]} spacing={[5]}>
                          <Box>
                            <Field
                              size="xs"
                              name="dt"
                              component={Input}
                              type="number"
                              label="DT *"
                              value={values.dt || ''}
                            />
                            <Field
                              size="xs"
                              name="count"
                              component={Input}
                              type="number"
                              label="Quantidade *"
                              value={values.count || ''}
                            />
                            <Field
                              size="xs"
                              name="height"
                              component={Input}
                              type="number"
                              label="Altura *"
                              value={values.height || ''}
                            />
                          </Box>
                          <Box>
                            <Field
                              size="xs"
                              name="rejectedHeat"
                              component={Input}
                              type="number"
                              label="Calor Rejeitado *"
                              value={values.rejectedHeat || ''}
                            />
                            <Field
                              size="xs"
                              name="range"
                              component={Input}
                              type="number"
                              label="Range (%) *"
                              value={values.range || ''}
                            />
                          </Box>
                        </SimpleGrid>
                        <SimpleGrid columns={[2]} spacing={[5]}>
                          <Box>
                            <Field
                              size="xs"
                              name="condenserType"
                              component={Input}
                              type="number"
                              label="Tipo de Condensador"
                              value={values.condenserType || ''}
                            />
                            <Field
                              size="xs"
                              name="numberOfPoles"
                              component={Input}
                              type="number"
                              label="Número de Pólos"
                              value={values.numberOfPoles || ''}
                            />
                            <Field
                              size="xs"
                              name="fanDiameter"
                              component={Input}
                              type="number"
                              label="Diâmetro do Ventilador"
                              value={values.fanDiameter || ''}
                            />
                          </Box>
                          <Box>
                            <Field
                              size="xs"
                              name="wingPerInch"
                              component={Input}
                              type="number"
                              label="Aletas por Polegada"
                              value={values.wingPerInch || ''}
                            />
                            <Field
                              size="xs"
                              name="fanCount"
                              component={Input}
                              type="number"
                              label="Número de Ventiladores"
                              value={values.fanCount || ''}
                            />
                            <Field
                              size="xs"
                              name="fanType"
                              component={Input}
                              type="number"
                              label="Tipo do Ventilador"
                              value={values.fanType || ''}
                            />
                          </Box>
                        </SimpleGrid>
                        <HStack justify="flex-end" mt={4}>
                          <Button
                            leftIcon={<MdSearch size={18} />}
                            type="button"
                            disabled={specialEnabled}
                            $primary
                            onClick={submitForm}
                          >
                            Pesquisar
                          </Button>
                        </HStack>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </GridItem> */}
              <GridItem colStart={[1, 1, 1]} colEnd={[13]} h="100%">
                <Box>
                  {/* <Text className="modal__select__section--title"> Condensador Customizado </Text> */}
                  {/* <Stack align="center" direction="row">
                    <FormControl display="flex" alignItems="center" mb={5}>
                      <FormLabel htmlFor="enable-special" mb="0" fontSize={13} fontWeight="500" color={colors.gray100}>
                        Adicionar Equipamento Especial?
                      </FormLabel>
                      <Switch size="sm" id="enable-special" onChange={handleChangeSpecial} isChecked={specialEnabled} />
                    </FormControl>
                  </Stack> */}

                  {specialEnabled ? (
                    <Formik
                      initialValues={initialValuesSpecial}
                      isInitialValid
                      validationSchema={schemaSpecial}
                      validateOnChange={false}
                      onSubmit={(values) => {
                        handleSubmitSpecial(values);
                      }}
                    >
                      {({ values, submitForm }) => (
                        <Form className="form">
                          <SimpleGrid columns={[1, 2, 4]} spacing={[0, 5]}>
                            <Box>
                              <Field
                                size="xs"
                                name="model"
                                component={Input}
                                type="text"
                                label="Código Completo do Produto"
                                value={values.model}
                              />
                              <Field
                                size="xs"
                                name="count"
                                component={Input}
                                type="number"
                                label="Quantidade"
                                value={values.count}
                              />
                              <Field size="xs" name="dt" component={Input} type="number" label="DT" value={values.dt} />
                              <Field
                                size="xs"
                                name="height"
                                component={Input}
                                type="number"
                                label="Altitude (m)"
                                value={values.height}
                              />
                            </Box>
                            <Box>
                              <Field
                                size="xs"
                                name="numberOfPoles"
                                component={Input}
                                type="number"
                                label="Número de Pólos"
                                value={values.numberOfPoles}
                              />
                              <Field
                                size="xs"
                                name="condenserType"
                                component={Input}
                                type="text"
                                label="Tipo de Condenser"
                                value={values.condenserType}
                              />
                              <Field
                                size="xs"
                                name="fanDiameter"
                                component={Input}
                                type="number"
                                label="Diâmetro do Ventilador"
                                value={values.fanDiameter}
                              />
                              <Field
                                size="xs"
                                name="fanType"
                                component={Input}
                                type="text"
                                label="Tipo do Ventilador"
                                value={values.fanType}
                              />
                            </Box>
                            <Box>
                              <Field
                                size="xs"
                                name="wingPerInch"
                                component={Input}
                                type="number"
                                label="Aletas por Polegada"
                                value={values.wingPerInch}
                              />
                              <Field
                                size="xs"
                                name="fanCount"
                                component={Input}
                                type="number"
                                label="Quantidade de Ventiladores"
                                value={values.fanCount}
                              />
                              <Field
                                size="xs"
                                name="fanPower"
                                component={Input}
                                type="number"
                                label="Potência dos Ventiladores (kW)"
                                value={values.fanPower}
                              />
                              <Field
                                size="xs"
                                name="unitCapacity"
                                component={Input}
                                type="number"
                                label="Capacidade Unitária (Kcal/h)"
                                value={values.unitCapacity}
                              />
                            </Box>
                            <Box>
                              <Field
                                size="xs"
                                name="noiseLevel"
                                component={Input}
                                type="number"
                                label="Nível de Ruído (dBA)"
                                value={values.noiseLevel}
                              />
                              <Field
                                size="xs"
                                name="rejectedHeat"
                                component={Input}
                                type="number"
                                label="Calor Rejeitado (Kcal/h)"
                                value={values.rejectedHeat}
                              />
                              <Table className="equipment__table" size="xs" variant="unstyled">
                                <Tbody>
                                  <Tr>
                                    <Td>Capacidade Total (kcal/h)</Td>
                                    <Td>{parseValue2Decimal(values.count * values.unitCapacity)}</Td>
                                  </Tr>
                                  <Tr>
                                    <Td>Consumo</Td>
                                    <Td>{parseValue2Decimal(values.count * values.fanPower)}</Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </Box>
                          </SimpleGrid>
                          <SimpleGrid columns={[1]} spacing={[5]} mt={[5, 0]}>
                            <Field
                              size="xs"
                              name="commercialDescription"
                              component={TextArea}
                              label="Descrição Comercial"
                              value={values.commercialDescription || ''}
                            />
                          </SimpleGrid>
                          <ModalFooter padding="40px 0 15px">
                            <Button mr={3} $default onClick={() => handleCondenserModalClose()}>
                              Cancelar
                            </Button>
                            <Button $primary disabled={false} mr={3} onClick={submitForm}>
                              Confirmar
                            </Button>
                          </ModalFooter>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <>
                      <HStack justify="center">
                        {condensers.length === 0 && !loading && (
                          <VStack align="center" mt={10}>
                            <Icon boxSize="80px" as={MdSearch} color={colors.gray100} />
                            <Text className="modal__select__section--info">Pesquise um equipamento.</Text>
                          </VStack>
                        )}
                        {condensers.length > 0 && (
                          <ButtonGroup className="modal__select__button--group" size="sm" isAttached variant="outline">
                            {equipmentProfiles.map((profile: any) => (
                              <ChakraButton
                                key={profile.value}
                                className={CondenserProfile.value === profile.value ? 'active' : null}
                                onClick={() => handleCondenserProfileChange(profile)}
                              >
                                {profile.label}
                              </ChakraButton>
                            ))}
                          </ButtonGroup>
                        )}
                      </HStack>
                      <VStack className="equipment__wrapper" align="center" justify="center" mt={5}>
                        {loading && (
                          <Stack w="100%">
                            <Skeleton height="200px" />
                            <Skeleton height="200px" />
                            <Skeleton height="200px" />
                          </Stack>
                        )}
                        {!loading &&
                          condensers.map((condenser: Condenser, index: number) => (
                            <button
                              onClick={() => handleSelectCondenser(condenser)}
                              key={String(index)}
                              type="button"
                              className={`equipment__item elgin__box--shadow ${
                                selectedCondenser?.id === condenser.id && 'active'
                              }`}
                            >
                              <SimpleGrid columns={[1, 2]} spacing={5} w="100%">
                                <VStack>
                                  <Image
                                    className="equipment__image"
                                    fit="contain"
                                    src={condenser.image}
                                    alt="Evaporator Image"
                                    fallbackSrc="https://via.placeholder.com/200x150"
                                  />
                                  <Text className="equipment__name" align="left">
                                    {condenser.model}
                                  </Text>
                                </VStack>
                                <HStack spacing={10}>
                                  {/* <EquipmentTable
                                equipment={Condenser}
                                handleDTChange={() => handleCondenserDTChange(Condenser)}
                                handleDecrease={() => handleCondenserDecrease(Condenser)}
                                handleIncrease={() => handleCondenserIncrease(Condenser)}
                                isEditable={false}
                              /> */}
                                </HStack>
                              </SimpleGrid>
                            </button>
                          ))}
                      </VStack>
                    </>
                  )}
                </Box>
              </GridItem>
            </SimpleGrid>
          </Box>
        </ModalBody>
        {!specialEnabled && (
          <ModalFooter mt={1}>
            <Button mr={3} $default onClick={() => handleCondenserModalClose()}>
              Cancelar
            </Button>
            <Button $primary disabled={!selectedCondenser} mr={3} onClick={() => handleSubmitCondenser()}>
              Confirmar
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalCondenser;
