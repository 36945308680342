import React, { useState } from 'react';

import { Text, Avatar, Menu, MenuButton, MenuList, MenuItem, Button, MenuDivider, MenuGroup } from '@chakra-ui/react';
import { MdExpandMore } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import logo from '~/assets/images/elgin.png';
import { Container, Content, Profile, StyledBurger } from './Header_Styles';
import RightNav from './RightNav';
import { colors } from '~/styles/colors';
import { ApplicationState } from '~/store/store';

export default function Header() {
  const [open, setOpen] = useState(false);
  const { accessToken } = useSelector((state: ApplicationState) => state.auth);
  const decodedToken: any = jwtDecode<JwtPayload>(accessToken);
  const { email, username } = decodedToken;
  const userInfo = email || username || '';

  return (
    <Container>
      <Content>
        <img src={logo} alt="Logo Elgin" />
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>

        <RightNav open={open} userInfo={userInfo} />

        <aside>
          <Profile>
            <div>
              <Menu autoSelect={false} strategy="fixed">
                <MenuButton
                  as={Button}
                  className="menu__btn"
                  variant="ghost"
                  rightIcon={<MdExpandMore size={18} color={colors.gray200} />}
                >
                  <Avatar size="sm" bg={colors.primary} />
                </MenuButton>
                <MenuList className="menu__list">
                  <MenuGroup title={userInfo} className="menu__group__title">
                    <MenuDivider />
                    <Link to={{ pathname: '/logout', state: { isLogin: false } }}>
                      <MenuItem className="menu__item" maxW="200px">
                        <Text fontSize={14} fontWeight={500} color={colors.gray200}>
                          Sair
                        </Text>
                      </MenuItem>
                    </Link>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </div>
            {/* <div>
              <Menu autoSelect={false} strategy="fixed">
                <MenuButton
                  as={Button}
                  className="menu__btn"
                  variant="ghost"
                  rightIcon={<MdExpandMore size={18} color={colors.gray200} />}
                >
                  <Avatar name="Portugues" />
                </MenuButton>
                <MenuList className="menu__list">
                  <MenuGroup title="Idioma" className="menu__group__title">
                    <MenuDivider />
                    <MenuItem className="menu__item" maxW="200px">
                      <Text fontSize={14} fontWeight={500} color={colors.gray200}>
                        Português
                      </Text>
                    </MenuItem>
                    <MenuItem className="menu__item" maxW="200px">
                      <Text fontSize={14} fontWeight={500} color={colors.gray200}>
                        Espanhol
                      </Text>
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </div> */}
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
