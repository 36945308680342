import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const Container = styled.div`
  .field-wrapper {
    margin-top: 0;
    width: 100%;
  }

  .form {
    label {
      color: ${colors.gray100};
      font-size: 14px;
    }
  }

  .input-ef {
    background-color: ${colors.white};
  }

  .sub-title {
    position: absolute;
    bottom: 40px;
  }

  p {
    line-height: 1;
    color: ${colors.gray100};

    &.title {
      font-size: 14px;
      color: ${colors.gray100};
    }

    &.value {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .calc__form--selected {
    display: block;
  }

  .calc__form--disabled {
    display: none;
  }

  .info-label {
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px solid #000000;
    background-color: #2537a5;
    text-align: center;
    text-orientation: initial;
    margin-left: 10px;
  }

  .info-icon {
    display: inline-block;
    margin-left: 10px;
    fill: #0069a5;
  }

  .bt-info {
    background-color: ${colors.white};
  }

  .bt-info:hover {
    background-color: ${colors.white};
  }

  .button-remove {
    border-radius: 25px;
    background-color: ${colors.transparent};
    border: 1px solid ${colors.red};
    font-size: 14px;
    color: ${colors.red};
    width: auto;
    height: 30px;
    transition: all 0.2s ease-in-out;

    @media ${devices.mobileS} {
      border: none;
    }

    @media ${devices.mobileL} {
      border: 1px solid ${colors.red};
    }

    span {
      @media (max-width: 768px) {
        margin: 0;
      }
    }

    .button-remove__text {
      color: inherit;
      display: none;

      @media ${devices.tablet} {
        display: block;
      }
    }

    svg {
      fill: ${colors.red};
      width: 25px;
      height: auto;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      background-color: ${colors.red};
      color: ${colors.white};

      svg {
        fill: ${colors.white};
      }
    }
  }
`;
