import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const Container = styled.div`
  .field-wrapper {
    margin-top: 0;
    width: 100%;
  }

  .field-wrapper__wall {
    margin-top: 30px;
    width: 60%;
    @media ${devices.tablet} {
      width: 70%;
    }
  }

  p {
    &.title {
      font-size: 16px;
      font-weight: bold;
      color: ${colors.gray100};
      margin-bottom: 25px;
    }
    &.subtitle {
      font-size: 18px;
      font-weight: bold;
      color: ${colors.gray100};
      margin-bottom: 25px;
      @media ${devices.tablet} {
        font-size: 14px;
      }
    }
  }

  .floor {
    @media ${devices.tablet} {
      max-width: 74%;
    }
  }
`;
