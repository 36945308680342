import React from 'react';
import { Box, Text, InputRightAddon as ChakraInputRightAddon } from '@chakra-ui/react';

type InputRightAddonProps = {
  unit: string;
};

const InputRightAddon = ({ unit }: InputRightAddonProps) => (
  <ChakraInputRightAddon className="right-addon">
    <Box className="unit-wrapper" w="100%">
      <Text className="unit-wrapper__value">{unit}</Text>
    </Box>
  </ChakraInputRightAddon>
);

export default InputRightAddon;
