import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const ProposalContainer = styled.div`
  margin-top: 40px;

  .proposal__section {
    background-color: rgba(0, 0, 0, 0.03);
    padding: 10px;
    border-radius: 4px;

    .proposal__subsection {
      margin: 40px 0;
    }

    .proposal__title {
      font-size: 16px;
      color: ${colors.gray200};
      font-weight: 500;

      &.bold {
        font-weight: 700;
      }
    }

    .proposal__subtitle {
      font-size: 14px;
      color: ${colors.gray200};
      font-weight: 500;
      margin-top: 15px;

      &.bold {
        font-weight: 700;
      }
    }

    .proposal__text,
    .proposal__result {
      font-size: 14px;
      font-weight: 400;
      color: ${colors.gray200};
      line-height: normal;
      text-align: justify;
      padding: 5px;
      opacity: 1;
      height: auto;

      span {
        background-color: ${colors.transparent} !important;
      }
    }
  }

  table td {
    padding: 4px 5px;
    text-align: center;
    border: 0;
  }

  table thead th {
    padding: 3px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    background: whitesmoke;
  }

  table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  table tbody th {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  table tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    text-align: left;
    min-width: 100px;
    background: inherit;

    @media ${devices.tablet} {
      min-width: 300px;
    }
  }

  table tbody tr:nth-child(even) {
    background-color: white;
  }

  table tbody tr:nth-child(odd) {
    background-color: #f0f0f0;
  }
`;
