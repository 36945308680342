/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Button, Collapse, HStack } from '@chakra-ui/react';
import { Editor } from 'react-draft-wysiwyg';
import { MdSave, MdAdd } from 'react-icons/md';
import DOMPurify from 'dompurify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import { colors } from '~/styles/colors';

import markdownConverter from '~/helpers/markdownConverter';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { TextEditorContainer } from './TextEditor_Styles';

type TextEditorType = {
  markdown?: string;
  saveContentEditor: (string) => void;
};

const TextEditor = ({ markdown, saveContentEditor }: TextEditorType) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(markdown)));
  const [editorEnabled, setEditorEnabled] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);

  const uploadCallback = (file) => {
    const imageObject = {
      file,
      localSrc: URL.createObjectURL(file),
    };

    setUploadedImages([...uploadedImages, imageObject]);

    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  const settings = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      // 'colorPicker',
      // 'link',
      // 'embedded',
      // 'image',
      'remove',
      'history',
    ],
    image: { uploadCallback, previewImage: true, alt: { present: true, mandatory: false } },
  };

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromText(markdown)));

    return () => null;
  }, [markdown]);

  const onEditorStateChange = (es) => {
    setEditorState(es);
  };

  const handleSaveContent = () => {
    setEditorEnabled(!editorEnabled);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const mk = draftToMarkdown(rawContentState);

    if (editorEnabled && markdown.length <= 1) {
      setEditorState(EditorState.createEmpty());
      saveContentEditor(mk);
    } else {
      saveContentEditor(mk);
    }
  };

  return (
    <TextEditorContainer>
      <Collapse in={!editorEnabled} animateOpacity unmountOnExit>
        <div
          className="proposal__result"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              markdownConverter(draftToMarkdown(convertToRaw(editorState.getCurrentContent())))
            ),
          }}
        />
      </Collapse>

      <HStack>
        <Button
          leftIcon={
            editorEnabled ? <MdSave size={20} color={colors.secondary} /> : <MdAdd size={20} color={colors.secondary} />
          }
          size="xs"
          backgroundColor={colors.white}
          variant="outline"
          color={colors.secondary}
          fontSize={14}
          onClick={handleSaveContent}
        >
          {editorEnabled ? 'Confirmar' : 'Adicionar Texto'}
        </Button>
      </HStack>
      <Collapse in={editorEnabled} animateOpacity unmountOnExit>
        <Editor
          toolbar={settings}
          editorState={editorState}
          wrapperClassName="editor-wrapper"
          editorClassName="text-editor"
          onEditorStateChange={onEditorStateChange}
          localization={{ locale: 'pt' }}
        />
      </Collapse>
    </TextEditorContainer>
  );
};

export default TextEditor;
