import { extendTheme, theme as base } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { size } from '~/styles/breakpoints';

const chakraBreakpoints = createBreakpoints({
  base: size.mobileS,
  sm: size.mobileL,
  md: size.tablet,
  lg: size.laptop,
  xl: size.desktop,
});

const fonts = {
  ...base.fonts,
  body: `VisbyCF, ${base.fonts?.body}`,
  heading: `VisbyCF, ${base.fonts?.heading}`,
};

export const theme = extendTheme({
  fonts,
  chakraBreakpoints,
  components: {
    Steps,
  },
});
