import React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import { FieldAttributes, useField } from 'formik';

import { TextAreaWrapper } from './TextArea_Styles';
import { colors } from '~/styles/colors';

type TextAreaProps = {
  form: any;
  field: FieldAttributes<any>;
  name: string;
  label: string;
  value: any;
  size?: string;
};

const TextArea = ({ ...props }: TextAreaProps) => {
  const { form, field, label, value, size, ...rest } = props;
  const { name } = field;
  const [, { error, touched }] = useField(name);

  return (
    <TextAreaWrapper>
      <FormControl className="formControl" isInvalid={!!error && touched}>
        <FormLabel className={size || 'sm'} color={colors.gray100} htmlFor={name}>
          {label}
        </FormLabel>
        <Textarea size={size || 'sm'} data-testid={name} {...field} {...rest} />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </TextAreaWrapper>
  );
};

export default TextArea;
