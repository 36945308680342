/**
 * Action types
 */
export enum RoomInfoActionTypes {
  STORE_DATA = '@roomInfo/STORE_DATA',
}

/**
 * Data types
 */
export interface RoomInfo {
  name: string;
  roomTemperature: number;
  ambientTemperature: number;
  operatingHours: number;
}

/**
 * State type
 */
export interface RoomInfoState extends RoomInfo {}
