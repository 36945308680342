import React from 'react';
import { Container, Heading } from '@chakra-ui/react';

import { colors } from '~/styles/colors';
import Header from '~/components/Header';
import Footer from '~/components/Footer';

type DefaultLayoutProps = {
  children: any;
};

export default function DefaultLayout({ children }: DefaultLayoutProps) {
  const { pageTitle } = children.props;

  return (
    <>
      <Header />
      <Container maxW="container.lg">
        <Heading as="h1" size="md" mb="2" fontWeight={500} color={colors.secondary}>
          {pageTitle}
        </Heading>

        {children}
      </Container>
      <Footer />
    </>
  );
}
