/* eslint-disable no-console */
import { put, takeLatest, all, select } from 'redux-saga/effects';
import { isObjectEqual } from '~/helpers/functions';
import { storeRoomInfoIntoRoom } from '~/store/ducks/Room/actions';
import { getRoomState } from '~/store/ducks/Room/selectors';
import { RoomState } from '~/store/ducks/Room/types';
import { RoomInfoActionTypes } from '~/store/ducks/RoomInfo/types';

export function* checkRoomInfoState(action: any): any {
  const roomState: RoomState = yield select(getRoomState);
  const { name, roomTemperature, operatingHours, ambientTemperature } = roomState.data;
  const roomInfoState = { name, roomTemperature, operatingHours, ambientTemperature };

  const isEquals = isObjectEqual(roomInfoState, action.payload.data);

  if (!isEquals) {
    yield put(storeRoomInfoIntoRoom(action.payload.data));
  }
}

export default all([takeLatest([RoomInfoActionTypes.STORE_DATA], checkRoomInfoState)]);
