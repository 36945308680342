import React, { useEffect, useRef } from 'react';

import { Form, Field, Formik } from 'formik';
import { SimpleGrid, Box } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import Input from '~/components/Form/Input';
import { ApplicationState } from '~/store/store';
import { storeRoomInfo } from '~/store/ducks/RoomInfo/actions';
import Yup from '~/helpers/validations';
import Warning from '~/components/Form/Warning';
import { storeRoomInfoIntoRoom } from '~/store/ducks/Room/actions';
import { RoomStatus } from '~/store/ducks/Room/types';
import { parseValue2Decimal } from '~/helpers/functions';

const schema = Yup.object().shape({
  name: Yup.string().max(60).nullable().required(),
  roomTemperature: Yup.number().required(),
  ambientTemperature: Yup.number().required(),
  operatingHours: Yup.number().positive().required(),
});

const FormRoomInfo: React.FC = () => {
  const dispatch = useDispatch();

  const { status } = useSelector((state: ApplicationState) => state.room);
  const { name, ambientTemperature, roomTemperature, operatingHours } = useSelector(
    (state: ApplicationState) => state.room.data
  );
  const firstRender = useRef(true);

  const initialValues = {
    name,
    ambientTemperature: parseValue2Decimal(ambientTemperature),
    roomTemperature: parseValue2Decimal(roomTemperature),
    operatingHours: parseValue2Decimal(operatingHours),
  };

  useEffect(() => {
    if (firstRender.current && status === RoomStatus.CREATING) {
      dispatch(storeRoomInfoIntoRoom(initialValues));
    }
    firstRender.current = false;
  }, []);

  const onRoomTemperatureChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const roomTemperatureValue = e.target.value;
    const operatingHoursValue = +roomTemperatureValue >= 0 ? 20 : 18;
    setFieldValue('operatingHours', operatingHoursValue, false);
  };

  const onCleanTemperature = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    setFieldValue('roomTemperature', '', false);
  };

  const onCleanAmbientTemperature = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    setFieldValue('ambientTemperature', '', false);
  };

  const onIdealTemperatureChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const temperatureValue = e.target.value;
    const maxValue = +temperatureValue > 21 ? 21 : +temperatureValue;
    const roomTemperatureValue = maxValue < -35 ? -35 : maxValue;
    setFieldValue('roomTemperature', roomTemperatureValue, false);
  };

  const onAmbientTemperatureChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const temperatureValue = e.target.value;
    const maxAmbientTemperature = +temperatureValue > 43 ? 43 : +temperatureValue;
    setFieldValue('ambientTemperature', maxAmbientTemperature, false);
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      validationSchema={schema}
      validateOnChange={false}
      enableReinitialize
      onSubmit={() => {}}
      validate={async (values) => {
        const isFormValid = await schema.isValid(values);
        if (isFormValid) {
          dispatch(storeRoomInfo(values));
        }
      }}
    >
      {({ values, setFieldValue, handleChange }) => (
        <Form className="form">
          <SimpleGrid className="print-grid" columns={[1, 1, 2]} spacing={10}>
            <Box>
              <Field
                name="name"
                id="roomName"
                component={Input}
                type="text"
                label="Referência da Câmara"
                value={values.name}
              />
              <Field
                name="roomTemperature"
                component={Input}
                type="number"
                label="Temperatura da Câmara"
                unit="°C"
                value={values.roomTemperature}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onCleanTemperature(e, setFieldValue);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onRoomTemperatureChange(e, setFieldValue);
                  onIdealTemperatureChange(e, setFieldValue);
                }}
              />
            </Box>
            <Box>
              <Field
                name="ambientTemperature"
                component={Input}
                type="number"
                label="Temperatura Ambiente"
                unit="°C"
                value={values.ambientTemperature}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onCleanAmbientTemperature(e, setFieldValue);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onAmbientTemperatureChange(e, setFieldValue);
                }}
              />
              <Field
                name="operatingHours"
                component={Input}
                type="number"
                label="Tempo de Funcionamento"
                unit="horas"
                value={values.operatingHours}
              />
            </Box>
          </SimpleGrid>

          {status === RoomStatus.CREATING && <Warning message="As alterações nesta seção impactam as seções abaixo." />}
        </Form>
      )}
    </Formik>
  );
};

export default FormRoomInfo;
