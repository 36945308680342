/* eslint-disable no-console */
import { Table, Thead, Tr, Th, Image, Tbody, Td, Box, Skeleton, Icon } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BsImage } from 'react-icons/bs';
import TableStriped from '~/components/TableStriped';
import { toastError } from '~/helpers/toast';
import EquipmentService from '~/store/ducks/Equipment/services';
import { CondensingUnit, EquipmentInterfaceTypes, Evaporator } from '~/store/ducks/Equipment/types';
import { colors } from '~/styles/colors';

type EquipmentTableComparisonProps = {
  comparisonSelected: any[];
  equipmentType: string;
};

const EquipmentTableComparison: React.FC<EquipmentTableComparisonProps> = ({ comparisonSelected, equipmentType }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [equipments, setEquipments] = useState([]);

  const equipmentPropertiesArray =
    equipmentType === EquipmentInterfaceTypes.EVAPORATOR
      ? [
          'model',
          'line',
          'nominalCapacity',
          'refrigerantFluid',
          'fanDiameter',
          'unitAirFlow',
          'dimension',
          'fanPower',
          // 'defrostPower',
          'finsProtection',
          'chamber',
        ]
      : [
          'brand',
          'model',
          'line',
          'nominalCapacity',
          'refrigerantFluid',
          // 'fairing',
          'voltage',
          // 'evaporationTemperature',
          'compressorType',
          // 'phase',
          // 'frequency',
        ];

  const getPropertyKey = (property: string) => {
    let stringTable = null;

    if (equipmentType === EquipmentInterfaceTypes.EVAPORATOR) {
      stringTable = {
        model: 'Modelo',
        line: 'Linha',
        nominalCapacity: 'Capacidade Nominal (Kcal/h)',
        refrigerantFluid: 'Fluído Refrigerante',
        fanDiameter: 'Diâmetro do Ventilador (mm)',
        unitAirFlow: 'Vazão de Ar',
        dimension: 'Dimensões (A x L x C)',
        fanPower: 'Potência do Ventilador',
        defrostPower: 'Potência de Degelo',
        finsProtection: 'Proteção de Aletas',
        chamber: 'Gabinete',
      };
    } else {
      stringTable = {
        brand: 'Marca',
        model: 'Modelo',
        line: 'Linha',
        nominalCapacity: 'Capacidade Nominal (Kcal/h)',
        refrigerantFluid: 'Fluído Refrigerante',
        fairing: 'Carenagem',
        voltage: 'Tensão (V)',
        evaporationTemperature: 'Temperatura de Evaporação (°C)',
        compressorType: 'Tipo de Compressor',
        phase: 'Fase',
        frequency: 'Frequência (Hz)',
      };
    }

    try {
      return stringTable[property];
    } catch (e) {
      return '';
    }
  };

  const fetchEvaporatorComparison = async () => {
    let body = null;

    try {
      if (equipmentType === EquipmentInterfaceTypes.EVAPORATOR) {
        body = {
          evaporator: comparisonSelected,
        };
      } else {
        body = {
          condensingUnit: comparisonSelected,
        };
      }

      setLoading(true);
      const response = await EquipmentService.getComparison(body);
      setEquipments(response.data);
    } catch (err) {
      console.error(err);
      toastError('equipment', 'Erro ao comparar equipamentos.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvaporatorComparison();
  }, [comparisonSelected]);

  const getImageFromAssets = (line: string): string | null => {
    if (!line) return null;

    if (equipmentType === EquipmentInterfaceTypes.EVAPORATOR) {
      return `${process.env.PUBLIC_URL}/assets/images/evaporadores/${line.split(' ').join('_')}.jpg`;
    }

    if (equipmentType === EquipmentInterfaceTypes.CONDENSING_UNIT) {
      return `${process.env.PUBLIC_URL}/assets/images/unidades-condensadoras/${line.split(' ').join('_')}.jpg`;
    }

    return null;
  };

  return (
    <Skeleton isLoaded={!loading}>
      <Box mt={10}>
        <TableStriped>
          <Table size="sm" variant="striped" colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Th />
                {equipments?.map((equipment: Evaporator | CondensingUnit, index: number) => (
                  <Th key={String(index)} align="center">
                    <Image
                      margin="0 auto"
                      boxSize="120px"
                      fit="contain"
                      src={getImageFromAssets(equipment.line)}
                      alt="Equipment Image"
                      fallback={<Icon as={BsImage} boxSize="80px" color={colors.gray100} />}
                    />
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {equipmentPropertiesArray?.map((property: string, index: number) => (
                <Tr key={String(index)}>
                  <Td>{getPropertyKey(property)}</Td>
                  {equipments?.map((equipment) => (
                    <Td key={equipment.model}>
                      {property === 'dimension'
                        ? `${equipment.height / 1000} x ${equipment.width / 1000} x ${equipment.length / 1000} (m)`
                        : equipment[property]}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableStriped>
      </Box>
    </Skeleton>
  );
};

export default EquipmentTableComparison;
