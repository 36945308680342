import {
  useBreakpointValue,
  FormControl,
  FormLabel,
  Select,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { colors } from '~/styles/colors';
import { ApplicationState } from '~/store/store';
import {
  storeGoodsIntoRoom,
  storeHeatTransfer,
  storeAdditionalLoad,
  storeDoors,
  storeCalculationType,
  deleteRoom,
  initRoom,
} from '~/store/ducks/Room/actions';
import { Button } from '~/components/Form/Button/Button_Styles';
import { ROOM_INITIAL_STATE } from '~/store/ducks/Room/types';
import RoomService from '~/store/ducks/Room/services';

const RoomCalculation: React.FC = () => {
  const dispatch = useDispatch();
  const room = useSelector((state: ApplicationState) => state.room);
  const responsiveSize = useBreakpointValue(['xs', 'sm']);
  const [calculationType, setCalculationType] = useState('');

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef();

  const handleCalculationType = (selectedCalculationType: string) => {
    dispatch(storeCalculationType(parseInt(selectedCalculationType, 10)));
  };

  const onCancel = () => setIsAlertOpen(false);

  const onConfirm = () => {
    if (room.data.id !== null) RoomService.deleteRoom(room.data.id, room.data.project.id);
    // dispatch(deleteRoom(room.data.id, room.data.project.id));

    handleCalculationType(calculationType);

    dispatch(storeGoodsIntoRoom(ROOM_INITIAL_STATE.goods));
    dispatch(storeHeatTransfer(ROOM_INITIAL_STATE.heatTransfer));
    dispatch(storeAdditionalLoad(ROOM_INITIAL_STATE.additionalLoad));
    dispatch(storeDoors(ROOM_INITIAL_STATE.doors));

    setIsAlertOpen(false);
  };

  return (
    <FormControl className="formControl" width={250}>
      <FormLabel color={colors.gray100} fontSize="12px" htmlFor="calculationType">
        Carga Termica
      </FormLabel>
      <Select
        name="calculationType"
        size="xs"
        color={colors.gray200}
        borderRadius={4}
        value={room.data.calculationType}
        onChange={(e) => {
          setIsAlertOpen(true);
          setCalculationType(e.target.value);
        }}
      >
        <option value={0} disabled>
          Selecione...
        </option>
        <option value={1}>Calcular Carga Termica</option>
        <option value={2}>Inserir Carga Termica</option>
      </Select>
      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={onCancel} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" color={colors.gray100} fontWeight="bold">
              Alterar Entrada de dados da Carga Termica
            </AlertDialogHeader>

            <AlertDialogBody color={colors.gray100}>
              Tem certeza que deseja alterar tipo de entrada de dados? As informações preenchidas serão perdidas.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button $default ref={cancelRef} onClick={onCancel}>
                Cancelar
              </Button>
              <Button $primary onClick={onConfirm} ml={3}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </FormControl>
  );
};

export default RoomCalculation;
