import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import api from '~/services/api';
import { store } from '~/store/store';

const querystring = require('querystring');

export class AuthService {
  static getToken = () => {
    const { accessToken } = store.getState().auth;
    return accessToken;
  };

  static getUsername = () => {
    const accessToken = AuthService.getToken();
    const decodedToken: any = jwtDecode<JwtPayload>(accessToken);
    const { email, username } = decodedToken;
    return username;
  };

  static isAuthenticated = (): boolean => {
    const accessToken = AuthService.getToken();
    if (!accessToken) return false;

    const { exp } = jwtDecode<JwtPayload>(accessToken);
    const isTokenExpired = exp < Date.now() / 1000;
    return !isTokenExpired;
    // return true;
  };

  static getAuthPath = (isLogin: boolean): string => {
    const method: string = isLogin ? process.env.REACT_APP_AUTH_LOGIN_URL : process.env.REACT_APP_AUTH_LOGOUT_URL;
    const clientID = process.env.REACT_APP_AUTH_CLIENT_ID;
    const responseType = process.env.REACT_APP_AUTH_RESPONSE_TYPE;
    const scope = process.env.REACT_APP_AUTH_SCOPE;
    const redirectUrl: string = isLogin
      ? process.env.REACT_APP_AUTH_LOGIN_REDIRECT_URI
      : process.env.REACT_APP_AUTH_LOGOUT_REDIRECT_URI;

    return method
      .concat('?')
      .concat(`client_id=${clientID}`)
      .concat('&')
      .concat(`response_type=${responseType}`)
      .concat('&')
      .concat(`scope=${scope}`)
      .concat('&')
      .concat(`redirect_uri=${redirectUrl}`);
  };

  static login = async (code, token) => {
    let response = null;

    if (code != null) {
      const isLocal =
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === '';

      let data = querystring.stringify({ code });
      if (isLocal && !process.env.REACT_APP_AUTH_LOCAL_DISABLED) {
        data = querystring.stringify({ code, redirect_uri: process.env.REACT_APP_AUTH_LOGIN_REDIRECT_URI });
      }
      response = await axios.post(`${process.env.REACT_APP_API_URL}/oauth2/token`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    } else {
      response = token;
    }

    return response;
  };

  static logout = () => {};
}
