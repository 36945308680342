import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const ComparisonContainer = styled.main`
  margin-top: 40px;
  height: 100vh;

  .comparison__section--box {
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .comparison__section--title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px;
    color: ${colors.gray100};
    text-align: left;
  }

  .comparison__section--image {
    width: 200px;
    height: 130px;
  }
`;
