/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';

import { Form, Field, Formik } from 'formik';

import {
  SimpleGrid,
  Button as ChakraButton,
  Box,
  Stack,
  Text,
  Center,
  Spacer,
  VStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  Divider,
} from '@chakra-ui/react';
import { MdDeleteForever } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Yup from '~/helpers/validations';
import { Button } from '~/components/Form/Button/Button_Styles';
import { Container } from './FormRoomDoor_Styles';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/Select';
import {
  calculationMethodOptions,
  CalculationMethodTypes,
  RoomDoorState,
  protectionBarrierStringOptions,
  ProtectionBarrierTypes,
} from '~/store/ducks/Door/types';
import DoorService from '~/store/ducks/Door/services';
import { ApplicationState } from '~/store/store';
import { storeDoors } from '~/store/ducks/Room/actions';
import { colors } from '~/styles/colors';
import protectionBarrierJson from '~/assets/mocks/protectionBarrier.json';
import { calcVolume, parseValue2Decimal } from '~/helpers/functions';
import Stat from '~/components/Form/Stat';
import { RoomStatus } from '~/store/ducks/Room/types';
import { ROOMDOOR_INITIAL_STATE } from '~/store/ducks/Door/reducer';

const doorsSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  room: Yup.number().nullable(),
  count: Yup.number().required(),
  width: Yup.number().required(),
  height: Yup.number().required(),
  adjacentAmbientTemperature: Yup.number().required(),
  adjacentAmbientRelativeHumidity: Yup.number().required(),
  internalRelativeHumidity: Yup.number().required(),
  openingFrequency: Yup.number().moreThan(0).required(),
  openCloseDuration: Yup.number().required(),
  openDuration: Yup.number().required(),
  protectionBarrier: Yup.object()
    .nullable()
    .shape({
      id: Yup.mixed()
        .nullable()
        .transform((value) => +value),
    }),
  protectionBarrierEfficiency: Yup.number().nullable(),
  airExchangeRate: Yup.number().nullable(),
});

const schema = Yup.object().shape({
  calculationMethod: Yup.string().required(),
  doors: Yup.array(doorsSchema),
});

type FormDoorProps = {
  values: any;
  index?: number;
  setValues: any;
  handleDoorRemoval?: () => void;
  setFieldValue?: any;
  volume?: number;
  protectionBarrierOptions?: any[];
  submitForm: any;
};

const FormDoor: React.FC<FormDoorProps> = ({
  protectionBarrierOptions,
  values,
  index,
  setFieldValue,
  handleDoorRemoval,
  volume,
  submitForm,
}) => {
  const onProtectionTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const objectReference = e.target.getAttribute('data-obj-ref');
    const updateFieldReference = e.target.getAttribute('data-field-ref');

    const selectedProtection = +e.target.value;
    setFieldValue(e.target.name, selectedProtection);

    setTimeout(() => {
      const protectionType = protectionBarrierOptions.find((pb: any) => selectedProtection === pb.value);

      if (!protectionType) {
        setFieldValue(objectReference, null);
        setFieldValue(updateFieldReference, 0);
        return;
      }

      if (protectionType.name === ProtectionBarrierTypes.AIR_CURTAIN) {
        setFieldValue(updateFieldReference, 10);
      } else {
        setFieldValue(updateFieldReference, 30);
      }
    }, 10);
  };

  const onCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updateFieldReference = e.target.getAttribute('data-field-ref');
    const fields: any = document.getElementsByClassName('doors__count');
    let count = 0;

    for (let i = 0; i < fields.length; i++) {
      count += +fields[i].value;
    }

    const openingFrequency = DoorService.getOpeningFrequency(count);
    setFieldValue(updateFieldReference, openingFrequency);
  };

  return (
    <Container>
      <SimpleGrid className="print-grid" columns={[1, 1, 2]} spacing={10}>
        <Spacer />
        <Box mr={0} textAlign="right">
          {values.calculationMethod === CalculationMethodTypes.AIR_VELOCITY && (
            <ChakraButton className="button-remove" onClick={handleDoorRemoval} leftIcon={<MdDeleteForever />}>
              <Text className="button-remove__text">Remover Porta</Text>
            </ChakraButton>
          )}
        </Box>
        <Box>
          <Stack direction={['column', 'column', 'column']}>
            {values.calculationMethod === CalculationMethodTypes.AIR_VELOCITY && (
              <Field
                className="doors__count"
                name={`doors[${index}].count`}
                component={Input}
                type="number"
                label="Quantidade"
                data-field-ref={`doors[${index}].openingFrequency`}
                value={values.doors ? values.doors[index]?.count : 0}
                onBlur={(e) => {
                  onCountChange(e);
                }}
              />
            )}
            <Field
              name={`doors[${index}].width`}
              component={Input}
              type="number"
              label="Largura"
              unit="metros"
              value={values.doors ? values.doors[index]?.width : 0}
            />
            <Field
              name={`doors[${index}].height`}
              component={Input}
              type="number"
              label="Altura"
              unit="metros"
              value={values.doors ? values.doors[index]?.height : 0}
            />
            <Field
              name={`doors[${index}].adjacentAmbientTemperature`}
              component={Input}
              type="number"
              label="Temperatura do ambiente adjacente"
              unit="°C"
              value={values.doors ? values.doors[index]?.adjacentAmbientTemperature : null}
            />
            <Field
              name={`doors[${index}].adjacentAmbientRelativeHumidity`}
              component={Input}
              type="number"
              label="Umidade Relativa do Ambiente Adjacente (%)"
              value={values.doors ? values.doors[index]?.adjacentAmbientRelativeHumidity : null}
            />
            <Field
              name={`doors[${index}].internalRelativeHumidity`}
              component={Input}
              type="number"
              label="Umidade Relativa Interna (%)"
              value={values.doors ? values.doors[index]?.internalRelativeHumidity : null}
            />
          </Stack>
        </Box>
        <Box>
          <Stack direction={['column', 'column', 'column']}>
            <Field
              name={`doors[${index}].openingFrequency`}
              component={Input}
              type="number"
              label="Passagens por hora"
              unit="qtd."
              value={values.doors ? values.doors[index]?.openingFrequency : null}
            />
            <Field
              name={`doors[${index}].openCloseDuration`}
              component={Input}
              type="number"
              label="Tempo Abre e Fecha Passagem"
              unit="segundos"
              value={values.doors ? values.doors[index]?.openCloseDuration : null}
            />
            <Field
              name={`doors[${index}].openDuration`}
              component={Input}
              type="number"
              label="Tempo de Permanencia da Porta Aberta"
              unit="minutos"
              value={values.doors ? values.doors[index]?.openDuration : null}
            />
            <Stack direction={['row']} spacing={1}>
              <Box className="field-wrapper">
                <Field
                  name={`doors[${index}].protectionBarrier.id`}
                  component={Select}
                  options={protectionBarrierOptions}
                  disabledOption="Sem Proteção"
                  label="Proteção"
                  value={values.doors ? values.doors[index]?.protectionBarrier?.id : null}
                  data-field-ref={`doors[${index}].protectionBarrierEfficiency`}
                  data-obj-ref={`doors[${index}].protectionBarrier`}
                  onChange={(e) => {
                    onProtectionTypeChange(e);
                  }}
                />
              </Box>
              <Box className="field-wrapper">
                <Field
                  name={`doors[${index}].protectionBarrierEfficiency`}
                  component={Input}
                  type="number"
                  label="Eficiência (%)"
                  disabled
                  value={values.doors ? values.doors[index]?.protectionBarrierEfficiency : null}
                />
              </Box>
            </Stack>

            {values.calculationMethod === CalculationMethodTypes.VOLUME && (
              <Stack direction={['row']} spacing={1}>
                <Box className="field-wrapper">
                  <Stat label="Volume" value={`${volume} m³`} field={null} />
                </Box>
                <Box className="field-wrapper">
                  <Field
                    name={`doors[${index}].airExchangeRate`}
                    component={Input}
                    type="number"
                    label="Fator (renovação de ar/hora)"
                    value={values.doors ? values.doors[index]?.airExchangeRate : null}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Box>
      </SimpleGrid>
      <VStack m={10}>
        <Center>
          <Button type="button" $default onClick={submitForm}>
            Salvar Portas
          </Button>
        </Center>
      </VStack>
    </Container>
  );
};

const FormRoomDoor: React.FC = () => {
  const dispatch = useDispatch();
  const [protectionBarrierOptions, setProtectionBarrierOptions] = useState([]);

  const { status } = useSelector((s: ApplicationState) => s.room);
  const roomHeight = useSelector((s: ApplicationState) => s.room.data.height);
  const roomTemperature = useSelector((s: ApplicationState) => s.room.data.roomTemperature);
  const roomDoors = useSelector((s: ApplicationState) => s.room.data.doors);
  const { length, width, height } = useSelector((s: ApplicationState) => s.room.data);

  const [doors, setDoors] = useState<RoomDoorState>(roomDoors || ROOMDOOR_INITIAL_STATE);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef();
  const [comingMethod, setComingMethod] = useState('');
  const firstRender = useRef(true);

  useEffect(() => {
    const parsedOptions = protectionBarrierJson.map((protection) => ({
      value: protection.id,
      label: protectionBarrierStringOptions[protection.name],
      name: protection.name,
    }));
    setProtectionBarrierOptions(parsedOptions);
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      setDoors(roomDoors || ROOMDOOR_INITIAL_STATE);
    } else if (roomDoors.doors) {
      setDoors(roomDoors);
    }
  }, [roomDoors]);

  const handleDoorRemoval = (index: any): void => {
    const newList = doors.doors.filter((_, i) => i !== index);
    const suggestedValues = DoorService.getSuggestion({ doors: newList }, true);
    const refreshedDoors = newList.map((door) => ({
      ...door,
      openingFrequency: suggestedValues.openingFrequency,
    }));

    setDoors({ ...doors, doors: [...refreshedDoors] });
    dispatch(storeDoors({ ...doors, doors: [...refreshedDoors] }));
  };

  const addNewDoor = (): void => {
    const suggestedValues = DoorService.getSuggestion(doors);
    const refreshedDoors = (doors.doors ? doors.doors : []).map((door) => ({
      ...door,
      openingFrequency: suggestedValues.openingFrequency,
    }));
    setDoors({ ...doors, doors: [...refreshedDoors, suggestedValues] });
  };

  const setNewDoor = (selectedMethod: string): void => {
    setDoors({
      ...doors,
      calculationMethod: selectedMethod,
      doors: ROOMDOOR_INITIAL_STATE.doors,
    });
  };

  const setNewVolume = (selectedMethod: string): void => {
    const suggestedDoor = DoorService.getSuggestion(doors);
    setDoors({
      ...doors,
      calculationMethod: selectedMethod,
      doors: [suggestedDoor],
    });
  };

  const setMethod = (selectedMethod: string): void => {
    selectedMethod === CalculationMethodTypes.VOLUME ? setNewVolume(selectedMethod) : setNewDoor(selectedMethod);
  };

  useEffect(() => {
    if (status === RoomStatus.UPDATING) return;
    return roomHeight < 4 ? setMethod(CalculationMethodTypes.VOLUME) : setMethod(CalculationMethodTypes.AIR_VELOCITY);
  }, [roomHeight]);

  useEffect(() => {
    if (status === RoomStatus.UPDATING) return;
    const { calculationMethod } = doors;
    if (calculationMethod === CalculationMethodTypes.VOLUME) {
      setMethod(calculationMethod);
    }
  }, [roomTemperature]);

  const onCancel = () => setIsAlertOpen(false);

  const onConfirm = () => {
    setIsAlertOpen(false);
    setMethod(comingMethod);
  };

  const parseDoorsValues = (drs) => {
    const parsedDoorsItems = drs?.doors?.map((door) => ({
      ...door,
      adjacentAmbientTemperature: parseValue2Decimal(door.adjacentAmbientTemperature),
      openDuration: parseValue2Decimal(door.openDuration),
      openCloseDuration: parseValue2Decimal(door.openCloseDuration),
      width: parseValue2Decimal(door.width),
      height: parseValue2Decimal(door.height),
    }));

    return { ...drs, doors: parsedDoorsItems };
  };

  return (
    <Container>
      <Formik
        initialValues={parseDoorsValues(doors)}
        validationSchema={schema}
        enableReinitialize
        validateOnChange
        validateOnBlur={false}
        onSubmit={async (values: any) => {
          const isFormValid = await schema.isValid(values);
          if (isFormValid) {
            dispatch(storeDoors(values));
          }
        }}
        validate={async (values) => {
          setDoors({ ...values });
        }}
      >
        {({ values, setValues, setFieldValue, submitForm }) => (
          <Form>
            <SimpleGrid className="print-grid" columns={[1, 1, 2]} spacing={10}>
              <Field
                name="calculationMethod"
                component={Select}
                options={calculationMethodOptions}
                label="Método de Cálculo"
                value={values.calculationMethod}
                onChange={(e) => {
                  setIsAlertOpen(true);
                  setComingMethod(e.target.value);
                }}
              />
              <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={onCancel} isCentered>
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" color={colors.gray100} fontWeight="bold">
                      Alterar Método de Cálculo
                    </AlertDialogHeader>

                    <AlertDialogBody color={colors.gray100}>
                      Tem certeza que deseja alterar método de cálculo? As informações preenchidas serão perdidas.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button $default ref={cancelRef} onClick={onCancel}>
                        Cancelar
                      </Button>
                      <Button $primary onClick={onConfirm} ml={3}>
                        Confirmar
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </SimpleGrid>

            <Box>
              {doors.doors?.map((_, index) => (
                <div key={String(index)}>
                  <FormDoor
                    values={values}
                    index={index}
                    setValues={setValues}
                    handleDoorRemoval={() => handleDoorRemoval(index)}
                    setFieldValue={setFieldValue}
                    protectionBarrierOptions={protectionBarrierOptions}
                    volume={calcVolume(length, width, height)}
                    submitForm={submitForm}
                  />
                  {values.calculationMethod === CalculationMethodTypes.AIR_VELOCITY && <Divider marginBottom={10} />}
                </div>
              ))}
              {values.calculationMethod === CalculationMethodTypes.AIR_VELOCITY && (
                <VStack mt={10}>
                  <Center>
                    <Button type="button" $primary onClick={() => addNewDoor()}>
                      Adicionar Porta
                    </Button>
                  </Center>
                </VStack>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FormRoomDoor;
