/* eslint-disable no-console */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import { Box, HStack, Skeleton, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import DOMPurify from 'dompurify';
import { ApplicationState } from '~/store/store';
import TextEditor from '~/components/TextEditor/TextEditor';
import { ProposalSectionProps } from '~/store/ducks/Proposal/types';
import ProposalService from '~/store/ducks/Proposal/services';
import { toastError, toastSuccess } from '~/helpers/toast';
import { Button } from '~/components/Form/Button/Button_Styles';
import Warning from '~/components/Form/Warning';
import markdownConverter from '~/helpers/markdownConverter';

const EquipmentFeature: React.FC<ProposalSectionProps> = ({ isExpanded }) => {
  const { id: projectId } = useSelector((state: ApplicationState) => state.project);
  const { data: proposalData } = useSelector((state: ApplicationState) => state.proposal);
  const [additionalInformation, setAdditionalInformation] = useState(null);
  const [equipmentFeature, setEquipmentFeature] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isTextModified, setIsTextModified] = useState<boolean>(false);

  const fetchEquipmentFeature = async () => {
    try {
      setLoading(true);
      const response = await ProposalService.getEquipmentFeature(projectId, proposalData.id);
      setEquipmentFeature(response.data.descriptions);
      setAdditionalInformation(response.data.additionalInformation);
    } catch (err) {
      console.error(err);
      toastError('proposal', 'Erro ao recuperar equipamentos.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      fetchEquipmentFeature();
    }
    return () => setEquipmentFeature(null);
  }, [isExpanded]);

  const saveComplementaryDescription = (ef, markdown) => {
    setIsTextModified(true);
    setEquipmentFeature(
      equipmentFeature.map((equipment) => {
        if (equipment.id === ef.id) {
          return {
            ...equipment,
            complementaryDescription: markdown,
          };
        }
        return { ...equipment };
      })
    );
  };

  const submitAditionalContentEditor = (markdown) => {
    setIsTextModified(true);
    setAdditionalInformation({
      ...additionalInformation,
      text: markdown,
    });
  };

  const handleSubmit = async () => {
    const body = {
      descriptions: equipmentFeature,
      additionalInformation: { ...additionalInformation },
    };

    try {
      setButtonLoading(true);
      const response = await ProposalService.saveEquipmentFeature(projectId, proposalData.id, body);
      toastSuccess('proposal', 'Salvo com sucesso!');
      setIsTextModified(false);
    } catch (err) {
      console.error(err);
      toastError('proposal', 'Erro ao salvar seção.');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Skeleton isLoaded={!loading}>
      <Box className="proposal__section">
        {equipmentFeature?.map((ef: any, index: number) => (
          <Box mb={5} key={String(index)}>
            <Text className="proposal__title"> {ef.title} </Text>
            <div
              className="proposal__text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(markdownConverter(ef.commercialDescription)),
              }}
            />
            <TextEditor
              markdown={ef?.complementaryDescription || ''}
              saveContentEditor={(markdown) => saveComplementaryDescription(ef, markdown)}
            />
          </Box>
        ))}
        <Text className="proposal__title">Informações Adicionais</Text>
        {additionalInformation && (
          <TextEditor markdown={additionalInformation.text} saveContentEditor={submitAditionalContentEditor} />
        )}
      </Box>
      {isTextModified && <Warning message="As alterações ainda não foram salvas." />}
      <HStack mt={5} justify="end">
        <Button isLoading={buttonLoading} disabled={!isTextModified} $primary onClick={handleSubmit}>
          Salvar
        </Button>
      </HStack>
    </Skeleton>
  );
};

export default EquipmentFeature;
