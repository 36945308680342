import { BusinessProposal, ProposalRoom, ProposalTypes } from '~/store/ducks/Proposal/types';

export const fetchProposal = (id: number) => ({
  type: ProposalTypes.FETCH_PROPOSAL,
  payload: {
    id,
  },
});

export const createProposal = (id: number) => ({
  type: ProposalTypes.FETCH_PROPOSAL,
  payload: {
    id,
  },
});

export const storeProposal = (data: BusinessProposal) => ({
  type: ProposalTypes.STORE_PROPOSAL,
  payload: {
    data,
  },
});

export const storePresentation = (data) => ({
  type: ProposalTypes.STORE_PRESENTATION,
  payload: {
    data,
  },
});

export const storeProposalRooms = (data: ProposalRoom[]) => ({
  type: ProposalTypes.STORE_PROPOSAL_ROOMS,
  payload: {
    data,
  },
});

export const storeCommercialConditionsEquipments = (data) => ({
  type: ProposalTypes.STORE_COMMERCIAL_CONDITIONS_EQUIPMENTS,
  payload: {
    data,
  },
});

export const proposalRequestLoading = () => ({
  type: ProposalTypes.REQUEST_LOADING,
});

export const proposalRequestSuccess = () => ({
  type: ProposalTypes.REQUEST_SUCCESS,
});

export const proposalRequestFailure = () => ({
  type: ProposalTypes.REQUEST_FAILURE,
});
