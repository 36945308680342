import React from 'react';
import { Table, Tbody, Tr, Td, Tooltip } from '@chakra-ui/react';
import { CondensingUnit, Configuration, EquipmentInterfaceTypes, Evaporator } from '~/store/ducks/Equipment/types';
import { parseValue2Decimal } from '~/helpers/functions';

type EquipmentTableProps = {
  equipment: Evaporator | CondensingUnit;
  equipmentType: string;
  searchData: any;
  selectedConfiguration?: Configuration;
};

const EvaporatorTable = ({ equipment, searchData, selectedConfiguration }) => {
  const { count } = searchData || equipment;
  const volume = (): number =>
    selectedConfiguration.room.width * selectedConfiguration.room.length * selectedConfiguration.room.height;
  const totalCapacity = (): number => equipment.unitCapacityDt || 0;
  const totalAirFlow = (): number => equipment.unitAirFlow * count || 0;
  const airExchangeRate = (): number => totalAirFlow() / volume() || 0;

  return (
    equipment && (
      <Table className="equipment__table" size="xs" variant="unstyled">
        <Tbody>
          <Tr>
            <Td>Diâmetro Ventilador</Td>
            <Td>{parseValue2Decimal(equipment.fanDiameter)}</Td>
          </Tr>
          {/* <Tr>
            <Td>Potência Nominal</Td>
            <Td>{parseValue2Decimal(equipment.nominalUnitCapacity)}</Td>
          </Tr> */}
          <Tr>
            <Td>Capacidade</Td>
            <Td>{parseValue2Decimal(equipment.unitCapacity)}</Td>
          </Tr>
          <Tr>
            <Td>Capacidade Total</Td>
            <Td>{parseValue2Decimal(totalCapacity())}</Td>
          </Tr>
          <Tr>
            <Td>Vazão de Ar</Td>
            <Td>{parseValue2Decimal(equipment.unitAirFlow)}</Td>
          </Tr>
          <Tr>
            <Td>Vazão de Ar Total</Td>
            <Td>{parseValue2Decimal(totalAirFlow())}</Td>
          </Tr>
          <Tr>
            <Td>Número de Trocas</Td>
            <Td>{parseValue2Decimal(airExchangeRate())}</Td>
            {/* <Tooltip label="Para ajustar o número de trocas, alterar o número de ventiladores e realizar a pesquisa novamente.">
              *
            </Tooltip> */}
          </Tr>
          <Tr>
            <Td colspan="2" />
            .
            <Td />
          </Tr>
          <Tr>
            <Td colspan="2" textAlign="right">
              * Para ajustar o numero de trocas, alterar o numero de ventiladores e realizar a pesquisa novamente. * É
              necessário verificar o dimensional do evaporador selecionado, para avaliar se é possível a instalação
              física dentro da câmara. * É necessário verificar flecha de ar do evaporador selecionado, para avaliar se
              atende a homogeneização da temperatura interna da câmara.
            </Td>
            <Td />
          </Tr>
        </Tbody>
      </Table>
    )
  );
};

const CondensingUnitTable = ({ equipment, searchData }) => {
  const { count } = searchData || equipment;
  const totalCapacity = (): number => equipment.unitCapacity * count || 0;
  const capacityOverload =
    parseValue2Decimal(searchData?.capacity || 0) === 0
      ? 0
      : (parseValue2Decimal(equipment.unitCapacity || 0) / parseValue2Decimal(searchData?.capacity || 0)) * 100;

  // console.log('equipment => ', equipment);

  if (equipment.nominalUnitCapacity <= 0) return null;

  return (
    equipment && (
      <Table className="equipment__table" size="xs" variant="unstyled">
        <Tbody>
          <Tr>
            <Td>Potência Consumida</Td>
            <Td>{parseValue2Decimal(equipment.consumedPower)} </Td>
          </Tr>
          <Tr>
            <Td>Tipo de Compressor</Td>
            <Td> {equipment.compressorType}</Td>
          </Tr>
          {/* <Tr>
            <Td>Capacidade</Td>
            <Td>{parseValue2Decimal(equipment.unitCapacity)}</Td>
          </Tr> */}
          <Tr>
            <Td>Capacidade</Td>
            <Td>{parseValue2Decimal(equipment.nominalUnitCapacity)}</Td>
          </Tr>
          <Tr>
            <Td>Capacidade Total</Td>
            {/* <Td>{parseValue2Decimal(totalCapacity())}</Td> */}
            <Td>{parseValue2Decimal(equipment.unitCapacity)}</Td>
          </Tr>
          <Tr>
            <Td>Marca do Compressor</Td>
            <Td>{equipment.compressor}</Td>
          </Tr>
          {/* <Tr>
            <Td>Modelo do Compressor</Td>
            <Td>{equipment.model}</Td>
          </Tr> */}
          {capacityOverload > 120 && (
            <>
              <Tr>
                <Td colspan="2" />
                .
                <Td />
              </Tr>
              <Tr>
                <Td colspan="2" textAlign="right">
                  * A capacidade da unidade condensadora esta acima de 20% da carga termica
                </Td>
                <Td />
              </Tr>
            </>
          )}
        </Tbody>
      </Table>
    )
  );
};

const EquipmentTable = ({ equipment, equipmentType, searchData, selectedConfiguration }: EquipmentTableProps) => (
  <>
    {(() => {
      switch (equipmentType) {
        case EquipmentInterfaceTypes.EVAPORATOR:
          return (
            <EvaporatorTable
              equipment={equipment}
              searchData={searchData}
              selectedConfiguration={selectedConfiguration}
            />
          );
        default:
          return <CondensingUnitTable equipment={equipment} searchData={searchData} />;
      }
    })()}
  </>
);

export default EquipmentTable;
