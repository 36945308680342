/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { toastError } from '~/helpers/toast';
import { getAllSystems } from '~/store/ducks/Equipment/actions';
import EquipmentService from '~/store/ducks/Equipment/services';
import { EquipmentTypes } from '~/store/ducks/Equipment/types';
import GoodsService from '~/store/ducks/Goods/services';
import {
  projectRequestFailure,
  projectRequestLoading,
  projectRequestSuccess,
  storeProjectData,
  storeProjectRooms,
} from '~/store/ducks/Project/actions';
import ProjectService from '~/store/ducks/Project/services';
import { ProjectActionTypes, ProjectRoom } from '~/store/ducks/Project/types';
import { storeCommercialConditionsEquipments, storeProposalRooms } from '~/store/ducks/Proposal/actions';
import ProposalService from '~/store/ducks/Proposal/services';
import { ProposalTypes } from '~/store/ducks/Proposal/types';
import RoomService from '~/store/ducks/Room/services';

function* getRoomFullData(rooms, projectId) {
  return yield all(rooms.map((room: ProjectRoom) => call(RoomService.getRoom, room.id, projectId)));
}

function* fetchProjectRooms(action: any) {
  const { id, params } = action.payload;

  try {
    yield put(projectRequestLoading());
    const response = yield call(ProjectService.fetchProjectRooms, id, params);
    yield put(storeProjectRooms(response.data));

    if (action.type === ProposalTypes.FETCH_PROPOSAL) {
      const rooms = response.data;
      const fullRooms = [];

      const roomsResponseArray = yield call(getRoomFullData, rooms, id);
      const equipmentsResponse = yield call(EquipmentService.getAllSystems, id);

      for (const roomResponse of roomsResponseArray) {
        const goodsResponse = yield call(GoodsService.getGoods);
        fullRooms.push({ goods: goodsResponse.data, data: roomResponse.data });
      }

      const proposalRooms = ProposalService.parseRoomsProposalFormat(fullRooms);
      const proposalRoomAndEquipments = ProposalService.parseEquipmentProposalFormat(
        proposalRooms,
        equipmentsResponse.data
      );
      const commercialConditionsEquipments = ProposalService.parseEquipmentCommercialConditionsFormat(
        equipmentsResponse.data
      );
      yield put(storeProposalRooms(proposalRoomAndEquipments));
      yield put(storeCommercialConditionsEquipments(commercialConditionsEquipments));
    }

    yield put(projectRequestSuccess());
  } catch (err) {
    yield put(projectRequestFailure());
    console.error(err);
    toastError('project', 'Erro ao recuperar câmaras.');
  }
}

function* fetchProjectById(action: any) {
  const { id } = action.payload;

  try {
    yield put(projectRequestLoading());
    const response = yield call(ProjectService.getProjectById, id);
    yield put(storeProjectData(response.data));
    yield put(projectRequestSuccess());
  } catch (err) {
    yield put(projectRequestFailure());
    console.error(err);
    toastError('project', 'Erro ao recuperar projeto.');
  }
}

export default all([
  takeLatest([ProjectActionTypes.FETCH_ROOMS, ProposalTypes.FETCH_PROPOSAL], fetchProjectRooms),
  takeLatest([ProjectActionTypes.FETCH_PROJECT, ProposalTypes.FETCH_PROPOSAL], fetchProjectById),
]);
