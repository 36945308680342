import { all, put, select, takeLatest } from 'redux-saga/effects';
import { getGoodsState } from '~/store/ducks/Goods/selectors';
import { GoodsActionTypes, GoodsState } from '~/store/ducks/Goods/types';
import { storeGoodsIntoRoom } from '~/store/ducks/Room/actions';

export function* checkGoodsState(action: any): any {
  const goodsState: GoodsState = yield select(getGoodsState);
  // const isEquals = isArrayEqual(goodsState, action.payload.data);
  yield put(storeGoodsIntoRoom(action.payload.data));
}

export default all([takeLatest(GoodsActionTypes.STORE_DATA, checkGoodsState)]);
