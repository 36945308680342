import { Box, Center, Image, Progress, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '~/styles/colors';
import logo from '~/assets/images/elgin-branco.png';
import { login } from '~/store/ducks/Auth/actions';
import { ApplicationState } from '~/store/store';

const Login: React.FC = () => {
  const queryParams: any = new URLSearchParams(useLocation().search);
  const code = queryParams.get('code');
  const token = queryParams.get('token');
  const dispatch = useDispatch();
  const history = useHistory();
  const { accessToken } = useSelector((state: ApplicationState) => state.auth);

  const fetchToken = async () => {
    dispatch(login(code, token));
  };

  useEffect(() => {
    fetchToken();
  }, []);

  useEffect(() => {
    if (accessToken) {
      history.push(`/projects`);
    }
    return () => null;
  }, [accessToken]);

  return (
    <Box w="200px">
      <Center>
        <Image src={logo} alt="Logo Elgin" />
      </Center>
      <Box align="center" width="100%" mt={5}>
        <Text color={colors.gray50} mb={2} fontSize={18}>
          Processando Login...
        </Text>
        <Progress size="sm" colorScheme="twitter" isIndeterminate borderRadius={2} />
      </Box>
    </Box>
  );
};

export default Login;
