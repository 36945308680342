import styled from 'styled-components';

export const TextAreaWrapper = styled.div`
  margin: 0 !important;

  .formControl {
    margin-bottom: 25px;

    label {
      font-size: 14px;
    }

    textarea {
      border-radius: 4px;
    }

    label {
      font-size: 14px;

      &.xs {
        font-size: 12px;
      }
    }
  }
`;
