/**
 * Action types
 */
export enum DoorTypes {
  STORE_DATA = '@door/STORE_DATA',
  ADD_DOOR = '@door/ADD_DOOR',
}

/**
 * Data types
 */

export interface Door {
  id?: number;
  room?: { id: number };
  count: number;
  width: number;
  height: number;
  airExchangeRate?: number;
  adjacentAmbientTemperature: number;
  adjacentAmbientRelativeHumidity: number;
  internalRelativeHumidity: number;
  openingFrequency: number;
  openCloseDuration: number;
  openDuration: number;
  protectionBarrier: { id: number };
  protectionBarrierEfficiency: number;
}

export interface RoomDoor {
  calculationMethod: string;
  doors: Door[];
  id?: number;
  room?: { id: number };
}

export enum CalculationMethodTypes {
  AIR_VELOCITY = 'AIR_VELOCITY',
  VOLUME = 'VOLUME',
}

export const calculationMethodOptions = [
  {
    value: 'AIR_VELOCITY',
    label: 'Velocidade do Ar',
  },
  {
    value: 'VOLUME',
    label: 'Volume',
  },
];

export enum ProtectionBarrierTypes {
  NO_PROTECTION = 'NO_PROTECTION',
  AIR_CURTAIN = 'AIR_CURTAIN',
  AUXILIAR_CURTAIN = 'AUXILIAR_CURTAIN',
}

export const protectionBarrierStringOptions = {
  NO_PROTECTION: 'Sem Proteção',
  AIR_CURTAIN: 'Cortina de Ar',
  AUXILIAR_CURTAIN: 'Cortina de PVC',
};

/**
 * State type
 */
export interface RoomDoorState extends RoomDoor {}
