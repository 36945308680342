import { all, put, select, takeLatest } from 'redux-saga/effects';
import { getAdditionalLoadState } from '~/store/ducks/AdditionalLoad/selectors';
import AdditionalLoadService from '~/store/ducks/AdditionalLoad/services';
import { storeAdditionalLoad } from '~/store/ducks/Room/actions';
import { getRoomState } from '~/store/ducks/Room/selectors';
import { RoomActionTypes, RoomStatus } from '~/store/ducks/Room/types';

export function* updateAdditionalLoadState(): any {
  const { status } = yield select(getRoomState);
  if (status === RoomStatus.UPDATING) return;

  const state = yield select(getAdditionalLoadState);
  const newState = AdditionalLoadService.getSuggestion({ ...state });
  yield put(storeAdditionalLoad(newState));
}

export default all([
  takeLatest(
    [RoomActionTypes.STORE_ROOM_INFO, RoomActionTypes.STORE_INTERNAL_DIMENSION, RoomActionTypes.STORE_GOODS],
    updateAdditionalLoadState
  ),
]);
