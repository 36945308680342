import api from '~/services/api';
import { Project } from '~/store/ducks/Project/types';

class ProjectService {
  static fetchProjectRooms = (id: number, param: string) =>
    api.get(`/v1/projects/${id}/rooms`, {
      params: { embed: param },
    });

  static createProject = (project: Project) => api.post(`/v1/projects`, project);

  static updateProject = (id: number, project: Project): any => api.put(`/v1/projects/${id}`, project);

  static getProjects = (params?: any): any =>
    api.get(`/v1/projects`, {
      params,
    });

  static getProjectById = (id: number) => api.get(`/v1/projects/${id}`);

  static copyProject = (id: number) => api.post(`/v1/projects/${id}/copy`);
}

export default ProjectService;
