import { Box, Fade, Heading, Skeleton, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProjectRouter from '~/components/ProjectRouter';
import ProposalWrapper from '~/components/Proposal/ProposalWrapper';
import ProposalHeader from '~/components/Proposal/ProposalHeader';
import { ApplicationState } from '~/store/store';
import { colors } from '~/styles/colors';
import { fetchProposal } from '~/store/ducks/Proposal/actions';

const ProposalWrapperSkeleton = () => (
  <Stack mt={10}>
    <Skeleton height="174px" />
    <Skeleton height="74px" />
    <Skeleton height="74px" />
    <Skeleton height="74px" />
    <Skeleton height="74px" />
    <Skeleton height="74px" />
  </Stack>
);

const Proposal: React.FC = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const projectId = params.id;
  const project = useSelector((state: ApplicationState) => state.project);
  const { loading, data: proposalData } = useSelector((state: ApplicationState) => state.proposal);

  useEffect(() => {
    dispatch(fetchProposal(projectId));
  }, []);

  return (
    <>
      <Box minHeight="16px" mb={8}>
        <Heading as="h2" size="xs" fontWeight={500} color={colors.gray100}>
          {project.name}
        </Heading>
      </Box>
      <ProjectRouter
        previousLink={`/projects/${projectId}/equipments`}
        previusText="Equipamentos"
        nextText=""
        noForward
        nextLink={`/projects/${projectId}/proposal`}
      />
      {loading && <ProposalWrapperSkeleton />}
      <Fade in={!loading} unmountOnExit>
        <ProposalHeader />
        {proposalData && <ProposalWrapper />}
      </Fade>
    </>
  );
};

export default Proposal;
