/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { MdArrowDropDown, MdCheckCircle, MdError } from 'react-icons/md';
import {
  Badge,
  Fade,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  Skeleton,
  Stack,
  Text,
  Tag,
  Center,
} from '@chakra-ui/react';
import { Container } from './SliderEquipments_Styles';
import { breakpointSizes } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';
import { addConfiguration, selectRoom } from '~/store/ducks/Equipment/actions';
import { ProjectRoom } from '~/store/ducks/Project/types';
import {
  Configuration,
  Equipment,
  EquipmentSystem,
  RefrigerantFluid,
  refrigerantFluids,
} from '~/store/ducks/Equipment/types';
import { ApplicationState } from '~/store/store';
import { parseValue2Decimal } from '~/helpers/functions';

type SliderEquipmentsProps = {
  equipment: Equipment;
};

const SliderEquipments: React.FC<SliderEquipmentsProps> = ({ equipment: equipmentState }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: ApplicationState) => state.equipment);
  const [isOpen, setIsOpen] = React.useState(false);
  const openPopOver = () => setIsOpen(!isOpen);
  const closePopOver = () => setIsOpen(false);
  let operationTemperature = null;
  let refrigerantFluid: RefrigerantFluid = null;

  const activeSystem = equipmentState.systems.find((system: EquipmentSystem) => system.active);

  if (activeSystem) {
    operationTemperature = activeSystem.operationTemperature;
    refrigerantFluid = activeSystem.refrigerantFluid;
  }

  const sortedRooms = equipmentState.projectRooms.sort((room1, room2) => room2.height - room1.height);

  const settings = {
    dots: true,
    infinite: sortedRooms ? sortedRooms.length > 5 : false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: breakpointSizes.laptop,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: sortedRooms ? sortedRooms.length > 5 : false,
          dots: true,
        },
      },
      {
        breakpoint: breakpointSizes.tablet,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: sortedRooms ? sortedRooms.length > 4 : false,
          dots: false,
        },
      },
      {
        breakpoint: breakpointSizes.mobileXL,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: sortedRooms ? sortedRooms.length > 3 : false,
          dots: false,
        },
      },
      {
        breakpoint: breakpointSizes.mobileL,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: sortedRooms ? sortedRooms.length > 3 : false,
          dots: false,
        },
      },
      {
        breakpoint: breakpointSizes.mobileS,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: sortedRooms ? sortedRooms.length > 2 : false,
          dots: false,
        },
      },
    ],
  };

  const checkRoomCompatibility = (room: ProjectRoom, opTemperature: number): boolean => {
    const isDefaultRefrigerant = refrigerantFluids.includes(refrigerantFluid?.code);
    const conditionFactor = isDefaultRefrigerant ? 5 : 2;
    const dt = room.roomTemperature - opTemperature;
    return dt >= conditionFactor;
  };

  const handleSelectRoom = (room: ProjectRoom, opTemperature) => {
    if (opTemperature === null) {
      openPopOver();
      return;
    }

    const isRoomCompatible = checkRoomCompatibility(room, opTemperature);
    if (!isRoomCompatible) return;

    dispatch(selectRoom(room.id));

    const configuration: Configuration = {
      room: {
        ...room,
        temperatureDelta: Math.abs(room.roomTemperature - operationTemperature),
      },
      equipment: null,
      configType: null,
    };

    dispatch(addConfiguration(configuration));
  };

  const checkRoomCompatibilityClass = (room: ProjectRoom, opTemperature: any): string => {
    if (opTemperature === null) return '';

    const isRoomCompatible = checkRoomCompatibility(room, opTemperature);
    if (isRoomCompatible) return `inner__item--compatible`;
    return `inner__item--not-compatible`;
  };

  const checkRoomCompatibilityBadge = (room: ProjectRoom, opTemperature: any): any => {
    if (opTemperature === null) return <></>;

    const isRoomCompatible = checkRoomCompatibility(room, opTemperature);
    if (isRoomCompatible) return <MdCheckCircle className="inner__item--badge" color={colors.green} size={20} />;
    return <MdError className="inner__item--badge" color={colors.red} size={20} />;
  };

  return (
    <Container>
      {loading && (
        <Stack>
          <Skeleton h={[90, 100, 110, 130, 130]} marginX={[5, 7, 10]} />
        </Stack>
      )}
      <Fade in={!loading}>
        <Slider {...settings}>
          {sortedRooms.length > 0 &&
            sortedRooms.map((room: ProjectRoom) => (
              <div key={room.id} className="item">
                <button
                  className={`inner__item elgin__box ${checkRoomCompatibilityClass(room, operationTemperature)}`}
                  type="button"
                  onClick={() => handleSelectRoom(room, operationTemperature)}
                >
                  {checkRoomCompatibilityBadge(room, operationTemperature)}
                  <p className="item__text" color={colors.primary}>
                    {room.name}
                  </p>
                  <HStack className="item__info">
                    <p className="item__dt" color={colors.primary}>
                      {parseValue2Decimal(room.roomTemperature)} °C
                    </p>
                    <p className="item__heatLoad" color={colors.primary}>
                      {parseValue2Decimal(room.heatLoad?.totalKcalh)} Kcal
                    </p>
                  </HStack>
                </button>
              </div>
            ))}
        </Slider>
        {sortedRooms.length === 0 && (
          <Center>
            <Text fontSize={14} fontWeight={400} color={colors.gray100}>
              Todas as câmaras foram incluídas
            </Text>
          </Center>
        )}
      </Fade>
      <Popover isOpen={isOpen} onClose={closePopOver} closeOnBlur returnFocusOnClose>
        <PopoverContent maxW="290px" bg={colors.gray100} color="white" onClick={() => closePopOver()}>
          <PopoverBody>
            <Text fontSize={14} lineHeight="1.2">
              Antes de continuar, defina a configuração do sistema.
            </Text>
          </PopoverBody>
          <Icon className="chakra-popover__popper__icon" as={MdArrowDropDown} />
        </PopoverContent>
      </Popover>
    </Container>
  );
};

export default SliderEquipments;
