import { RoomDoor } from '~/store/ducks/Door/types';
import { AdditionalLoad } from '~/store/ducks/AdditionalLoad/types';
import { RoomGoods } from '~/store/ducks/Goods/types';
import { HeatTransfer } from '~/store/ducks/HeatTransfer/types';
import { INITIAL_STATE as INITIAL_STATE_HEAT_TRANSFER } from '~/store/ducks/HeatTransfer/reducer';

/**
 * Action types
 */
export enum RoomActionTypes {
  GET_ROOM = '@room/GET_ROOM',
  DELETE_ROOM = '@room/DELETE_ROOM',
  RESET_ROOM = '@room/RESET_ROOM',
  STORE_ROOM_INITIAL_STATE = '@room/STORE_ROOM_INITIAL_STATE',
  STORE_DATA = '@room/STORE_DATA',
  STORE_DATA_CREATE = '@room/STORE_DATA_CREATE',
  STORE_ROOM_INFO = '@room/STORE_ROOM_INFO',
  STORE_INTERNAL_DIMENSION = '@room/STORE_INTERNAL_DIMENSION',
  STORE_HEAT_TRANSFER = '@room/STORE_HEAT_TRANSFER',
  STORE_HEAT_TRANSFER_DIMENSIONS = '@room/STORE_HEAT_TRANSFER_DIMENSIONS',
  STORE_ADDITIONAL_LOAD = '@room/STORE_ADDITIONAL_LOAD',
  STORE_GOODS = '@room/STORE_GOODS',
  STORE_DOORS = '@room/STORE_DOORS',
  STORE_SAFETY_MARGIN = '@room/STORE_SAFETY_MARGIN',
  STORE_TOTAL_KCALH = '@room/STORE_TOTAL_KCALH',
  STORE_CALCULATION_TYPE = '@room/STORE_CALCULATION_TYPE',
  REQUEST_LOADING = '@room/REQUEST_LOADING',
  REQUEST_SUCCESS = '@room/REQUEST_SUCCESS',
  REQUEST_FAILURE = '@room/REQUEST_FAILURE',
  SET_STATUS_CREATING = '@room/SET_STATUS_CREATING',
  SET_STATUS_UPDATING = '@room/SET_STATUS_UPDATING',
  COPY_ROOM = '@room/COPY_ROOM',
}

/**
 * Data types
 */

export interface HeatLoad {
  heatTransfer: number;
  goods: number;
  additionalLoad: number;
  doors: number;
  totalKw: number;
  totalKcalh: number;
  room?: { id: number };
}

export interface Room {
  id?: number;
  project?: { id: number };
  safetyMargin: number;
  status?: boolean;
  heatLoad: HeatLoad;
  name: string;
  ambientTemperature: number;
  roomTemperature: any;
  operatingHours: number;
  calculationType: number;
  length: number;
  width: number;
  height: number;
  additionalLoad: AdditionalLoad;
  heatTransfer: HeatTransfer;
  goods: RoomGoods;
  doors: RoomDoor;
}

/**
 * State type
 */

export enum RoomStatus {
  UPDATING = 'UPDATING',
  CREATING = 'CREATING',
}

export interface RoomState {
  data: Room;
  loading: boolean;
  status: string;
}

export const ROOM_INITIAL_STATE: Room = {
  id: null,
  project: { id: null },
  safetyMargin: 10,
  heatLoad: {
    heatTransfer: 0,
    goods: 0,
    additionalLoad: 0,
    doors: 0,
    totalKw: 0,
    totalKcalh: 0,
  },
  name: '',
  roomTemperature: '',
  ambientTemperature: 38,
  operatingHours: 20,
  calculationType: 1,
  length: 0,
  width: 0,
  height: 0,
  additionalLoad: {
    staff: {
      count: 0,
      duration: 0,
    },
    forklift: {
      count: 0,
      duration: 0,
    },
    evaporator: {
      count: 1,
      power: 0,
      duration: 0,
    },
    lighting: {
      power: 0,
      duration: 24,
    },
    other: {
      power: 0,
      duration: 0,
    },
  },
  heatTransfer: INITIAL_STATE_HEAT_TRANSFER,
  goods: [],
  doors: {
    calculationMethod: 'VOLUME',
    doors: [],
  },
};
