import { lighten } from 'polished';
import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const StepSKUContainer = styled.section`
  margin-top: 20px;

  p.title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px;
    color: ${colors.gray100};
    text-align: left;
  }

  .table-sku {
    .table-sku__row {
      transition: background-color 0.15s ease-in-out;

      &.selected {
        background: ${lighten(0.33, `${colors.secondary}`)};
      }

      &:hover,
      &:focus {
        cursor: pointer;
      }
    }
  }
`;
