import api from '~/services/api';
import { AdditionalLoad } from '~/store/ducks/AdditionalLoad/types';
import { Door, RoomDoor } from '~/store/ducks/Door/types';
import { GoodsItem } from '~/store/ducks/Goods/types';
import { Edge, HeatTransfer } from '~/store/ducks/HeatTransfer/types';
import { Project, ProjectRoom } from '~/store/ducks/Project/types';
import { HeatLoad, Room, ROOM_INITIAL_STATE } from '~/store/ducks/Room/types';
import { INITIAL_STATE as INITIAL_STATE_HEAT_TRANSFER } from '~/store/ducks/HeatTransfer/reducer';

const parseDoorsBeforeCopy = (doorz: RoomDoor): RoomDoor => {
  const { id, room, doors, ...data } = doorz;
  const parsedDoors = doorz.doors?.map((door: Door) => {
    const { id: doorId, ...rest } = door;
    return rest;
  });

  return {
    ...data,
    doors: parsedDoors,
  };
};

const parseGoodsBeforeCopy = (goods: GoodsItem[]): GoodsItem[] => {
  const parsedGoods = goods.map((item: GoodsItem) => {
    const { id, room, ...rest } = item;
    return rest;
  });
  return parsedGoods;
};

const parseHeatLoadBeforeCopy = (heatLoad: HeatLoad): HeatLoad => {
  const { room, ...rest } = heatLoad;
  return rest;
};

const parseHeatTransferBeforeCopy = (heatTransfer: HeatTransfer): HeatTransfer => {
  const { id: ceilingId, room: ceilingRoom, ...parsedCeiling } = heatTransfer.ceiling;
  const { id: floorId, room: floorRoom, ...parsedFloor } = heatTransfer.floor;
  const parsedWalls = heatTransfer.walls.map((wall: Edge) => {
    const { id, room, ...rest } = wall;
    return rest;
  });

  return {
    ceiling: parsedCeiling,
    floor: parsedFloor,
    walls: parsedWalls,
  };
};

const parseAdditionalLoadBeforeCopy = (additionalLoad: AdditionalLoad): AdditionalLoad => {
  const { id: staffId, ...parsedStaff } = additionalLoad.staff;
  const { id: forkliftId, ...parsedForklift } = additionalLoad.forklift;
  const { id: evaporatorId, ...parsedEvaporator } = additionalLoad.evaporator;
  const { id: lightingId, ...parsedLighting } = additionalLoad.lighting;
  const { id: otherId, ...persedOther } = additionalLoad.other;

  return {
    staff: parsedStaff,
    forklift: parsedForklift,
    evaporator: parsedEvaporator,
    lighting: parsedLighting,
    other: persedOther,
  };
};

class RoomService {
  static createRoom = (room: Room, options?: {}) => api.post(`/v1/projects/${room.project.id}/rooms`, room, options);

  static updateRoom = (id: number, room: Room, options: {}) =>
    api.put(`/v1/projects/${room.project.id}/rooms/${id}`, room, options);

  static getRoom = (id: number, projectId: number) => api.get(`/v1/projects/${projectId}/rooms/${id}`);

  static deleteRoom = (id: number, projectId: number): any => api.delete(`/v1/projects/${projectId}/rooms/${id}`);

  static initRoom = (name: string, projectId: number) => {
    const initialState = ROOM_INITIAL_STATE;
    return { ...initialState, id: Math.floor(Math.random() * 10000000) + 1, name, project: { id: projectId } };
  };

  static getLocalRoom = (project: Project, index: number): Room => {
    const roomInitialState = ROOM_INITIAL_STATE;
    const selectedRoom: ProjectRoom = project.rooms[index];
    return {
      ...roomInitialState,
      project: { ...selectedRoom.project },
      name: selectedRoom.name,
    };
  };

  static isRoomValid = (room: Room): boolean => {
    const { height, width, length } = room;
    return height * width * length > 0;
  };

  static parseRoomBeforeCopy = (room: Room): Room => {
    const { id, name, doors, additionalLoad, goods, heatLoad, heatTransfer, ...rest } = room;

    return {
      name: `Cópia de ${name}`,
      additionalLoad: parseAdditionalLoadBeforeCopy(additionalLoad),
      doors: parseDoorsBeforeCopy(doors),
      goods: parseGoodsBeforeCopy(goods),
      heatLoad: parseHeatLoadBeforeCopy(heatLoad),
      heatTransfer: parseHeatTransferBeforeCopy(heatTransfer),
      ...rest,
    };
  };
}

export default RoomService;
