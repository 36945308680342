import React from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';

import { AccordionContainer } from '~/components/Checklist/FormRoomWrapper/FormRoomWrapper_Styles';

import Presentation from '~/components/Proposal/Presentation';
import EquipmentFeature from '~/components/Proposal/EquipmentFeature';
import { ProposalContainer } from './ProposalWrapper_Styles';
import Characteristics from '~/components/Proposal/Characteristics';
import CommercialCondition from '~/components/Proposal/CommercialCondition';
import TermsConditions from '~/components/Proposal/TermsConditions';
import ProposalOwners from '~/components/Proposal/ProposalOwners';

const ProposalWrapper: React.FC = () => (
  <ProposalContainer>
    <AccordionContainer>
      <Accordion className="accordion" allowToggle>
        {/* <AccordionItem className="accordion__item elgin__box--shadow">
          {({ isExpanded }) => (
            <>
              <AccordionButton className="accordion__button">
                <Box flex="1" textAlign="left">
                  <h2>APRESENTAÇÃO</h2>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="accordion__panel">
                <Presentation isExpanded={isExpanded} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem> */}
        <AccordionItem className="accordion__item elgin__box--shadow">
          {({ isExpanded }) => (
            <>
              <AccordionButton className="accordion__button">
                <Box flex="1" textAlign="left">
                  <h2>PARÂMETROS DE DIMENSIONAMENTO E SELEÇÃO DE EQUIPAMENTOS</h2>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="accordion__panel">
                <Characteristics isExpanded={isExpanded} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem className="accordion__item elgin__box--shadow">
          {({ isExpanded }) => (
            <>
              <AccordionButton className="accordion__button">
                <Box flex="1" textAlign="left">
                  <h2>CARACTERÍSTICAS DOS EQUIPAMENTOS</h2>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="accordion__panel">
                <EquipmentFeature isExpanded={isExpanded} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        {/* <AccordionItem className="accordion__item elgin__box--shadow">
          {({ isExpanded }) => (
            <>
              <AccordionButton className="accordion__button">
                <Box flex="1" textAlign="left">
                  <h2>CONDIÇÕES COMERCIAIS</h2>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="accordion__panel">
                <CommercialCondition isExpanded={isExpanded} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem className="accordion__item elgin__box--shadow">
          {({ isExpanded }) => (
            <>
              <AccordionButton className="accordion__button">
                <Box flex="1" textAlign="left">
                  <h2>CONDIÇÕES GERAIS DE VENDA DA ELGIN S.A.</h2>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="accordion__panel">
                <TermsConditions isExpanded={isExpanded} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem className="accordion__item elgin__box--shadow">
          {({ isExpanded }) => (
            <>
              <AccordionButton className="accordion__button">
                <Box flex="1" textAlign="left">
                  <h2>RESPONSÁVEIS</h2>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="accordion__panel">
                <ProposalOwners isExpanded={isExpanded} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem> */}
      </Accordion>
    </AccordionContainer>
  </ProposalContainer>
);

export default ProposalWrapper;
