import { Reducer } from 'redux';
import { DoorTypes, RoomDoorState, Door } from './types';

export const ROOMDOOR_INITIAL_STATE: RoomDoorState = {
  calculationMethod: 'VOLUME',
  doors: [],
};

export const DOOR_INITIAL_STATE: Door = {
  count: 1,
  width: 0,
  height: 0,
  adjacentAmbientTemperature: 0,
  adjacentAmbientRelativeHumidity: 0,
  internalRelativeHumidity: 0,
  openingFrequency: 0,
  openCloseDuration: 0,
  openDuration: 0,
  protectionBarrier: null,
  protectionBarrierEfficiency: 0,
};

const reducer: Reducer<any> = (state = DOOR_INITIAL_STATE, action) => {
  switch (action.type) {
    case DoorTypes.STORE_DATA:
      return { ...state, ...action.payload.data };
    case DoorTypes.ADD_DOOR:
      return { ...state, doors: [...state.doors.concat(action.payload.data)] };
    default:
      return state;
  }
};

export default reducer;
