import styled from 'styled-components';

import { devices } from '~/styles/breakpoints';

import { colors } from '~/styles/colors';

export const Container = styled.header`
  background: ${colors.white};
  padding: 0 16px;
  border-bottom: 1px solid ${colors.gray50};
  margin-bottom: 40px;
`;

export const Content = styled.section`
  height: 64px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid ${colors.gray50};
    max-width: 180px;
    max-height: 60px;
  }

  aside {
    display: none;
    align-items: center;
    align-self: center;
    flex-grow: 2;
    justify-content: flex-end;

    @media ${devices.tablet} {
      display: flex;
    }
  }
`;

export const Profile = styled.section`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;

  div {
    text-align: right;

    .menu__btn {
      color: ${colors.gray200};
      font-size: 14px;
      font-weight: 400;
      max-width: 160px;
    }

    .menu__item {
      max-width: unset;
    }

    .menu__group__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
      margin: 0;
      text-align: left;
      padding: 0 12px;
      font-size: 12px;
      color: ${colors.gray100};
      font-weight: 400;
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

type StyledBurgerType = {
  open: boolean;
};

export const StyledBurger = styled.div<StyledBurgerType>`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 20;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;

  @media ${devices.tablet} {
    display: none;
    justify-content: space-around;
    flex-flow: row nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${(props) => (props.open ? '#ccc' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${(props) => (props.open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${(props) => (props.open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
