import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const Container = styled.div`
  .client-data__title {
    font-size: 16px;
    color: ${colors.gray100};
    font-weight: 500;
  }

  .client-data__sub {
    font-size: 14px;
    color: ${colors.gray100};
    font-weight: 500;
  }

  .client-data__email {
    font-size: 14px;
    color: ${colors.primary};
    font-weight: 500;
  }
`;
