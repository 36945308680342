import { Skeleton, SimpleGrid, Box, HStack, Container } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import React from 'react';
import { MdSearch } from 'react-icons/md';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import SelectGivenOptions from '~/components/Form/Select/SelectGivenOptions';
import SelectAllGivenOptions from '~/components/Form/Select/SelectAllGivenOptions';
import { filterObjectEmptyValues } from '~/helpers/functions';
import { CondensingUnitOptionals } from '~/store/ducks/Equipment/types';
import EquipmentService from '~/store/ducks/Equipment/services';

type CondensingUnitFormProps = {
  searchData?: any;
  initialValuesFilter: any;
  schemaFilter: any;
  handleCondensingSearch: any;
  optionalsLoading: boolean;
  optionalsFull: CondensingUnitOptionals;
  optionals: CondensingUnitOptionals;
  specialEnabled?: boolean;
};

const CondensingUnitForm: React.FC<CondensingUnitFormProps> = ({
  searchData,
  initialValuesFilter,
  schemaFilter,
  handleCondensingSearch,
  optionalsLoading,
  optionalsFull,
  optionals,
  specialEnabled,
}) => {
  const onLineChange = async (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const line = e.target.value || 'Todos';
    setFieldValue(e.target.name, line);
    const response = await EquipmentService.getOptionals('condensingUnits', line);
    optionals = response.data;
  };

  return (
    <Container>
      <Formik
        initialValues={searchData || initialValuesFilter}
        isInitialValid
        validationSchema={schemaFilter}
        validateOnChange={false}
        onSubmit={(values) => {
          handleCondensingSearch(filterObjectEmptyValues(values));
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Skeleton isLoaded={!optionalsLoading}>
            <Form className="form">
              <SimpleGrid columns={[1, 2]} spacing={[5]}>
                <Box>
                  <Field
                    size="xs"
                    name="productLine"
                    component={SelectAllGivenOptions}
                    label="Linha"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      onLineChange(e, setFieldValue);
                    }}
                    value={values.productLine || ''}
                    options={optionalsFull?.productLine.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="count"
                    component={Input}
                    type="number"
                    label="Quantidade *"
                    value={values.count || ''}
                  />
                  <Field
                    size="xs"
                    name="loadLoss"
                    component={Input}
                    type="number"
                    label="Perda de Carga (K) *"
                    value={values.loadLoss || ''}
                  />
                  <Field
                    size="xs"
                    name="ambientTemperature"
                    component={SelectGivenOptions}
                    label="Temp. Ambiente *"
                    value={values.ambientTemperature || ''}
                    options={['32', '35', '38', '43'].map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="powerSupply"
                    component={SelectGivenOptions}
                    label="Alimentação"
                    value={values.powerSupply}
                    options={[
                      '50hz, 220v, 1F',
                      '50hz, 220v, 3F',
                      '50hz, 380v, 3F',
                      '60hz, 220v, 1F',
                      '60hz, 220v, 3F',
                      '60hz, 380v, 3F',
                      '60hz, 440v, 3F',
                    ].map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="capacityControl"
                    component={SelectAllGivenOptions}
                    label="Controle de Capacidade"
                    value={values.capacityControl || ''}
                    options={optionals?.capacityControl.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="compressorType"
                    component={SelectAllGivenOptions}
                    label="Tipo de Compressor"
                    value={values.compressorType || ''}
                    options={optionals?.compressorType.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                </Box>
                <Box>
                  <Field
                    size="xs"
                    name="chamber"
                    component={SelectAllGivenOptions}
                    label="Gabinete"
                    value={values.chamber || ''}
                    options={optionals?.chamber.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="capacity"
                    component={Input}
                    type="number"
                    label="Capacidade *"
                    value={values.capacity || ''}
                  />
                  <Field
                    size="xs"
                    name="hotGasDefrost"
                    component={SelectAllGivenOptions}
                    label="Degelo a Gás Quente"
                    value={values.hotGasDefrost || ''}
                    options={optionals?.hotAirDefrost.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  {/* <Field
                size="xs"
                name="compressor"
                component={SelectAllGivenOptions}
                label="Compressor"
                value={values.compressor || ''}
                options={optionals?.compressor.map((item: string) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              /> */}
                  <Field
                    size="xs"
                    name="compressor"
                    component={SelectGivenOptions}
                    label="Compressor"
                    value={values.compressor || ''}
                    options={optionals?.compressor.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="numberOfCompressors"
                    component={SelectAllGivenOptions}
                    label="Quantidade de Compressores"
                    value={values.numberOfCompressors || ''}
                    options={optionals?.numberOfCompressors.map((item: number) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="logo"
                    component={SelectAllGivenOptions}
                    label="Logo"
                    value={values.logo || ''}
                    options={optionals?.logo.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                </Box>
              </SimpleGrid>
              <HStack justify="flex-end" mt={4}>
                <Button
                  leftIcon={<MdSearch size={18} />}
                  type="button"
                  disabled={specialEnabled}
                  $primary
                  onClick={submitForm}
                >
                  Pesquisar
                </Button>
              </HStack>
            </Form>
          </Skeleton>
        )}
      </Formik>
    </Container>
  );
};

export default CondensingUnitForm;
