/* eslint-disable no-case-declarations */
import { Reducer } from 'redux';
import { ProposalState, ProposalTypes } from '~/store/ducks/Proposal/types';

const INITIAL_STATE: ProposalState = {
  data: null,
  rooms: [],
  equipments: [],
  loading: false,
};

const reducer: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProposalTypes.STORE_PROPOSAL:
      return {
        ...state,
        data: action.payload.data,
      };
    case ProposalTypes.STORE_PRESENTATION:
      return state;
    case ProposalTypes.STORE_PROPOSAL_ROOMS:
      return {
        ...state,
        rooms: action.payload.data,
      };
    case ProposalTypes.STORE_COMMERCIAL_CONDITIONS_EQUIPMENTS:
      return {
        ...state,
        equipments: action.payload.data,
      };
    case ProposalTypes.REQUEST_SUCCESS:
    case ProposalTypes.REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ProposalTypes.REQUEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
