import { combineReducers } from 'redux';

import auth from './Auth';
import room from './Room';
import project from './Project';
import equipment from './Equipment';
import proposal from './Proposal';

export default combineReducers({
  auth,
  room,
  project,
  equipment,
  proposal,
});
