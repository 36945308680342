import styled from 'styled-components';

export const Container = styled.div`
  margin: 50px 0;

  p.project__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  form {
    width: 100%;
  }
`;
