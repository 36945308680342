import React from 'react';
import { TableStripedContainer } from '~/components/TableStriped/TableStriped_Styles';

type TableStripedProps = {
  children: any;
};

export default function TableStriped({ children }: TableStripedProps) {
  return <TableStripedContainer>{children}</TableStripedContainer>;
}
