import showdown from 'showdown';

const markdownConverter = (markdown: any) => {
  const converter = new showdown.Converter();
  converter.setFlavor('github');
  converter.setOption('table', true);
  converter.setOption('simpleLineBreaks', true);

  // markdown to HTML
  return converter.makeHtml(markdown);
};

export default markdownConverter;
