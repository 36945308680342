/* eslint-disable no-param-reassign */
import { Edge, EdgeTypes, HeatTransferState, HeatTransferTemperatures } from '~/store/ducks/HeatTransfer/types';

import { store } from '../../store';

const calcTemperatureDifference = () => {
  const { ambientTemperature, roomTemperature } = store.getState().room.data;
  const dt = ambientTemperature - roomTemperature;
  return Math.ceil(dt / 5) * 5;
};

const calcTemperature = (edge: string) => {
  const { ambientTemperature } = store.getState().room.data;

  switch (edge) {
    case EdgeTypes.CEILING:
      return ambientTemperature + HeatTransferTemperatures.TEMPERATURE_AVERAGE_CEILING;
    case EdgeTypes.FLOOR:
      return ambientTemperature - HeatTransferTemperatures.TEMPERATURE_AVERAGE_FLOOR;
    default:
      return ambientTemperature;
  }
};

const calcMaterial = () => {
  const { roomTemperature } = store.getState().room.data;
  const dt = roomTemperature >= HeatTransferTemperatures.TEMPERATURE_ROOM_FLOOR;
  return dt ? 14 : 10; // CONCRETO : PUR
};

const getThicknessSuggestion = (dt: number, material: number) => {
  const table: any = {
    // PUR
    '10': {
      15: 50,
      20: 50,
      25: 80,
      30: 80,
      35: 100,
      40: 100,
      45: 120,
      50: 150,
      55: 150,
      60: 150,
      65: 175,
      70: 175,
      75: 200,
      80: 200,
      85: 250,
    },
    // EPS
    '12': {
      15: 50,
      20: 80,
      25: 100,
      30: 100,
      35: 125,
      40: 165,
      45: 165,
      50: 165,
      55: 200,
      60: 200,
      65: 250,
      70: 250,
      75: 250,
      80: 250,
      85: 250,
    },
  };

  if (!table[material]) return 0;
  return table[material][dt] || 0;
};

export const getThicknessByMaterial = (materialId: number) => {
  const dt = calcTemperatureDifference();
  return getThicknessSuggestion(dt, materialId);
};

class HeatTransferService {
  static insertDimensions = (heatTransferData: HeatTransferState) => {
    const { ceiling, floor, walls } = heatTransferData;
    const { length, width, height } = store.getState().room.data;

    ceiling.width = width;
    ceiling.length = length;

    floor.width = width;
    floor.length = length;

    walls.map((wall: Edge, index: number) => {
      const wallNumber = index + 1;

      if (wallNumber % 2 === 0) {
        wall.width = height;
        wall.length = length;
      } else {
        wall.width = height;
        wall.length = width;
      }

      return wall;
    });

    return heatTransferData;
  };

  static getSuggestion = (heatTransferData: HeatTransferState) => {
    const { ceiling, floor, walls } = heatTransferData;

    const dt = calcTemperatureDifference();
    const materialSelected = calcMaterial();

    ceiling.thickness = getThicknessSuggestion(dt, ceiling.material.id);
    ceiling.temperature = calcTemperature(EdgeTypes.CEILING);

    floor.thickness = getThicknessSuggestion(dt, materialSelected);
    floor.material.id = materialSelected;
    floor.temperature = calcTemperature(EdgeTypes.FLOOR);

    walls.map((wall: Edge, index: number) => {
      wall.thickness = getThicknessSuggestion(dt, wall.material.id);
      wall.temperature = calcTemperature(EdgeTypes.WALL);
      return wall;
    });

    return heatTransferData;
  };
}

export default HeatTransferService;
