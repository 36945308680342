/* eslint-disable no-restricted-syntax */
import api from '~/services/api';
import {
  ProposalCommercialConditionsEquipment,
  ProposalEquipment,
  ProposalRoom,
  ProposalTemplateDetail,
} from '~/store/ducks/Proposal/types';
import materialJson from '~/assets/mocks/material.json';
import { Room } from '~/store/ducks/Room/types';
import { parseValue2Decimal, removeDuplicatedObjectFromArray, mountVoltageString } from '~/helpers/functions';
import { EquipmentSystem } from '~/store/ducks/Equipment/types';

const parseInternalDimension = (room: Room): string =>
  `${parseValue2Decimal(room.width)}m X ${parseValue2Decimal(room.length)}m X ${parseValue2Decimal(room.height)} (h)`;

const parseThicknessCeilingWall = (room: Room): string => {
  const materialId = room.heatTransfer.ceiling.material.id;
  const material = materialJson.find((mat) => mat.id === materialId);
  const { thickness } = room.heatTransfer.ceiling;

  return `${material.name} ${thickness}mm`;
};

const parseThicknessCeilingFloor = (room: Room): string => {
  const materialId = room.heatTransfer.floor.material.id;
  const material = materialJson.find((mat) => mat.id === materialId);
  const { thickness } = room.heatTransfer.floor;

  return `${material.name} ${thickness}mm`;
};

const parseDoorsArea = (room: Room): string => {
  const { doors } = room.doors;
  let totalArea = 0;

  if (doors) {
    totalArea = doors.reduce((acc, item) => acc + item.count * item.height * item.width, 0);
  }
  return `${parseValue2Decimal(totalArea)} m²`;
};

const parseDoorsOpenDuration = (room: Room): string => {
  const { doors } = room.doors;
  let totalTime = 0;

  if (doors) {
    totalTime = doors.reduce((acc, item) => acc + item.count * item.openDuration, 0);
  }
  return `${parseValue2Decimal(totalTime)}h/dia`;
};

const parseStaffHeat = (room: Room): string => {
  const { staff } = room.additionalLoad;
  return `${parseValue2Decimal(staff.count)} pessoa(s) / ${staff.duration} por dia`;
};

const parseEquipmentHeat = (room: Room): string => {
  const { forklift } = room.additionalLoad;
  if (forklift.count === 0) return '-';
  return `${parseValue2Decimal(forklift.count)} empilhadeira(s) / ${forklift.duration} por dia`;
};

const parseStorageCapacity = (room: Room): string => {
  const { goods } = room;
  if (!goods) return '-';

  const totalCapacity = goods.reduce((acc, item) => acc + parseValue2Decimal(item.storageCapacity), 0);
  return `${totalCapacity} kg`;
};

const parseGoodsType = (goodz: any, room: Room): string => {
  const goodsArray = [];
  const { goods } = room;
  if (goods.length === 0) return '-';

  for (const goodsItem of goods) {
    const item = goodz.find((g: any) => g.id === goodsItem.goods.id);
    goodsArray.push(item);
  }

  const goodsNames = goodsArray.reduce((acc, item, index, arr) => {
    if (index === arr.length - 1) {
      return acc.concat(`${item.name} `);
    }
    return acc.concat(`${item.name}, `);
  }, '');
  return `${goodsNames}`;
};

const parseCheckinTemperature = (room: Room): string => {
  const { goods } = room;
  if (goods.length === 0) return '-';

  const checkingConcat = goods.reduce((acc, item, index, arr) => {
    if (index === arr.length - 1) {
      return acc.concat(`${parseValue2Decimal(item.checkinTemperature)}°C `);
    }
    return acc.concat(`${parseValue2Decimal(item.checkinTemperature)}°C, `);
  }, '');
  return `${checkingConcat}`;
};

const parseDailyMovement = (room: Room): string => {
  const { goods } = room;
  if (!goods) return '-';

  const totalDaiylMovement = goods.reduce((acc, item) => acc + item.dailyMovement, 0);
  return `${parseValue2Decimal(totalDaiylMovement)} kg/dia`;
};

const parseProcessingTime = (room: Room): string => {
  const { goods } = room;
  if (!goods) return '-';

  const maxProcessingTimeItem = goods.sort((a, b) => b.processingTime - a.processingTime)[0];
  const maxProcessingTime = maxProcessingTimeItem?.processingTime;
  return maxProcessingTime ? `${maxProcessingTime}h / dia` : '-';
};

class ProposalService {
  // Proposal
  static getProposal = (projectId: number) => api.get(`/v1/projects/${projectId}/proposals/current`);

  static createProposal = (projectId: number) => api.post(`/v1/projects/${projectId}/proposals`);

  static downloadProposal = (projectId: number, proposalId: number) =>
    api.get(`/v1/projects/${projectId}/proposals/${proposalId}/download`, { responseType: 'blob' });

  static senddProposal = (projectId: number, proposalId: number) =>
    api.post(`/v1/projects/${projectId}/proposals/${proposalId}/send`);

  // Presentation
  static getPresentationTemplate = () => api.get(`/v1/proposals/templates/current/presentation`);

  static getPresentation = (projectId: number, proposalId: number) =>
    api.get(`/v1/projects/${projectId}/proposals/${proposalId}/presentation`);

  static savePresentation = (projectId: number, proposalId: number, data) =>
    api.put(`/v1/projects/${projectId}/proposals/${proposalId}/presentation`, data);

  // Equipment Feature (Characteristics)
  static getCharacteristicsTemplate = () => api.get(`/v1/proposals/templates/current/equipment-selection`);

  static getEquipmentFeature = (projectId: number, proposalId: number) =>
    api.get(`/v1/projects/${projectId}/proposals/${proposalId}/equipment-characteristics`);

  static saveEquipmentFeature = (projectId: number, proposalId: number, data) =>
    api.put(`/v1/projects/${projectId}/proposals/${proposalId}/equipment-characteristics`, data);

  // Commercial Conditions
  static getCommercialConditionsOptions = () => api.get(`/v1/proposals/templates/current/commercial-conditions`);

  static getCommercialConditions = (projectId: number, proposalId: number) =>
    api.get(`/v1/projects/${projectId}/proposals/${proposalId}/commercial-conditions`);

  static saveCommercialConditions = (projectId: number, proposalId: number, data) =>
    api.put(`/v1/projects/${projectId}/proposals/${proposalId}/commercial-conditions`, data);

  // Terms and Conditions
  static getTermsConditionsTemplate = () => api.get(`/v1/proposals/templates/current/general-conditions`);

  static getTermsConditions = (projectId: number, proposalId: number) =>
    api.get(`/v1/projects/${projectId}/proposals/${proposalId}/general-conditions`);

  // Responsible (Owners)
  static getProposalOwners = (projectId: number, proposalId: number) =>
    api.get(`/v1/projects/${projectId}/proposals/${proposalId}/responsible`);

  static saveProposalOwners = (projectId: number, proposalId: number, data) =>
    api.put(`/v1/projects/${projectId}/proposals/${proposalId}/responsible`, data);

  static parseRoomsProposalFormat = (fullRooms): Array<ProposalRoom> => {
    const proposalRoomArray = fullRooms.reduce((acc: ProposalRoom[], fullRoom: any) => {
      const proposalRoom: ProposalRoom = {
        id: fullRoom.data.id,
        name: fullRoom.data.name,
        calculationType: fullRoom.data.calculationType,
        internalDimension: parseInternalDimension(fullRoom.data),
        thicknessCeilingWall: parseThicknessCeilingWall(fullRoom.data),
        thicknessFloor: parseThicknessCeilingFloor(fullRoom.data),
        doorsArea: parseDoorsArea(fullRoom.data),
        doorsOpenDuration: parseDoorsOpenDuration(fullRoom.data),
        staffHeat: parseStaffHeat(fullRoom.data),
        equipmentHeat: parseEquipmentHeat(fullRoom.data),
        roomTemperature: `${parseValue2Decimal(fullRoom.data.roomTemperature)} °C`,
        ambientTemperature: `${parseValue2Decimal(fullRoom.data.ambientTemperature)} °C`,
        storageCapacity: parseStorageCapacity(fullRoom.data),
        goodsType: parseGoodsType(fullRoom.goods, fullRoom.data),
        dailyMovement: parseDailyMovement(fullRoom.data),
        checkinTemperature: parseCheckinTemperature(fullRoom.data),
        processingTime: parseProcessingTime(fullRoom.data),
        safetyMargin: `${fullRoom.data?.safetyMargin ? fullRoom.data?.safetyMargin : 0}%`,
        heatLoad: `${parseValue2Decimal(fullRoom.data.heatLoad.totalKcalh)} kcal/h`,
        equipment: null,
      };

      acc.push(proposalRoom);
      return acc;
    }, []);

    return proposalRoomArray;
  };

  static parseEquipmentProposalFormat = (proposalRooms, systems): Array<ProposalRoom> => {
    const proposalRoomArray = systems.reduce((acc: EquipmentSystem[], system: EquipmentSystem) => {
      for (const configuration of system.configurations) {
        const room = proposalRooms.find((r: ProposalRoom) => r.id === configuration.room.id);
        const proposalEquipment: ProposalEquipment = {
          room: configuration.room.id,
          evaporator: `${configuration.equipment?.count}x ${configuration.equipment?.model}`,
          evaporatorAirFlow: `${configuration.equipment?.count}x ${parseValue2Decimal(
            configuration.equipment?.unitAirFlow
          )} m³/h`,
          evaporatorAirExchangeRate: `${configuration.equipment?.count}x ${parseValue2Decimal(
            configuration.equipment?.airExchangeRate,
            0
          )} trocas/h`,
          refrigerantFluid: system.refrigerantFluid?.code,
          compressingUnit: system.condensingUnit
            ? `${system.condensingUnit?.equipment.count}x ${system.condensingUnit?.equipment.model}`
            : '-',
          // condenser: '-',
          // totalCapacity: configuration.equipment?.unitCapacity
          //   ? `${parseValue2Decimal(configuration.equipment?.count * configuration.equipment?.unitCapacityDt)} Kcal/h`
          //   : '-',
          totalCapacity: system?.resultingCapacity ? `${parseValue2Decimal(system?.resultingCapacity)} Kcal/h` : '-',
          compressingUnitEletricVoltage: system.condensingUnit?.equipment.consumedPower
            ? // ? `${parseValue2Decimal(system.condensingUnit?.equipment.voltage50hz)}v/${parseValue2Decimal(
              //     system.condensingUnit?.equipment.powerSupplyPhases,
              //     0
              //   )}F (50hz)/ ${parseValue2Decimal(system.condensingUnit?.equipment.voltage60hz)}v/${parseValue2Decimal(
              //     system.condensingUnit?.equipment.powerSupplyPhases,
              //     0
              //   )}F (60hz)`
              // : '-',
              `${mountVoltageString(
                system.condensingUnit?.equipment.voltage50hz,
                system.condensingUnit?.equipment.voltage60hz,
                system.condensingUnit?.equipment.powerSupplyPhases
              )}`
            : '-',
          evaporatorEletricVoltage: configuration.equipment?.consumedPower
            ? // ? `${parseValue2Decimal(configuration.equipment?.voltage50hz)}v/${parseValue2Decimal(
              //     configuration.equipment.powerSupplyPhases,
              //     0
              //   )}F (50hz) / ${parseValue2Decimal(configuration.equipment?.voltage60hz)}v/${parseValue2Decimal(
              //     configuration.equipment.powerSupplyPhases,
              //     0
              //   )}F (60hz)`
              `${mountVoltageString(
                configuration.equipment?.voltage50hz,
                configuration.equipment?.voltage60hz,
                configuration.equipment?.powerSupplyPhases
              )}`
            : '-',
        };

        const proposalRoomAndEquipment = {
          ...room,
          equipment: proposalEquipment,
        };
        acc.push(proposalRoomAndEquipment);
      }

      return acc;
    }, []);

    return proposalRoomArray;
  };

  static parseCommercialConditionsOptions = (data) => ({
    pricingComposition: data.find((template: ProposalTemplateDetail) => template.key.includes('pricing-composition')),
    paymentConditions: data.filter((template: ProposalTemplateDetail) => template.key.includes('payment-conditions')),
    warranty: data.filter((template: ProposalTemplateDetail) => template.key.includes('warranty')),
    installation: data.filter((template: ProposalTemplateDetail) => template.key.includes('installation')),
    deliveryTime: data.filter((template: ProposalTemplateDetail) => template.key.includes('delivery-time')),
    transportation: data.filter((template: ProposalTemplateDetail) => template.key.includes('transportation')),
    creditRegistration: data.filter((template: ProposalTemplateDetail) => template.key.includes('credit-registration')),
    proposalValidity: data.filter((template: ProposalTemplateDetail) => template.key.includes('proposal-validity')),
  });

  static parseEquipmentCommercialConditionsFormat = (systems): ProposalCommercialConditionsEquipment[] => {
    const equipmentCommercialConditionsArray = systems.reduce((acc: any[], system: EquipmentSystem) => {
      for (const configuration of system.configurations) {
        const evaporator = {
          id: configuration?.equipment?.sku?.id,
          sku: configuration?.equipment?.sku?.sku,
        };

        acc.push(evaporator);
      }

      const condensingUnit = {
        id: system.condensingUnit?.equipment?.sku?.id,
        sku: system.condensingUnit?.equipment?.sku?.sku,
      };

      acc.push(condensingUnit);

      return acc;
    }, []);

    const filteredEquipments = removeDuplicatedObjectFromArray(equipmentCommercialConditionsArray, 'sku');
    return filteredEquipments;
  };
}

export default ProposalService;
