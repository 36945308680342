/**
 * Action types
 */
export enum HeatTransferTypes {
  STORE_DATA = '@heatTransfer/STORE_DATA',
}

/**
 * Data types
 */

export enum HeatTransferMaterial {
  PUR = 'PUR',
  PIR = 'PIR',
  EPS = 'EPS',
  ALVERNARIA = 'Alvenaria',
  CONCRETO = 'Concreto',
  VIDRO = 'Vidro',
}

export interface Edge {
  id?: number;
  room?: { id: number };
  temperature: number;
  incidentSolarRadiation: boolean;
  thickness: number;
  material: {
    id: number;
    name?: string;
  };
  width?: number;
  length?: number;
}

export interface HeatTransfer {
  ceiling: Edge;
  floor: Edge;
  walls: Edge[];
}

export enum HeatTransferTemperatures {
  TEMPERATURE_AVERAGE_CEILING = 6,
  TEMPERATURE_AVERAGE_FLOOR = 16,
  TEMPERATURE_ROOM_FLOOR = 5,
}

export enum EdgeTypes {
  CEILING = 'CEILING',
  FLOOR = 'FLOOR',
  WALL = 'WALL',
}

/**
 * State type
 */
export interface HeatTransferState extends HeatTransfer {}
