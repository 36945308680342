import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSaga';
import { ProjectState } from '~/store/ducks/Project/types';
import { RoomState } from './ducks/Room/types';
import { EquipmentState } from '~/store/ducks/Equipment/types';
import { ProposalState } from '~/store/ducks/Proposal/types';
import { AuthState } from '~/store/ducks/Auth/types';

export interface ApplicationState {
  auth: AuthState;
  room: RoomState;
  project: ProjectState;
  equipment: EquipmentState;
  proposal: ProposalState;
}

const persistConfig = {
  key: 'coldstorage',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware);

const store: Store<ApplicationState> = createStore(persistedReducer, composeEnhancers);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
