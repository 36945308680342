import api from '~/services/api';

class EquipmentService {
  static getModels = (data) => api.post(`/v1/catalog`, data);

  static getSKUs = (data) => api.post(`/v1/catalog/skus`, data);

  // static getOptionals = (type: string) => api.get(`/v1/catalog/optionals/${type}`);
  static getOptionals = (type: string, line: string): any => {
    let ret = null;

    if (type === 'evaporators')
      ret =
        EquipmentService.optionalsEvaporators[EquipmentService.optionalsEvaporators.findIndex((x) => x.linha === line)];
    if (type === 'condensingUnits')
      ret =
        EquipmentService.optionalsUnitCondensing[
          EquipmentService.optionalsUnitCondensing.findIndex((x) => x.linha === line)
        ];

    return { data: ret };
  };

  static getCompressor = (data) => api.get(`https://run.mocky.io/v3/4d92c4aa-e8f7-4dff-b8cf-4d7601aaaa37`, data);

  static getCondenser = (data) => api.get(`https://run.mocky.io/v3/4d92c4aa-e8f7-4dff-b8cf-4d7601aaaa37`, data);

  static getAllSystems = (projectId) => api.get(`/v1/projects/${projectId}/operating-systems`);

  static getSystemById = (projectId, systemId) => api.get(`/v1/projects/${projectId}/operating-systems/${systemId}`);

  static createSystem = (projectId, data) => api.post(`/v1/projects/${projectId}/operating-systems`, data);

  static updateSystem = (projectId, systemId, data) =>
    api.put(`/v1/projects/${projectId}/operating-systems/${systemId}`, data);

  static getRefrigerantFluids = () => api.get(`/v1/refrigerants`);

  static calculateResult = (data) => api.post(`/v1/catalog/calculation`, data);

  static parseConfiguration = (data): any => {
    const { active, type, refrigerantFluid, compressor, condenser, ...rest } = data;
    return rest;
  };

  static getComparison = (data: any[]) => api.post(`/v1/catalog/comparison`, data);

  static optionalsEvaporators = [
    {
      linha: 'Todos',
      productLine: ['HP', 'MP', 'EP', 'EDH', 'FL', 'FM', 'FXB+', 'BH Plus', 'EU', 'FBA', 'VCM', 'ET'],
      delta_temperature_difference: [
        [[10, 11], [10]],
        [[6, 7], [6]],
        [[8, 9], [8]],
        [[8, 9, 10], [10]],
      ],
      defrost: ['Ar', 'Elétrico', 'Gás'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Com Pintura', 'Sem Pintura'],
      chamberFins: [],
      gicle: [
        'N/A',
        '10',
        '15',
        '12',
        '17',
        '20',
        '5',
        '4',
        '25',
        '6',
        '8',
        '3',
        '40',
        '30',
        '35',
        '50',
        'Sem',
        '2',
        '1',
        '2,5',
      ],
      valveKit: [
        'N/A',
        'TEX12',
        'TES12',
        'E3V45',
        'E3V55',
        'E3V65',
        'TES5',
        'TES20',
        'TEX5',
        'TES55',
        'TEX20',
        'E2V35',
        'E2V30',
        'TES2',
        'Sem',
        'E2V18',
        'TEX2',
        'E2V24',
        'E2V14',
        'TEN2',
        'TEN5',
      ],
      marketStandard: [],
      engine: [],
      numberOfFans: [2, 3, 4, 1, 5, 6, 7, 8, 9],
      optionals: [],
      orifice: ['N/A', '5', '6', '2', '8', '4', '7', '3', '9', '10', '13', '12', 'Sem', '1'],
      finsProtection: ['Sem Proteção', 'Com Proteção'],
      voltage: [220, 440, 0, 380, 127],
      voltageAndDefrost: [],
      valveType: ['Termostática', 'Sem', 'EVD ice', 'Eletrônica'],
      version: [],
      finsPerInch: ['6', '4', '2.5', '5'],
      isolatedTray: ['Sem', 'Com'],
      engineType: ['AC', 'AC ELGIN', 'EC', 'Polo Sombreado', 'Eletrônico 1 Velocidade', 'Eletrônico 2 Velocidades'],
      expansionValve: ['Termostática', 'Sem', 'EVD ice', 'Eletrônica'],
      logo: ['Elgin'],
      powerSupply60Hz: [220, 440, 380, 127],
      powerSupply50Hz: [220, 0, 380],
      powerSupplyPhases: [3, 1],
      fanDiameter: [800, 450, 500, 300, 400, 254, 630, 910, 203],
    },
    {
      linha: 'HP',
      productLine: ['HP'],
      delta_temperature_difference: [
        [[10, 11], [10]],
        [[6, 7], [6]],
        [[8, 9], [8]],
      ],
      defrost: ['Ar', 'Elétrico', 'Gás'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Com Pintura'],
      chamberFins: [],
      gicle: ['N/A', '10', '15', '12', '17', '20', '5', '25', '6', '8', '4', '3', '40', '30', '35', '50'],
      valveKit: [
        'N/A',
        'TEX12',
        'TES12',
        'E3V45',
        'E3V55',
        'E3V65',
        'TES20',
        'TEX5',
        'TES5',
        'TES55',
        'TEX20',
        'E2V35',
        'E2V30',
      ],
      marketStandard: [],
      engine: [],
      numberOfFans: [2, 3, 4, 1],
      optionals: [],
      orifice: ['N/A', '5', '6', '8', '4', '7', '2', '3', '9', '10', '13', '12'],
      finsProtection: ['Sem Proteção', 'Com Proteção'],
      voltage: [220, 440, 0, 380],
      voltageAndDefrost: [],
      valveType: ['Termostática', 'Sem', 'EVD ice', 'Eletrônica'],
      version: [],
      finsPerInch: ['6', '4'],
      isolatedTray: ['Sem'],
      engineType: ['AC'],
      expansionValve: ['Termostática', 'Sem', 'EVD ice', 'Eletrônica'],
      logo: ['Elgin'],
      powerSupply60Hz: [220, 440, 380],
      powerSupply50Hz: [220, 0, 380],
      powerSupplyPhases: [3],
      fanDiameter: [800],
    },
    {
      linha: 'MP',
      productLine: ['MP'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[10, 11], [10]],
        [[8, 9], [8]],
      ],
      defrost: ['Ar', 'Elétrico', 'Gás'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Com Pintura'],
      chamberFins: [],
      gicle: ['4', 'N/A', '17', '20', '10', '2', '1', '3', '6', '15', '5', '12', '8'],
      valveKit: [
        'TES5',
        'N/A',
        'E3V45',
        'TES2',
        'E2V18',
        'TEX2',
        'E2V24',
        'E2V30',
        'TEX5',
        'E2V35',
        'E2V14',
        'TEX12',
        'TES12',
        'TES20',
      ],
      marketStandard: [],
      engine: [],
      numberOfFans: [3, 5, 2, 1, 4],
      optionals: [],
      orifice: ['2', 'N/A', '3', '4', '6', '5', '1', '8'],
      finsProtection: ['Sem Proteção', 'Com Proteção'],
      voltage: [220, 380, 440, 0],
      voltageAndDefrost: [],
      valveType: ['Termostática', 'EVD ice', 'Sem', 'Eletrônica'],
      version: [],
      finsPerInch: ['6', '4'],
      isolatedTray: ['Sem'],
      engineType: ['AC ELGIN', 'AC', 'EC'],
      expansionValve: ['Termostática', 'EVD ice', 'Sem', 'Eletrônica'],
      logo: ['Elgin'],
      powerSupply60Hz: [220, 380, 440],
      powerSupply50Hz: [220, 380, 0],
      powerSupplyPhases: [1, 3],
      fanDiameter: [450],
    },
    {
      linha: 'EP',
      productLine: ['EP'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9], [8]],
      ],
      defrost: ['Elétrico', 'Ar'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Sem Pintura'],
      chamberFins: [],
      gicle: ['3', 'N/A', '4', '5', '6', '8', '17', '15', '40', '10', '12', '25', '50', '20', '35'],
      valveKit: ['N/A', 'TES5', 'TES12', 'TES20', 'TES55'],
      marketStandard: [],
      engine: [],
      numberOfFans: [1, 2, 3],
      optionals: [],
      orifice: ['N/A', '2', '3', '4', '5', '6', '8', '7', '12'],
      finsProtection: ['Sem Proteção'],
      voltage: [220, 440, 0, 380],
      voltageAndDefrost: [],
      valveType: ['Sem', 'Termostática'],
      version: [],
      finsPerInch: ['4', '2.5'],
      isolatedTray: ['Sem', 'Com'],
      engineType: ['AC', 'EC'],
      expansionValve: ['Sem', 'Termostática'],
      logo: ['Elgin'],
      powerSupply60Hz: [220, 440, 380],
      powerSupply50Hz: [220, 0, 380],
      powerSupplyPhases: [3],
      fanDiameter: [500],
    },
    {
      linha: 'EDH',
      productLine: ['EDH'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9], [8]],
        [[10, 11], [10]],
      ],
      defrost: ['Ar', 'Elétrico'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Com Pintura', 'Sem Pintura'],
      chamberFins: [],
      gicle: ['Sem'],
      valveKit: ['N/A', 'TES2'],
      marketStandard: [],
      engine: [],
      numberOfFans: [1, 2, 3, 4, 5],
      optionals: [],
      orifice: ['N/A', '2'],
      finsProtection: ['Com Proteção', 'Sem Proteção'],
      voltage: [220],
      voltageAndDefrost: [],
      valveType: ['Sem', 'Termostática'],
      version: [],
      finsPerInch: ['6', '4'],
      isolatedTray: ['Sem'],
      engineType: ['Polo Sombreado', 'Eletrônico 1 Velocidade', 'Eletrônico 2 Velocidades'],
      expansionValve: ['Sem', 'Termostática'],
      logo: ['Elgin'],
      powerSupply60Hz: [220],
      powerSupply50Hz: [220],
      powerSupplyPhases: [1],
      fanDiameter: [300],
    },
    {
      linha: 'FL',
      productLine: ['FL'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9], [8]],
        [[10, 11], [10]],
      ],
      defrost: ['Ar', 'Elétrico'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Sem Pintura'],
      chamberFins: [],
      gicle: ['Sem'],
      valveKit: ['Sem'],
      marketStandard: [],
      engine: [],
      numberOfFans: [1, 2, 3, 4, 5, 6, 7, 8],
      optionals: [],
      orifice: ['Sem'],
      finsProtection: ['Sem Proteção'],
      voltage: [220],
      voltageAndDefrost: [],
      valveType: ['Sem'],
      version: [],
      finsPerInch: ['5'],
      isolatedTray: ['Sem'],
      engineType: ['Polo Sombreado'],
      expansionValve: ['Sem'],
      logo: ['Elgin'],
      powerSupply60Hz: [220],
      powerSupply50Hz: [220],
      powerSupplyPhases: [1],
      fanDiameter: [300],
    },
    {
      linha: 'FM',
      productLine: ['FM'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9], [8]],
        [[10, 11], [10]],
      ],
      defrost: ['Ar', 'Elétrico'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Sem Pintura'],
      chamberFins: [],
      gicle: ['Sem'],
      valveKit: ['Sem'],
      marketStandard: [],
      engine: [],
      numberOfFans: [1, 2, 3, 4, 5],
      optionals: [],
      orifice: ['Sem'],
      finsProtection: ['Sem Proteção'],
      voltage: [220, 380, 440, 0],
      voltageAndDefrost: [],
      valveType: ['Sem'],
      version: [],
      finsPerInch: ['4', '6'],
      isolatedTray: ['Sem'],
      engineType: ['AC'],
      expansionValve: ['Sem'],
      logo: ['Elgin'],
      powerSupply60Hz: [220, 380, 440],
      powerSupply50Hz: [220, 380, 0],
      powerSupplyPhases: [1, 3],
      fanDiameter: [400],
    },
    {
      linha: 'FXB+',
      productLine: ['FXB+'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9], [8]],
        [[10, 11], [10]],
      ],
      defrost: ['Elétrico', 'Ar'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Sem Pintura'],
      chamberFins: [],
      gicle: ['Sem'],
      valveKit: ['Sem'],
      marketStandard: [],
      engine: [],
      numberOfFans: [1, 2, 3, 4, 5, 6],
      optionals: [],
      orifice: ['Sem'],
      finsProtection: ['Sem Proteção'],
      voltage: [220],
      voltageAndDefrost: [],
      valveType: ['Sem'],
      version: [],
      finsPerInch: ['4'],
      isolatedTray: ['Sem'],
      engineType: ['Polo Sombreado'],
      expansionValve: ['Sem'],
      logo: ['Elgin'],
      powerSupply60Hz: [220],
      powerSupply50Hz: [220],
      powerSupplyPhases: [1],
      fanDiameter: [254],
    },
    {
      linha: 'BH Plus',
      productLine: ['BH Plus'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9], [8]],
        [[10, 11], [10]],
      ],
      defrost: ['Elétrico', 'Gás'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Com Pintura'],
      chamberFins: [],
      gicle: ['N/A', '5', '10', '15', '20', '8', '12', '17', '25', '6'],
      valveKit: [
        'N/A',
        'E2V24',
        'E2V30',
        'E2V35',
        'E3V55',
        'E3V45',
        'TES12',
        'TES20',
        'TEX12',
        'TES5',
        'TEX5',
        'E3V65',
      ],
      marketStandard: [],
      engine: [],
      numberOfFans: [1, 2, 3, 4],
      optionals: [],
      orifice: ['N/A', '5', '8', '2', '6', '9'],
      finsProtection: ['Sem Proteção'],
      voltage: [220, 440, 0, 380],
      voltageAndDefrost: [],
      valveType: ['EVD ice', 'Termostática'],
      version: [],
      finsPerInch: ['2.5'],
      isolatedTray: ['Sem'],
      engineType: ['AC', 'EC'],
      expansionValve: ['EVD ice', 'Termostática'],
      logo: ['Elgin'],
      powerSupply60Hz: [220, 440, 380],
      powerSupply50Hz: [220, 0, 380],
      powerSupplyPhases: [3],
      fanDiameter: [630, 800],
    },
    {
      linha: 'EU',
      productLine: ['EU'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9], [8]],
        [[10, 11], [10]],
      ],
      defrost: ['Gás', 'Elétrico'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Com Pintura'],
      chamberFins: [],
      gicle: ['N/A', '8', '12', '15', '5', '6', '10', '17', '20', '25', '30'],
      valveKit: ['N/A', 'TES12', 'TEX12', 'TES5', 'TEX5', 'E2V30', 'E2V35', 'E3V45', 'E3V55', 'E2V24', 'E3V65'],
      marketStandard: [],
      engine: [],
      numberOfFans: [2, 3, 4, 5],
      optionals: [],
      orifice: ['N/A', '5', '6', '7', '4'],
      finsProtection: ['Com Proteção'],
      voltage: [220, 440, 0, 380],
      voltageAndDefrost: [],
      valveType: ['Termostática', 'EVD ice'],
      version: [],
      finsPerInch: ['4'],
      isolatedTray: ['Sem'],
      engineType: ['EC'],
      expansionValve: ['Termostática', 'EVD ice'],
      logo: ['Elgin'],
      powerSupply60Hz: [220, 440, 380],
      powerSupply50Hz: [220, 0, 380],
      powerSupplyPhases: [3],
      fanDiameter: [910],
    },
    {
      linha: 'FBA',
      productLine: ['FBA'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9, 10], [10]],
      ],
      defrost: ['Ar', 'Elétrico'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Com Pintura'],
      chamberFins: [],
      gicle: ['Sem', '1', 'N/A', '2', '2,5', '5', '6'],
      valveKit: ['Sem', 'TEN2', 'N/A', 'TES2', 'TES5', 'TEN5'],
      marketStandard: [],
      engine: [],
      numberOfFans: [1, 2, 3, 4, 5, 6],
      optionals: [],
      orifice: ['Sem', '1', 'N/A', '2', '3', '4', '5', '6'],
      finsProtection: ['Com Proteção'],
      voltage: [220],
      voltageAndDefrost: [],
      valveType: ['Sem', 'Termostática'],
      version: [],
      finsPerInch: ['4', '6'],
      isolatedTray: ['Sem'],
      engineType: ['Eletrônico 1 Velocidade'],
      expansionValve: ['Sem', 'Termostática'],
      logo: ['Elgin'],
      powerSupply60Hz: [220],
      powerSupply50Hz: [220],
      powerSupplyPhases: [1],
      fanDiameter: [254],
    },
    {
      linha: 'VCM',
      productLine: ['VCM'],
      delta_temperature_difference: [[[6, 7], [6]]],
      defrost: ['Ar', 'Elétrico'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Sem Pintura'],
      chamberFins: [],
      gicle: ['Sem'],
      valveKit: ['Sem'],
      marketStandard: [],
      engine: [],
      numberOfFans: [1, 2, 3, 4, 5],
      optionals: [],
      orifice: ['Sem'],
      finsProtection: ['Sem Proteção'],
      voltage: [127, 0, 220],
      voltageAndDefrost: [],
      valveType: ['Sem'],
      version: [],
      finsPerInch: ['5'],
      isolatedTray: ['Sem'],
      engineType: ['AC'],
      expansionValve: ['Sem'],
      logo: ['Elgin'],
      powerSupply60Hz: [127, 220],
      powerSupply50Hz: [0, 220],
      powerSupplyPhases: [1],
      fanDiameter: [203],
    },
    {
      linha: 'ET',
      productLine: ['ET'],
      delta_temperature_difference: [
        [[6, 7], [6]],
        [[8, 9], [8]],
        [[10, 11], [10]],
      ],
      defrost: ['Elétrico', 'Gás'],
      helixDiameter: [],
      packaging: [],
      fpi: [],
      chamber: ['Com Pintura'],
      chamberFins: [],
      gicle: ['N/A', '12', '6', '10', '15', '30', '17'],
      valveKit: ['N/A', 'E2V30'],
      marketStandard: [],
      engine: [],
      numberOfFans: [3, 6, 9],
      optionals: [],
      orifice: ['N/A'],
      finsProtection: ['Com Proteção'],
      voltage: [220, 380],
      voltageAndDefrost: [],
      valveType: ['Sem', 'EVD ice'],
      version: [],
      finsPerInch: ['4'],
      isolatedTray: ['Sem'],
      engineType: ['AC'],
      expansionValve: ['Sem', 'EVD ice'],
      logo: ['Elgin'],
      powerSupply60Hz: [220, 380],
      powerSupply50Hz: [220, 380],
      powerSupplyPhases: [3],
      fanDiameter: [500],
    },
  ];

  static optionalsUnitCondensing = [
    {
      linha: 'Todos',
      productLine: ['US', 'FLEX+', 'FLEX SLIM', 'FRM', 'US 23 66 HP', 'USMB', 'UCR', 'UCM', 'UCB', 'ES', 'TU'],
      refrigerantFluid: ['R404A', 'R134a', 'R22', 'R448A', 'R449A'],
      compressorType: ['Semi-Hermético', 'Scroll', 'Hermético'],
      compressor: ['Bitzer', 'Dorin', 'Copeland', 'Elgin'],
      logo: ['Elgin'],
      chamber: ['Sem', 'Com'],
      numberOfCompressors: ['1', '2', '3'],
      hotAirDefrost: ['Sem', 'Com'],
      capacityControl: ['Sem', '30% a 100%', 'Em Estágios', '35 a 100%', 'Inversor'],
      condensationControl: ['Sem', 'Com'],
      oilSeparator: ['Com', 'Sem'],
      suctionAccumulator: ['Sem', 'Com'],
      suctionFilter: ['Sem', 'Com'],
      contactor: ['Sem', 'Com', 'AC', 'EC'],
      liquidServiceValve: ['Com', 'Sem'],
      tankInletValve: ['Com', 'Sem'],
      liquidFilter: ['Com', 'Sem'],
      tank: ['Com', 'Sem'],
      viewfinder: ['Com', 'Sem'],
      insulationinTheSuctionLine: ['Sem', 'Com'],
      suctionServiceValve: ['Com', 'Sem'],
      overloadRelay: ['Sem', 'Com'],
      circuitBreaker: ['Sem', 'Com', 'com'],
      sequenceRelayAndPhaseOutage: ['Sem', 'Com'],
      controller: ['Sem', 'Com', '2'],
      elgin_smart: ['Sem', 'Com'],
    },
    {
      linha: 'US',
      productLine: ['US'],
      refrigerantFluid: ['R404A', 'R134a'],
      compressorType: ['Semi-Hermético', 'Scroll'],
      compressor: ['Bitzer', 'Dorin', 'Copeland', 'Elgin'],
      logo: ['Elgin'],
      chamber: ['Sem', 'Com'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem', 'Com'],
      capacityControl: ['Sem', '30% a 100%'],
      condensationControl: ['Sem', 'Com'],
      oilSeparator: ['Com', 'Sem'],
      suctionAccumulator: ['Sem', 'Com'],
      suctionFilter: ['Sem', 'Com'],
      contactor: ['Sem', 'Com'],
      liquidServiceValve: ['Com'],
      tankInletValve: ['Com'],
      liquidFilter: ['Com'],
      tank: ['Com'],
      viewfinder: ['Com'],
      insulationinTheSuctionLine: ['Sem', 'Com'],
      suctionServiceValve: ['Com'],
      overloadRelay: ['Sem', 'Com'],
      circuitBreaker: ['Sem', 'Com'],
      sequenceRelayAndPhaseOutage: ['Sem', 'Com'],
      controller: ['Sem', 'Com'],
      elgin_smart: ['Sem', 'Com'],
    },
    {
      linha: 'FLEX+',
      productLine: ['FLEX+'],
      refrigerantFluid: ['R22', 'R404A', 'R134a', 'R448A', 'R449A'],
      compressorType: ['Hermético', 'Scroll'],
      compressor: ['Copeland'],
      logo: ['Elgin'],
      chamber: ['Com'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem'],
      oilSeparator: ['Sem', 'Com'],
      suctionAccumulator: ['Sem', 'Com'],
      suctionFilter: ['Sem'],
      contactor: ['Sem', 'Com'],
      liquidServiceValve: ['Com'],
      tankInletValve: ['Com'],
      liquidFilter: ['Com'],
      tank: ['Com'],
      viewfinder: ['Com'],
      insulationinTheSuctionLine: ['Com'],
      suctionServiceValve: ['Com'],
      overloadRelay: ['Sem'],
      circuitBreaker: ['Sem', 'Com'],
      sequenceRelayAndPhaseOutage: ['Sem', 'Com'],
      controller: ['Sem'],
      elgin_smart: ['Sem'],
    },
    {
      linha: 'FLEX SLIM',
      productLine: ['FLEX SLIM'],
      refrigerantFluid: ['R22', 'R404A', 'R134a'],
      compressorType: ['Hermético'],
      compressor: ['Copeland', 'Elgin'],
      logo: ['Elgin'],
      chamber: ['Sem'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem'],
      oilSeparator: ['Sem'],
      suctionAccumulator: ['Sem'],
      suctionFilter: ['Sem'],
      contactor: ['Sem'],
      liquidServiceValve: ['Sem'],
      tankInletValve: ['Sem', 'Com'],
      liquidFilter: ['Com'],
      tank: ['Sem', 'Com'],
      viewfinder: ['Sem', 'Com'],
      insulationinTheSuctionLine: ['Sem'],
      suctionServiceValve: ['Sem'],
      overloadRelay: ['Sem'],
      circuitBreaker: ['Sem'],
      sequenceRelayAndPhaseOutage: ['Sem'],
      controller: ['Sem'],
      elgin_smart: ['Sem'],
    },
    {
      linha: 'FRM',
      productLine: ['FRM'],
      refrigerantFluid: ['R404A', 'R134a'],
      compressorType: ['Hermético', 'Scroll'],
      compressor: ['Copeland'],
      logo: ['Elgin'],
      chamber: ['Com'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem', 'Com'],
      oilSeparator: ['Com', 'Sem'],
      suctionAccumulator: ['Com', 'Sem'],
      suctionFilter: ['Com', 'Sem'],
      contactor: ['Com'],
      liquidServiceValve: ['Com'],
      tankInletValve: ['Com'],
      liquidFilter: ['Com'],
      tank: ['Com'],
      viewfinder: ['Com'],
      insulationinTheSuctionLine: ['Com'],
      suctionServiceValve: ['Com'],
      overloadRelay: ['Sem'],
      circuitBreaker: ['Com'],
      sequenceRelayAndPhaseOutage: ['Sem', 'Com'],
      controller: ['Sem'],
      elgin_smart: ['Sem'],
    },
    {
      linha: 'US 23 66 HP',
      productLine: ['US 23 66 HP'],
      refrigerantFluid: ['R404A', 'R134a'],
      compressorType: ['Scroll', 'Semi-Hermético'],
      compressor: ['Copeland', 'Bitzer', 'Dorin'],
      logo: ['Elgin'],
      chamber: ['Com'],
      numberOfCompressors: ['2', '3', '1'],
      hotAirDefrost: ['Sem', 'Com'],
      capacityControl: ['Em Estágios', '30% a 100%', '35 a 100%', 'Sem', 'Inversor'],
      condensationControl: ['Sem', 'Com'],
      oilSeparator: ['Com'],
      suctionAccumulator: ['Com'],
      suctionFilter: ['Com'],
      contactor: ['Com'],
      liquidServiceValve: ['Com'],
      tankInletValve: ['Com'],
      liquidFilter: ['Com'],
      tank: ['Com'],
      viewfinder: ['Com'],
      insulationinTheSuctionLine: ['Com'],
      suctionServiceValve: ['Com'],
      overloadRelay: ['Com'],
      circuitBreaker: ['Com'],
      sequenceRelayAndPhaseOutage: ['Com'],
      controller: ['Com', '2'],
      elgin_smart: ['Com'],
    },
    {
      linha: 'USMB',
      productLine: ['USMB'],
      refrigerantFluid: ['R404A'],
      compressorType: ['Scroll'],
      compressor: ['Elgin', 'Copeland'],
      logo: ['Elgin'],
      chamber: ['Sem'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem'],
      oilSeparator: ['Com'],
      suctionAccumulator: ['Com'],
      suctionFilter: ['Sem'],
      contactor: ['Sem'],
      liquidServiceValve: ['Com'],
      tankInletValve: ['Com'],
      liquidFilter: ['Com'],
      tank: ['Com'],
      viewfinder: ['Com'],
      insulationinTheSuctionLine: ['Sem'],
      suctionServiceValve: ['Sem'],
      overloadRelay: ['Sem'],
      circuitBreaker: ['Sem'],
      sequenceRelayAndPhaseOutage: ['Sem'],
      controller: ['Sem'],
      elgin_smart: ['Sem'],
    },
    {
      linha: 'UCR',
      productLine: ['UCR'],
      refrigerantFluid: ['R22', 'R404A'],
      compressorType: ['Semi-Hermético'],
      compressor: ['Bitzer'],
      logo: ['Elgin'],
      chamber: ['Sem'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem'],
      oilSeparator: ['Com'],
      suctionAccumulator: ['Com'],
      suctionFilter: ['Com'],
      contactor: ['Sem', 'AC', 'EC'],
      liquidServiceValve: ['Com'],
      tankInletValve: ['Com'],
      liquidFilter: ['Com'],
      tank: ['Com'],
      viewfinder: ['Com'],
      insulationinTheSuctionLine: ['Com'],
      suctionServiceValve: ['Com'],
      overloadRelay: ['Com'],
      circuitBreaker: ['Com'],
      sequenceRelayAndPhaseOutage: ['Com'],
      controller: ['Sem', 'Com'],
      elgin_smart: ['Com'],
    },
    {
      linha: 'UCM',
      productLine: ['UCM'],
      refrigerantFluid: ['R134a', 'R22', 'R404A'],
      compressorType: ['Hermético'],
      compressor: ['Elgin'],
      logo: ['Elgin'],
      chamber: ['Sem'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem'],
      oilSeparator: ['Sem'],
      suctionAccumulator: ['Sem'],
      suctionFilter: ['Sem'],
      contactor: ['Sem'],
      liquidServiceValve: ['Sem', 'Com'],
      tankInletValve: ['Sem', 'Com'],
      liquidFilter: ['Sem', 'Com'],
      tank: ['Sem', 'Com'],
      viewfinder: ['Sem', 'Com'],
      insulationinTheSuctionLine: ['Sem'],
      suctionServiceValve: ['Sem'],
      overloadRelay: ['Sem'],
      circuitBreaker: ['Sem'],
      sequenceRelayAndPhaseOutage: ['Sem'],
      controller: ['Sem'],
      elgin_smart: ['Sem'],
    },
    {
      linha: 'UCB',
      productLine: ['UCB'],
      refrigerantFluid: ['R134a', 'R404A'],
      compressorType: ['Hermético'],
      compressor: ['Elgin', 'Copeland'],
      logo: ['Elgin'],
      chamber: ['Sem'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem'],
      oilSeparator: ['Sem'],
      suctionAccumulator: ['Sem'],
      suctionFilter: ['Sem'],
      contactor: ['Sem'],
      liquidServiceValve: ['Sem', 'Com'],
      tankInletValve: ['Sem', 'Com'],
      liquidFilter: ['Sem', 'Com'],
      tank: ['Sem', 'Com'],
      viewfinder: ['Sem', 'Com'],
      insulationinTheSuctionLine: ['Sem'],
      suctionServiceValve: ['Sem'],
      overloadRelay: ['Sem'],
      circuitBreaker: ['Sem'],
      sequenceRelayAndPhaseOutage: ['Sem'],
      controller: ['Sem'],
      elgin_smart: ['Sem'],
    },
    {
      linha: 'ES',
      productLine: ['ES'],
      refrigerantFluid: ['R22', 'R404A'],
      compressorType: ['Hermético', 'Scroll'],
      compressor: ['Elgin', 'Copeland'],
      logo: ['Elgin'],
      chamber: ['Com'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem'],
      oilSeparator: ['Sem'],
      suctionAccumulator: ['Sem'],
      suctionFilter: ['Sem'],
      contactor: ['Sem', 'Com'],
      liquidServiceValve: ['Com'],
      tankInletValve: ['Sem', 'Com'],
      liquidFilter: ['Sem'],
      tank: ['Sem', 'Com'],
      viewfinder: ['Sem'],
      insulationinTheSuctionLine: ['Sem', 'Com'],
      suctionServiceValve: ['Com'],
      overloadRelay: ['Sem'],
      circuitBreaker: ['Sem', 'com'],
      sequenceRelayAndPhaseOutage: ['Sem', 'Com'],
      controller: ['Sem'],
      elgin_smart: ['Sem'],
    },
    {
      linha: 'TU',
      productLine: ['TU'],
      refrigerantFluid: ['R22', 'R404A', 'R134a'],
      compressorType: ['Hermético'],
      compressor: ['Elgin'],
      logo: ['Elgin'],
      chamber: ['Sem'],
      numberOfCompressors: ['1'],
      hotAirDefrost: ['Sem'],
      capacityControl: ['Sem'],
      condensationControl: ['Sem'],
      oilSeparator: ['Sem'],
      suctionAccumulator: ['Sem'],
      suctionFilter: ['Sem'],
      contactor: ['Sem'],
      liquidServiceValve: ['Com'],
      tankInletValve: ['Com'],
      liquidFilter: ['Sem'],
      tank: ['Com'],
      viewfinder: ['Sem'],
      insulationinTheSuctionLine: ['Sem'],
      suctionServiceValve: ['Com'],
      overloadRelay: ['Sem'],
      circuitBreaker: ['Sem'],
      sequenceRelayAndPhaseOutage: ['Sem'],
      controller: ['Sem'],
      elgin_smart: ['Sem'],
    },
  ];
}

export default EquipmentService;
