import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const Container = styled.div`
  p {
    &.title {
      font-size: 16px;
      font-weight: bold;
      color: ${colors.gray100};
      margin-bottom: 25px;
    }
  }
`;
