/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid, HStack, Skeleton } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { MdSearch } from 'react-icons/md';
import SelectGivenOptions from '~/components/Form/Select/SelectGivenOptions';
import { Button } from '~/components/Form/Button/Button_Styles';
import Yup from '~/helpers/validations';
import { EquipmentInterfaceTypes, EvaporatorOptionals as EvaporatorOptionalsType } from '~/store/ducks/Equipment/types';
import EquipmentService from '~/store/ducks/Equipment/services';
import { toastError } from '~/helpers/toast';
import { filterObjectEmptyValues } from '~/helpers/functions';

type OptionalsProps = {
  equipmentLine: string;
  handleSKUSearch: ({}, string) => void;
};

const EvaporatorOptionals: React.FC<OptionalsProps> = ({ handleSKUSearch, equipmentLine }) => {
  const [optionals, setOptionals] = useState<EvaporatorOptionalsType>(null);
  const [optionalsLoading, setOptionalsLoading] = useState(false);

  const initialValues = {
    chamber: null,
    isolatedTray: null,
    finsProtection: null,
    engineType: null,
    expansionValve: null,
    logo: null,
  };

  const schema = Yup.object().shape({
    chamber: Yup.string().nullable(),
    isolatedTray: Yup.string().nullable(),
    finsProtection: Yup.string().nullable(),
    engineType: Yup.string().nullable(),
    expansionValve: Yup.string().nullable(),
    logo: Yup.string().nullable(),
  });

  const getOptionals = async (line) => {
    try {
      setOptionalsLoading(true);
      const response = await EquipmentService.getOptionals('evaporators', line);
      setOptionals(response.data);
      setOptionalsLoading(false);
    } catch (err) {
      setOptionalsLoading(false);
      toastError('project', 'Erro ao recuperar opcionais.');
      console.error(err);
    }
  };

  useEffect(() => {
    console.log('equipmentLine => ', equipmentLine);
    getOptionals(equipmentLine || 'Todos');

    return () => setOptionals(null);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      validationSchema={schema}
      validateOnChange={false}
      onSubmit={(values) => {
        handleSKUSearch(filterObjectEmptyValues(values), EquipmentInterfaceTypes.EVAPORATOR);
      }}
    >
      {({ values, submitForm }) => (
        <Skeleton isLoaded={!optionalsLoading}>
          <Form className="form">
            <SimpleGrid columns={[1, 2]} spacing={[5]}>
              <Box>
                <Field
                  size="xs"
                  name="chamber"
                  component={SelectGivenOptions}
                  label="Gabinete"
                  value={values.chamber || ''}
                  options={optionals?.chamber?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="isolatedTray"
                  component={SelectGivenOptions}
                  label="Bandeja Isolada"
                  value={values.isolatedTray || ''}
                  options={optionals?.isolatedTray?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="finsProtection"
                  component={SelectGivenOptions}
                  label="Proteção (aletas)"
                  value={values.finsProtection || ''}
                  options={optionals?.finsProtection?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
              </Box>
              <Box>
                <Field
                  size="xs"
                  name="engineType"
                  component={SelectGivenOptions}
                  label="Tipo do Motor"
                  value={values.engineType || ''}
                  options={optionals?.engineType?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="expansionValve"
                  component={SelectGivenOptions}
                  label="Válvula de Expansão"
                  value={values.expansionValve || ''}
                  options={optionals?.expansionValve?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="logo"
                  component={SelectGivenOptions}
                  label="Logo"
                  value={values.logo || ''}
                  options={optionals?.logo?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={[1]} spacing={[5]} mb={10}>
              <HStack justify="flex-end" mt={4}>
                <Button leftIcon={<MdSearch size={18} />} type="button" $primary onClick={submitForm}>
                  Pesquisar
                </Button>
              </HStack>
            </SimpleGrid>
          </Form>
        </Skeleton>
      )}
    </Formik>
  );
};

export default EvaporatorOptionals;
