/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Box, HStack, SimpleGrid, Spacer, Spinner, VStack } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { SystemSettingsContainer } from '~/components/Equipments/SystemSettings/SystemSettings_Styles';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import SelectGivenOptions from '~/components/Form/Select/SelectGivenOptions';
import Yup from '~/helpers/validations';
import { storeSettings } from '~/store/ducks/Equipment/actions';
import { EquipmentSystem, SystemTypes } from '~/store/ducks/Equipment/types';
import { toastError } from '~/helpers/toast';
import EquipmentService from '~/store/ducks/Equipment/services';
import { colors } from '~/styles/colors';

const schemaSettings = Yup.object().shape({
  name: Yup.string().required().nullable(),
  operationTemperature: Yup.number().required().nullable(),
  refrigerantFluid: Yup.object().required().nullable(),
  type: Yup.string().required().nullable(),
});

const systemTypes = [
  {
    rack: SystemTypes.DEFAULT,
    label: 'Unidade Condensadora',
  },
  {
    type: SystemTypes.SPECIAL,
    label: 'Rack/Compressor + Condensador',
  },
];

const SystemSettings = (system: EquipmentSystem) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [refrigerantFluids, setRefrigerantFluids] = useState<[]>([]);
  const { active, name, operationTemperature, refrigerantFluid, rack, configurations } = system;

  const fetchRefrigerantFluids = async () => {
    try {
      setLoading(true);
      const response = await EquipmentService.getRefrigerantFluids();
      setRefrigerantFluids(response.data);
    } catch (err) {
      console.error(err);
      toastError('equipment', 'Erro ao recuperar Fluído Refrigerante');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (active) {
      fetchRefrigerantFluids();
    }
  }, [active]);

  const initialValues = {
    name,
    operationTemperature: operationTemperature || '',
    refrigerantFluid,
    type: rack ? SystemTypes.SPECIAL : SystemTypes.DEFAULT,
  };

  const handleSubmit = (values) => {
    const { type, ...rest } = values;
    const data = {
      ...rest,
      rack: type === SystemTypes.SPECIAL,
    };

    dispatch(storeSettings(data));
  };

  return (
    <SystemSettingsContainer>
      <VStack spacing="5px" align="left">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schemaSettings}
          validateOnChange={false}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, submitForm, handleChange, setValues }) => (
            <Form className="form">
              <SimpleGrid columns={[1, 1, 5]} spacing={[0, 5]}>
                <Field name="name" component={Input} type="text" size="xs" label="Nome" value={values.name || ''} />
                <Box className="refrigerantFluid__wrapper">
                  <Field
                    name="refrigerantFluid"
                    component={SelectGivenOptions}
                    size="xs"
                    label="Fluído Refrigerante"
                    // disabled={configurations?.length > 0}
                    value={values.refrigerantFluid?.id || ''}
                    onChange={(e) => {
                      handleChange(e);
                      setValues({
                        ...values,
                        refrigerantFluid: refrigerantFluids.find((rf: any) => rf.id === +e.target.value),
                      });
                    }}
                    options={refrigerantFluids?.map(({ id, code }) => (
                      <option value={id} key={id}>
                        {code}
                      </option>
                    ))}
                  />
                  {loading && (
                    <Spinner
                      className="refrigerantFluid__spinner"
                      thickness="1px"
                      speed="0.6s"
                      emptyColor={colors.gray50}
                      color={colors.primary}
                      size="sm"
                    />
                  )}
                </Box>
                <Field
                  name="operationTemperature"
                  component={Input}
                  type="number"
                  size="xs"
                  label="Temp. Evaporação"
                  // disabled={configurations?.length > 0}
                  value={values.operationTemperature || ''}
                />
                <Field
                  name="type"
                  component={SelectGivenOptions}
                  type="text"
                  size="xs"
                  label="Tipo de Máquina"
                  notNull
                  value={values.type || ''}
                  options={systemTypes.map((item) => (
                    <option value={item.type} key={item.type}>
                      {item.label}
                    </option>
                  ))}
                />
                <Spacer display={[null, null, 'none']} />
                <HStack justify={['flex-end', 'flex-end', 'center']}>
                  <Button type="button" disabled={false} data-testid="Confirmar" $primary onClick={submitForm}>
                    Confirmar
                  </Button>
                </HStack>
              </SimpleGrid>
            </Form>
          )}
        </Formik>
      </VStack>
    </SystemSettingsContainer>
  );
};

export default SystemSettings;
