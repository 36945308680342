import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Progress, Text, Image, Center } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '~/styles/colors';
import logo from '~/assets/images/elgin-branco.png';
import { AuthService } from '~/store/ducks/Auth/services';
import { removeToken } from '~/store/ducks/Auth/actions';
import { ApplicationState } from '~/store/store';

type AuthRedirectType = {
  path?: string;
  location: {
    pathname?: string;
    search?: string;
    state: {
      isLogin: boolean;
    };
  };
};

const AuthRedirect = ({ ...props }: AuthRedirectType) => {
  const history = useHistory();
  const isLogin = props.location.state?.isLogin;
  const isToken = props.location?.pathname === '/login' && props.location?.search.includes('token=');
  const { accessToken } = useSelector((state: ApplicationState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isToken && accessToken === null) {
      dispatch(removeToken());
      window.location.replace(AuthService.getAuthPath(isLogin));
      // window.location.replace(`/login`);
    } else if (accessToken !== null) {
      dispatch(removeToken());
      // window.location.replace(`/login`);
    } else {
      // window.location.replace(`/login`);
      window.location.replace(`/projects`);
    }
    // history.push(`/projects`);
    return () => null;
  }, []);

  return (
    <Box w="200px">
      <Center>
        <Image src={logo} alt="Logo Elgin" />
      </Center>
      <Box align="center" width="100%" mt={5}>
        <Text color={colors.gray50} mb={2} fontSize={18}>
          Redirecionando...
        </Text>
        <Progress size="sm" colorScheme="twitter" isIndeterminate borderRadius={2} />
      </Box>
    </Box>
  );
};

export default AuthRedirect;
