/* eslint-disable no-restricted-syntax */
import { Skeleton, SimpleGrid, Box, HStack, Container, Select } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { forEach } from 'lodash';
import React from 'react';
import { MdSearch, MdSpeakerGroup } from 'react-icons/md';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import SelectAllGivenOptions from '~/components/Form/Select/SelectAllGivenOptions';
import { filterObjectEmptyValues } from '~/helpers/functions';
import EquipmentService from '~/store/ducks/Equipment/services';
import { EvaporatorOptionals } from '~/store/ducks/Equipment/types';

type EvaporatorFormProps = {
  searchData?: any;
  initialValuesFilter: any;
  schemaFilter: any;
  handleEvaporatorSearch: any;
  optionalsFull: any;
  optionalsLoading: boolean;
  optionals: EvaporatorOptionals;
  optionalsFiltered: any;
  optionalsPowerSupply: any[];
  specialEnabled?: boolean;
  operationTemperature?: any;
};

const EvaporatorForm: React.FC<EvaporatorFormProps> = ({
  searchData,
  initialValuesFilter,
  schemaFilter,
  handleEvaporatorSearch,
  optionalsFull,
  optionalsLoading,
  optionals,
  optionalsFiltered,
  optionalsPowerSupply,
  specialEnabled,
  operationTemperature,
}) => {
  const filterObjectDefrost = async (item: string[], temperature: number) => {
    const array: string[] = [];
    if (item !== undefined) {
      item.forEach((element) => {
        if (temperature < 2) {
          if (element.toUpperCase() !== 'AR') {
            array.push(element);
          }
        } else {
          array.push(element);
        }
      });
    }
    return array;
  };

  const onLineChange = async (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const line = e.target.value || 'Todos';
    setFieldValue(e.target.name, line);
    const response = await EquipmentService.getOptionals('evaporators', line);
    optionals = response.data;
    // console.log('operationTemperature => ', operationTemperature);
    // console.log('optionalsFiltered 1 => ', optionalsFiltered);
    optionalsFiltered = await filterObjectDefrost(optionals.defrost, operationTemperature);
    // console.log('optionalsFiltered 2 => ', optionalsFiltered);
  };

  return (
    <Container>
      <Formik
        initialValues={searchData || initialValuesFilter}
        isInitialValid
        validationSchema={schemaFilter}
        validateOnChange={false}
        onSubmit={(values: any) => {
          handleEvaporatorSearch(filterObjectEmptyValues(values));
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Skeleton isLoaded={!optionalsLoading}>
            <Form className="form">
              <SimpleGrid columns={[1, 2]} spacing={[5]}>
                <Box>
                  <Field
                    size="xs"
                    name="productLine"
                    component={SelectAllGivenOptions}
                    label="Linha"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      onLineChange(e, setFieldValue);
                    }}
                    value={values.productLine || ''}
                    options={optionalsFull?.productLine.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="count"
                    component={Input}
                    type="number"
                    label="Quantidade *"
                    value={values.count || ''}
                  />
                  <Field
                    size="xs"
                    name="finsPerInch"
                    component={SelectAllGivenOptions}
                    label="Aletas por Polegada"
                    value={values.finsPerInch || ''}
                    options={optionals?.finsPerInch.map((item: number) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="powerSupply"
                    component={SelectAllGivenOptions}
                    label="Tensão de Alimentação"
                    value={values.powerSupply || ''}
                    options={optionalsPowerSupply?.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                </Box>
                <Box>
                  <Field
                    size="xs"
                    name="defrost"
                    component={SelectAllGivenOptions}
                    label="Tipo de Degelo"
                    value={values.defrost || ''}
                    options={optionalsFiltered?.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="fanDiameter"
                    component={SelectAllGivenOptions}
                    label="Diâm. do Ventilador"
                    value={values.fanDiameter || ''}
                    options={optionals?.fanDiameter.map((item: number) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                  <Field
                    size="xs"
                    name="numberOfFans"
                    component={SelectAllGivenOptions}
                    label="Núm. de Ventiladores"
                    value={values.numberOfFans || ''}
                    options={optionals?.numberOfFans.map((item: number) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  />
                </Box>
              </SimpleGrid>
              <HStack justify="flex-end" mt={4}>
                <Button
                  leftIcon={<MdSearch size={18} />}
                  type="button"
                  disabled={specialEnabled}
                  $primary
                  onClick={submitForm}
                >
                  Pesquisar
                </Button>
              </HStack>
            </Form>
          </Skeleton>
        )}
      </Formik>
    </Container>
  );
};

export default EvaporatorForm;
