import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';

export const TableStripedContainer = styled.div`
  table td {
    padding: 4px 5px;
    text-align: center;
    border: 0;
  }

  table thead th {
    padding: 3px;
    position: sticky;
    top: -10px;
    z-index: 1;
    /* width: 25vw; */
    background: whitesmoke;
    text-align: center;
  }

  table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
  }

  table tbody th {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  table tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    text-align: left;
    min-width: 100px;
    background: inherit;

    @media ${devices.tablet} {
      min-width: 200px;
    }
  }

  table tbody tr:nth-child(even) {
    background-color: white;
  }

  table tbody tr:nth-child(odd) {
    background-color: #f0f0f0;
  }
`;
