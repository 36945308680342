import { Customer, ProjectRoom } from '~/store/ducks/Project/types';

/**
 * Action types
 */
export enum ProposalTypes {
  FETCH_PROPOSAL = '@proposal/FETCH_PROPOSAL',
  CREATE_PROPOSAL = '@proposal/FETCH_PROPOSAL',

  FETCH_PRESENTATION = '@proposal/FETCH_PRESENTATION',

  STORE_PROPOSAL = '@proposal/STORE_PROPOSAL',
  STORE_PRESENTATION = '@proposal/STORE_PRESENTATION',
  STORE_PROPOSAL_ROOMS = '@proposal/STORE_PROPOSAL_ROOMS',
  STORE_COMMERCIAL_CONDITIONS_EQUIPMENTS = '@proposal/STORE_COMMERCIAL_CONDITIONS_EQUIPMENTS',

  REQUEST_SUCCESS = '@proposal/REQUEST_SUCCESS',
  REQUEST_FAILURE = '@proposal/REQUEST_FAILURE',
  REQUEST_LOADING = '@proposal/REQUEST_LOADING',
}

/**
 * Data types
 */
export interface ProposalTemplate {
  id: number;
  name: string;
  createdDate: string;
  lastModifiedDate: string;
}

export interface ProposalTemplateDetail {
  id: number;
  template: ProposalTemplate;
  key: string;
  title: string;
  text: string;
}

export interface BusinessProposal {
  id: number;
  project: {
    id: number;
    customer: Customer;
  };
  code: string;
  showIndividualPrices: boolean;
  total: number;
  status: string;
}

export interface BusinessProposalDetail {
  id: number;
  proposal: BusinessProposal;
  template: ProposalTemplate;
  text: string;
}

export interface BusinessProposalCommercialCondition {
  prices: number;
  pricingComposition: BusinessProposal;
  paymentConditions: BusinessProposal;
  warranty: BusinessProposal;
  installation: BusinessProposal;
  deliveryTime: BusinessProposal;
  transportation: BusinessProposal;
  creditRegistration: BusinessProposal;
  proposalValidity: BusinessProposal;
}

export interface ProposalTemplateCommercialCondition {
  pricingComposition: ProposalTemplateDetail;
  paymentConditions: ProposalTemplateDetail[];
  warranty: ProposalTemplateDetail;
  installation: ProposalTemplateDetail[];
  deliveryTime: ProposalTemplateDetail[];
  transportation: ProposalTemplateDetail[];
  creditRegistration: ProposalTemplateDetail;
  proposalValidity: ProposalTemplateDetail;
}

export interface User {
  username: string;
}

export interface Product {
  id: number;
}

export interface ProductSKU extends Product {}

export interface ProductDescription {
  id: number;
  proposal: BusinessProposal;
  product: Product;
  title: string;
  commercialDescription: string;
  complementaryDescription: string;
}

export interface ProductSKUPrice {
  id: number;
  proposal: BusinessProposal;
  productSku: { id: number };
  quantity: number;
  icms: number;
  pisCofins: number;
  ipi: number;
  unitPrice: number;
  totalPrice: number;
}

export interface ProposalEquipment {
  room?: number;
  evaporator?: string;
  evaporatorAirFlow?: string;
  evaporatorAirExchangeRate?: string;
  evaporatorEletricVoltage?: string;
  refrigerantFluid?: string;
  compressingUnit?: string;
  condenser?: string;
  totalCapacity?: string;
  compressingUnitEletricVoltage?: string;
}

export interface ProposalRoom {
  id: number;
  name: string;
  calculationType: number;
  internalDimension: string;
  thicknessCeilingWall: string;
  thicknessFloor: string;
  doorsArea: string;
  doorsOpenDuration: string;
  staffHeat: string;
  equipmentHeat: string;
  roomTemperature: string;
  ambientTemperature: string;
  storageCapacity: string;
  goodsType: string;
  dailyMovement: string;
  checkinTemperature: string;
  processingTime: string;
  safetyMargin: string;
  heatLoad: string;
  equipment: ProposalEquipment;
}

export interface ProposalCommercialConditionsEquipment {
  id: number;
  sku: string;
}

export enum EquipmentVariables {
  ICMS = 'ICMS',
  PISCOFINS = 'PISCOFINS',
  IPI = 'IPI',
  PRICE = 'PRICE',
}

export type ProposalSectionProps = {
  isExpanded: boolean;
};

/**
 * State type
 */
export interface ProposalState {
  data: BusinessProposal;
  rooms: ProposalRoom[];
  equipments: ProposalCommercialConditionsEquipment[];
  loading: boolean;
  options?: any; // TODO: remove
}
