import React from 'react';

import { HStack, Text } from '@chakra-ui/react';
import { MdWarning } from 'react-icons/md';

import { colors } from '~/styles/colors';

type WarningProps = {
  message: string;
};

const Warning: React.FC<WarningProps> = ({ message }) => (
  <HStack mt={10}>
    <MdWarning color={colors.orange} fontSize={22} />
    <Text fontSize={13} lineHeight={1.2} color={colors.gray200}>
      <strong> Atenção! </strong>
      {message}
    </Text>
  </HStack>
);

export default Warning;
