class HeatLoadService {
  static formatValues = ({ ...values }) => ({
    formattedHeatTransfer: values && values.heatTransfer ? values.heatTransfer.toFixed(2) : 0,
    formattedGoods: values && values.goods ? values.goods.toFixed(2) : 0,
    formattedAdditionalLoad: values && values.additionalLoad ? values.additionalLoad.toFixed(2) : 0,
    formattedDoors: values && values.doors ? values.doors.toFixed(2) : 0,
    formattedTotalKw: values && values.totalKw ? values.totalKw.toFixed(2) : 0,
    formattedTotalKcalh: values && values.totalKcalh ? values.totalKcalh.toFixed(2) : 0,
  });

  static calcPercentages = ({ ...values }) => {
    // const totalPercentage =
    //   values.heatTransfer || 0 + values.goods || 0 + values.additionalLoad || 0 + values.doors || 0;
    const totalPercentage = values.totalKw;

    return {
      heatTransferPercentage: totalPercentage ? +((values.heatTransfer * 100) / totalPercentage).toFixed(2) : 0,
      goodPercentage: totalPercentage ? +((values.goods * 100) / totalPercentage).toFixed(2) : 0,
      additionalLoadPercentage: totalPercentage ? +((values.additionalLoad * 100) / totalPercentage).toFixed(2) : 0,
      doorsPercentage: totalPercentage ? +((values.doors * 100) / totalPercentage).toFixed(2) : 0,
    };
  };
}

export default HeatLoadService;
