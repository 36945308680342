import React, { ChangeEvent } from 'react';
import { FormControl, FormErrorMessage, FormLabel, Select as ChakraSelect } from '@chakra-ui/react';
import { FieldAttributes, useField } from 'formik';

import { SelectWrapper } from './Select_Styles';
import { colors } from '~/styles/colors';

type OptionsType = {
  value: any;
  label: string;
};

type SelectProps = {
  form: any;
  field: FieldAttributes<any>;
  children: any;
  name: string;
  label: string;
  onChange?: ChangeEvent<HTMLInputElement>;
  options: OptionsType[];
  value: string;
  disabledOption?: string;
  size?: string;
};

const Select = ({ ...props }: SelectProps) => {
  const { form, field, label, value, options, disabledOption, size, ...rest } = props;
  const { name } = field;
  const [, { error, touched }] = useField(name);

  return (
    <SelectWrapper>
      <FormControl className="formControl" isInvalid={!!error && touched}>
        <FormLabel className={size || 'sm'} color={colors.gray100} htmlFor={name}>
          {label}
        </FormLabel>
        <ChakraSelect size={size || 'sm'} {...field} {...rest} data-testid={name} value={value}>
          {disabledOption ? (
            <option value="">{disabledOption}</option>
          ) : (
            <option value="" disabled>
              Selecione
            </option>
          )}
          {options.map((option: OptionsType, index: number) => (
            <option value={option.value} key={String(index)}>
              {option.label}
            </option>
          ))}
        </ChakraSelect>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </SelectWrapper>
  );
};

export default Select;
