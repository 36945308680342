import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const SystemSettingsContainer = styled.div`
  .refrigerantFluid__wrapper {
    position: relative;

    .refrigerantFluid__spinner {
      position: absolute;
      bottom: 20px;
      right: 8px;
      background: white;
    }
  }
`;
