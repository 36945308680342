import { Room, RoomActionTypes } from './types';

export const initRoom = (data: any) => ({
  type: RoomActionTypes.STORE_ROOM_INITIAL_STATE,
  payload: {
    data,
  },
});

export const resetRoom = () => ({
  type: RoomActionTypes.RESET_ROOM,
});

export const getRoomByProject = (id: number, projectId: number, index: number) => ({
  type: RoomActionTypes.GET_ROOM,
  payload: {
    id,
    projectId,
    index,
  },
});

export const deleteRoom = (id: number, projectId: number) => ({
  type: RoomActionTypes.DELETE_ROOM,
  payload: {
    id,
    projectId,
  },
});

export const storeRoomDataCreate = (data: any) => ({
  type: RoomActionTypes.STORE_DATA_CREATE,
  payload: data,
});

export const storeRoomData = (data: any) => ({
  type: RoomActionTypes.STORE_DATA,
  payload: {
    data,
  },
});

export const storeRoomInfoIntoRoom = (data: any) => ({
  type: RoomActionTypes.STORE_ROOM_INFO,
  payload: {
    data,
  },
});

export const storeInternalDimensionIntoRoom = (data: any) => ({
  type: RoomActionTypes.STORE_INTERNAL_DIMENSION,
  payload: {
    data,
  },
});

export const storeGoodsIntoRoom = (data: any) => ({
  type: RoomActionTypes.STORE_GOODS,
  payload: {
    data,
  },
});

export const storeDoors = (data: any) => ({
  type: RoomActionTypes.STORE_DOORS,
  payload: {
    data,
  },
});

export const storeHeatTransfer = (data: any) => ({
  type: RoomActionTypes.STORE_HEAT_TRANSFER,
  payload: {
    data,
  },
});

export const storeHeatTransferDimensions = (data: any) => ({
  type: RoomActionTypes.STORE_HEAT_TRANSFER_DIMENSIONS,
  payload: {
    data,
  },
});

export const storeAdditionalLoad = (data: any) => ({
  type: RoomActionTypes.STORE_ADDITIONAL_LOAD,
  payload: {
    data,
  },
});

export const storeSafetyMargin = (safetyMargin: number) => ({
  type: RoomActionTypes.STORE_SAFETY_MARGIN,
  payload: {
    safetyMargin,
  },
});

export const storeTotalKcalh = (totalKcalh: number) => ({
  type: RoomActionTypes.STORE_TOTAL_KCALH,
  payload: {
    totalKcalh,
  },
});

export const storeCalculationType = (calculationType: number) => ({
  type: RoomActionTypes.STORE_CALCULATION_TYPE,
  payload: {
    calculationType,
  },
});

export const copyRoom = (room: Room) => ({
  type: RoomActionTypes.COPY_ROOM,
  payload: {
    room,
  },
});

export const roomRequestLoading = () => ({
  type: RoomActionTypes.REQUEST_LOADING,
});

export const roomRequestSuccess = () => ({
  type: RoomActionTypes.REQUEST_SUCCESS,
});

export const roomRequestFailure = () => ({
  type: RoomActionTypes.REQUEST_FAILURE,
});

export const setStatusCreating = () => ({
  type: RoomActionTypes.SET_STATUS_CREATING,
});

export const setStatusUpdating = () => ({
  type: RoomActionTypes.SET_STATUS_UPDATING,
});
