/* eslint-disable prettier/prettier */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { calcArea, calcVolume } from '~/helpers/functions';
import { AdditionalLoadState } from '~/store/ducks/AdditionalLoad/types';
import { RoomGoods } from '~/store/ducks/Goods/types';

import { store } from '../../store';

const getCount = (property: string, height: number, area?: number, goods?: RoomGoods): number => {
  const count: any = {
    staff: () => (height <= 4 ? Math.ceil(area / 15) : Math.ceil(area / 50)),
    evaporator: () => 1,
    forklift: () => {
      if (height <= 4) return 0;
      if (goods.length === 0) return 0;

      const duration = height <= 4 ? 4 : 8;

      const qty = goods.reduce((acc, item) => {
        const { dailyMovement, palletCapacity } = item;
        const calc = dailyMovement / palletCapacity / duration / 12;
        return acc + calc;
      }, 0);

      return +qty.toFixed(1);
    },
  };

  return count[property]();
};

const getDuration = (height: number): number => (height <= 4 ? 4 : 8);

const getPower = (property: string, height?: number, volume?: number): number => {
  const count: any = {
    evaporator: () => {
      if (height <= 4) return +((volume * 60 * 0.13) / 1000).toFixed(2);
      if (height <= 7) return +((volume * 40 * 0.13) / 1000).toFixed(2);
      return +((volume * 20 * 0.13) / 1000).toFixed(2);
    },
    lighting: () => 10.8,
  };

  return count[property]();
};

class AdditionalLoadService {
  static getSuggestion = (state: AdditionalLoadState) => {
    const { length, width, height } = store.getState().room.data;
    const area = calcArea(length, width);
    const volume = calcVolume(length, width, height);
    const { operatingHours } = store.getState().room.data;
    const { goods } = store.getState().room.data;

    for (const property in state) {
      switch (property) {
        case 'staff':
        case 'forklift':
          state[property].count = getCount(property, height, area, goods);
          state[property].duration = state[property].count === 0 ? 0 : getDuration(height);
          break;
        case 'evaporator':
          state[property].count = getCount(property, height, area);
          state[property].duration = operatingHours;
          state[property].power = getPower(property, height, volume);
          break;
        case 'lighting':
          state[property].power = getPower(property, height);
          break;
        default:
          break;
      }
    }

    return state;
  };
}

export default AdditionalLoadService;
