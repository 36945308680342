/* eslint-disable no-console */
import React, { useState } from 'react';
import { Divider, Heading, HStack, Spacer, Text, useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { MdEmail, MdFileDownload } from 'react-icons/md';
import { colors } from '~/styles/colors';
import { Container } from '~/components/Proposal/ProposalHeader/ProposalHeader_Styles';
import { ApplicationState } from '~/store/store';
import { toastError } from '~/helpers/toast';
import ProposalService from '~/store/ducks/Proposal/services';
import { Button } from '~/components/Form/Button/Button_Styles';

const ProposalHeader: React.FC = () => {
  const { data: proposalData } = useSelector((state: ApplicationState) => state.proposal);
  const { id: projectId, name: projectName } = useSelector((state: ApplicationState) => state.project);
  const { customer } = useSelector((state: ApplicationState) => state.project);
  const responsiveSize = useBreakpointValue(['sm', 'lg']);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [mailToLoading, setMailToLoading] = useState(false);

  const handleMailTo = async (): Promise<void> => {
    try {
      setMailToLoading(true);
      await ProposalService.senddProposal(projectId, proposalData.id);
    } catch (err) {
      console.error(err);
      toastError('proposal', 'Erro ao enviar proposta');
    } finally {
      setMailToLoading(false);
    }
  };

  const handleDownload = async (): Promise<void> => {
    const saveFile = (response, filename) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const blob = new Blob([response.data], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    };

    try {
      setDownloadLoading(true);
      await ProposalService.downloadProposal(projectId, proposalData.id).then((response) => {
        // TODO
        // saveFile(response, response.headers['Content-Disposition'].split('filename=')[1]);
        saveFile(response, `Proposta ${proposalData.code} - ${projectName}.pdf`);
      });
    } catch (err) {
      console.error(err);
      // toastError('proposal', 'Erro ao baixar proposta');
      toastError('proposal', 'Erro ao acessar informações dos detalhes dos itens');
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <Container>
      <HStack>
        <Heading as="h3" size="md" textAlign="left" fontWeight={500} color={colors.gray100}>
          Resultado da Seleção {proposalData?.code}
        </Heading>
        <Spacer />
        <Button
          size="xs"
          $primary
          isLoading={mailToLoading}
          leftIcon={<MdEmail size={18} />}
          iconSpacing={responsiveSize === 'lg' ? 2 : 0}
          onClick={() => handleMailTo()}
        >
          {responsiveSize === 'lg' ? 'Enviar por Email' : ''}
        </Button>
        <Button
          size="xs"
          $primary
          marginLeft="5px"
          isLoading={downloadLoading}
          leftIcon={<MdFileDownload size={18} />}
          iconSpacing={responsiveSize === 'lg' ? 2 : 0}
          onClick={() => handleDownload()}
        >
          {responsiveSize === 'lg' ? 'Download' : ''}
        </Button>
      </HStack>
      <Divider my={5} />
      <Text className="client-data__title"> Dados do Cliente </Text>
      {customer?.name ? (
        <>
          <Text className="client-data__sub">
            {customer?.name} - {customer?.phone}
          </Text>
          <Text className="client-data__sub"> CNPJ: {customer?.cnpj} </Text>
          <Text className="client-data__email"> {customer?.email} </Text>
          {customer?.address.street && (
            <>
              <Text className="client-data__sub">
                {customer?.address.street} - {customer?.address.city} - {customer?.address.state}
              </Text>
              <Text className="client-data__sub">{customer?.address.zipcode}</Text>
              <Text className="client-data__sub">{customer?.address.country}</Text>
            </>
          )}
        </>
      ) : (
        <Text className="client-data__sub"> Não há dados do cliente.</Text>
      )}
    </Container>
  );
};

export default ProposalHeader;
