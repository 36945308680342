import { Reducer } from 'redux';
import { RoomState, RoomActionTypes, RoomStatus } from './types';

const INITIAL_STATE: RoomState = {
  data: null,
  loading: false,
  status: RoomStatus.CREATING,
};

const reducer: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RoomActionTypes.STORE_DATA_CREATE:
      return { ...state, status: RoomStatus.CREATING, data: { ...action.payload.data } };
    case RoomActionTypes.STORE_DATA:
      return {
        ...state,
        data: {
          ...action.payload.data,
          goods: action.payload.data.goods.sort((g1, g2) => g1.id - g2.id),
        },
      };
    case RoomActionTypes.STORE_ROOM_INFO:
      return {
        ...state,
        data: {
          ...state.data,
          name: action.payload.data.name,
          ambientTemperature: action.payload.data.ambientTemperature,
          roomTemperature: action.payload.data.roomTemperature,
          operatingHours: action.payload.data.operatingHours,
        },
      };
    case RoomActionTypes.STORE_INTERNAL_DIMENSION:
      return {
        ...state,
        data: {
          ...state.data,
          length: action.payload.data.length,
          width: action.payload.data.width,
          height: action.payload.data.height,
        },
      };
    case RoomActionTypes.STORE_GOODS:
      return {
        ...state,
        data: {
          ...state.data,
          goods: action.payload.data,
        },
      };
    case RoomActionTypes.STORE_ADDITIONAL_LOAD:
      return {
        ...state,
        data: {
          ...state.data,
          additionalLoad: action.payload.data,
        },
      };
    case RoomActionTypes.STORE_HEAT_TRANSFER:
      return {
        ...state,
        data: {
          ...state.data,
          heatTransfer: action.payload.data,
        },
      };
    case RoomActionTypes.STORE_HEAT_TRANSFER_DIMENSIONS:
      return {
        ...state,
        data: {
          ...state.data,
          heatTransfer: action.payload.data,
        },
      };
    case RoomActionTypes.STORE_DOORS:
      return {
        ...state,
        data: {
          ...state.data,
          doors: {
            ...state.data.doors,
            calculationMethod: action.payload.data.calculationMethod,
            doors: action.payload.data.doors,
          },
        },
      };
    case RoomActionTypes.STORE_SAFETY_MARGIN:
      return {
        ...state,
        data: {
          ...state.data,
          safetyMargin: action.payload.safetyMargin,
        },
      };
    case RoomActionTypes.STORE_TOTAL_KCALH:
      return {
        ...state,
        data: {
          ...state.data,
          heatLoad: {
            ...state.data.heatLoad,
            totalKcalh: action.payload.totalKcalh,
          },
        },
      };
    case RoomActionTypes.STORE_CALCULATION_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          calculationType: action.payload.calculationType,
        },
      };
    case RoomActionTypes.REQUEST_SUCCESS:
    case RoomActionTypes.REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RoomActionTypes.REQUEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RoomActionTypes.SET_STATUS_CREATING:
      return {
        ...state,
        status: RoomStatus.CREATING,
      };
    case RoomActionTypes.SET_STATUS_UPDATING:
      return {
        ...state,
        status: RoomStatus.UPDATING,
      };
    case RoomActionTypes.DELETE_ROOM:
    case RoomActionTypes.RESET_ROOM:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default reducer;
