import { ButtonGroup, HStack, IconButton } from '@chakra-ui/react';
import React from 'react';
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { colors } from '~/styles/colors';

type PaginationProps = {
  fetchNewItems: (...number) => void;
  firstPageLink: string;
  previousPageLink: string;
  nextPageLink: string;
  lastPageLink: string;
};

const Pagination: React.FC<PaginationProps> = ({
  fetchNewItems,
  firstPageLink,
  previousPageLink,
  nextPageLink,
  lastPageLink,
}) => {
  const parseUrlParamsToObj = (urlParams: string) =>
    JSON.parse(`{"${decodeURI(urlParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);

  const handlePagination = (urlParams: string) => {
    const { page, size, ...rest } = parseUrlParamsToObj(urlParams);
    fetchNewItems(page, size, rest);
  };

  return (
    <HStack justify="center" mt={10}>
      <ButtonGroup variant="outline" spacing="5">
        <IconButton
          size="sm"
          disabled={!firstPageLink}
          color={colors.secondary}
          aria-label="First Page"
          onClick={() => handlePagination(firstPageLink)}
          icon={<MdFirstPage size={22} />}
        />
        <IconButton
          size="sm"
          disabled={!previousPageLink}
          color={colors.secondary}
          aria-label="Previous Page"
          onClick={() => handlePagination(previousPageLink)}
          icon={<MdNavigateBefore size={22} />}
        />
        <IconButton
          size="sm"
          disabled={!nextPageLink}
          color={colors.secondary}
          aria-label="Next Page"
          onClick={() => handlePagination(nextPageLink)}
          icon={<MdNavigateNext size={22} />}
        />
        {/* <IconButton
          size="sm"
          disabled={!lastPageLink}
          color={colors.secondary}
          aria-label="Last Page"
          onClick={() => handlePagination(lastPageLink)}
          icon={<MdLastPage size={22} />}
        /> */}
      </ButtonGroup>
    </HStack>
  );
};

export default Pagination;
