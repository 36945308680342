/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import {
  Box,
  FormControl,
  FormLabel,
  GridItem,
  Select,
  SimpleGrid,
  VStack,
  Text,
  Image,
  Checkbox,
  HStack,
  Icon,
  Skeleton,
  Fade,
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import React, { useState } from 'react';
import { BsImage } from 'react-icons/bs';
import { MdSearch } from 'react-icons/md';
import EquipmentTableComparison from '~/components/Equipments/EquipmentTable/EquipmentTableComparison';
import CondensingUnitComparisonForm from '~/components/Equipments/SearchForms/CondensingUnitComparisonForm';
import EvaporatorComparisonForm from '~/components/Equipments/SearchForms/EvaporatorComparisonForm';
import { Button } from '~/components/Form/Button/Button_Styles';
import { toastError } from '~/helpers/toast';
import EquipmentService from '~/store/ducks/Equipment/services';
import { EquipmentInterfaceTypes } from '~/store/ducks/Equipment/types';
import { colors } from '~/styles/colors';

import { ComparisonContainer } from './Comparison_Styles';

const EvaporatorSearch = ({ handleEvaporatorSearch, searchData }) => (
  <Box>
    <Text className="comparison__section--title"> Pesquisa de Evaporadores</Text>
    <EvaporatorComparisonForm handleEvaporatorSearch={handleEvaporatorSearch} searchData={searchData} />
  </Box>
);

const CondensingUnitSearch = ({ handleCondensingSearch, searchData }) => (
  <Box>
    <Text className="comparison__section--title"> Pesquisa de Unidades Condensadoras</Text>
    <CondensingUnitComparisonForm handleCondensingSearch={handleCondensingSearch} searchData={searchData} />
  </Box>
);

const Comparison: React.FC = () => {
  const [type, setType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [equipments, setEquipments] = useState<any[]>([]);
  const [comparisonSelected, setComparisonSelected] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const { nextStep, prevStep, reset: resetStep, activeStep } = useSteps({ initialStep: 0 });

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value);
    setEquipments([]);
    setComparisonSelected([]);
    setSearchData(null);
    resetStep();
  };

  const getEvaporators = async (data) => {
    const body = {
      evaporator: {
        ...data,
      },
    };

    try {
      setLoading(true);
      const response = await EquipmentService.getModels(body);
      setEquipments(response.data);
      setLoading(false);
      setComparisonSelected([]);
    } catch (err) {
      setLoading(false);
      toastError('project', 'Erro ao recuperar evaporadores.');
      console.error(err);
    }
  };

  const getCondensingUnits = async (data) => {
    const body = {
      condensingUnit: {
        ...data,
      },
    };

    try {
      setLoading(true);
      const response = await EquipmentService.getModels(body);
      setEquipments(response.data);
      setComparisonSelected([]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastError('project', 'Erro ao recuperar unidades condensadoras.');
      console.error(err);
    }
  };

  const handleEvaporatorSearch = (values): void => {
    getEvaporators(values);
    setSearchData(values);
  };

  const handleCondensingSearch = (values): void => {
    getCondensingUnits(values);
    setSearchData(values);
  };

  const handleStepNext = (): void => {
    nextStep();
  };

  const handleStepPrevious = (): void => {
    prevStep();
  };

  const getImageFromAssets = (line: string): string | null => {
    if (!line) return null;

    if (type === EquipmentInterfaceTypes.EVAPORATOR) {
      return `${process.env.PUBLIC_URL}/assets/images/evaporadores/${line.split(' ').join('_')}.jpg`;
    }

    if (type === EquipmentInterfaceTypes.CONDENSING_UNIT) {
      return `${process.env.PUBLIC_URL}/assets/images/unidades-condensadoras/${line.split(' ').join('_')}.jpg`;
    }

    return null;
  };

  const handleComparisonSelected = (event: React.ChangeEvent<HTMLInputElement>, equipment: any) => {
    const isChecked = event.target.checked;

    console.log(isChecked);

    if (isChecked) {
      if (comparisonSelected.length === 3) return;
      let newItem = null;

      if (type === EquipmentInterfaceTypes.EVAPORATOR) {
        newItem = {
          id: equipment.id,
          model: equipment.model,
          evaporationTemperature: searchData.evaporationTemperature,
          refrigerantFluid: searchData.refrigerantFluid,
          nominalCapacity: equipment.nominalUnitCapacity,
        };
      } else {
        newItem = {
          id: equipment.id,
          model: equipment.model,
          evaporationTemperature: searchData.evaporationTemperature,
          refrigerantFluid: searchData.refrigerantFluid,
          nominalCapacity: equipment.nominalUnitCapacity,
          ambientTemperature: searchData.ambientTemperature,
        };
      }

      setComparisonSelected([...comparisonSelected, newItem]);
    } else {
      const newArray = comparisonSelected.filter((selected) => selected.id !== equipment.id);
      setComparisonSelected([...newArray]);
    }
  };

  return (
    <ComparisonContainer>
      <VStack spacing="5px" align="left" w={200}>
        <FormControl className="formControl">
          <FormLabel color={colors.gray100} fontSize="12px" htmlFor="product-type">
            Tipo de equipamento
          </FormLabel>

          <Select
            name="product-type"
            size="xs"
            color={colors.gray200}
            borderRadius={4}
            value={type}
            onChange={handleTypeChange}
          >
            <option value="" disabled>
              Selecione
            </option>
            <option value={EquipmentInterfaceTypes.EVAPORATOR}>Evaporadores</option>
            <option value={EquipmentInterfaceTypes.CONDENSING_UNIT}>Unidades Condensadoras</option>
          </Select>
        </FormControl>
      </VStack>

      {type && (
        <Steps activeStep={activeStep} mt={10} colorScheme="twitter" size="sm" responsive={false}>
          <Step label="Modelo">
            <SimpleGrid columns={[12]} gap={5} mt={10}>
              <GridItem colStart={[1]} colEnd={[13, 13, 6, 6]}>
                {type === EquipmentInterfaceTypes.EVAPORATOR && (
                  <EvaporatorSearch handleEvaporatorSearch={handleEvaporatorSearch} searchData={searchData} />
                )}
                {type === EquipmentInterfaceTypes.CONDENSING_UNIT && (
                  <CondensingUnitSearch handleCondensingSearch={handleCondensingSearch} searchData={searchData} />
                )}
              </GridItem>
              <GridItem colStart={[1, 1, 7]} colEnd={[13]} mt={[10, 10, null]}>
                {equipments.length > 0 && (
                  <HStack justifyContent="space-between" alignItems="center" mb={5} p={2}>
                    <Text color={colors.gray100} fontSize={12}>
                      {`${comparisonSelected.length} de 3 selecionados`}
                    </Text>
                    <Button $primary disabled={comparisonSelected.length === 0} mr={3} onClick={() => handleStepNext()}>
                      Comparar
                    </Button>
                  </HStack>
                )}
                {loading && <Skeleton h="200px" />}
                <Fade in={!loading}>
                  <Box mb={10}>
                    {equipments.length === 0 && !loading && (
                      <>
                        {searchData && (
                          <VStack align="center" mt={10}>
                            <Icon boxSize="60px" as={MdSearch} color={colors.gray100} />
                            <Text className="comparison__section--title">
                              Nenhum modelo encontrado. Recomendação: Selecionar os filtros para &ldquo;Todos&ldquo;.
                            </Text>
                          </VStack>
                        )}
                      </>
                    )}
                    <SimpleGrid columns={[1, 1, 1, 2]} gap={5}>
                      {equipments?.map((equip) => (
                        <Box className="elgin__box--shadow comparison__section--box" key={equip.id}>
                          <SimpleGrid columns={[1]} p={3}>
                            <VStack justify="space-between">
                              <Box alignSelf="start">
                                <Checkbox
                                  onChange={(e) => handleComparisonSelected(e, equip)}
                                  colorScheme="twitter"
                                  isChecked={
                                    comparisonSelected.filter((selected) => selected.id === equip.id).length > 0
                                  }
                                >
                                  <Text fontSize={13} fontWeight={400} color={colors.gray100}>
                                    Comparar
                                  </Text>
                                </Checkbox>
                              </Box>

                              <Image
                                className="comparison__section--image"
                                fit="contain"
                                src={getImageFromAssets(equip.line)}
                                alt="Equipment Image"
                                fallback={<Icon as={BsImage} color={colors.gray100} />}
                              />
                              <Text
                                fontSize="16px"
                                fontWeight="500"
                                color={colors.primary}
                                textShadow={`1px 2px ${colors.gray50}`}
                                align="left"
                              >
                                {equip.model}
                              </Text>
                            </VStack>
                          </SimpleGrid>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Box>
                </Fade>
              </GridItem>
            </SimpleGrid>
          </Step>
          <Step label="Comparativo">
            <EquipmentTableComparison equipmentType={type} comparisonSelected={comparisonSelected} />
            <HStack mt={10} justify="end">
              <Button $default onClick={() => handleStepPrevious()}>
                Voltar
              </Button>
            </HStack>
          </Step>
        </Steps>
      )}
    </ComparisonContainer>
  );
};

export default Comparison;
