/* eslint-disable no-console */
import { all, call, put, race, select, takeLatest } from 'redux-saga/effects';
import { AuthTypes } from '~/store/ducks/Auth/types';
import { toastError } from '~/helpers/toast';
import { AuthService } from '~/store/ducks/Auth/services';
import { storeToken } from '~/store/ducks/Auth/actions';

export function* login(action): any {
  const { code, token } = action.payload;

  function redirect() {
    if (token !== null) window.location.replace(`/projects`);
  }

  try {
    const response = yield call(AuthService.login, code, token);
    yield put(storeToken(response.data));
    setInterval(redirect, 5000);
    // if (token !== null) window.location.replace(`/projects`);
  } catch (err) {
    console.error(err);
    toastError('login', 'Erro ao processar login.');
  }
}

export default all([takeLatest(AuthTypes.LOGIN, login)]);
