import { Reducer } from 'redux';
import { Room, RoomStatus } from '~/store/ducks/Room/types';
import { ProjectState, ProjectActionTypes, ProjectRoom } from './types';

const INITIAL_STATE: ProjectState = {
  id: null,
  name: null,
  projectLoading: false,
  sliderLoading: false,
  activeRoom: null,
  status: null,
  rooms: [],
  customer: null,
};

const reducer: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProjectActionTypes.STORE_DATA:
      return {
        ...state,
        id: action.payload.id,
        status: action.payload.status,
        name: action.payload.name,
        customer: action.payload.customer,
      };
    case ProjectActionTypes.STORE_ROOMS:
      return {
        ...state,
        activeRoom: null,
        rooms: action.payload.reduce(
          (acc: any, room: ProjectRoom) => [
            ...acc,
            {
              ...room,
              id: room.id,
              name: room.name,
              status: RoomStatus.UPDATING,
            },
          ],
          []
        ),
      };
    case ProjectActionTypes.STORE_ROOM_INTO_PROJECT:
      return { ...state, rooms: [...state.rooms, action.payload] };
    case ProjectActionTypes.UPDATE_ROOM_INTO_PROJECT:
      return {
        ...state,
        rooms: state.rooms.map((room: Room, index: number) =>
          index === state.activeRoom ? { ...room, ...action.payload } : room
        ),
      };
    case ProjectActionTypes.REMOVE_ROOM_FROM_PROJECT:
      return {
        ...state,
        rooms: state.rooms.filter((room: Room) => action.payload !== room.id),
      };
    case ProjectActionTypes.REQUEST_SUCCESS:
    case ProjectActionTypes.REQUEST_FAILURE:
      return {
        ...state,
        projectLoading: false,
      };
    case ProjectActionTypes.REQUEST_LOADING:
      return {
        ...state,
        projectLoading: true,
      };
    case ProjectActionTypes.SLIDER_REQUEST_SUCCESS:
    case ProjectActionTypes.SLIDER_REQUEST_FAILURE:
      return {
        ...state,
        sliderLoading: false,
      };
    case ProjectActionTypes.SLIDER_REQUEST_LOADING:
      return {
        ...state,
        sliderLoading: true,
      };
    case ProjectActionTypes.SET_ACTIVE_ROOM:
      return {
        ...state,
        activeRoom: action.payload.index,
      };
    default:
      return state;
  }
};

export default reducer;
