import {
  HStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaCopy } from 'react-icons/fa';
import { ApplicationState } from '~/store/store';
import { Button } from '~/components/Form/Button/Button_Styles';
import { copyRoom } from '~/store/ducks/Room/actions';

const RoomCopy: React.FC = () => {
  const room = useSelector((state: ApplicationState) => state.room);
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const dispatch = useDispatch();
  const responsiveSize = useBreakpointValue(['xs', 'sm']);

  const handleCopy = () => {
    dispatch(copyRoom(room.data));
    setIsOpen(false);
  };

  const handleDialogCopy = () => {
    setIsOpen(true);
  };

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} isCentered onClose={() => setIsOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Copiar Câmara
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja copiar câmara <i>{room?.data.name}</i>?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button $default ref={cancelRef} onClick={() => setIsOpen(false)}>
                Cancelar
              </Button>
              <Button $primary onClick={() => handleCopy()} ml={3}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {room?.data.id && (
        <Button
          size={responsiveSize}
          $primary
          leftIcon={<FaCopy size={16} />}
          iconSpacing={responsiveSize === 'sm' ? 2 : 0}
          onClick={() => handleDialogCopy()}
        >
          {responsiveSize === 'sm' ? 'Criar Cópia' : ''}
        </Button>
      )}
    </>
  );
};

export default RoomCopy;
