import React, { ChangeEvent } from 'react';
import { Checkbox as ChakraCheckbox, FormControl, FormLabel } from '@chakra-ui/react';
import { FieldAttributes } from 'formik';

import { CheckBoxWrapper } from './CheckBox_Styles';
import { colors } from '~/styles/colors';

type CheckBoxProps = {
  form: any;
  field: FieldAttributes<any>;
  children: any;
  name: string;
  label: string;
  options: [];
  checked: boolean;
};

const CheckBox = ({ ...props }: CheckBoxProps) => {
  const { form, field, label, checked, options, ...rest } = props;
  const { name } = field;

  return (
    <CheckBoxWrapper>
      <FormControl className="formControl">
        <FormLabel color={colors.gray100} htmlFor={name}>
          {label}
        </FormLabel>
        <ChakraCheckbox mt="10px" {...field} {...rest} isChecked={checked} />
      </FormControl>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
