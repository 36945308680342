import React, { useState, FC } from 'react';
import { Avatar, Box, Text, VStack } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AuthService } from '~/store/ducks/Auth/services';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

type MobileMenuProps = {
  open?: boolean;
};

interface RightNavProps {
  open?: boolean;
  userInfo: string;
}

const MobileMenu = styled.nav<MobileMenuProps>`
  flex-flow: column nowrap;
  background-color: ${colors.secondary};
  font-size: 2rem;
  position: fixed;
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  top: 0;
  right: 0;
  height: 100vh;
  width: 300px;
  padding-top: 3.5rem;
  transition: transform 0.3s ease-in-out;
  z-index: 10;
  list-style: none;
  display: flex;

  .logged-info {
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;

    @media ${devices.tablet} {
      display: none;
    }

    .userinfo {
      font-size: 16px;
      font-weight: 400;
      color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;

      @media ${devices.tablet} {
        display: none;
      }
    }
  }

  li {
    padding: 0 15px;
    font-size: 16px;
    color: ${colors.white};
    padding-bottom: 1rem;

    a.selected,
    a:hover,
    a:focus {
      color: ${colors.white};
      font-weight: 600;

      @media ${devices.tablet} {
        color: ${colors.primary};
      }
    }
  }

  @media ${devices.tablet} {
    flex-flow: row nowrap;
    background-color: ${colors.white};
    top: auto;
    right: unset;
    height: initial;
    width: auto;
    padding-top: initial;
    transform: translateX(10px);
    position: relative;
    transition: none;

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;

      li {
        padding: 0 15px;
        font-weight: 400;
        font-size: 16px;
        color: ${colors.gray200};
        display: block;

        &.logout {
          display: block;

          @media ${devices.tablet} {
            display: none;
          }
        }
      }
    }
  }
`;

const RightNav: FC<RightNavProps> = ({ open, userInfo }) => {
  const { pathname } = useLocation();
  const [menu, setMenu] = useState(pathname);

  return (
    <>
      <MobileMenu open={open}>
        <Box className="logged-info">
          <VStack align="start">
            <Avatar className="avatar" size="sm" bg={colors.gray100} />
            <Text className="userinfo"> {userInfo} </Text>
          </VStack>
        </Box>
        <ul>
          <li>
            <Link
              to="/projects"
              className={menu === '/projects' ? 'selected' : ''}
              onClick={() => setMenu('/projects')}
            >
              Projetos
            </Link>
          </li>
          <li>
            <Link
              to="/comparison"
              className={menu === '/comparison' ? 'selected' : ''}
              onClick={() => setMenu('/comparison')}
            >
              Produtos
            </Link>
          </li>
          {AuthService.isAuthenticated() && (
            <li className="logout">
              <Link to={{ pathname: '/logout', state: { isLogin: false } }}>Sair</Link>
            </li>
          )}
        </ul>
      </MobileMenu>
    </>
  );
};

export default RightNav;
