import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from '~/routes';

import { theme } from '~/styles/theme/chakra';
import GlobalStyles from '~/styles/global';
import { store, persistor } from '~/store/store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <GlobalStyles />
            <Routes />
          </ChakraProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
