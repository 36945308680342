import { createGlobalStyle } from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

import VisbyCFRegular from '../assets/fonts/VisbyCF-Regular.otf';
import VisbyCFBold from '../assets/fonts/VisbyCF-Bold.otf';
import VisbyCFMedium from '../assets/fonts/VisbyCF-Medium.otf';
import VisbyCFLight from '../assets/fonts/VisbyCF-Light.otf';
import VisbyCFDemiBold from '../assets/fonts/VisbyCF-DemiBold.otf';

export default createGlobalStyle`
  @font-face {
    font-family: 'VisbyCF';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${VisbyCFLight}) format('opentype');
  }

  @font-face {
    font-family: 'VisbyCF';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${VisbyCFRegular}) format('opentype');
  }

  @font-face {
    font-family: 'VisbyCF';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${VisbyCFMedium}) format('opentype');
  }

  @font-face {
    font-family: 'VisbyCF';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${VisbyCFDemiBold}) format('opentype');
  }

  @font-face {
    font-family: 'VisbyCF';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${VisbyCFBold}) format('opentype');
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    width: 100%;
  }

  body, input, button {
    font: 16px, 400, sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
    line-height: normal !important;
  }

  .elgin__box {
    background: linear-gradient(90deg, ${colors.secondary} 0%, ${colors.primary} 100%) no-repeat top;
    background-size: 100% 2px;
    border-radius: 4px;
    box-shadow: 0px 2px 10px -4px rgb(0 0 0 / 30%);
  }

  .elgin__box--shadow {
    border-radius: 4px;
    box-shadow: 0px 2px 10px -4px rgb(0 0 0 / 30%);
  }

  .fade__item-enter {
    opacity: 0;
  }

  .fade__item-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .fade__item-exit {
    opacity: 1;
  }

  .fade__item-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }

  .modal__select {
    max-height: 95vh;

    .modal__select__button--group {
      button {
        transition: all 0.2s ease-in-out;
        color: ${colors.gray100};
        border-radius: 4px;

        &.active {
          background-color: ${colors.secondary};
          color: ${colors.white};
        }
      }
    }

    .modal__select__button--item {
      font-size: 12px;

      @media ${devices.mobileL} {
        font-size: 14px;
      }
    }

    .equipment__wrapper {
      margin-right: auto;
      margin-left: auto;
      max-width: 480px;
      width: 100%;

      .equipment__item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px;
        min-height: 300px;
        transition: all 0.2s ease-in-out;
        border: 2px solid transparent;

        @media ${devices.mobileL} {
          min-height: 200px;
        }

        &:hover,
        &:focus,
        &.active {
          border: 2px solid ${colors.secondary};
        }

        &:disabled,
        &.no--focus:hover,
        &.no--focus:focus {
          border: 2px solid transparent;
        }
      }

      .equipment__checkbox--wrapper {
          position: absolute;
          top: 10px;
          left: 20px;
        }

      .equipment__image {
        width: 200px;
        height: 120px;
        margin-top: 10px;
      }

      .equipment__name {
        font-size: 16px;
        font-weight: 500;
        color: ${colors.primary};
        text-shadow: 1px 2px ${colors.gray50};
      }

      .equipment__table {
        @media ${devices.mobileXL} {
          margin-left: 15px;
        }

        td {
          font-size: 11px;
          color: ${colors.gray100};
          border: none;
          font-weight: 500;

          &:last-child {
            text-align: right;
            font-weight: 600;
            color: ${colors.primary};
          }
        }

        .equipment__table--editable {
          position: relative;

          .equipment__table--icon {
            position: absolute;
            bottom: 0;
            right: 50px;
          }
        }
      }
    }

    p {
      &.modal__select__section--title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 25px;
        color: ${colors.gray100};
        text-align: left;
      }

      &.modal__select__section--info {
        font-size: 16px;
        font-weight: 400;
        color: ${colors.gray100};
      }

      &.modal__select__section--range {
        font-size: 12px;
        font-weight: 500;
        color: ${colors.gray100};
      }

      &.modal__select__section--and {
        margin-right: 8px;
        font-size: 14px
      }
    }


  }

  .modal, .modal__select {
    .form {
      .formControl {
        margin-bottom: 15px;
      }
    }

    .equipment__table {
        td {
          font-size: 11px;
          color: ${colors.gray100};
          border: none;
          font-weight: 500;

          &:last-child {
            text-align: right;
            font-weight: 600;
            color: ${colors.primary};
          }
        }
      }
  }

  .proposal__text {
    /* @image */
    img {
      max-width: auto;
      height: auto;

      @media ${devices.mobileS} {
        max-width: 80px;
      }

      @media ${devices.mobileL} {
        max-width: 140px;
      }

      @media ${devices.mobileXL} {
        max-width: 180px;
      }

      @media ${devices.tablet} {
        max-width: 240px;
      }
    }

    /* @group Headings */
    h1,h2,h3,h4,h5,h6 {
      line-height: 1;
      margin: 0;
      margin-top: 1.5rem;
      text-rendering: optimizeLegibility;
      color: unset;
    }

    h1 { font-size: 2.75rem; }
    h2 { font-size: 2rem; }
    h3 { font-size: 1.65rem; }
    h4 { font-size: 1.25rem; }
    h5 { font-size: 1.1rem; }
    h6 { font-size: 1rem; }

    h2 em, h3 em{
      color:grey;
    }

    /* @end */

    small {
      font-size: 65%;
    }

    p {
      margin-top: .75rem;
    }

    hr {
      margin: .75rem 0;

      opacity: .5;
    }

    ol,ul {
      margin-top: .75rem;
    }

    ol ul, ol ol, ul ul, ul ol {
      margin-left: 2rem;
      margin-bottom: .75rem;
    }

    li {
      margin: .5em 0;
    }

    abbr {
      font-variant: small-caps;
      font-weight: 600;
      text-transform: lowercase;
      color: #808080;
    }

    abbr[title]:hover {
      cursor: help;
    }

    /* @group Block Quotes */

    blockquote {
      border-left: 5px solid #333;
      padding-left: .75em;
      margin-left: calc(-.75em - 5px);

      font-style: italic;
    }

    blockquote + figcaption {
      display: block;

      margin-top: -1.5rem;
      margin-bottom: 1.5rem;

      font-size: 75%;
      text-align: right;
    }

    blockquote + figcaption:before {
      content: "— ";

      opacity: .05;
    }

    /* @end */

    /* @group Pre-formatted and Code */

    pre {
      overflow: auto;

      margin: .75rem 0;
      padding: .5rem;

      font-size: .875em;
      white-space: pre;

      background-color: #f8f8f8;
      border: 1px solid #ccc;
      border-radius: 3px;
    }

    code {
      padding: 0 .25em;

      white-space: pre;
      font-family: Source Code Pro, monospace;

      background-color: #f8f8f8;
      border: 1px solid #ccc;
      border-radius: 3px;
    }

    pre code {
      padding: 0;

      word-wrap: normal;
      white-space: pre-wrap;
    }

    pre code, pre tt {
      background-color: transparent;
      border: none;
    }

    /* @end */

    /* @group Tables */

    table {
      margin: .75rem 0;
      padding: 0;

      border-collapse: collapse;
    }
    table tr {
      margin: 0;
      padding: 0;

      border-top: 1px solid #ccc;
      background-color: #fff;
    }
      table tr:nth-child(2n) {
        background-color: #f8f8f8;
      }

    table tr th {
      margin: 0;
      padding: .35em .75em;

      font-weight: bold;
      text-align: left;

      border: 1px solid #ccc;
    }

    table tr td {
      margin: 0;
      padding: .35em .75em;

      text-align: left;

      border: 1px solid #ccc;
    }

    table tr th :first-child, table tr td :first-child { margin-top: 0; }
    table tr th :last-child, table tr td :last-child { margin-bottom: 0; }
  }

  // Print Page Configuration
  @media print {
    * {
      font-size: 12px;
      background: transparent;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
    }

    header, button:not(.accordion__button), svg, #chakra-toast-portal, .hide--on--print {
      display: none !important;
    }

    .elgin__box, .elgin__box--shadow, .accordion__item {
      box-shadow: none !important;
      border: none !important;
    }

    input {
      margin: 0 !important;
    }

    form .print-grid, .print-grid {
      display: grid;
      gap: 15px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .accordion .chakra-collapse {
      height: auto !important;
      overflow: auto !important;
      display: block !important;
      opacity: 1 !important;
    }
  }
`;
