import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const TextEditorContainer = styled.div`
  margin: 5px 0 10px;

  .editor-wrapper {
    margin-top: 15px;
    border: 1px solid ${colors.gray50};
    border-radius: 4px;

    .text-editor {
      padding: 0px 15px;
      background-color: ${colors.white};
      margin: -4px 2px 0;
      min-height: 200px;
      max-height: 450px;
      overflow: auto;
    }
  }
`;
