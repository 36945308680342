import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const Container = styled.div`
  .product-select {
    margin-bottom: 60px;

    .product-select__group,
    .product-select__item {
      margin-bottom: 25px;
      width: 200px;
      position: relative;

      .product-select__item__spinner {
        position: absolute;
        bottom: 7px;
        right: 12px;
        background: white;
      }
    }

    p {
      font-weight: 500;
      color: ${colors.gray100};
    }
  }

  .form {
    label {
      color: ${colors.gray100};
    }

    select {
      font-size: 12px;
      padding-left: 10px;
    }

    .button-remove {
      border-radius: 25px;
      background-color: ${colors.transparent};
      border: 1px solid ${colors.red};
      font-size: 14px;
      color: ${colors.red};
      width: auto;
      height: 30px;
      transition: all 0.2s ease-in-out;
      margin-top: 25px;

      @media ${devices.mobileS} {
        border: none;
      }

      @media ${devices.mobileL} {
        border: 1px solid ${colors.red};
      }

      span {
        @media (max-width: 768px) {
          margin: 0;
        }
      }

      .button-remove__text {
        color: inherit;
        display: none;

        @media ${devices.tablet} {
          display: block;
        }
      }

      svg {
        fill: ${colors.red};
        width: 25px;
        height: auto;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        background-color: ${colors.red};
        color: ${colors.white};

        svg {
          fill: ${colors.white};
        }
      }
    }
  }

  .form__item {
    @media ${devices.mobileL} {
      gap: 0px 40px;
    }
  }

  p {
    &.title {
      font-size: 16px;
      font-weight: bold;
      color: ${colors.gray100};
      margin-bottom: 25px;
    }
  }
`;
