import { List, ListIcon, ListItem } from '@chakra-ui/react';
import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import generatedGitInfo from '~/generatedGitInfo.json';
import { colors } from '~/styles/colors';

function About() {
  return (
    <List spacing={1} mt={10}>
      <ListItem fontSize={16}>
        <ListIcon as={MdCheckCircle} color={colors.primary} />
        Versão: {generatedGitInfo.appVersion}
      </ListItem>
      <ListItem fontSize={14}>
        <code>{generatedGitInfo.gitCommitHash}</code>
      </ListItem>
      <ListItem fontSize={14}>
        <code>{generatedGitInfo.gitCommitDate}</code>
      </ListItem>
    </List>
  );
}

export default About;
