import React, { useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  Skeleton,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SliderEquipments from '~/components/Equipments/SliderEquipments';
import { ApplicationState } from '~/store/store';
import { colors } from '~/styles/colors';

import ProjectRouter from '~/components/ProjectRouter';
import EquipmentsSystem from '~/components/Equipments/EquipmentsSystem';
import { changeActiveSystem, getAllSystems } from '~/store/ducks/Equipment/actions';
import { EquipmentSystem } from '~/store/ducks/Equipment/types';
import { AccordionContainer } from '~/components/Checklist/FormRoomWrapper/FormRoomWrapper_Styles';
import { fetchProjectById } from '~/store/ducks/Project/actions';
import PrintPage from '~/components/PrintPage';

const Equipments: React.FC = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const projectId = params.id;
  const project = useSelector((state: ApplicationState) => state.project);
  const equipment = useSelector((state: ApplicationState) => state.equipment);
  const { systems } = equipment;
  const firstRender = useRef(true);

  useEffect(() => {
    dispatch(fetchProjectById(projectId));
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      dispatch(getAllSystems(projectId));
    }
  }, [project]);

  const handleSystemChange = (expandedIndex) => {
    dispatch(changeActiveSystem(expandedIndex));
  };

  const getActiveSystemIndex = (): number => systems.map((system) => system.active).indexOf(true);

  return (
    <>
      <Box minHeight="16px" mb={8}>
        <Heading as="h2" size="xs" fontWeight={500} color={colors.gray100}>
          {project.name}
        </Heading>
      </Box>

      <ProjectRouter
        previousLink={`/projects/${projectId}/rooms`}
        previusText="Carga Térmica"
        nextText="Resultado da Seleção"
        nextLink={`/projects/${projectId}/proposal`}
      />
      <SliderEquipments equipment={equipment} />

      <Skeleton isLoaded={!equipment.loading}>
        {/* <HStack justify="end" align="center" mt={5}>
          <PrintPage />
        </HStack> */}

        <AccordionContainer>
          <Accordion
            className="accordion"
            mt={10}
            onChange={handleSystemChange}
            allowToggle
            defaultIndex={0}
            index={getActiveSystemIndex()}
          >
            {systems?.map(
              (system: EquipmentSystem, index: number) =>
                (system.configurations.length > 0 || system.name === '') && (
                  <div key={String(index)}>
                    <AccordionItem className={`accordion__item ${system.active ? 'elgin__box' : 'elgin__box--shadow'}`}>
                      <AccordionButton className={`accordion__button ${system.active && 'hidden'}`}>
                        <Box flex="1" textAlign="left">
                          <h2>{system.name || 'Novo sistema'}</h2>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel className="accordion__panel">
                        <EquipmentsSystem {...system} />
                      </AccordionPanel>
                    </AccordionItem>
                  </div>
                )
            )}
          </Accordion>
        </AccordionContainer>
      </Skeleton>
    </>
  );
};

export default Equipments;
