import React, { useEffect, useState } from 'react';

import { Form, Field, Formik } from 'formik';
import { Box, Text, Stack, Button as ChakraButton } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { Container } from './FormHeatTransfer_Styles';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/Select';
import CheckBox from '~/components/Form/CheckBox';
import { ApplicationState } from '~/store/store';
import { Edge } from '~/store/ducks/HeatTransfer/types';
import { getThicknessByMaterial } from '~/store/ducks/HeatTransfer/services';
import { storeHeatTransfer } from '~/store/ducks/Room/actions';
import Yup from '~/helpers/validations';
import materialJson from '~/assets/mocks/material.json';
import { parseValue2Decimal } from '~/helpers/functions';
import { INITIAL_STATE as INITIAL_STATE_HEAT_TRANSFER } from '~/store/ducks/HeatTransfer/reducer';

const edgeSChema = Yup.object().shape({
  thickness: Yup.number().required().min(1),
  incidentSolarRadiation: Yup.boolean(),
  material: Yup.object().shape({
    id: Yup.number().required(),
  }),
  temperature: Yup.number().required(),
});

const schema = Yup.object().shape({
  ceiling: edgeSChema,
  floor: edgeSChema,
  walls: Yup.array(edgeSChema),
});

const FormHeatTransfer: React.FC = () => {
  const dispatch = useDispatch();

  const { ceiling, floor, walls } = useSelector((state: ApplicationState) => state.room.data.heatTransfer);
  const initialValues = {
    ceiling,
    floor,
    walls,
  };

  const [materialOptions, setMaterialOptions] = useState([]);

  useEffect(() => {
    const parsedOptions = materialJson.map((material) => ({
      value: material.id,
      label: material.name,
    }));
    setMaterialOptions(parsedOptions);
  }, []);

  const onmaterialChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const materialId = +e.target.value;
    setFieldValue(e.target.name, materialId);

    const reference = e.target.getAttribute('data-ref');
    const thickness = getThicknessByMaterial(materialId);
    setFieldValue(reference, thickness);
  };

  const onInsolationChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any, temperature: number) => {
    const insolation = e.target.checked;
    const reference = e.target.parentElement.getAttribute('data-ref');
    const insolationTemperature = insolation ? temperature + 5 : temperature - 5;
    setFieldValue(reference, insolationTemperature);
  };

  const parseHeatTransferValues = (heatTransfer) => {
    if (heatTransfer.ceiling === null || heatTransfer.floor === null || heatTransfer.walls === null) {
      return INITIAL_STATE_HEAT_TRANSFER;
    }

    return {
      ceiling: {
        ...heatTransfer.ceiling,
        temperature: parseValue2Decimal(heatTransfer.ceiling?.temperature),
      },
      floor: {
        ...heatTransfer.floor,
        temperature: parseValue2Decimal(heatTransfer.floor?.temperature),
      },
      walls: heatTransfer.walls?.map((wall) => ({
        ...wall,
        temperature: parseValue2Decimal(wall?.temperature),
      })),
    };
  };

  return (
    <Container>
      <Formik
        initialValues={parseHeatTransferValues(initialValues)}
        validationSchema={schema}
        validateOnChange={false}
        enableReinitialize
        onSubmit={() => {}}
        validate={async (values) => {
          const isFormValid = await schema.isValid(values);
          if (isFormValid) {
            dispatch(storeHeatTransfer(values));
          }
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form className="form print-grid">
            <Box>
              <Text className="title"> Teto </Text>
              <Stack direction={['column', 'column', 'row']} spacing={10}>
                <Box className="field-wrapper">
                  <Field
                    name="ceiling.material.id"
                    component={Select}
                    options={materialOptions}
                    label="Tipo do Material"
                    value={values.ceiling.material.id}
                    data-ref="ceiling.thickness"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      onmaterialChange(e, setFieldValue);
                    }}
                  />
                </Box>
                <Box className="field-wrapper">
                  <Field
                    name="ceiling.thickness"
                    component={Input}
                    type="number"
                    label="Espessura (mm)"
                    value={values.ceiling.thickness}
                  />
                </Box>
                <Box className="field-wrapper">
                  <Field
                    name="ceiling.incidentSolarRadiation"
                    component={CheckBox}
                    label="Insolação"
                    checked={values.ceiling.incidentSolarRadiation}
                    data-ref="ceiling.temperature"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      onInsolationChange(e, setFieldValue, +values.ceiling.temperature);
                    }}
                  />
                </Box>
                <Box className="field-wrapper">
                  <Field
                    name="ceiling.temperature"
                    component={Input}
                    type="number"
                    label="Temperatura (ºC)"
                    value={values.ceiling.temperature}
                  />
                </Box>
              </Stack>
            </Box>

            <Box>
              <Text className="title"> Piso </Text>
              <Stack direction={['column', 'column', 'row']} spacing={10}>
                <Box className="field-wrapper">
                  <Field
                    name="floor.material.id"
                    component={Select}
                    options={materialOptions}
                    label="Tipo do Material"
                    value={values.floor.material.id}
                    data-ref="floor.thickness"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      onmaterialChange(e, setFieldValue);
                    }}
                  />
                </Box>
                <Box className="field-wrapper">
                  <Field
                    name="floor.thickness"
                    component={Input}
                    type="number"
                    label="Espessura (mm)"
                    value={values.floor.thickness}
                  />
                </Box>
                <Box className="field-wrapper" />
                <Box className="field-wrapper">
                  <Field
                    name="floor.temperature"
                    component={Input}
                    type="number"
                    label="Temperatura (ºC)"
                    value={values.floor.temperature}
                  />
                </Box>
              </Stack>
            </Box>

            {values.walls.map(({ ...wall }: Edge, index: number) => (
              <Box key={String(index)}>
                <Text className="title"> Parede #{index + 1} </Text>
                {/* {index + 1 === 1 && (
                  <Box mr={0}>
                    <ChakraButton className="button-remove">
                      <Text className="button-remove__text">Replicar configuracao</Text>
                    </ChakraButton>
                  </Box>
                )} */}
                {/* {index + 1 === 1 && (
                  <Box mr={0}>
                    <ChakraButton className="button-remove" onClick={handleItemRemoval} leftIcon={<MdDeleteForever />}>
                      <Text className="button-remove__text">Remover Produto</Text>
                    </ChakraButton>
                  </Box>
                )} */}{' '}
                <Stack direction={['column', 'column', 'row']} spacing={10}>
                  <Box className="field-wrapper">
                    <Field
                      name={`walls[${index}].material.id`}
                      component={Select}
                      options={materialOptions}
                      label="Tipo do Material"
                      value={wall.material.id}
                      data-ref={`walls[${index}].thickness`}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onmaterialChange(e, setFieldValue);
                      }}
                    />
                  </Box>
                  <Box className="field-wrapper">
                    <Field
                      name={`walls[${index}].thickness`}
                      component={Input}
                      type="number"
                      label="Espessura (mm)"
                      value={wall.thickness}
                    />
                  </Box>
                  <Box className="field-wrapper">
                    <Field
                      name={`walls[${index}].incidentSolarRadiation`}
                      component={CheckBox}
                      label="Insolação"
                      checked={wall.incidentSolarRadiation}
                      data-ref={`walls[${index}].temperature`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        onInsolationChange(e, setFieldValue, +wall.temperature);
                      }}
                    />
                  </Box>
                  <Box className="field-wrapper">
                    <Field
                      name={`walls[${index}].temperature`}
                      component={Input}
                      type="number"
                      label="Temperatura (ºC)"
                      value={wall.temperature}
                    />
                  </Box>
                </Stack>
              </Box>
            ))}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FormHeatTransfer;
