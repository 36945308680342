import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const AccordionContainer = styled.div`
  margin: 40px 0;

  h2 {
    font-size: 14px;
    color: ${colors.secondary};
    font-weight: 500;
  }

  .accordion {
    .accordion__item {
      margin-bottom: 15px;
      box-shadow: 0px 2px 10px -4px rgb(0 0 0 / 30%);
      border-radius: 4px;

      .accordion__button {
        height: 100%;
        padding: 25px;
        text-transform: uppercase;
        max-height: 70px;

        &.hidden {
          display: none;
        }
      }
    }

    .accordion__panel {
      padding: 25px 25px;
    }
  }
`;
