/* eslint-disable no-restricted-syntax */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { Form, Field, Formik } from 'formik';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Box,
  Button as ChakraButton,
  Badge,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Skeleton,
  useBreakpointValue,
  Text,
  IconButton,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Progress,
  VStack,
  Wrap,
  Flex,
  GridItem,
  Spacer,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import { useDispatch } from 'react-redux';
import { MdFilterList, MdMoreVert } from 'react-icons/md';
import { Container } from './Projects_Styles';
import { colors } from '~/styles/colors';
import { Button } from '~/components/Form/Button/Button_Styles';
import ProjectService from '~/store/ducks/Project/services';
import { toastError, toastSuccess } from '~/helpers/toast';
import { Project } from '~/store/ducks/Project/types';
import { storeProjectData } from '~/store/ducks/Project/actions';
import Yup from '~/helpers/validations';
import Input from '~/components/Form/Input';
import SelectGivenOptions from '~/components/Form/Select/SelectGivenOptions';
import { states } from '~/assets/mocks/states';
import Pagination from '~/components/Pagination';
import { filterObjectEmptyValues } from '~/helpers/functions';

const schema = Yup.object().shape({
  name: Yup.string().max(60).required().nullable(),
  customer: Yup.object().shape({
    name: Yup.string().max(60).nullable(),
    email: Yup.string().email().nullable(),
    cnpj: Yup.string().max(19).nullable(),
    phone: Yup.string().max(15).nullable(),
    address: Yup.object().shape({
      street: Yup.string().max(120).nullable(),
      state: Yup.string().nullable(),
      city: Yup.string().nullable(),
      country: Yup.string(),
      zipcode: Yup.string().max(9).nullable(),
    }),
    segment: Yup.string().nullable(),
  }),
});

const schemaSearch = Yup.object().shape({
  projectName: Yup.string().nullable(),
  status: Yup.string().nullable(),
  customerName: Yup.string().nullable(),
  startDate: Yup.string().nullable(),
  endDate: Yup.string().nullable(),
});

const Projects: React.FC = () => {
  const queryParams: any = new URLSearchParams(useLocation().search);
  const queryParamsPage = queryParams.get('page');
  const queryParamsSize = queryParams.get('size');
  const [searchParams, setSearchParams] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstPageLink, setFirstPageLink] = useState(null);
  const [previousPageLink, setPreviousPageLink] = useState(null);
  const [nextPageLink, setNextPageLink] = useState(null);
  const [lastPageLink, setLastPageLink] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [progressBar, setProgressBar] = useState({ enabled: false, project: null });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef();

  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const initialValues = {
    name: '',
    customer: {
      name: '',
      email: '',
      cnpj: '',
      phone: '',
      address: {
        street: '',
        state: '',
        city: '',
        country: 'Brasil',
        zipcode: '',
      },
      segment: '',
    },
  };

  const initialValuesSearch = {
    projectName: searchParams?.projectName || '',
    status: searchParams?.status || '',
    customerName: searchParams?.customerName || '',
    startDate: searchParams?.startDate || '',
    endDate: searchParams?.endDate || '',
  };

  const handleResponseHeaderLinks = (headerLinksString: string): void => {
    setFirstPageLink(null);
    setPreviousPageLink(null);
    setNextPageLink(null);
    setLastPageLink(null);

    try {
      const headerLinks = headerLinksString.replace(/[<]*[>]*[\s]*(rel)*(=")*(")*/g, '').split(',');
      headerLinks.forEach((link) => {
        const parsedLink = link.split(';')[0].replace(/[\D\d]*\/projects\?/g, '');
        const refLink = link.split(';')[1];

        switch (refLink) {
          case 'first':
            setFirstPageLink(parsedLink);
            break;
          case 'prev':
            setPreviousPageLink(parsedLink);
            break;
          case 'next':
            setNextPageLink(parsedLink);
            break;
          case 'last':
            setLastPageLink(parsedLink);
            break;
          default:
            break;
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  // TODO: syncronize url params with list of projects
  const fetchUrlParams = (): {} => {
    let parsedParams = {};

    for (const value of queryParams.entries()) {
      const paramEntry = {
        [value[0]]: value[1],
      };
      parsedParams = { ...parsedParams, ...paramEntry };
    }

    setSearchParams(parsedParams);
    return parsedParams;
  };

  const updateUrl = (requestParams) => {
    const urlParams = Object.keys(requestParams)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(requestParams[k])}`)
      .join('&');
    history.push(`?${urlParams}`);
  };

  const fetchProjects = async (page?, size = 10, params?) => {
    const requestParams = {
      page: page || queryParamsPage,
      size: size || queryParamsSize,
      ...params,
    };

    try {
      setLoading(true);
      const response = await ProjectService.getProjects(requestParams);
      handleResponseHeaderLinks(response.headers.link);
      setProjects([...response.data]);
    } catch (err) {
      toastError('project', 'Erro ao recuperar projetos.');
      console.error(err);
    } finally {
      setLoading(false);
      if (page) updateUrl(requestParams);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchUrlParams();

    return () => {
      setProjects(null);
    };
  }, []);

  const handleModalClose = () => {
    setSelectedProject(null);
    onClose();
  };

  const updateProjectInfo = (project) => {
    projects.map((p: Project) => {
      if (p.id === project.id) {
        return { ...p, name: project.name };
      }
      return p;
    });
  };

  const handleSubmit = async (values) => {
    const { id } = values;
    const body = {
      ...values,
      customer: {
        ...values.customer,
        cnpj: values.customer?.cnpj?.replace(/\D/g, ''),
        phone: values.customer?.phone?.replace(/\D/g, ''),
        address: {
          ...values.customer?.address,
          zipcode: values.customer?.address?.zipcode?.replace(/\D/g, ''),
        },
        segment: values.customer?.segment,
      },
    };

    try {
      setButtonLoading(true);
      if (id) {
        const response = await ProjectService.updateProject(id, body);
        updateProjectInfo(response.data);
        setProjects([
          ...projects.map((project) => {
            if (project.id === response.data.id) {
              return response.data;
            }
            return project;
          }),
        ]);
        toastSuccess('project', 'Projeto atualizado!');
      } else {
        const response = await ProjectService.createProject(body);
        setProjects([response.data, ...projects]);
        toastSuccess('project', 'Novo projeto criado!');
      }
    } catch (err) {
      toastError('project', id ? 'Erro ao editar projeto.' : 'Erro ao criar projeto.');
      console.error(err);
    } finally {
      setButtonLoading(false);
    }

    handleModalClose();
  };

  const checkStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return { color: 'red', text: 'PENDENTE' };
      default:
        return { color: 'green', text: 'CONCLUÍDO' };
    }
  };

  const handleSelectProject = useCallback(
    (project) => {
      dispatch(storeProjectData(project));
      history.push(`/projects/${project.id}/rooms`);
    },
    [history]
  );

  const handleProjectEdit = (project: Project) => {
    setSelectedProject(project);
    onOpen();
  };

  const handleProjectCopy = (project: Project) => {
    setSelectedProject(project);
    setIsAlertOpen(true);
  };

  const submitProjectCopy = async (project: Project) => {
    const { id } = project;

    try {
      setProgressBar({ enabled: true, project });
      await ProjectService.copyProject(id);
      toastSuccess('project', 'Projeto copiado com sucesso.');
    } catch (err) {
      toastError('project', 'Erro ao criar cópia de projeto.');
      console.error(err);
    } finally {
      setProgressBar({ enabled: false, project: null });
      fetchProjects();
    }
  };

  const onCancelProjectCopy = () => {
    setIsAlertOpen(false);
    setSelectedProject(null);
  };

  const onConfirmProjectCopy = () => {
    setIsAlertOpen(false);
    submitProjectCopy(selectedProject);
  };

  const handleSubmitSearch = async (values) => {
    fetchProjects(1, 10, values);
  };

  const handleResetForm = (resetForm, setValues) => {
    resetForm();
    fetchProjects(1, 10, null);
    setValues({
      projectName: '',
      status: '',
      customerName: '',
      startDate: '',
      endDate: '',
    });
  };

  return (
    <Container>
      <HStack justify="left">
        <Button $primary onClick={() => onOpen()}>
          Novo Projeto
        </Button>
      </HStack>
      <HStack justify="left" mt={10}>
        <Text color={colors.primary}>Busca de Projetos</Text>
      </HStack>
      <HStack justify={['flex-start']} mt={4}>
        <Formik
          initialValues={initialValuesSearch}
          enableReinitialize
          validationSchema={schemaSearch}
          validateOnChange={false}
          onSubmit={(values) => {
            handleSubmitSearch(filterObjectEmptyValues(values));
          }}
        >
          {({ values, submitForm, resetForm, setValues }) => (
            <Form className="form">
              <SimpleGrid columns={[1, 3, 6]} width="100%" spacing={[0, 5]}>
                <Field
                  name="projectName"
                  component={Input}
                  type="text"
                  size="xs"
                  label="Nome do Projeto"
                  value={values.projectName || ''}
                />
                <Field
                  name="customerName"
                  component={Input}
                  type="text"
                  size="xs"
                  label="Nome do Cliente"
                  value={values.customerName || ''}
                />
                <Field
                  name="status"
                  component={SelectGivenOptions}
                  type="text"
                  size="xs"
                  label="Status"
                  value={values.status || ''}
                  options={[
                    {
                      value: 'PENDING',
                      label: 'Pendente',
                    },
                    {
                      value: 'SENT',
                      label: 'Enviado',
                    },
                    {
                      value: 'IN_REVIEW',
                      label: 'Em Revisão',
                    },
                  ].map(({ value, label }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                />
                <Field
                  name="startDate"
                  component={Input}
                  type="date"
                  size="xs"
                  label="Criado a partir de"
                  value={values.startDate || ''}
                />
                <Field
                  name="endDate"
                  component={Input}
                  type="date"
                  size="xs"
                  label="até"
                  value={values.endDate || ''}
                />
                <VStack justify="end" align={['stretch', 'stretch', 'center']}>
                  <Button
                    leftIcon={<MdFilterList size={18} />}
                    type="button"
                    variant="ghost"
                    $primary
                    onClick={submitForm}
                  >
                    Filtrar
                  </Button>
                  <ChakraButton
                    type="button"
                    variant="link"
                    size="sm"
                    fontWeight={400}
                    fontSize="12"
                    color={colors.gray200}
                    onClick={() => handleResetForm(resetForm, setValues)}
                  >
                    Limpar Filtros
                  </ChakraButton>
                </VStack>
              </SimpleGrid>
            </Form>
          )}
        </Formik>
      </HStack>
      <Box>
        <Skeleton isLoaded={!loading}>
          <Box className="elgin__box" overflowX="auto" mt={10}>
            <Table size="md" variant="simple" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th color={colors.secondary}>Ref.</Th>
                  <Th color={colors.secondary}>Nome</Th>
                  <Th color={colors.secondary}>Cliente</Th>
                  <Th color={colors.secondary}>Status</Th>
                  <Th color={colors.secondary}> Ações </Th>
                </Tr>
              </Thead>
              <Tbody>
                {projects?.map((project: Project) => (
                  <Tr key={project.id}>
                    {project.id === progressBar.project?.id ? (
                      <Td colSpan={5} h={65}>
                        <Progress size="sm" isIndeterminate colorScheme="twitter" borderRadius={2} />
                      </Td>
                    ) : (
                      <>
                        <Td fontSize={14} color={colors.gray100}>
                          {project.id}
                        </Td>
                        <Td>
                          <ChakraButton
                            type="button"
                            variant="link"
                            size="sm"
                            fontWeight={400}
                            color={colors.primary}
                            onClick={() => handleSelectProject(project)}
                          >
                            {project.name}
                          </ChakraButton>
                        </Td>
                        <Td>
                          <Text fontSize={14} color={colors.gray100}>
                            {project?.customer.name}
                          </Text>
                        </Td>
                        <Td>
                          <Badge colorScheme={checkStatus(project.status).color} borderRadius="4px" fontSize="0.75em">
                            {checkStatus(project.status).text}
                          </Badge>
                        </Td>
                        <Td>
                          <Menu autoSelect={false} strategy="fixed">
                            <MenuButton
                              as={IconButton}
                              size="sm"
                              color={colors.secondary}
                              aria-label="Options"
                              icon={<MdMoreVert size={18} />}
                              variant="outline"
                            />
                            <MenuList>
                              <MenuItem onClick={() => handleProjectEdit(project)}>
                                <Text fontSize={14} fontWeight={500} color={colors.gray100}>
                                  Editar Dados
                                </Text>
                              </MenuItem>
                              <MenuDivider />
                              <MenuItem onClick={() => handleProjectCopy(project)}>
                                <Text fontSize={14} fontWeight={500} color={colors.gray100}>
                                  Criar cópia
                                </Text>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            fetchNewItems={fetchProjects}
            firstPageLink={firstPageLink}
            previousPageLink={previousPageLink}
            nextPageLink={nextPageLink}
            lastPageLink={lastPageLink}
          />
        </Skeleton>
      </Box>
      <Modal
        size={useBreakpointValue(['xs', 'md', 'xl', '3xl'])}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleModalClose}
      >
        <ModalOverlay />
        <ModalContent className="modal">
          <ModalHeader color={colors.secondary} fontSize={16}>
            {selectedProject ? 'Editar Projeto' : 'Novo Projeto'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={selectedProject || initialValues}
              validationSchema={schema}
              validateOnChange={false}
              onSubmit={async (values) => {
                handleSubmit(values);
              }}
            >
              {({ values, submitForm }) => (
                <Form className="form">
                  <SimpleGrid columns={[1, 2]} spacing={5} mb={5}>
                    <Field name="name" component={Input} type="text" label="Nome do Projeto *" value={values.name} />
                  </SimpleGrid>
                  <SimpleGrid columns={[1, 2]} spacing={5}>
                    <Field
                      name="customer.name"
                      component={Input}
                      type="text"
                      label="Cliente"
                      value={values.customer?.name}
                    />
                    <Field
                      name="customer.cnpj"
                      component={Input}
                      type="text"
                      label="CNPJ"
                      value={values.customer?.cnpj}
                    />
                    <Field
                      name="customer.phone"
                      component={Input}
                      type="text"
                      label="Telefone"
                      value={values.customer?.phone}
                    />
                    <Field
                      name="customer.email"
                      component={Input}
                      type="text"
                      label="E-mail"
                      value={values.customer?.email}
                    />
                    <Field
                      name="customer.address.street"
                      component={Input}
                      type="text"
                      label="Endereço de Instalação"
                      value={values.customer?.address?.street}
                    />
                    <Field
                      name="customer.address.country"
                      component={SelectGivenOptions}
                      type="text"
                      label="País"
                      disabled
                      value={values.customer?.address?.country}
                      options={['Brasil'].map((item: string, index: number) => (
                        <option value={item} key={String(index)}>
                          {item}
                        </option>
                      ))}
                    />
                    <Field
                      name="customer.address.state"
                      component={SelectGivenOptions}
                      type="text"
                      label="Estado"
                      value={values.customer?.address?.state}
                      options={states.map(({ sigla, nome }) => (
                        <option value={sigla} key={String(sigla)}>
                          {nome}
                        </option>
                      ))}
                    />
                    <Field
                      name="customer.address.city"
                      component={Input}
                      type="text"
                      label="Cidade"
                      value={values.customer?.address?.city}
                    />
                    <Field
                      name="customer.address.zipcode"
                      component={Input}
                      type="text"
                      label="CEP"
                      value={values.customer?.address?.zipcode}
                    />
                    <Field
                      name="customer.segment"
                      component={SelectGivenOptions}
                      type="text"
                      label="Segmento de Negócio"
                      value={values.customer?.segment}
                      options={[
                        'Revenda',
                        'Câmaras Industriais',
                        'Supermercado',
                        'Exportação',
                        'Fabricantes de Equipamentos',
                      ].map((item: string) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    />
                  </SimpleGrid>
                  <HStack justify="flex-end">
                    <Button $default mr={3} onClick={handleModalClose}>
                      Cancelar
                    </Button>
                    <Button isLoading={buttonLoading} disabled={false} $primary mr={3} onClick={submitForm}>
                      Confirmar
                    </Button>
                  </HStack>
                </Form>
              )}
            </Formik>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={onCancelProjectCopy} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" color={colors.gray100} fontWeight="bold">
              Copiar Projeto
            </AlertDialogHeader>

            <AlertDialogBody color={colors.gray100}>
              Tem certeza que deseja copiar o projeto <i>{selectedProject?.name}</i>?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button $default ref={cancelRef} onClick={onCancelProjectCopy}>
                Cancelar
              </Button>
              <Button $primary onClick={onConfirmProjectCopy} ml={3}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default Projects;
