/* eslint-disable no-console */
import { Skeleton, SimpleGrid, Box, HStack } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import SelectGivenOptions from '~/components/Form/Select/SelectGivenOptions';
import { filterObjectEmptyValues } from '~/helpers/functions';
import { toastError } from '~/helpers/toast';
import Yup from '~/helpers/validations';
import EquipmentService from '~/store/ducks/Equipment/services';

type CondensingUnitComparisonFormProps = {
  handleCondensingSearch: (any) => void;
  searchData: any;
};

const CondensingUnitComparisonForm: React.FC<CondensingUnitComparisonFormProps> = ({
  handleCondensingSearch,
  searchData,
}) => {
  const [refrigerantFluids, setRefrigerantFluids] = useState(null);
  const [loading, setLoading] = useState(false);

  const schemaFilter = Yup.object().shape({
    ambientTemperature: Yup.number().nullable().required(),
    evaporationTemperature: Yup.number().nullable().required(),
    loadLoss: Yup.number().nullable().required(),
    refrigerantFluid: Yup.string().nullable().required(),
    unitCapacity: Yup.number().nullable().required(),
  });

  const initialValuesFilter = {
    ambientTemperature: '',
    refrigerantFluid: '',
    evaporationTemperature: '',
    loadLoss: '',
    unitCapacity: '',
  };

  const getRefrigerantFluids = async () => {
    try {
      setLoading(true);
      const response = await EquipmentService.getRefrigerantFluids();
      setRefrigerantFluids(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastError('project', 'Erro ao recuperar fluídos.');
      console.error(err);
    }
  };

  // TODO
  useEffect(() => {
    getRefrigerantFluids();

    return () => setRefrigerantFluids(null);
  }, []);

  return (
    <Formik
      initialValues={searchData || initialValuesFilter}
      isInitialValid
      validationSchema={schemaFilter}
      validateOnChange={false}
      onSubmit={(values) => {
        handleCondensingSearch(filterObjectEmptyValues(values));
      }}
    >
      {({ values, submitForm }) => (
        <Skeleton isLoaded={!loading}>
          <Form className="form">
            <SimpleGrid columns={[1, 2]} spacing={[0, 5]}>
              <Box>
                <Field
                  size="xs"
                  name="unitCapacity"
                  component={Input}
                  type="number"
                  label="Capacidade (Kcal/h)"
                  value={values.unitCapacity || ''}
                />
                <Field
                  size="xs"
                  name="evaporationTemperature"
                  component={Input}
                  type="number"
                  label="Temp. de Evaporação (°C)"
                  value={values.evaporationTemperature || ''}
                />
                <Field
                  size="xs"
                  name="loadLoss"
                  component={Input}
                  type="number"
                  label="Perda de Carga (K)"
                  value={values.loadLoss || ''}
                />
              </Box>
              <Box>
                <Field
                  size="xs"
                  name="ambientTemperature"
                  component={SelectGivenOptions}
                  label="Temp. Ambiente (°C)"
                  value={values.ambientTemperature || ''}
                  options={['32', '35', '38', '43'].map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="refrigerantFluid"
                  component={SelectGivenOptions}
                  label="Fluído Refrigerante"
                  value={values.refrigerantFluid || ''}
                  options={refrigerantFluids?.map(({ id, code }) => (
                    <option value={code} key={id}>
                      {code}
                    </option>
                  ))}
                />
              </Box>
            </SimpleGrid>
            <HStack justify="flex-end" mt={4}>
              <Button leftIcon={<MdSearch size={18} />} type="button" $primary onClick={submitForm}>
                Pesquisar
              </Button>
            </HStack>
          </Form>
        </Skeleton>
      )}
    </Formik>
  );
};

export default CondensingUnitComparisonForm;
