import styled from 'styled-components';
import { colors } from '~/styles/colors';

export const Container = styled.div`
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 40px; */
  color: ${colors.gray200};
  text-shadow: 1px 1px ${colors.gray200};
  font-size: 20px;
  /* background: linear-gradient(
    to right,
    ${colors.secondary} 0%,
    ${colors.secondary} 25%,
    ${colors.primary} 75%,
    ${colors.primary} 100%
  ); */
`;
