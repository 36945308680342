import React, { ChangeEvent } from 'react';
import { FormControl, FormErrorMessage, FormLabel, Select as ChakraSelect } from '@chakra-ui/react';
import { FieldAttributes, useField } from 'formik';

import { SelectWrapper } from './Select_Styles';
import { colors } from '~/styles/colors';

type SelectProps = {
  form: any;
  field: FieldAttributes<any>;
  children: any;
  name: string;
  label: string;
  onChange?: ChangeEvent<HTMLInputElement>;
  options: any;
  value: string;
  notNull?: boolean;
  size?: string;
};

const SelectGivenOptions = ({ ...props }: SelectProps) => {
  const { form, field, label, value, options, notNull, size, ...rest } = props;
  const { name } = field;
  const [, { error, touched }] = useField(name);

  return (
    <SelectWrapper>
      <FormControl className="formControl" isInvalid={!!error && touched}>
        <FormLabel className={size || 'sm'} color={colors.gray100} htmlFor={name}>
          {label}
        </FormLabel>
        <ChakraSelect marginTop="-2px" size={size || 'sm'} data-testid={name} {...field} {...rest} value={value}>
          {notNull || <option value="">Selecione</option>}
          {options}
        </ChakraSelect>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </SelectWrapper>
  );
};

export default SelectGivenOptions;
