/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { calcVolume } from '~/helpers/functions';
import api from '~/services/api';
import { goodsItemInitialState } from '~/store/ducks/Goods/types';
import { store } from '~/store/store';

class GoodsService {
  static getFieldSuggestion = (
    property: string,
    roomTemperature: number,
    height: number,
    volume: number,
    itemsQuantity?: number
  ): number => {
    const functions: any = {
      palletCapacity: () => (height <= 4 ? 30 : 800),
      storageCapacity: () => +((height <= 4 ? 380 * volume * 0.65 : 110 * volume) / itemsQuantity).toFixed(2),
      checkinTemperature: () => roomTemperature + 5,
      checkoutTemperature: () => roomTemperature,
    };

    if (!functions[property]) return;

    return functions[property]();
  };

  static calcDailyMovement = (stockLoad: number): number => +(stockLoad * 0.2).toFixed(2);

  static getSuggestion = (goods: any, isRemove?: boolean) => {
    const { length, width, height, roomTemperature } = store.getState().room.data;
    const volume = calcVolume(length, width, height);
    const suggestedItem: any = goodsItemInitialState;
    const itemsQuantity = isRemove ? goods.items.length : goods.items.length + 1;

    for (const property in suggestedItem) {
      const suggestion = GoodsService.getFieldSuggestion(property, roomTemperature, height, volume, itemsQuantity);
      if (!suggestion) continue;
      suggestedItem[property] = suggestion;
    }

    suggestedItem.dailyMovement = GoodsService.calcDailyMovement(suggestedItem.storageCapacity);
    return suggestedItem;
  };

  static getGroups = () => api.get(`/v1/goods/groups`);

  static getGoods = () => api.get(`/v1/goods`);

  static getGroupGoods = (id: number) => api.get(`/v1/goods/groups/${id}/goods`);
}

export default GoodsService;
