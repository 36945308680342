import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Button as ChakraButton } from '@chakra-ui/react';
import { colors } from '~/styles/colors';
import { CondensingUnitSKU } from '~/store/ducks/Equipment/types';
import { parseValue2Decimal } from '~/helpers/functions';

type CondensingUnitTableSKUProps = {
  tableRef: any;
  skus: CondensingUnitSKU[];
  selectedSKU: any;
  handleSelectSKU: any;
};

const CondensingUnitTableSKU: React.FC<CondensingUnitTableSKUProps> = ({
  tableRef,
  skus,
  selectedSKU,
  handleSelectSKU,
}) => (
  <Table ref={tableRef} className="table-sku" size="sm" mt={5} colorScheme="blackAlpha">
    <Thead>
      <Tr>
        <Th>SKU</Th>
        <Th>Altura</Th>
        <Th>Capacidade</Th>
        <Th>Capacidade Nominal</Th>
        <Th>Comprimento</Th>
        <Th>Consumo</Th>
        <Th>Tipo Compressor</Th>
      </Tr>
    </Thead>
    <Tbody>
      {skus.map((sku: CondensingUnitSKU, index: number) => (
        <Tr key={String(sku.id)} className={`table-sku__row ${sku.id === selectedSKU?.id ? 'selected' : ''}`}>
          <Td>
            <ChakraButton
              type="button"
              variant="link"
              size="sm"
              fontWeight={400}
              color={colors.primary}
              onClick={() => {
                handleSelectSKU(sku);
              }}
            >
              {sku.sku}
            </ChakraButton>
          </Td>
          <Td>{sku.heigth}</Td>
          <Td>{parseValue2Decimal(sku.unitCapacity)}</Td>
          <Td>{parseValue2Decimal(sku.nominalUnitCapacity)}</Td>
          <Td>{sku.length}</Td>
          <Td>{parseValue2Decimal(sku.consumedPower)}</Td>
          <Td>{sku.compressorType}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

export default CondensingUnitTableSKU;
