/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { DOOR_INITIAL_STATE } from '~/store/ducks/Door/reducer';
import { store } from '~/store/store';

const getFieldSuggestion = (state: any, property: string, roomTemperature: number, roomHeight: number): any => {
  const functions: any = {
    count: () => 1,
    width: () => (roomHeight <= 4 ? 1 : 2),
    height: () => (roomHeight <= 4 ? 2 : 4),
    adjacentAmbientTemperature: () => (roomTemperature >= 0 ? 30 : 10),
    adjacentAmbientRelativeHumidity: () => 0,
    internalRelativeHumidity: () => 85,
    openCloseDuration: () => (roomHeight <= 4 ? 3 : 15),
    openDuration: () => 0.5,
    airExchangeRate: () => 0,
  };

  if (!functions[property]) {
    return state[property];
  }

  return functions[property]();
};

const getRelativeHumidity = (adjacentAmbientTemperature: number): number =>
  adjacentAmbientTemperature >= 30 ? 60 : 85;

class DoorService {
  static getOpeningFrequency = (count: number): number => {
    const { goods } = store.getState().room.data;

    const of = goods.reduce((acc, item) => {
      const { dailyMovement, palletCapacity, processingTime } = item;
      const calc = (dailyMovement / palletCapacity / processingTime / count) * 2;
      return acc + calc;
    }, 0);

    return Math.ceil(of);
  };

  static getSuggestion = (state: any, isRemove?: boolean) => {
    const { height, roomTemperature } = store.getState().room.data;
    const suggestedDoor: any = DOOR_INITIAL_STATE;
    const totalCount = state.doors
      ? state.doors.reduce((acc, item) => acc + item.count, isRemove ? 0 : suggestedDoor.count)
      : 0;

    for (const property in suggestedDoor) {
      suggestedDoor[property] = getFieldSuggestion(suggestedDoor, property, roomTemperature, height);
    }

    suggestedDoor.openingFrequency = DoorService.getOpeningFrequency(totalCount);
    suggestedDoor.adjacentAmbientRelativeHumidity = getRelativeHumidity(suggestedDoor.adjacentAmbientTemperature);
    return suggestedDoor;
  };
}

export default DoorService;
