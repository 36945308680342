/* eslint-disable no-console */
import React, { useRef, useState } from 'react';
import { Text, Box, Skeleton, Stack } from '@chakra-ui/react';
import TableStriped from '~/components/TableStriped';
import { StepSKUContainer } from '~/components/Equipments/StepSKU/StepSKU_Styles';
import { Configuration, EquipmentInterfaceTypes, EquipmentSystem } from '~/store/ducks/Equipment/types';
import EquipmentService from '~/store/ducks/Equipment/services';
import { toastError } from '~/helpers/toast';
import EvaporatorOptionals from '~/components/Equipments/StepSKU/EvaporatorOptionals';
import EvaporatorTableSKU from '~/components/Equipments/StepSKU/EvaporatorTableSKU';
import CondensingUnitOptionals from '~/components/Equipments/StepSKU/CondensingUnitOptionals';
import CondensingUnitTableSKU from '~/components/Equipments/StepSKU/CondensingUnitTableSKU';
import { handlePowerSupply } from '~/helpers/functions';

type StepSKUProps = {
  equipmentType: string;
  selectedConfiguration?: Configuration;
  selectedModel: any;
  searchData: any;
  system: EquipmentSystem;
  selectSKU: ({}) => void;
};

const StepSKU: React.FC<StepSKUProps> = ({
  equipmentType,
  selectSKU,
  selectedConfiguration,
  selectedModel,
  searchData,
  system,
}) => {
  const [loading, setLoading] = useState(false);
  const [skus, setSKUs] = useState([]);
  const [selectedSKU, setSelectedSKU] = useState(null);
  const tableRef = useRef(null);

  const handleSelectSKU = (sku) => {
    setSelectedSKU(sku);
    selectSKU(sku);
  };

  const parseRequestBody = (values, equipType: string) => {
    const {
      count,
      // defrost,
      fanDiameter,
      finsPerInch,
      numberOfFans,
      productLine,
      powerSupply,
      evaporatorDt,
      ambientTemperature,
      capacity,
      evaporationTemperature,
      loadLoss,
      compressorType,
      hotGasDefrost,
      capacityControl,
      compressor,
      logo,
      chamber,
      condensationControl,
      numberOfCompressors,
    } = searchData;
    const { model } = selectedModel;

    const dTResponse = EquipmentService.getOptionals(
      'evaporators',
      selectedModel.line === 'Todos' ? null : selectedModel.line
    );

    if (equipType === EquipmentInterfaceTypes.EVAPORATOR) {
      const { gicle, valveKit, orifice } = dTResponse.data;

      // Gets to for delta temperature difference
      const dTIndex = dTResponse.data.delta_temperature_difference.findIndex((x) => x[0].includes(evaporatorDt));
      const dT = dTIndex >= 0 ? dTResponse.data.delta_temperature_difference[dTIndex][1][0] : 0;

      console.log('sku-defrost => ', selectedModel.defrost);
      console.log('sku-searchData => ', searchData);

      return {
        evaporator: {
          evaporationTemperature: selectedConfiguration?.room.roomTemperature - evaporatorDt,
          refrigerantFluid: system?.refrigerantFluid.code,
          evaporatorDt: dT,
          unitCapacity: selectedConfiguration?.room.heatLoad.totalKcalh / count,
          model,
          defrost: [selectedModel.defrost],
          fanDiameter,
          finsPerInch,
          numberOfFans,
          productLine: selectedModel.line === 'Todos' ? null : selectedModel.line,
          gicle,
          valveKit,
          orifice,
          ...handlePowerSupply(powerSupply),
          ...values,
        },
      };
    }

    return {
      condensingUnit: {
        evaporationTemperature,
        ambientTemperature: +ambientTemperature,
        refrigerantFluid: system?.refrigerantFluid.code,
        unitCapacity: capacity / count,
        model,
        loadLoss,
        compressorType,
        hotGasDefrost,
        capacityControl,
        compressor,
        logo,
        chamber,
        condensationControl,
        numberOfCompressors,
        productLine: productLine === 'Todos' ? null : productLine,
        ...handlePowerSupply(powerSupply),
        ...values,
      },
    };
  };

  const handleSKUSearch = async (values, equipType: string) => {
    const body = parseRequestBody(values, equipType);

    try {
      setLoading(true);
      const response = await EquipmentService.getSKUs(body);
      setSKUs(response.data);
      setLoading(false);
      tableRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (err) {
      setLoading(false);
      toastError('project', 'Erro ao recuperar SKU`s.');
      console.error(err);
    }
  };

  const renderOptionalsForEquipment = () => {
    console.log('selectedModel => ', selectedModel);
    switch (equipmentType) {
      case EquipmentInterfaceTypes.EVAPORATOR:
        return <EvaporatorOptionals handleSKUSearch={handleSKUSearch} equipmentLine={selectedModel?.line} />;
      default:
        return <CondensingUnitOptionals handleSKUSearch={handleSKUSearch} equipmentLine={selectedModel?.line} />;
    }
  };

  const renderTableKUForEquipment = () => {
    switch (equipmentType) {
      case EquipmentInterfaceTypes.EVAPORATOR:
        return (
          <EvaporatorTableSKU
            handleSelectSKU={handleSelectSKU}
            tableRef={tableRef}
            skus={skus}
            selectedSKU={selectedSKU}
          />
        );
      default:
        return (
          <CondensingUnitTableSKU
            handleSelectSKU={handleSelectSKU}
            tableRef={tableRef}
            skus={skus}
            selectedSKU={selectedSKU}
          />
        );
    }
  };

  return (
    <StepSKUContainer>
      <Text className="modal__select__section--title"> Selecione os Opcionais </Text>
      <Box>{renderOptionalsForEquipment()}</Box>
      <Text className="modal__select__section--title"> Selecione o SKU </Text>
      {skus.length === 0 && <Text className="modal__select__section--info"> Nenhum SKU encontrado. </Text>}
      {loading && (
        <Stack w="100%" mt={10}>
          <Skeleton height="27px" />
          <Skeleton height="27px" />
          <Skeleton height="27px" />
        </Stack>
      )}

      <TableStriped>{!loading && skus.length > 0 && <>{renderTableKUForEquipment()}</>}</TableStriped>
    </StepSKUContainer>
  );
};

export default StepSKU;
