/* eslint-disable no-console */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastError, toastSuccess } from '~/helpers/toast';
import {
  confirmSystem,
  equipmentRequestLoading,
  equipmentRequestSuccess,
  storeInitialData,
  storeSystem,
  storeSystems,
} from '~/store/ducks/Equipment/actions';
import { getEquipmentState } from '~/store/ducks/Equipment/selectors';
import EquipmentService from '~/store/ducks/Equipment/services';
import { EquipmentSystem, EquipmentTypes } from '~/store/ducks/Equipment/types';
import ProjectService from '~/store/ducks/Project/services';

export function* fetchAllSystems(action) {
  const { id: projectId } = action.payload;

  try {
    yield put(equipmentRequestLoading());
    const responseSystems = yield call(EquipmentService.getAllSystems, projectId);
    const responseRooms = yield call(ProjectService.fetchProjectRooms, projectId, 'heatLoadSummary');
    const projectRooms = responseRooms.data;
    const systems = responseSystems.data;

    if (systems.length > 0) {
      yield put(storeInitialData(projectId, projectRooms));
      yield put(storeSystems(projectId, projectRooms, systems));
    } else {
      yield put(storeInitialData(projectId, projectRooms));
    }
  } catch (err) {
    console.error(err);
    toastError('equipment', 'Erro ao recuperar sistemas.');
  } finally {
    yield put(equipmentRequestSuccess());
  }
}

export function* fetchSystem() {
  const state = yield select(getEquipmentState);
  const { id, project } = state;

  try {
    const response = yield call(EquipmentService.getSystemById, project.id, id);
    yield put(storeSystem(response.data));
  } catch (err) {
    console.error(err);
    toastError('equipment', 'Erro ao recuperar sistema.');
  }
}

export function* saveSystem() {
  const state = yield select(getEquipmentState);
  const activeSystemState = state.systems.find((system: EquipmentSystem) => system.active);
  const { active, type, ...rest } = activeSystemState;
  const { project, id } = rest;
  const data = {
    ...rest,
  };

  data.condensingUnit.equipment.unitCapacityDt = data.resultingCapacity;

  let response = null;

  try {
    if (id) {
      console.log('data => ', data);
      response = yield call(EquipmentService.updateSystem, project.id, id, data);
    } else {
      response = yield call(EquipmentService.createSystem, project.id, data);
    }

    // yield put(storeSystem(response.data));
    yield put(confirmSystem());
    toastSuccess('equipment', 'Sistema salvo com sucesso.');
  } catch (err) {
    console.error(err);
    toastError('equipment', 'Erro ao salvar sistema.');
  }
}

export default all([
  takeLatest(EquipmentTypes.SAVE_SYSTEM, saveSystem),
  // takeLatest(EquipmentTypes.CHANGE_ACTIVE_SYSTEM, fetchSystem),
  takeLatest(EquipmentTypes.GET_ALL_SYSTEMS, fetchAllSystems),
]);
