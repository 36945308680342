import { all, put, select, takeLatest } from 'redux-saga/effects';
import { getHeatTransferState } from '~/store/ducks/HeatTransfer/selectors';
import HeatTransferService from '~/store/ducks/HeatTransfer/services';
import { storeHeatTransfer, storeHeatTransferDimensions } from '~/store/ducks/Room/actions';
import { getRoomState } from '~/store/ducks/Room/selectors';
import { RoomActionTypes, RoomStatus } from '~/store/ducks/Room/types';

export function* updateHeatTransferState(): any {
  const { status } = yield select(getRoomState);
  if (status === RoomStatus.UPDATING) return;

  const state = yield select(getHeatTransferState);
  const newState = HeatTransferService.getSuggestion({ ...state });
  yield put(storeHeatTransfer(newState));
}

export function* updateHeatTransferDimensions(): any {
  const state = yield select(getHeatTransferState);
  const newState = HeatTransferService.insertDimensions({ ...state });
  yield put(storeHeatTransferDimensions(newState));
}

export default all([
  takeLatest(
    [RoomActionTypes.STORE_HEAT_TRANSFER, RoomActionTypes.STORE_INTERNAL_DIMENSION],
    updateHeatTransferDimensions
  ),
  takeLatest([RoomActionTypes.STORE_ROOM_INFO], updateHeatTransferState),
]);
