import React from 'react';

import {
  Text,
  SimpleGrid,
  IconButton,
  ButtonGroup,
  useEditableControls,
  Flex,
  Spacer,
  Editable,
  EditablePreview,
  EditableInput,
  HStack,
  VStack,
} from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { MdCheck, MdClose, MdEdit } from 'react-icons/md';
import { Container } from './HeatLoad_Styles';
import { ApplicationState } from '~/store/store';
import HeatLoadService from '~/store/ducks/HeatLoad/services';
import Stat from '~/components/Form/Stat';
import { colors } from '~/styles/colors';
import { storeSafetyMargin, storeTotalKcalh } from '~/store/ducks/Room/actions';

const HeatLoad: React.FC = () => {
  const dispatch = useDispatch();
  const { heatLoad, safetyMargin, calculationType } = useSelector((state: ApplicationState) => state.room.data);
  if (calculationType !== 1) {
    heatLoad.additionalLoad = 0;
    heatLoad.heatTransfer = 0;
    heatLoad.doors = 0;
    heatLoad.goods = 0;
  }
  const { heatTransferPercentage, goodPercentage, additionalLoadPercentage, doorsPercentage } =
    HeatLoadService.calcPercentages(heatLoad);

  const {
    formattedHeatTransfer,
    formattedDoors,
    formattedGoods,
    formattedAdditionalLoad,
    formattedTotalKw,
    formattedTotalKcalh,
  } = HeatLoadService.formatValues(heatLoad);

  const EditableControls = () => {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" align="center" size="xs">
        <IconButton
          variant="outline"
          aria-label="Confirm Edit"
          size="xs"
          ml="20px"
          icon={<MdCheck color={colors.secondary} size={16} />}
          {...getSubmitButtonProps()}
        />
        <Spacer />
        <IconButton
          variant="outline"
          aria-label="Cancel Edit"
          size="xs"
          icon={<MdClose color={colors.secondary} size={16} />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          variant="outline"
          aria-label="Edit DT"
          size="xs"
          ml="20px"
          icon={<MdEdit color={colors.secondary} size={14} />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  };

  const handleSafetyMargin = (value: string): void => {
    if (safetyMargin === +value) return;
    dispatch(storeSafetyMargin(+value));
  };

  const handleTotalKcalh = (value: string): void => {
    if (heatLoad.totalKcalh === +value) return;
    dispatch(storeTotalKcalh(+value));
  };

  return (
    <Container className="elgin__box">
      <Text className="heat-load__title">CARGA TÉRMICA</Text>

      <SimpleGrid spacing={10} columns={[3]}>
        <SimpleGrid spacing={5} columns={[1, 1, 2]}>
          <Stat label="Transmissão" value={heatTransferPercentage} sub={formattedHeatTransfer} field={null} hasSub />
          <Stat label="Infiltração" value={doorsPercentage} sub={formattedDoors} field={null} hasSub />
        </SimpleGrid>

        <SimpleGrid spacing={5} columns={[1, 1, 2]}>
          <Stat label="Produtos" value={goodPercentage} sub={formattedGoods} field={null} hasSub />
          <Stat
            label="Outras Cargas"
            value={additionalLoadPercentage}
            sub={formattedAdditionalLoad}
            field={null}
            hasSub
          />
        </SimpleGrid>

        <SimpleGrid spacing={5} columns={[1, 1, 2]}>
          <Stat label="Total (KW)" value={formattedTotalKw} field={null} hasSub={false} />
          {/* <Stat label="Total (Kcal/h)" value={formattedTotalKcal} field={null} hasSub={false} /> */}
          {calculationType === 1 && (
            <Stat label="Total (Kcal/h)" value={formattedTotalKcalh} field={null} hasSub={false} />
          )}
          {calculationType !== 1 && (
            <>
              <VStack mt={1}>
                <Text className="stat-label" fontSize="sm">
                  Total (Kcal/h)
                </Text>
                <Editable
                  className="safety-margin"
                  fontSize="xs"
                  defaultValue={heatLoad.totalKcalh !== undefined ? String(heatLoad.totalKcalh) : '0'}
                  textAlign="center"
                  onSubmit={handleTotalKcalh}
                  isPreviewFocusable={false}
                >
                  <EditablePreview className="safety-margin__preview" />
                  <EditableInput boxSize="10" />
                  <EditableControls />
                </Editable>
              </VStack>
            </>
          )}
        </SimpleGrid>
      </SimpleGrid>
      {calculationType === 1 && (
        <>
          <VStack align="flex-end" mt={5}>
            <Text className="heat-load__subtitle">Margem de Segurança (%)</Text>
            <HStack>
              <Editable
                className="safety-margin"
                defaultValue={safetyMargin !== undefined ? String(safetyMargin) : '0'}
                textAlign="center"
                onSubmit={handleSafetyMargin}
                isPreviewFocusable={false}
              >
                <EditablePreview className="safety-margin__preview" />
                <EditableInput boxSize="15" />
                <EditableControls />
              </Editable>
            </HStack>
          </VStack>
        </>
      )}
    </Container>
  );
};

export default HeatLoad;
