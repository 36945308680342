import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fade, Heading, HStack, Skeleton, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import HeatLoad from '~/components/Checklist/HeatLoad';
import FormRoomWrapper from '~/components/Checklist/FormRoomWrapper';
import SliderRoom from '~/components/Checklist/SliderRoom';
import { fetchProjectById, fetchProjectRooms } from '~/store/ducks/Project/actions';
import { ApplicationState } from '~/store/store';
import { colors } from '~/styles/colors';
import ProjectRouter from '~/components/ProjectRouter';
import RoomCopy from '~/components/Checklist/RoomCopy';
import RoomCalculation from '~/components/Checklist/RoomCalculation';
import PrintPage from '~/components/PrintPage';

const RoomWrapperSkeleton = () => (
  <Stack mt={10}>
    <Skeleton height="200px" />
    <Box mt={10} />
    <Box mt={10} />
    <Box mt={10} />
    <Box mt={10} />
    <Skeleton height="75px" />
    <Skeleton height="75px" />
    <Skeleton height="75px" />
    <Skeleton height="75px" />
    <Skeleton height="75px" />
    <Skeleton height="75px" />
  </Stack>
);

const Checklist: React.FC = () => {
  const params: any = useParams();
  const projectId = params.id;

  const project = useSelector((state: ApplicationState) => state.project);
  const room = useSelector((state: ApplicationState) => state.room);
  const { loading } = useSelector((state: ApplicationState) => state.room);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjectById(projectId));
    dispatch(fetchProjectRooms(projectId));
  }, []);

  return (
    <>
      <Box minHeight="16px" mb={8}>
        <Heading as="h2" size="xs" fontWeight={500} color={colors.gray100}>
          {project.name}
        </Heading>
      </Box>
      <ProjectRouter
        previousLink="/projects"
        previusText="Projetos"
        nextText="Seleção de Equipamentos"
        nextLink={`/projects/${projectId}/equipments`}
      />
      <SliderRoom projectId={projectId} rooms={project.rooms} />
      {loading && <RoomWrapperSkeleton />}
      {room.data && (
        <Fade in={!loading} unmountOnExit>
          <HStack justify="end" align="end" mt={5}>
            {/* <PrintPage /> */}
            <RoomCopy />
            <RoomCalculation />
          </HStack>
          <HeatLoad />
          <FormRoomWrapper />
        </Fade>
      )}
    </>
  );
};

export default Checklist;
