/**
 * Action types
 */
export enum InternalDimensionActionTypes {
  STORE_DATA = '@internalDimension/STORE_DATA',
}

/**
 * Data types
 */
export interface InternalDimension {
  length: number;
  width: number;
  height: number;
}

/**
 * State type
 */
export interface InternalDimensionState extends InternalDimension {}
