/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';

import { Form, Field, Formik } from 'formik';
import {
  Box,
  Text,
  Stack,
  GridItem,
  SimpleGrid,
  VStack,
  Flex,
  Spacer,
  Button as ChakraButton,
  Divider,
  Spinner,
} from '@chakra-ui/react';

import { MdDeleteForever } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './FormRoomGoods_Styles';
import { Button } from '~/components/Form/Button/Button_Styles';
import Input from '~/components/Form/Input';
import GoodsService from '~/store/ducks/Goods/services';
import { storeGoods } from '~/store/ducks/Goods/actions';
import Yup from '~/helpers/validations';
import Warning from '~/components/Form/Warning';
import { GoodsItem, GoodsType } from '~/store/ducks/Goods/types';
import { ApplicationState } from '~/store/store';
import SelectGivenOptions from '~/components/Form/Select/SelectGivenOptions';
import { toastError } from '~/helpers/toast';
import { colors } from '~/styles/colors';
import { RoomStatus } from '~/store/ducks/Room/types';
import { parseValue2Decimal } from '~/helpers/functions';

const itemSchema = Yup.object().shape({
  goods: Yup.object().shape({
    id: Yup.number().required(),
  }),
  storageCapacity: Yup.number().required(),
  dailyMovement: Yup.number().required(),
  palletCapacity: Yup.number().required(),
  freezingPoint: Yup.number().nullable().required(),
  specificHeatAboveFreezing: Yup.number().nullable().required(),
  specificHeatBelowFreezing: Yup.number().nullable().required(),
  latentHeatOfFusion: Yup.number().nullable().required(),
  heatOfRespiration: Yup.number().nullable().required(),
  respirationHeatLoad: Yup.number()
    .nullable()
    .when('heatOfRespiration', {
      is: (heatOfRespiration) => heatOfRespiration !== 0,
      then: Yup.number().nullable().required(),
    }),
  checkinTemperature: Yup.number().required(),
  checkoutTemperature: Yup.number().required(),
  processingTime: Yup.number().required(),
});

const schema = Yup.object().shape({
  items: Yup.array(itemSchema),
});

type ProductGroupType = {
  id: number;
  name: string;
};

type ProductItemType = {
  id: number;
  name: string;
  freezingPoint: number;
  specificHeatAboveFreezing: number;
  specificHeatBelowFreezing: number;
  latentHeatOfFusion: number;
  respirationHeatLoad: number;
  heatOfRespiration: number;
  group: { id: number };
};

type FormGoodsProps = {
  index: number;
  values: any;
  setValues: any;
  handleItemRemoval: any;
  handleChange: any;
  submitForm: any;
  productGroupOptions: ProductGroupType[];
};

const FormGoodsItem = ({
  values,
  setValues,
  handleChange,
  handleItemRemoval,
  index,
  submitForm,
  productGroupOptions,
}: FormGoodsProps) => {
  const [productGroup, setProductGroup] = useState<ProductGroupType>(null);
  const [productItem, setProductItem] = useState<ProductItemType>(null);
  const [productsList, setProductsList] = useState([]);
  const firstRender = useRef(true);
  const [loading, setLoading] = useState(false);

  const getProductsByGroup = async (id: number, callback) => {
    try {
      setLoading(true);
      const response = await GoodsService.getGroupGoods(id);
      setLoading(false);
      setProductsList(response.data);
      callback(response.data);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toastError('goods', 'Erro ao recuperar produtos');
    }
  };

  const handleGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGroupId = +event.target.value;
    if (!selectedGroupId) return;
    const group: ProductGroupType = productGroupOptions.find((item: ProductGroupType) => item.id === selectedGroupId);
    setProductGroup(group);
    getProductsByGroup(selectedGroupId, (prods: ProductItemType[]) => setProductsList(prods));
  };

  const handleItemChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = +event.target.value;
    const product: ProductItemType = productsList.find((item: ProductItemType) => item.id === selectedItem);
    setProductItem(product);
  };

  const checkFieldValue = (storedValue, newValue, isFirstRender: boolean) => {
    if (storedValue && isFirstRender) return parseValue2Decimal(storedValue);
    if (newValue) return parseValue2Decimal(newValue);
    return 0;
  };

  const setFieldValues = (product: ProductItemType, isFirstRender: boolean): void => {
    setValues(
      {
        ...values,
        items: values.items.map((good: GoodsItem, i: number) =>
          i === index
            ? {
                ...good,
                goods: { ...good.goods, id: product.id },
                respirationHeatLoad: checkFieldValue(good.respirationHeatLoad, good.storageCapacity, isFirstRender),
                freezingPoint: checkFieldValue(good.freezingPoint, product.freezingPoint, isFirstRender),
                specificHeatAboveFreezing: checkFieldValue(
                  good.specificHeatAboveFreezing,
                  product.specificHeatAboveFreezing,
                  isFirstRender
                ),
                specificHeatBelowFreezing: checkFieldValue(
                  good.specificHeatBelowFreezing,
                  product.specificHeatBelowFreezing,
                  isFirstRender
                ),
                latentHeatOfFusion: checkFieldValue(good.latentHeatOfFusion, product.latentHeatOfFusion, isFirstRender),
                heatOfRespiration: checkFieldValue(good.heatOfRespiration, product.heatOfRespiration, isFirstRender),
              }
            : good
        ),
      },
      true
    );
  };

  useEffect(() => {
    if (values.items.length === 0 || !productItem) return;
    setFieldValues(productItem, false);
  }, [productItem]);

  useEffect(() => {
    if (!firstRender.current) return;

    firstRender.current = false;
    let group: ProductGroupType = null;
    let product: ProductItemType = null;

    if (values.items[index]?.goods) {
      const savedGroupId = values.items[index]?.goods.group?.id;
      const savedProductId = values.items[index]?.goods.id;

      getProductsByGroup(savedGroupId, (prods: ProductItemType[]) => {
        group = prods.find((item: ProductItemType) => item.id === savedGroupId);
        product = prods.find((item: ProductItemType) => item.id === savedProductId);
        setProductGroup(group);
        setFieldValues(product, true);
      });
    } else {
      group = productGroupOptions.find((item: ProductGroupType) => item.name === GoodsType.MISCELLANEOUS);

      if (group) {
        getProductsByGroup(group.id, (prods: ProductItemType[]) => {
          product = prods.find((item: ProductItemType) => item.name === GoodsType.MISCELLANEOUS);
          setProductGroup(group);
          setProductItem(product);
        });
      }
    }

    return () => null;
  }, []);

  return values.items.length === 0 ? (
    <></>
  ) : (
    <Container>
      <Box direction={['row']} marginTop={30}>
        <Flex className="product-select" gap={5}>
          <Box>
            <Box className="product-select__group">
              <Text fontSize={14}>Grupo de Produtos</Text>
              <Field
                name={`items[${index}].goods.group.id`}
                value={values.items[index]?.goods.group?.id || productGroup?.id}
                component={SelectGivenOptions}
                onChange={(e) => {
                  handleChange(e);
                  handleGroupChange(e);
                }}
                options={productGroupOptions.map((group: ProductGroupType) => (
                  <option value={group.id} key={group.id}>
                    {group.name}
                  </option>
                ))}
              />
            </Box>
            <Box className="product-select__item">
              <Text fontSize={14}>Produto Armazenado</Text>
              <Field
                name={`items[${index}].goods.id`}
                value={values.items[index]?.goods.id || productItem?.id}
                component={SelectGivenOptions}
                disabled={loading}
                onChange={(e) => {
                  handleChange(e);
                  handleItemChange(e);
                }}
                options={
                  productsList &&
                  productsList.map((product: ProductItemType, i: number) => (
                    <option value={product.id} key={product.id}>
                      {product.name}
                    </option>
                  ))
                }
              />
              {loading && (
                <Spinner
                  className="product-select__item__spinner"
                  thickness="1px"
                  speed="0.6s"
                  emptyColor={colors.gray50}
                  color={colors.primary}
                  size="sm"
                />
              )}
            </Box>
          </Box>
          <Spacer />
          {index === values?.items.length - 1 && (
            <Box mr={0}>
              <ChakraButton className="button-remove" onClick={handleItemRemoval} leftIcon={<MdDeleteForever />}>
                <Text className="button-remove__text">Remover Produto</Text>
              </ChakraButton>
            </Box>
          )}
        </Flex>
        <SimpleGrid className="print-grid" columns={[1, 1, 1, 12]}>
          <GridItem colSpan={[1, 1, 1, 6]}>
            <Text className="title"> Dados de Operação </Text>
            <Stack direction={['column', 'row', 'row', 'column']} className="form__item">
              <Field
                name={`items[${index}].storageCapacity`}
                component={Input}
                type="number"
                label="Capacidade de Estocagem"
                unit="Kg"
                value={values.items[index]?.storageCapacity || null}
              />
              <Field
                name={`items[${index}].palletCapacity`}
                component={Input}
                type="number"
                label="Peso por Pallet ou Embalagem"
                unit="Kg"
                value={values.items[index]?.palletCapacity || null}
              />
              <Field
                name={`items[${index}].dailyMovement`}
                component={Input}
                type="number"
                label="Movimentação Diária"
                unit="Kg"
                value={values.items[index]?.dailyMovement || null}
              />
            </Stack>
            <Stack direction={['column', 'row', 'row', 'column']} className="form__item">
              <Field
                name={`items[${index}].checkinTemperature`}
                component={Input}
                type="number"
                label="Temperatura de Entrada"
                unit="ºC"
                value={values.items[index]?.checkinTemperature || null}
              />
              <Field
                name={`items[${index}].checkoutTemperature`}
                component={Input}
                type="number"
                label="Temperatura de Saída"
                unit="ºC"
                value={values.items[index]?.checkoutTemperature || null}
              />
            </Stack>

            <SimpleGrid className="print-grid" columns={[1, 2, 2, 1]}>
              <GridItem colSpan={[1, 1, 1, 1]} spacing={10}>
                <Stack direction={['column', 'row', 'row', 'column']} className="form__item">
                  <Field
                    name={`items[${index}].processingTime`}
                    component={Input}
                    type="number"
                    label="Tempo de Processo"
                    unit="h"
                    value={values.items[index]?.processingTime || null}
                  />
                </Stack>
              </GridItem>
            </SimpleGrid>
          </GridItem>

          <GridItem colStart={[1, 1, 1, 9]} colEnd={[1, 1, 1, 13]} spacing={10}>
            <Text className="title"> Características do Produto </Text>
            <Stack direction={['column', 'row', 'row', 'column']} className="form__item">
              <Field
                name={`items[${index}].freezingPoint`}
                component={Input}
                type="number"
                label="Temperatura de Congelamento"
                unit="ºC"
                value={values.items[index]?.freezingPoint || null}
              />
              <Field
                name={`items[${index}].specificHeatAboveFreezing`}
                component={Input}
                type="number"
                label="Calor Esp. Antes do Congelamento"
                unit="kJ/kg x °K"
                value={values.items[index]?.specificHeatAboveFreezing || null}
              />
            </Stack>
            <Stack direction={['column', 'row', 'row', 'column']} className="form__item">
              <Field
                name={`items[${index}].specificHeatBelowFreezing`}
                component={Input}
                type="number"
                label="Calor Esp. Depois do Congelamento"
                unit="kJ/kg x °K"
                value={values.items[index]?.specificHeatBelowFreezing || null}
              />
              <Field
                name={`items[${index}].latentHeatOfFusion`}
                component={Input}
                type="number"
                label="Calor Latente"
                unit="kJ/kg"
                value={values.items[index]?.latentHeatOfFusion || null}
              />
            </Stack>
            <Stack direction={['column', 'row', 'row', 'column']} mt={0} className="form__item">
              <Field
                name={`items[${index}].respirationHeatLoad`}
                component={Input}
                type="number"
                label="Carga de Produto Respirando"
                unit="Kg"
                value={values.items[index]?.respirationHeatLoad || null}
              />
              <Field
                id="heatOfRespiration"
                name={`items[${index}].heatOfRespiration`}
                component={Input}
                type="number"
                label="Calor de Respiração"
                unit="mW/Kg"
                value={values.items[index]?.heatOfRespiration || null}
              />
            </Stack>
          </GridItem>
        </SimpleGrid>
        <VStack direction={['column', 'column', 'row']} m={10}>
          <Button type="button" $default onClick={submitForm}>
            Salvar Produtos
          </Button>
        </VStack>
      </Box>
    </Container>
  );
};

const FormRoomGoods: React.FC = () => {
  const dispatch = useDispatch();
  const { status } = useSelector((state: ApplicationState) => state.room);
  const roomGoods = useSelector((state: ApplicationState) => state.room.data.goods);
  const [goods, setGoods] = useState({ items: roomGoods || [] });
  const [productGroupOptions, setProductGroupOptions] = useState([]);

  useEffect(() => {
    const getProductGroups = async () => {
      try {
        const response = await GoodsService.getGroups();
        setProductGroupOptions(response.data);
      } catch (err) {
        console.log(err);
        toastError('goods', 'Erro ao recuperar grupos de produtos');
      }
    };

    getProductGroups();

    return () => {
      setProductGroupOptions(null);
    };
  }, []);

  useEffect(() => {
    setGoods({ items: roomGoods || [] });
  }, [roomGoods]);

  const handleItemRemoval = () => {
    const newList = goods.items.slice(0, -1);
    const suggestedValues = GoodsService.getSuggestion({ items: newList }, true);
    const refreshedGoods = newList.map((good) => ({
      ...good,
      dailyMovement: suggestedValues.dailyMovement,
      storageCapacity: suggestedValues.storageCapacity,
    }));
    setGoods({ ...goods, items: [...refreshedGoods] });
    dispatch(storeGoods([...refreshedGoods]));
  };

  const setNewForm = () => {
    const suggestedValues = GoodsService.getSuggestion(goods);
    const refreshedGoods = goods.items.map((good) => ({
      ...good,
      dailyMovement: suggestedValues.dailyMovement,
      storageCapacity: suggestedValues.storageCapacity,
    }));
    setGoods({ ...goods, items: [...refreshedGoods, suggestedValues] });
  };

  const parseGoodsValues = (gds) => {
    const parsedGoodsItems = gds?.items?.map((good) => ({
      ...good,
      checkinTemperature: parseValue2Decimal(good.checkinTemperature),
      checkoutTemperature: parseValue2Decimal(good.checkoutTemperature),
      dailyMovement: parseValue2Decimal(good.dailyMovement),
      freezingPoint: parseValue2Decimal(good.freezingPoint),
      heatOfRespiration: parseValue2Decimal(good.heatOfRespiration),
      latentHeatOfFusion: parseValue2Decimal(good.latentHeatOfFusion),
      palletCapacity: parseValue2Decimal(good.palletCapacity),
      respirationHeatLoad: parseValue2Decimal(good.respirationHeatLoad),
      specificHeatAboveFreezing: parseValue2Decimal(good.specificHeatAboveFreezing),
      specificHeatBelowFreezing: parseValue2Decimal(good.specificHeatBelowFreezing),
      storageCapacity: parseValue2Decimal(good.storageCapacity),
    }));

    return { items: parsedGoodsItems };
  };

  return (
    <Container>
      <Formik
        initialValues={parseGoodsValues(goods)}
        validationSchema={schema}
        enableReinitialize
        validateOnChange
        validateOnBlur={false}
        onSubmit={async (values: any) => {
          const isFormValid = await schema.isValid(values);
          if (isFormValid) {
            dispatch(storeGoods(values.items));
          }
        }}
        validate={async (values) => {
          setGoods({ ...values });
        }}
      >
        {({ values, setValues, handleChange, submitForm }) => (
          <Form className="form">
            {productGroupOptions &&
              goods &&
              goods.items.map((_, index) => (
                <div key={String(index)}>
                  <FormGoodsItem
                    values={values}
                    index={index}
                    setValues={setValues}
                    handleItemRemoval={handleItemRemoval}
                    handleChange={handleChange}
                    submitForm={submitForm}
                    productGroupOptions={productGroupOptions}
                  />
                  <Divider />
                </div>
              ))}
            <VStack direction={['column', 'column', 'row']} mt={10}>
              <Button type="button" $primary onClick={() => setNewForm()}>
                Adicionar Novo Produto
              </Button>
            </VStack>
            {status === RoomStatus.CREATING && (
              <Warning message="As alterações nesta seção impactam as seções abaixo." />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FormRoomGoods;
