/**
 * Action types
 */
export enum GoodsActionTypes {
  STORE_DATA = '@goods/STORE_DATA',
  ADD_ITEM = '@goods/ADD_ITEM',
}

/**
 * Data types
 */

export interface GoodsItem {
  id?: number;
  room?: { id: number };
  goods: { id: number };
  palletCapacity: number;
  dailyMovement: number;
  storageCapacity: number;
  checkinTemperature: number;
  checkoutTemperature: number;
  processingTime: number;
  freezingPoint: number;
  specificHeatAboveFreezing: number;
  specificHeatBelowFreezing: number;
  latentHeatOfFusion: number;
  respirationHeatLoad: number;
  heatOfRespiration: number;
}

export const goodsItemInitialState = {
  goods: { id: null },
  palletCapacity: 0,
  dailyMovement: 0,
  storageCapacity: 0,
  checkinTemperature: 0,
  checkoutTemperature: 0,
  processingTime: 24,
  freezingPoint: 0,
  specificHeatAboveFreezing: 0,
  specificHeatBelowFreezing: 0,
  latentHeatOfFusion: 0,
  respirationHeatLoad: 0,
  heatOfRespiration: 0,
};

export enum GoodsType {
  MISCELLANEOUS = 'Miscelâneos',
}

export interface RoomGoods extends Array<GoodsItem> {}

/**
 * State type
 */
export interface GoodsState extends RoomGoods {}
