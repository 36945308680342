import { createStandaloneToast } from '@chakra-ui/react';
import { theme } from '~/styles/theme/chakra';

const toast = createStandaloneToast({ theme });

export const toastError = (toastId: string, message: string): void => {
  if (!toast.isActive(toastId)) {
    toast({
      description: message,
      status: 'warning',
      position: 'top-right',
      duration: 5000,
      isClosable: true,
    });
  }
};

export const toastSuccess = (toastId: string, message: string): void => {
  if (!toast.isActive(toastId)) {
    toast({
      description: message,
      status: 'success',
      position: 'top-right',
      duration: 5000,
      isClosable: true,
    });
  }
};
