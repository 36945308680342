import { Center, HStack, Text } from '@chakra-ui/react';
import React from 'react';

import { Container } from './Footer_Styles';

const Footer: React.FC = () => (
  <Container>
    <HStack>
      <Center>
        <Text />
      </Center>
    </HStack>
  </Container>
);

export default Footer;
