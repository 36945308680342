import { ProjectActionTypes } from './types';

export const fetchProjectRooms = (id: number, params?: string) => ({
  type: ProjectActionTypes.FETCH_ROOMS,
  payload: { id, params },
});

export const fetchProjectById = (id: number) => ({
  type: ProjectActionTypes.FETCH_PROJECT,
  payload: { id },
});

export const storeProjectData = (data: any) => ({
  type: ProjectActionTypes.STORE_DATA,
  payload: data,
});

export const storeProjectRooms = (data: any) => ({
  type: ProjectActionTypes.STORE_ROOMS,
  payload: data,
});

export const storeRoomIntoProject = (data: any) => ({
  type: ProjectActionTypes.STORE_ROOM_INTO_PROJECT,
  payload: data,
});

export const updateRoomIntoProject = (data: any) => ({
  type: ProjectActionTypes.UPDATE_ROOM_INTO_PROJECT,
  payload: data,
});

export const removeRoomFromProject = (id: number) => ({
  type: ProjectActionTypes.REMOVE_ROOM_FROM_PROJECT,
  payload: id,
});

export const projectRequestLoading = () => ({
  type: ProjectActionTypes.REQUEST_LOADING,
});

export const projectRequestSuccess = () => ({
  type: ProjectActionTypes.REQUEST_SUCCESS,
});

export const projectRequestFailure = () => ({
  type: ProjectActionTypes.REQUEST_FAILURE,
});

export const sliderRequestLoading = () => ({
  type: ProjectActionTypes.SLIDER_REQUEST_LOADING,
});

export const slidertRequestSuccess = () => ({
  type: ProjectActionTypes.SLIDER_REQUEST_SUCCESS,
});

export const slidertRequestFailure = () => ({
  type: ProjectActionTypes.SLIDER_REQUEST_FAILURE,
});

export const setProjectActiveRoom = (index: number) => ({
  type: ProjectActionTypes.SET_ACTIVE_ROOM,
  payload: { index },
});
