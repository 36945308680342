/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid, HStack, Skeleton } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { MdSearch } from 'react-icons/md';
import SelectAllGivenOptions from '~/components/Form/Select/SelectAllGivenOptions';
import { Button } from '~/components/Form/Button/Button_Styles';
import Yup from '~/helpers/validations';
import {
  CondensingUnitOptionals as CondensingUnitOptionalsType,
  EquipmentInterfaceTypes,
} from '~/store/ducks/Equipment/types';
import EquipmentService from '~/store/ducks/Equipment/services';
import { toastError } from '~/helpers/toast';
import { filterObjectEmptyValues } from '~/helpers/functions';

type OptionalsProps = {
  equipmentLine: string;
  handleSKUSearch: ({}, string) => void;
};

const CondensingUnitOptionals: React.FC<OptionalsProps> = ({ handleSKUSearch, equipmentLine }) => {
  const [optionals, setOptionals] = useState<CondensingUnitOptionalsType>(null);
  const [optionalsLoading, setOptionalsLoading] = useState(false);

  const initialValues = {
    circuitBreaker: null,
    contactor: null,
    insulationinTheSuctionLine: null,
    liquidFilter: null,
    liquidServiceValve: null,
    oilSeparator: null,
    overloadRelay: null,
    sequenceRelayAndPhaseOutage: null,
    suctionAccumulator: null,
    suctionFilter: null,
    suctionServiceValve: null,
    tank: null,
    tankInletValve: null,
    viewfinder: null,
    controller: null,
    elginSmart: null,
  };

  const schema = Yup.object().shape({
    circuitBreaker: Yup.string().nullable(),
    contactor: Yup.string().nullable(),
    insulationinTheSuctionLine: Yup.string().nullable(),
    liquidFilter: Yup.string().nullable(),
    liquidServiceValve: Yup.string().nullable(),
    oilSeparator: Yup.string().nullable(),
    overloadRelay: Yup.string().nullable(),
    sequenceRelayAndPhaseOutage: Yup.string().nullable(),
    suctionAccumulator: Yup.string().nullable(),
    suctionFilter: Yup.string().nullable(),
    suctionServiceValve: Yup.string().nullable(),
    tank: Yup.string().nullable(),
    tankInletValve: Yup.string().nullable(),
    viewfinder: Yup.string().nullable(),
    controller: Yup.string().nullable(),
    elginSmart: Yup.string().nullable(),
  });

  const getOptionals = async (line) => {
    try {
      setOptionalsLoading(true);
      const response = await EquipmentService.getOptionals('condensingUnits', line);
      setOptionals(response.data);
      setOptionalsLoading(false);
    } catch (err) {
      setOptionalsLoading(false);
      toastError('project', 'Erro ao recuperar opcionais.');
      console.error(err);
    }
  };

  useEffect(() => {
    console.log('equipmentLine => ', equipmentLine);
    getOptionals(equipmentLine || 'Todos');

    return () => setOptionals(null);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      validationSchema={schema}
      validateOnChange={false}
      onSubmit={(values) => {
        handleSKUSearch(filterObjectEmptyValues(values), EquipmentInterfaceTypes.CONDENSING_UNIT);
      }}
    >
      {({ values, submitForm }) => (
        <Skeleton isLoaded={!optionalsLoading}>
          <Form className="form">
            <SimpleGrid columns={[1, 2, 4]} spacing={[5]}>
              <Box>
                <Field
                  size="xs"
                  name="circuitBreaker"
                  component={SelectAllGivenOptions}
                  label="Disjuntor"
                  value={values.circuitBreaker || ''}
                  options={optionals?.circuitBreaker?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="contactor"
                  component={SelectAllGivenOptions}
                  label="Contator"
                  value={values.contactor || ''}
                  options={optionals?.contactor?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="insulationinTheSuctionLine"
                  component={SelectAllGivenOptions}
                  label="Isolamento na Linha de Sucção"
                  value={values.insulationinTheSuctionLine || ''}
                  options={optionals?.insulationinTheSuctionLine?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="liquidFilter"
                  component={SelectAllGivenOptions}
                  label="Filtro de Líquido"
                  value={values.liquidFilter || ''}
                  options={optionals?.liquidFilter?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
              </Box>
              <Box>
                <Field
                  size="xs"
                  name="liquidServiceValve"
                  component={SelectAllGivenOptions}
                  label="Válvula de Serviço Líquido"
                  value={values.liquidServiceValve || ''}
                  options={optionals?.liquidServiceValve?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="oilSeparator"
                  component={SelectAllGivenOptions}
                  label="Separador de Óleo"
                  value={values.oilSeparator || ''}
                  options={optionals?.oilSeparator?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="overloadRelay"
                  component={SelectAllGivenOptions}
                  label="Relé de Sobrecarga"
                  value={values.overloadRelay || ''}
                  options={optionals?.overloadRelay?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="viewfinder"
                  component={SelectAllGivenOptions}
                  label="Visor"
                  value={values.viewfinder || ''}
                  options={optionals?.viewfinder?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
              </Box>
              <Box>
                <Field
                  size="xs"
                  name="sequenceRelayAndPhaseOutage"
                  component={SelectAllGivenOptions}
                  label="Relé de Sequência e Falta de Fase"
                  value={values.sequenceRelayAndPhaseOutage || ''}
                  options={optionals?.sequenceRelayAndPhaseOutage?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="suctionAccumulator"
                  component={SelectAllGivenOptions}
                  label="Acumulador de Sucção"
                  value={values.suctionAccumulator || ''}
                  options={optionals?.suctionAccumulator?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="suctionFilter"
                  component={SelectAllGivenOptions}
                  label="Filtro de Sucção"
                  value={values.suctionFilter || ''}
                  options={optionals?.suctionFilter?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="elginSmart"
                  component={SelectAllGivenOptions}
                  label="Elgin Smart"
                  value={values.elginSmart || ''}
                  options={optionals?.elginSmart?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
              </Box>
              <Box>
                <Field
                  size="xs"
                  name="suctionServiceValve"
                  component={SelectAllGivenOptions}
                  label="Válvula de Serviço Sucção"
                  value={values.suctionServiceValve || ''}
                  options={optionals?.suctionServiceValve?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="tank"
                  component={SelectAllGivenOptions}
                  label="Tanque"
                  value={values.tank || ''}
                  options={optionals?.tank?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="tankInletValve"
                  component={SelectAllGivenOptions}
                  label="Válvula Entrada do Tanque"
                  value={values.tankInletValve || ''}
                  options={optionals?.tankInletValve?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
                <Field
                  size="xs"
                  name="controller"
                  component={SelectAllGivenOptions}
                  label="Controlador"
                  value={values.controller || ''}
                  options={optionals?.controller?.map((item: string) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={[1]} spacing={[5]} mb={10}>
              <HStack justify="flex-end" mt={4}>
                <Button leftIcon={<MdSearch size={18} />} type="button" $primary onClick={submitForm}>
                  Pesquisar
                </Button>
              </HStack>
            </SimpleGrid>
          </Form>
        </Skeleton>
      )}
    </Formik>
  );
};

export default CondensingUnitOptionals;
