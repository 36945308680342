import { all } from 'redux-saga/effects';
import heatTransfer from '~/store/ducks/HeatTransfer/sagas';
import additionalLoad from '~/store/ducks/AdditionalLoad/sagas';
import { room, roomUpdate } from '~/store/ducks/Room/sagas';
import project from '~/store/ducks/Project/sagas';
import roomInfo from '~/store/ducks/RoomInfo/sagas';
import internalDimension from '~/store/ducks/InternalDimension/sagas';
import goods from '~/store/ducks/Goods/sagas';
import proposal from '~/store/ducks/Proposal/sagas';
import auth from '~/store/ducks/Auth/sagas';
import equipment from '~/store/ducks/Equipment/sagas';

export default function* rootSaga() {
  yield all([
    room,
    roomUpdate,
    heatTransfer,
    additionalLoad,
    project,
    roomInfo,
    internalDimension,
    goods,
    proposal,
    auth,
    equipment,
  ]);
}
