import { Button as ChakraButton } from '@chakra-ui/react';
import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const Button = styled(ChakraButton)`
  border-radius: 25px !important;
  border: 1px solid;
  min-width: 50px !important;

  @media ${devices.mobileXL} {
    min-width: 100px !important;
  }

  // Theming Border
  ${(props) => props.$primary && `border-color: ${colors.secondary};`}
  ${(props) => props.$red && `border-color: ${colors.red};`}
  ${(props) => props.$default && `border-color: ${colors.gray100};`}

  // Theming Color
  ${(props) => props.$primary && `color: ${colors.secondary} !important;`}
  ${(props) => props.$red && `color: ${colors.red} !important;`}
  ${(props) => props.$default && `color: ${colors.gray100} !important;`}

  background-color: ${colors.transparent} !important;
  height: 30px !important;
  transition: all 0.1s ease;
  font-size: 14px !important;
  font-weight: 500 !important;

  &:hover,
  &:focus {
    // Theming Background
    ${(props) => props.$primary && `background-color: ${colors.secondary} !important;`}
    ${(props) => props.$red && `background-color: ${colors.red} !important;`}
    ${(props) => props.$default && `background-color: ${colors.gray50} !important;`}

    ${(props) => props.$primary && `color: ${colors.white} !important;`}
    ${(props) => props.$red && `color: ${colors.white} !important;`}
    ${(props) => props.$default && `color: ${colors.gray200} !important;`}
  }
`;
