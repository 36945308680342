import React, { useEffect, useState } from 'react';

import { Form, Field, Formik } from 'formik';
import { SimpleGrid, Box, Center, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '~/store/store';
import { storeInternalDimension } from '~/store/ducks/InternalDimension/actions';
import { calcArea, calcVolume, parseValue2Decimal } from '~/helpers/functions';

import Yup from '~/helpers/validations';
import Input from '~/components/Form/Input';
import Stat from '~/components/Form/Stat';
import Warning from '~/components/Form/Warning';
import { RoomStatus } from '~/store/ducks/Room/types';

const schema = Yup.object().shape({
  length: Yup.number().positive().required(),
  width: Yup.number().positive().required(),
  height: Yup.number().min(1).required(),
});

const FormInternalDimension: React.FC = () => {
  const dispatch = useDispatch();

  const { status } = useSelector((state: ApplicationState) => state.room);
  const length = useSelector((state: ApplicationState) => state.room.data.length);
  const width = useSelector((state: ApplicationState) => state.room.data.width);
  const height = useSelector((state: ApplicationState) => state.room.data.height);

  const [are, setArea] = useState(0);
  const [vol, setVolume] = useState(0);

  const initialValues = {
    length: parseValue2Decimal(length),
    width: parseValue2Decimal(width),
    height: parseValue2Decimal(height),
  };

  useEffect(() => {
    setArea(calcArea(length, width));
    setVolume(calcVolume(length, width, height));
  }, [length, width, height]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      enableReinitialize
      validateOnChange={false}
      validate={async (values) => {
        const isFormValid = await schema.isValid(values);
        if (isFormValid) {
          dispatch(storeInternalDimension(values));
        }
      }}
    >
      {({ values }) => (
        <Form className="form">
          <SimpleGrid className="print-grid" columns={[1, 1, 2]} spacing={10}>
            <Box>
              <Field
                name="length"
                component={Input}
                type="number"
                label="Comprimento"
                unit="metros"
                value={values.length}
              />
              <Field name="width" component={Input} type="number" label="Largura" unit="metros" value={values.width} />
              <Field name="height" component={Input} type="number" label="Altura" unit="metros" value={values.height} />
            </Box>
            <Center>
              <Stack direction={['row', 'row', 'column']} spacing={10}>
                <Stack spacing={0}>
                  <Stat label="Área (m²)" value={are} field={null} />
                </Stack>
                <Stack spacing={0}>
                  <Stat label="Volume (m³)" value={vol} field={null} />
                </Stack>
              </Stack>
            </Center>
          </SimpleGrid>
          {status === RoomStatus.CREATING && <Warning message="As alterações nesta seção impactam as seções abaixo." />}
        </Form>
      )}
    </Formik>
  );
};

export default FormInternalDimension;
