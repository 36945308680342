import React from 'react';

import { Form, Field, Formik } from 'formik';
import { SimpleGrid, Box, Stack, Text } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { Container } from './FormAdditionalLoad_Styles';
import Input from '~/components/Form/Input';
import { ApplicationState } from '~/store/store';
import { storeAdditionalLoad } from '~/store/ducks/Room/actions';
import Yup from '~/helpers/validations';
import { parseValue2Decimal } from '~/helpers/functions';

const schema = Yup.object().shape({
  staff: Yup.object().shape({
    count: Yup.number().required(),
    duration: Yup.number().required(),
  }),
  forklift: Yup.object().shape({
    count: Yup.number().required(),
    duration: Yup.number().required(),
  }),
  evaporator: Yup.object().shape({
    count: Yup.number().required(),
    duration: Yup.number().required(),
    power: Yup.number().required(),
  }),
  lighting: Yup.object().shape({
    power: Yup.number().required(),
    duration: Yup.number().required(),
  }),
  other: Yup.object().shape({
    power: Yup.number().required(),
    duration: Yup.number().required(),
  }),
});

const FormAdditionalLoad: React.FC = () => {
  const dispatch = useDispatch();
  const { staff, forklift, evaporator, lighting, other } = useSelector(
    (state: ApplicationState) => state.room.data.additionalLoad
  );

  const initialValues = {
    staff,
    forklift,
    evaporator,
    lighting,
    other,
  };

  const parseAdditionalLoadValues = (AdditionalLoad) => ({
    staff: {
      ...AdditionalLoad.staff,
      count: parseValue2Decimal(AdditionalLoad.staff.count),
    },
    forklift: {
      ...AdditionalLoad.forklift,
      count: parseValue2Decimal(AdditionalLoad.forklift.count),
    },
    evaporator: {
      ...AdditionalLoad.evaporator,
      count: parseValue2Decimal(AdditionalLoad.evaporator.count),
      power: parseValue2Decimal(AdditionalLoad.evaporator.power),
    },
    lighting: {
      ...AdditionalLoad.lighting,
      power: parseValue2Decimal(AdditionalLoad.lighting.power),
    },
    other: {
      ...AdditionalLoad.other,
      power: parseValue2Decimal(AdditionalLoad.other.power),
    },
  });

  return (
    <Container>
      <Formik
        initialValues={parseAdditionalLoadValues(initialValues)}
        validationSchema={schema}
        validateOnChange={false}
        enableReinitialize
        onSubmit={() => {}}
        validate={async (values) => {
          const isFormValid = await schema.isValid(values);
          if (isFormValid) {
            dispatch(storeAdditionalLoad(values));
          }
        }}
      >
        {({ values }) => (
          <Form className="form">
            <SimpleGrid className="print-grid" columns={[1, 2, 2]} spacing={10}>
              <Box>
                <Text className="title"> Pessoas </Text>
                <Stack direction={['column', 'row', 'column']} spacing={10}>
                  <Box w="100%">
                    <Field
                      name="staff.count"
                      component={Input}
                      type="number"
                      label="Quantidade"
                      values={values.staff.count}
                    />
                    <Field
                      name="staff.duration"
                      component={Input}
                      type="number"
                      label="Permanência"
                      unit="horas"
                      value={values.staff.duration}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Text className="title"> Empilhadeira </Text>
                <Stack direction={['column', 'row', 'column']} spacing={10}>
                  <Box w="100%">
                    <Field
                      name="forklift.count"
                      component={Input}
                      type="number"
                      label="Quantidade"
                      value={values.forklift.count}
                    />
                    <Field
                      name="forklift.duration"
                      component={Input}
                      type="number"
                      label="Permanência"
                      unit="horas"
                      value={values.forklift.duration}
                    />
                  </Box>
                </Stack>
              </Box>
            </SimpleGrid>

            <SimpleGrid className="print-grid" columns={[1, 2, 2]} spacing={10} mt={10}>
              <Box>
                <Text className="title"> Evaporador </Text>
                <Stack direction={['column', 'row', 'column']} spacing={10}>
                  <Box w="100%">
                    <Field
                      name="evaporator.count"
                      component={Input}
                      type="number"
                      label="Quantidade"
                      value={values.evaporator.count}
                    />
                    <Field
                      name="evaporator.power"
                      component={Input}
                      type="number"
                      label="Potência"
                      value={values.evaporator.power}
                      unit="kW"
                    />
                    <Field
                      name="evaporator.duration"
                      component={Input}
                      type="number"
                      label="Funcionamento"
                      value={values.evaporator.duration}
                      unit="horas"
                    />
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Text className="title"> Iluminação </Text>
                <Stack direction={['column', 'row', 'column']} spacing={10}>
                  <Box w="100%">
                    <Field
                      name="lighting.power"
                      component={Input}
                      type="number"
                      label="Potência (W/m²)"
                      value={values.lighting.power}
                    />
                    <Field
                      name="lighting.duration"
                      component={Input}
                      type="number"
                      label="Tempo ligada (h)"
                      value={values.lighting.duration}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Text className="title"> Cargas Diversas </Text>
                <Stack direction={['column', 'row', 'column']} spacing={10}>
                  <Box w="100%">
                    <Field
                      name="other.power"
                      component={Input}
                      type="number"
                      label="Potência (kW)"
                      value={values.other.power}
                    />
                    <Field
                      name="other.duration"
                      component={Input}
                      type="number"
                      label="Tempo ligada (h)"
                      value={values.other.duration}
                    />
                  </Box>
                </Stack>
              </Box>
            </SimpleGrid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default FormAdditionalLoad;
