/**
 * Action types
 */
export enum AuthTypes {
  LOGIN = '@auth/LOGIN',
  LOGOUT = '@auth/LOGOUT',
  REMOVE_TOKEN = '@auth/REMOVE_TOKEN',
  STORE_TOKEN = '@auth/STORE_TOKEN',
}

/**
 * Data types
 */

export interface Auth {
  accessToken: string;
  refreshToken?: string;
  idToken?: string;
  expiresIn: string;
}

/**
 * State type
 */
export interface AuthState extends Auth {}
