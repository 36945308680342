import {
  StatHelpText,
  StatLabel,
  StatNumber,
  VStack,
  Stat as ChakraStat,
  Input as ChakraInput,
} from '@chakra-ui/react';
import React from 'react';
import { FieldAttributes, useField } from 'formik';

import { Container } from './Stat_Styles';

type StatProps = {
  label: string;
  field: FieldAttributes<any>;
  value: number | string;
  sub?: number | string;
  hasSub?: boolean;
  size?: string;
};

const Stat: React.FC<StatProps> = ({ label, field, value, sub, hasSub, size }: StatProps) => (
  <Container className={size}>
    <VStack spacing={3} textAlign="center">
      <ChakraStat className="stat-wrapper">
        <StatLabel className="stat-label">{label}</StatLabel>
        <StatNumber className="stat-value">
          {value}
          {!!hasSub && '%'}
        </StatNumber>
        {hasSub && field === null && <StatHelpText className="stat-sub">{sub}</StatHelpText>}
        {hasSub && field !== null && (
          <ChakraInput size={size || 'sm'} className="formControl__input__unit" {...field} />
        )}
      </ChakraStat>
    </VStack>
  </Container>
);

export default Stat;
