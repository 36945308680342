import styled from 'styled-components';
import { devices } from '~/styles/breakpoints';
import { colors } from '~/styles/colors';

export const Container = styled.section`
  padding: 0 0;
  padding-left: 0;

  .slick-slider {
    width: 90%;
    margin: 0 auto;

    @media ${devices.tablet} {
      width: 95%;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${colors.gray200};
  }

  .item {
    height: 85px;
    display: flex !important;
    align-items: flex-start;
    justify-content: center;
    min-height: 0px;
    min-width: 0px;
    position: relative;

    @media ${devices.mobileL} {
      height: 120px;
    }

    @media ${devices.mobileXL} {
      height: 130px;
    }

    @media ${devices.tablet} {
      height: 140px;
    }

    .item__text {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 15px;

      @media ${devices.tablet} {
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .item__info {
      font-size: 12px;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
    }
  }

  .inner__item {
    position: relative;
    width: 100px;
    height: 80px;
    display: flex;
    margin: 0 5px;
    padding-bottom: 6px;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${colors.gray100};
    transition: border 0.2s ease;
    border-left: 2px solid ${colors.transparent};
    border-bottom: 2px solid ${colors.transparent};
    border-right: 2px solid ${colors.transparent};

    @media ${devices.mobileL} {
      width: 120px;
      height: 100px;
    }

    @media ${devices.mobileXL} {
      width: 130px;
      height: 110px;
    }

    @media ${devices.tablet} {
      width: 150px;
      height: 120px;
    }

    @media ${devices.laptop} {
      height: 130px;
    }

    &:hover,
    &:focus {
      border-color: ${colors.secondary};
    }

    &.inner__item--compatible {
      background: linear-gradient(90deg, ${colors.lightGreen} 0%, ${colors.green} 100%) no-repeat top;
      background-size: 100% 2px;
      border-radius: 4px;
      box-shadow: 0px 2px 10px -4px rgb(0 0 0 / 30%);

      &:hover,
      &:focus {
        border-color: ${colors.lightGreen};
      }
    }

    &.inner__item--not-compatible {
      background: linear-gradient(90deg, ${colors.lightRed} 0%, ${colors.red} 100%) no-repeat top;
      background-size: 100% 2px;
      border-radius: 4px;
      box-shadow: 0px 2px 10px -4px rgb(0 0 0 / 30%);

      &:hover,
      &:focus {
        border-color: ${colors.lightRed};
      }
    }

    .inner__item--badge {
      position: absolute;
      top: 7px;
      left: 5px;
      display: none;

      @media ${devices.tablet} {
        display: block;
      }
    }
  }

  .form {
    margin-top: 40px;

    .formControl {
      margin-bottom: 25px;
    }
  }

  .chakra-popover__popper {
    position: relative;

    .chakra-popover__popper__icon {
      color: ${colors.white};
      position: absolute;
      font-size: 40px;
      top: 80%;
      left: 95%;
      transform: translate(-50%, -50%);
    }
  }
`;
