import styled from 'styled-components';

export const SelectWrapper = styled.div`
  .formControl {
    margin-bottom: 25px;

    label {
      font-size: 14px;

      &.xs {
        font-size: 12px;
      }
    }

    select {
      border-radius: 4px;
    }
  }
`;
